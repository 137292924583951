import { BinDeleteIcon, ViewIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { TableInput } from '@/react/componentAssets/TableInput'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { css } from '@emotion/react'
import { CurrentUserType } from '@/react/types/documentation'
import { UISref } from '@/react/components/UISref'

export const GroupRow = ({ group, index, search, setRefresh, page }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [hoveredField, setHoveredField] = useState(null)

  const { Api, CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const { name } = group

  const { stateService } = useRouter()

  const handleDelete = async () => {
    await Api.delete(`categories/${group?.id}`, {})
    setRefresh(true)
  }

  const handleNameUpdate = async (newName: string) => {
    try {
      await Api.patch(`categories/${group?.id}`, {
        ...group,
        name: newName,
      })
    } catch {
      console.error('Failed to update the name')
    } finally {
      setRefresh(true)
    }
  }

  return (
    <tr
      className={classNames('', {
        dark: index % 2 === 0,
      })}
    >
      {group?.client ? (
        <>
          {!isEdit ? (
            <td
              className="title__td"
              onMouseOver={() => {
                setHoveredField({
                  field__name: 'name',
                  text: name,
                  max__length: 97,
                })
              }}
              onMouseOut={() => {
                setHoveredField(null)
              }}
              onClick={() => {
                setEdit(true)
              }}
            >
              <Highlighter
                highlightClassName={'highlighted__item'}
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={name}
              />
            </td>
          ) : (
            <TableInput
              defaultValue={name}
              handleClose={() => {
                setEdit(false)
              }}
              handleSubmit={handleNameUpdate}
            />
          )}
        </>
      ) : (
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name',
              text: name,
              max__length: 109,
            })
          }}
          style={{
            color: 'gray',
            cursor: 'default',
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <Highlighter
            highlightClassName={'highlighted__item'}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={name}
          />
        </td>
      )}

      <td className="icon__td">
        <UISref
          to={'app.questions.list'}
          params={{
            categoryId: group?.id,
            showBackButton: true,
            previousPageNumber: page,
            previousSearch: search,
          }}
        >
          <a>
            <ViewIcon color={variableColor} />
          </a>
        </UISref>
      </td>
      <td className="icon__td">
        {group?.client ? (
          <div>
            <BinDeleteIcon
              color={variableColor}
              additionalStyles={css({
                marginTop: '5px',
              })}
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
            {isDeleteModalOpen && (
              <ConfirmationModal
                action={'Delete'}
                title={name}
                handleClose={() => {
                  setDeleteModalOpen(false)
                }}
                handleClick={handleDelete}
              />
            )}
          </div>
        ) : (
          <BinDeleteIcon
            color={'gray'}
            additionalStyles={css({
              marginTop: '5px',
              cursor: 'default !important',
            })}
            onClick={() => {
              setDeleteModalOpen(true)
            }}
          />
        )}
      </td>
      {!isEdit && hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
