import { css } from '@emotion/react'
import { FC } from 'react'

interface BackButtonProps {
  color: string
  onClick: () => void
}

const BackButton: FC<BackButtonProps> = ({ color, onClick }) => {
  const baseStyle = getBaseStyle(color)

  return (
    <button onClick={onClick} css={baseStyle}>
      Back
    </button>
  )
}

const getBaseStyle = (color: string) =>
  css({
    border: `1px solid ${color}`,
    backgroundColor: 'white',
    color: color,
    fontSize: '12px',
    padding: '6px 25px 6px 25px',
  })

export default BackButton
