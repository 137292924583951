import {
  AdvancedFilter,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { singleHeaderTypes } from '../../components'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'
import { ViewRow } from './components/ViewRow'
import actionIcon from '@/assets/icons/action-icon.svg'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { AdvancedDateFilter } from '@/react/components/AdvancedDateFilter'
import { buildCombinedQueryString } from '@/utils/queryBuilder'
import useDateFilter from '@/react/hooks/useDateFilter'
import { CurrentUserType } from '@/react/types/documentation'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

export const MobileFormList = () => {
  const { stateService } = useRouter()

  const [search, setSearch] = useState(
    stateService.params.search ? stateService.params.search : '',
  )
  const [order, setOrder] = useState('-date_created')
  const [isAllChecked, setAllChecked] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isDeleteButtonClicked, setDeleteButtonClicked] = useState(false)
  // const [isExportPDFAvailable, setExportPDFAvailable] = useState(true)
  const [isDeleteAvailable, setDeleteAvailable] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isAnalyticsIconVisible, setAnalyticsIconVisible] = useState(false)
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [page, setPage] = useState(
    1 + (stateService.params.pageNumber ? stateService.params.pageNumber : 0),
  )
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const [advancedFilters, setAdvancedFiltersObj] = useState(
    getFilterStates(stateService),
  )
  const [formList, setFormList] = useState([])
  const [listCount, setListCount] = useState(20)
  const [isActionsAvailable, setActionsAvailble] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [filteredFormList, setFilteredFormList] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const application = stateService.params.app

  useEffect(() => {
    if (!isFilterApplied) {
      const getFormList = async () => {
        const params = {
          application: application,
          is_draft: false,
          non_empty: true,
          order: order,
          page: page,
          role: true,
          table: true,
          tz_offset: '-120',
        }

        if (search) params.search = search

        const queryString = buildCombinedQueryString(params, advancedFilters)

        const finalQueryString = `${queryString}&eq(deleted,false)`

        const { data } = await Api.get('reports', finalQueryString)

        if (isAllChecked) setSelectedItems(data?.results)

        setFormList(data?.results)
        setListCount(data?.count)
        if (refresh) setRefresh(false)
      }
      getFormList()
    }
  }, [order, page, advancedFilters, search, refresh, isFilterApplied])

  useEffect(() => {
    const role = CurrentUser.getRole()
    setAnalyticsIconVisible(role === 'client_admin')
    setDeleteAvailable(role !== 'client_user')
    setActionsAvailble(role !== 'supervisor' && role !== 'client_user')
  }, [])

  const setAdvancedFilters = (data) => {
    setAdvancedFiltersObj(data)
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }

  const { filteredData, filteredCount, fetchFilteredData } = useDateFilter({
    url: 'reports',
  })

  useEffect(() => {
    if (isFilterApplied && startDate && endDate) {
      fetchFilteredData({
        startDate,
        endDate,
        page,
        advancedFilters,
        apiVersion: 'v1',
        search,
        order,
        application,
        isDraft: false,
        tableParams: true,
        nonEmpty: true,
      }).catch((err) => console.error(err))
    }
  }, [
    application,
    startDate,
    endDate,
    fetchFilteredData,
    isFilterApplied,
    search,
    page,
    advancedFilters,
    order,
  ])

  useEffect(() => {
    if (isFilterApplied) {
      setFilteredFormList(filteredData)
    }
  }, [filteredData, isFilterApplied])

  const configurableNames = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const handleSelect = (item) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter((id) => id !== item.id))
    } else {
      setSelectedItems((prev) => [...prev, item.id])
    }
  }

  const handleSelectAll = () => {
    const formIds = formList.map((form) => form?.id)
    const filteredFormIds = filteredFormList.map((form) => form?.id)

    const listToUse = isFilterApplied ? filteredFormIds : formIds

    if (!isAllChecked) {
      setSelectedItems(listToUse)
      setAllChecked(true)
    } else {
      setSelectedItems([])
      setAllChecked(false)
    }
  }

  const handleDelete = async () => {
    if (isAllChecked) {
      setPage(1)
    }

    const idsToDelete = isAllChecked
      ? isFilterApplied
        ? filteredCount > 20
          ? 'all'
          : selectedItems.join(',')
        : listCount > 20
        ? 'all'
        : selectedItems.join(',')
      : selectedItems.join(',')

    const params = {
      ids: idsToDelete,
      search: search,
      non_empty: true,
      is_draft: false,
    }

    if (idsToDelete === 'all' && isFilterApplied) {
      params.start_date = startDate
      params.end_date = endDate
    }

    const queryString = buildCombinedQueryString(params, advancedFilters)

    try {
      await Api.delete('reports/bulk_delete', queryString)
    } catch {
      console.log('error')
    }

    if (isFilterApplied && startDate && endDate) {
      fetchFilteredData({
        startDate,
        endDate,
        page,
        advancedFilters,
        apiVersion: 'v1',
        search,
        order,
        application,
        isDraft: false,
        tableParams: true,
        nonEmpty: true,
      }).catch((err) => console.error(err))
    }

    setRefresh(true)
    setAllChecked(false)
    setSelectedItems([])
  }

  // const downloadFile = (fileUrl: string, fileName: string) => {
  //   const anchor = document.createElement('a')
  //   anchor.href = fileUrl
  //   anchor.download = fileName
  //   anchor.target = '_blank'
  //   anchor.click()
  // }

  // const handleExportPDF = async () => {
  //   setActionsClicked(false)
  //   if (selectedItems.length === 1) {
  //     try {
  //       const { data: createFileResponse } = await Api.get(
  //         `reports/${selectedItems[0]}/export_pdf`,
  //       )

  //       setTimeout(async () => {
  //         try {
  //           const { data: fileResponseURL } = await Api.getWithoutDeletedParam(
  //             `file_upload/${createFileResponse?.file_id}`,
  //             {},
  //           )

  //           downloadFile(fileResponseURL?.file, 'List of Toolboxes')
  //         } catch {
  //           Notification.warning('Failed to get file url for download')
  //         }
  //       }, createFileResponse.time * 1000)
  //     } catch {
  //       Notification.warning('Failed to get file url for download')
  //     }
  //   } else {
  //     try {
  //       const { data: createFileResponse } = await Api.post(
  //         'reports/export_pdf_multiple',
  //         {
  //           ids: selectedItems,
  //         },
  //       )

  //       setTimeout(async () => {
  //         try {
  //           const { data: fileResponseURL } = await Api.getWithoutDeletedParam(
  //             `file_upload/${createFileResponse?.file_id}`,
  //             {},
  //           )

  //           downloadFile(fileResponseURL?.file, 'List of Toolboxes')
  //         } catch {
  //           Notification.warning('Failed to get file url for download')
  //         }
  //       }, createFileResponse.time * 1000)
  //     } catch {
  //       Notification.warning('Failed to get file url for download')
  //     }
  //   }
  // }

  const resetPagination = () => {
    setPage(1)
  }

  const deleteCount = isAllChecked
    ? isFilterApplied
      ? filteredCount
      : listCount
    : selectedItems.length

  const deleteMessage = `${deleteCount} ${
    deleteCount > 1 ? 'Reports' : 'Report'
  }?`

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        paddingLeft: '12px',
        paddingRight: 0,
        minWidth: '50px',
        width: '50px',
        textAlign: 'left',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingBottom: '4px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
        '.header-actions': {
          display: 'flex',
          gap: '7px',
          position: 'relative',
        },
        '.modal__wrapper': {
          right: '-200px',
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleSelectAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isDeleteAvailable && selectedItems.length ? (
                <div
                  className="action__text"
                  onClick={() => {
                    setActionsClicked(false)
                    setDeleteButtonClicked(true)
                  }}
                >
                  Delete
                </div>
              ) : null}
              {/* {isExportPDFAvailable && (
                <div
                  className="action__text dark"
                  onClick={() => {
                    handleExportPDF()
                  }}
                >
                  Export PDF
                </div>
              )} */}
            </div>
          )}
          {isDeleteButtonClicked && selectedItems.length && (
            <ConfirmationModal
              handleClick={handleDelete}
              handleClose={() => {
                setDeleteButtonClicked(false)
              }}
              action={'Delete'}
              title={deleteMessage}
              questionMark={false}
            />
          )}
        </div>
      ),
    },
    {
      className: 'table__header title__column',
      name: 'Title',
      type: 'report__title',
      additionalStyles: {
        height: '30px',
        minWidth: '257px',
        width: '257px',
        maxWidth: '257px',
        textAlign: 'left',
        paddingLeft: '10px !important',
        padding: 0,
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'report_title'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={resetPagination}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Template',
      type: 'form__name',
      additionalStyles: {
        height: '30px',
        minWidth: '201px',
        width: '201px',
        maxWidth: '201px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'form_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={resetPagination}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Owner',
      type: 'user__first_name',
      additionalStyles: {
        height: '30px',
        minWidth: '109px',
        width: '109px',
        maxWidth: '109px',
        padding: 0,
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={resetPagination}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company_name',
      additionalStyles: {
        height: '30px',
        minWidth: '139px',
        width: '139px',
        maxWidth: '139px',
        padding: 0,
        textAlign: 'left',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'company_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={resetPagination}
        />
      ),
    },
    {
      className: 'table__header',
      name: configurableNames.projects_display,
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '140px',
        width: '140px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
          width: 'auto',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
        '.alert-button-opening': {
          marginLeft: '5px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'project_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={resetPagination}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Created',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '76px',
        width: '76px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedDateFilter
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setIsFilterApplied={setIsFilterApplied}
          setAllChecked={setAllChecked}
          setPage={setPage}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Copy',
      filterHeader: false,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '45px',
        width: '45px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'View',
      filterHeader: false,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '45px',
        width: '45px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Edit',
      filterHeader: false,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '55px',
        width: '55px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
    {
      className: 'table__header',
      name: 'Share',
      filterHeader: false,
      additionalStyles: {
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        minWidth: '55px',
        width: '55px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
      },
    },
  ]

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)
  const displayList = isFilterApplied ? filteredFormList : formList
  const effectiveListCount = isFilterApplied ? filteredCount : listCount

  return (
    <section css={baseStyle} className="forms_wrapper">
      <CommonPageHeader
        headerText={`${configurableNames.mobile_form} Reports`}
        isAnalyticsIconVisible={isAnalyticsIconVisible}
        iconLink="https://rtrspro.knowledgetransfer.us/#/workbooks/8/views"
      />
      <div className="mobile__wrapper">
        <MobileFormsNavBar />
        <div className="content__wrapper">
          <div className="search__block">
            <Search
              search={search}
              searchCallback={setSearch}
              pageCallback={setPage}
              updateUrl={false}
              onClear={() => {
                setAllChecked(false)
              }}
            />
          </div>
          <div className="table__wrapper">
            <table>
              <TableHeaders
                headers={
                  isActionsAvailable
                    ? TABLE_HEADER_SETTINGS
                    : TABLE_HEADER_SETTINGS?.slice(1)
                }
                callback={setOrder}
                popUpLocation={false}
                updateUrl={false}
                order={order}
                setOrder={setOrder}
              />
              <tbody>
                {displayList.length > 0 ? (
                  isFilterApplied ? (
                    filteredFormList.map((formData, index) => (
                      <ViewRow
                        key={index}
                        formData={formData}
                        index={index}
                        handleSelect={handleSelect}
                        selectedItems={selectedItems}
                        setRefresh={setRefresh}
                        page={page}
                        isActionsAvailable={isActionsAvailable}
                        search={search}
                      />
                    ))
                  ) : (
                    formList.map((formData, index) => (
                      <ViewRow
                        key={index}
                        formData={formData}
                        index={index}
                        handleSelect={handleSelect}
                        selectedItems={selectedItems}
                        setRefresh={setRefresh}
                        page={page}
                        isActionsAvailable={isActionsAvailable}
                        search={search}
                      />
                    ))
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={TABLE_HEADER_SETTINGS.length}
                      style={{ textAlign: 'center', padding: '20px' }}
                    >
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="page-switcher-base">
            <PageSwitcher
              pageLength={20}
              listCount={effectiveListCount}
              currentPage={page}
              callback={setPage}
              updateUrl={false}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const getBaseStyle = (variableColor: string, isSafari: boolean) =>
  css({
    paddingBottom: '300px',
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.mobile__wrapper': {
      marginLeft: '5px',
    },
    '.content__wrapper': {
      background: '#fff',
      paddingInline: '25px',
      width: 'fit-content',
    },
    '.search__block': {
      paddingTop: '20px',
      paddingBottom: '15px',
    },
    '#search': {
      width: '264px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.table__text': {
      maxHeight: '40px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: 0,
      textOverflow: 'ellipsis',
    },
    '.title': {
      maxWidth: '233px',
    },
    '.template': {
      maxWidth: '191px',
    },
    '.owner': {
      maxWidth: '99px',
    },
    '.company': {
      maxWidth: '129px',
    },
    '.project': {
      maxWidth: '140px',
    },
    '.view__row': {
      color: '#575757',
      position: 'relative',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.page-switcher-base': {
      paddingBottom: '10px',
    },
    '.checkmark__td': {
      paddingLeft: '12px',
    },
    '.title__td': {
      paddingLeft: '10px',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.template__fullname': {
      left: '300px',
    },
    '.owner__fullname': {
      left: '530px',
    },
    '.company__fullname': {
      left: '650px',
    },
    '.project__fullname': {
      left: '700px',
    },
    '.title__cell__wrapper': {
      display: 'flex',
      gap: '5px',
    },
    svg: {
      cursor: 'pointer',
    },
    '.icon__td': {
      textAlign: 'center',
      padding: 0,
      paddingTop: '4px',
      position: 'relative',
    },
    '.sucess__copy': {
      display: 'flex',
      gap: '4px',
      zIndex: 1,
      background: ' #FFF',
      paddingInline: '9px',
      paddingTop: '5px',
      paddingBottom: '5px',
      width: '153px',
      position: 'absolute',
      top: '7px',
      right: '45px',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.25)',
    },
    '.copy__message': {
      fontSize: '10px',
      margin: 0,
      fontWeight: 600,
      color: '#68696D',
    },
    '.action': {
      marginTop: '2px',
      height: '17px',
      width: '17px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '@media(max-width: 1400px)': {
      '.content__wrapper': {
        width: '100%',
      },
      '.full__name': {
        display: 'none',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
        minHeight: '600px',
      },
    },
    '.alert-block': {
      right: '-90px !important',
    },
  })

function getFilterStates(stateService) {
  return {
    report_title: JSON.parse(stateService.params.report_title || '{}'),
    form_name: JSON.parse(stateService.params.form_name || '{}'),
    full_name: JSON.parse(stateService.params.full_name || '{}'),
    company_name: JSON.parse(stateService.params.company_name || '{}'),
    project_name: JSON.parse(stateService.params.project_name || '{}'),
    date_created: JSON.parse(stateService.params.date_created || '{}'),
  }
}
