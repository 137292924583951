import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { NavBar } from '../LMSCommonComponents/NavBar'
import { AssignSwitch } from '../LMSCommonComponents/AssignSwitch'
import { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/react'
import { TrainingRow } from './components/TrainingRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { CurrentUserType } from '@/react/types/documentation'
import { useStateServiceParams } from '@/react/hooks/useUrlParams'

export const TrainingAssign = () => {
  const [order, setOrder] = useState('name')
  const [count, setCount] = useState(20)
  const [trainings, setTrainings] = useState([])
  const { Api, CurrentUser } = useAngularServices()
  const { page, setPage, search, setSearch, refresh, setRefresh } =
    useStateServiceParams()

  const prevRequestParams = useRef({ order, page, search, refresh })

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'project',
      name: 'Training',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '16px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'templates',
      name: 'Employees',
      type: 'templates',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'left',
        width: '10%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
  ]

  useEffect(() => {
    const paramsChanged =
      prevRequestParams.current.order !== order ||
      prevRequestParams.current.page !== page ||
      prevRequestParams.current.search !== search ||
      prevRequestParams.current.refresh !== refresh

    if (!paramsChanged && !refresh) {
      return
    }

    const getTrainingList = async () => {
      prevRequestParams.current = { order, page, search, refresh }

      const { data } = await Api.get('assign_trainings', {
        is_active: true,
        order,
        search,
        page,
        page_size: 20,
      })

      setTrainings(data.results)
      setCount(data.count)
      setRefresh(false)
    }

    getTrainingList()
  }, [order, page, search, refresh])

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText="Learning Management System" />
      <NavBar />
      <main className="projects__wrapper">
        <div className="top__wrapper">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
          <AssignSwitch />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {trainings.map((training, index) => (
            <TrainingRow
              key={training?.id}
              training={training}
              index={index}
              search={search}
              page={page}
            />
          ))}
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </section>
  )
}

function getBaseStyle(variableColor: string) {
  return css({
    paddingBottom: '300px',
    '.projects__wrapper': {
      background: '#fff',
      paddingLeft: '23px',
      width: '834px',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    table: {
      width: '783px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      paddingLeft: '20px',
    },
    '.training__row': {
      height: '40px',
      position: 'relative',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.projects__count': {
      margin: 0,
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.name__cell': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.full__name': {
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '@media(max-width: 1050px)': {
      '.top__wrapper': {
        flexDirection: 'column-reverse',
        gap: '17px',
      },
      '#search': {
        width: '264px',
      },
      '.projects__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__cell': {
        maxWidth: '204px',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
}
