import {
  CommonPageHeader,
  FloatingWindowHorizontalNavigation,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { getAdminNavigationData } from '@screens/Admin/utils/commonVariables'
import { DataWrap, PaginatedGet } from '@/react/types'
import { adminUser } from '@screens/Admin/utils/commomTypes'
import { AdminTableRow } from '@screens/Admin/AdminTable/components/AdminTableRow'
import { CurrentUserType } from '@/react/types/documentation'

export const AdminTable = () => {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('username')
  const [page, setPage] = useState(1)
  const [userList, setUserList] = useState<adminUser[]>([])
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)

  const { Api } = useAngularServices()

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'user',
      name: 'User',
      type: 'username',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
          paddingLeft: 10,
        },
      },
    },
    {
      className: 'full_name',
      name: 'Full name',
      type: 'first_name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'email',
      name: 'Email',
      type: 'email',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'active',
      name: 'Active',
      type: 'is_active',
      filterHeader: true,
      additionalStyles: {
        minWidth: 30,
      },
    },
    {
      className: 'delete',
      name: 'Delete',
      type: 'delete',
      filterHeader: false,
      additionalStyles: {
        minWidth: 30,
      },
    },
  ]

  useEffect(() => {
    const getTemplates = async () => {
      const params = {
        page,
        order,
      }

      if (search) params.search = search

      const { data }: DataWrap<PaginatedGet<adminUser[]>> = await Api.get(
        'users/admins',
        params,
      )
      setUserList(data?.results)
      setCount(data?.count)
    }
    getTemplates()
  }, [page, search, order, refresh])

  const handleRefresh = () => {
    setRefresh((prev) => !prev)
  }

  return (
    <div className="manage__assessments" css={baseStyle}>
      <CommonPageHeader headerText={'Accounts'} />
      <FloatingWindowHorizontalNavigation
        navSettings={getAdminNavigationData('manage_superadmin')}
      />
      <section className="manage__section">
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={setPage}
          />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <tbody>
            {userList.map((user, idx) => {
              return (
                user.role === 'admin' && (
                  <AdminTableRow
                    user={user}
                    variableColor={variableColor}
                    idx={idx}
                    search={search}
                    onDelete={handleRefresh}
                  />
                )
              )
            })}
          </tbody>
        </table>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

const baseStyle = css({
  paddingBottom: '200px',
  table: {
    width: '100%',
  },
  '.manage__section': {
    background: '#fff',
    paddingInline: '20px',
    paddingBottom: '10px',
    width: '925px',
  },
  '.search__wrapper': {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '#search': {
    width: '264px',
  },
  '.switch__wrapper': {
    paddingBottom: '1px',
  },
  thead: {
    borderBottom: '2px solid rgb(198, 198, 198)',
  },
  tbody: {
    tr: {
      height: 40,
      borderBottom: '1px solid rgb(231, 234, 236)',
      '.username__td': {
        paddingLeft: 15,
      },
      td: {
        paddingLeft: 5,
      },
    },
  },
  '.icon__td': {
    cursor: 'pointer',
    textAlign: 'center',
  },
  '.active__td': {
    paddingTop: 8,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  '@media(max-width: 1150px)': {
    '.manage__section': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
})
