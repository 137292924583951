import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import templateOne from '@/assets/observation-preview/template-1.png'
import templateTwo from '@/assets/observation-preview/template-2.png'
import templateThree from '@/assets/observation-preview/template-3.png'
import templateFour from '@/assets/observation-preview/template-4.png'
import templateFive from '@/assets/observation-preview/template-5.png'
import templateSix from '@/assets/observation-preview/template-6.png'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { useRouter } from '@/react/hooks'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import { CurrentUserType } from '@/react/types/documentation'
import Checkbox from '@/react/components/common/Checkbox'
import { useFetchObservations } from '../ViewTemplates/hooks'

interface Templates {
  id: number
  image: string
}

export const ObservationSettings = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [baseObservationData, processedObservationData] = useFetchObservations()
  const [title, setTitle] = useState('')
  const [isActive, setActive] = useState(false)
  const [isRequired, setRequired] = useState(false)
  const [isNAQuestionVisible, setNAQuestionVisible] = useState(false)
  const [isNACategoriesVisible, setNACategoriesVisible] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [templateId, setTemplateId] = useState(0)
  const [selectedTemplateId, setSelectedTemplateId] = useState(1)
  const [previousNACategoriesValue, setPreviousNACategoriesValue] =
    useState<boolean>(true)

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const { stateService } = useRouter()

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  useEffect(() => {
    const getTemplateData = async () => {
      const { data } = await Api.get('report_settings', {
        deleted: false,
      })

      const templateData = data?.results[0]

      setSelectedTemplateId(templateData?.theme)
      setNAQuestionVisible(templateData?.view_na_questions ?? true)
      setNACategoriesVisible(templateData?.view_na_categories ?? true)
      setPreviousNACategoriesValue(templateData?.view_na_categories ?? true)      
      setActive(templateData?.wildcard_active)
      setTitle(templateData?.wildcard_display)
      setRequired(templateData?.wildcard_required)
      setTemplateId(templateData?.id)
    }
    getTemplateData()
  }, [])

  const templates: Templates[] = [
    {
      id: 1,
      image: templateOne,
    },
    {
      id: 2,
      image: templateTwo,
    },
    {
      id: 3,
      image: templateThree,
    },
    {
      id: 4,
      image: templateFour,
    },
    {
      id: 5,
      image: templateFive,
    },
    {
      id: 6,
      image: templateSix,
    },
  ]

  const handleSettingsChange = async () => {
    const clientId = CurrentUser.getClientId()

    await Api.patch(`report_settings/${templateId}`, {
      id: templateId,
      wildcard_display: title,
      view_na_questions: isNAQuestionVisible,
      view_na_categories: isNACategoriesVisible,
      wildcard_required: isRequired,
      wildcard_active: isActive,
      client: clientId,
      theme: selectedTemplateId,
    })
  }

  const handleTogglePopup = () => {
    setModalOpen((prev) => !prev)
  }

  return (
    <section css={styles}>
      <CommonPageHeader
        headerText={configurableNamesList?.observations_display}
      />
      <ObservationNavBar />

      <div className="setting__wrapper">
        <div className="top__header">
          <h3 className="header__text">Wildcard Settings</h3>
          <ButtonElement
            buttonType="submit"
            text="Save"
            functionToTrigger={handleSettingsChange}
            disabled={
              (isRequired && !isActive) ||
              (isActive && (title?.length > 50 || !title))
            }
          />
        </div>
        <div className="input__block">
          <div className="input__wrapper">
            <input
              className={classNames('title__input', {
                error: isActive && (title?.length > 50 || !title),
              })}
              placeholder={`Enter Wildcard Title`}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
            <div className="info__block">
              <InfoIcon color={variableColor} handleClick={handleTogglePopup} />
              <p className="input__limit">{50 - title.length}</p>
              {isModalOpen ? (
                <InfoModal
                  message="Wildcard field is a customized field created by Administrators to capture additional data when completing a report"
                  setModalOpen={setModalOpen}
                  top="-55px"
                  right="-160px"
                />
              ) : null}
            </div>
          </div>
          <div className="checkmark__block">
            <p className="checkmark__title">Active</p>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isActive}
                onChange={() => setActive(!isActive)}
              />
              <span className="checkmark">
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
          </div>
          <div className="checkmark__block">
            <p className="checkmark__title">Required</p>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isRequired}
                onChange={() => setRequired(!isRequired)}
              />
              <span className="checkmark">
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
          </div>
        </div>
        <div className="report__wrapper">
          <p className="report__title">
            <strong className="bold__text">Report Settings: </strong>
            The actual colors in the final report templates may vary due to
            client properties.
          </p>
          <div className="checkmark__container">
            <Checkbox
              name="View Unanswered (NA) Questions on Final Report"
              checked={isNAQuestionVisible}
              onChange={() => {
                const newValue = !isNAQuestionVisible
                setNAQuestionVisible(newValue)

                if (!newValue) {
                  setPreviousNACategoriesValue(isNACategoriesVisible)
                  setNACategoriesVisible(false)
                } else {
                  setNACategoriesVisible(previousNACategoriesValue)
                }
              }}
              variableColor={variableColor}
            />
            {isNAQuestionVisible && (
              <Checkbox
                name="View Unanswered Categories on Final Report"
                checked={isNACategoriesVisible}
                onChange={() => setNACategoriesVisible(!isNACategoriesVisible)}
                variableColor={variableColor}
              />
            )}
          </div>
          <div className="reports__wrapper">
            {templates.map((template) => (
              <div className="single__report">
                <img
                  className="report__image"
                  src={template.image}
                  alt="template"
                  onClick={() => setSelectedTemplateId(template.id)}
                />
                <label className="checkbox-container report">
                  <input
                    type="checkbox"
                    checked={selectedTemplateId === template?.id}
                    onChange={() => {
                      setSelectedTemplateId(template.id)
                    }}
                  />
                  <span className="checkmark">
                    <ThinCheckmark color={variableColor} />
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const styles = css({
  paddingBottom: '200px',
  '.setting__wrapper': {
    background: '#fff',
    marginRight: '20px',
    paddingTop: '20px',
  },
  '.top__header': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingInline: '20px',
  },
  '.header__text': {
    fontSize: '14px',
  },
  '.bold__text': {
    fontWeight: 600,
  },
  '.input__wrapper': {
    display: 'flex',
    alignItems: 'flex-end',
  },
  '.input__block': {
    display: 'flex',
    gap: '15px',
    paddingBottom: '20px',
    borderBottom: '1px solid #EEEFF3',
    paddingInline: '20px',
  },
  '.title__input': {
    width: '410px',
    height: '32px',
    border: '1px solid #CDCCCC',
    outline: 'none',
    paddingLeft: '15px',
    '::placeholder': {
      color: '#B1B1B1',
      fontSize: '12px',
    },
  },
  '.error': {
    borderColor: '#BD2828',
  },
  '.info__block': {
    position: 'relative',
    marginLeft: '5px',

    svg: {
      cursor: 'pointer',
    },
  },
  '.input__limit': {
    color: '#888888',
    fontSize: '10px',
    margin: 0,
  },
  '.checkmark__container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  '.checkmark__block': {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  '.checkmark__title': {
    margin: 0,
    color: '#676A6C',
    fontSize: '14px',
    paddingLeft: '5px',
  },
  '.checkbox-container': {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    paddingLeft: '15px',
    margin: 0,
    height: '22px',
  },
  '.checkmark': {
    height: '22px',
    width: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E6E6E6',
    background: '#fff',
    svg: {
      display: 'none',
    },
  },
  '.checkmark::after': {
    display: 'none !important',
  },
  'input:checked ~ .checkmark': {
    img: {
      height: '22px',
      width: '22px',
      display: 'block',
      background: 'white',
    },
    svg: {
      height: '22px',
      width: '22px',
      display: 'block',
      background: 'white',
    },
  },
  '.reports__wrapper': {
    display: 'flex',
    gap: '40px',
    flexWrap: 'wrap',
    paddingTop: '20px',
  },
  '.report__wrapper': {
    paddingTop: '20px',
    paddingInline: '20px',
    paddingBottom: '20px',
  },
  '.report__title': {
    fontSize: '14px',
    marginBottom: '20px',
    color: '#696B6A',
  },
  '.report__image': {
    width: '351px',
    height: '471px',
    border: '1px solid #F0F1F5',
    cursor: 'pointer',
  },
  '.single__report': {
    position: 'relative',
  },
  '.report': {
    position: 'absolute',
    top: '7px',
    left: '8px',
  },
  'button:disabled': {
    background: '#888 !important',
    color: '#fff !important',
  },
})
