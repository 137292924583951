import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'
import { NavBar } from '../LMSCommonComponents/NavBar'
import sortArrows from '@/assets/icons/sort-arrows.svg'
import sortArrowsActiveFirst from '@/assets/icons/sort-arrows-active.svg'
import sortArrowsActiveSecond from '@/assets/icons/sort-arrows-inactive.svg'
import { TrainingRow } from './components/TrainingRow'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { CurrentUserType } from '@/react/types/documentation'

export const AssignTrainingList = () => {
  const { Api, CurrentUser } = useAngularServices()
  const [order, setOrder] = useState('name')
  const [employee, setEmployee] = useState({})
  const [trainingList, setTrainingList] = useState([])
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const { stateService } = useRouter()
  const [page, setPage] = useState(1)
  const [isAllChecked, setAllChecked] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)
  const [wholeIdsList, setWholeIdsList] = useState([])
  const [listToAssign, setListToAssign] = useState([])
  const [listToUnassign, setListToUnassign] = useState([])
  const [alreadyAssigned, setAlreadyAssigned] = useState([])
  const isEmpGroup = !stateService.params.user

  const handleCheckedAll = () => {
    if (!isAllChecked) {
      setAllChecked(true)
      setListToUnassign([])
      setListToAssign(wholeIdsList)
    } else {
      setAllChecked(false)
      setListToAssign([])
      setListToUnassign(wholeIdsList)
    }
  }

  const handleAssign = (training, isGroup: boolean) => {
    const condition = isGroup
      ? training?.trainings?.every((id) => !listToAssign.includes(id))
      : !listToAssign.includes(training.id)

    if (condition) {
      setListToAssign((prevList) =>
        !isGroup
          ? [...prevList, training?.id]
          : [...prevList, ...training?.trainings],
      )
      setListToUnassign(listToUnassign.filter((id) => id !== training?.id))
      setAllChecked(listToAssign.length === wholeIdsList.length)
    } else {
      setAllChecked(false)
      setListToAssign(
        listToAssign.filter((id) => {
          if (isGroup) {
            return !training?.trainings.includes(id)
          } else {
            return id !== training?.id
          }
        }),
      )
      setListToUnassign((prevList) =>
        !isGroup
          ? [...prevList, training?.id]
          : [...prevList, ...training?.trainings],
      )
    }
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'training',
      name: 'Training',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '18px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          display: 'flex',
          gap: '4px',
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '46px',
        minWidth: '46px',
        paddingLeft: 0,
        textAlign: 'left',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        p: {
          width: '0px',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '13px',
        },
      },
      additionalInnerElement: (
        <div className="select__wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            src={
              order === '-assigned'
                ? sortArrowsActiveSecond
                : order === 'assigned'
                  ? sortArrowsActiveFirst
                  : sortArrows
            }
            className="sort__icon"
            alt="sort icon"
            onClick={() => {
              setOrder(order === 'assigned' ? '-assigned' : 'assigned')
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getEmployeeData = async () => {
      if (stateService.params.userSet) {
        const { data: groupRes } = await Api.get(
          `user_sets/${stateService.params.userSet}`,
          {},
        )

        const { data: wholeListRes } = await Api.get(
          'assign_user_set/training_ids',
          {
            user_set: stateService.params.userSet,
            search,
          },
        )

        const { data: assignedTrainingRes } = await Api.get(
          'assign_user_set/training_ids',
          {
            user_set: stateService.params.userSet,
            is_assigned: true,
          },
        )

        setAlreadyAssigned(assignedTrainingRes)
        setListToAssign(assignedTrainingRes)
        setEmployee(groupRes)
        setWholeIdsList(wholeListRes)
      } else {
        const { data: employeeRes } = await Api.get(
          `users/${stateService.params.user}`,
          {},
        )

        const { data: wholeListRes } = await Api.get(
          'assign_user_set/training_ids',
          {
            user: stateService.params.user,
            search,
          },
        )

        const { data: assignedTrainingRes } = await Api.get(
          'assign_user_set/training_ids',
          {
            user: stateService.params.user,
            is_assigned: true,
          },
        )

        setAlreadyAssigned(assignedTrainingRes)
        setListToAssign(assignedTrainingRes)
        setWholeIdsList(wholeListRes)
        setEmployee(employeeRes)
      }
    }
    getEmployeeData()
  }, [])

  useEffect(() => {
    const getTrainingData = async () => {
      const queryParams = {
        training: stateService.params.training,
        deleted: false,
        is_active: true,
        order,
        page,
        search,
      }

      if (stateService.params.user) {
        queryParams.user = stateService.params.user
      }
      if (stateService.params.userSet) {
        queryParams.user_set = stateService.params.userSet
      }

      const { data: trainingRes } = await Api.get(
        'assign_user_set/training_set',
        queryParams,
      )

      const filteredTrainingRes = trainingRes.results.filter(
        (emp) => !Array.isArray(emp.trainings) || emp.trainings.length > 0
      );

      setTrainingList(filteredTrainingRes)
      setCount(trainingRes.count)
      setRefresh(false)
    }
    getTrainingData()
  }, [search, page, order, refresh])

  const baseStyle = getBaseStyle(variableColor)

  const handleButtonClick = async () => {
    const assignPromise = listToAssign.filter(
      (id) => !alreadyAssigned.includes(id),
    ).length
      ? Api.post('assign', {
        active: true,
        trainings: listToAssign.filter((id) => !alreadyAssigned.includes(id)),
        users: isEmpGroup ? employee?.users : [employee?.id],
      })
      : Promise.resolve()
    const unassignPromise = listToUnassign.length
      ? Api.post('assign', {
        active: false,
        trainings: listToUnassign,
        users: isEmpGroup ? employee?.users : [employee?.id],
      })
      : Promise.resolve()

    await Promise.all([assignPromise, unassignPromise])

    stateService.go('trainings.assign.trainings_list', {
      pageNumber: stateService.params.previousPageNumber,
      search: stateService.params.previousSearch,
    })
  }

  return (
    <div css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <section className="project__assign__wrapper">
        <div className="top__wrapper">
          <h4 className="employee__name">
            {isEmpGroup
              ? employee?.name
                ? `Employee Group: ${employee.name}`
                : ''
              : employee?.first_name || employee?.last_name
                ? `Employee: ${employee.first_name ?? ''} ${employee.last_name ?? ''}`.trim()
                : ''}
          </h4>
          <ButtonElement
            buttonType="submit"
            text="Assign"
            functionToTrigger={handleButtonClick}
            additionalStyles={css({ padding: 0 })}
          />
        </div>
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
        />
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {trainingList?.map((training, index) => (
            <TrainingRow
              user={employee}
              index={index}
              search={search}
              setRefresh={setRefresh}
              training={training}
              handleAssign={handleAssign}
              listToAssign={listToAssign}
            />
          ))}
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getBaseStyle(variableColor: string) {
  return css({
    paddingBottom: '200px',
    '.project__assign__wrapper': {
      background: '#fff',
      paddingLeft: '20px',
      width: '834px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingBottom: '20px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.project__name': {
      fontSize: '13px',
      margin: 0,
    },
    table: {
      width: '791px',
    },
    '.project__row': {
      position: 'relative',
      height: '40px',
    },
    '.name__td': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.group__row': {
      position: 'relative',
      height: '40px',
      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.1),
    },
    '.checkmark__td': {
      textAlign: 'center',
      minWidth: '80px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '.name__cell': {
      paddingLeft: '16px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBAWithOpacity(variableColor),
    },
    '.info__wrapper': {
      position: 'absolute',
      right: '15px',
      top: '-3px',
    },
    '.info__icon': {
      cursor: 'pointer',
    },
    '.sort__icon': {
      cursor: 'pointer',
      height: '17px',
    },
    '.full__name': {
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '@media(max-width: 1050px)': {
      '#search': {
        width: '264px',
      },
      '.project__name': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '243px',
        overflow: 'hidden',
      },
      '.top__wrapper': {
        paddingRight: 0,
        gap: '8px',
      },
      '.project__assign__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__td': {
        maxWidth: '204px',
      },
    },
  })
}
