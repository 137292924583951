import { resolvers } from '../resolvers'

/** @ngInject */
export function adminProjectsConfig($stateProvider) {
  $stateProvider
    .state('admin.projects', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
      data: { pageTitle: 'Project Administration' },
    })
    .state('admin.projects.list', {
      url: '/projects?pageNumber&order&reverse&search',
      templateUrl: 'app/views/project_list.html',
      controller: 'ProjectsListCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.projects.bulkUpload', {
      url: '/projects/bulkUpload?previousPageNumber&previousOrder&previousReverse&previousSearch',
      templateUrl: 'app/views/project_bulk_upload.html',
      controller: 'ProjectBulkUploadCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.projects.add', {
      url: '/add-project',
      template: '<admin-add-project-component></admin-add-project-component>',
      controller: 'ProjectsEditCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.projects.edit', {
      url: '/projects/:projectID?previousPageNumber&previousOrder&previousReverse&previousSearch',
      template: '<admin-add-project-component></admin-add-project-component>',
      controller: 'ProjectsEditCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.projects.editUsers', {
      url: '/projects/:projectID/users?previousPageNumber&previousOrder&previousReverse&previousSearch',
      templateUrl: 'app/views/project_user_form.html',
      controller: 'ProjectEditUsersCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
}
