import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { TableInput } from '@/react/componentAssets/TableInput'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { css } from '@emotion/react'
import { GroupAssignModal } from '@/react/components/GroupAssignModal'

export const GroupRow = ({
  group,
  index,
  search,
  setRefresh,
  defaultId,
  page,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [isCheckmarkClicked, setCheckmarkClicked] = useState(false)
  const [hoveredField, setHoveredField] = useState(null)

  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { name, categories, is_default } = group

  const { stateService } = useRouter()

  const handleDelete = async () => {
    await Api.delete(`category_sets/${group?.id}`, {})
    setRefresh(true)
  }

  const handleNameUpdate = async (newName: string) => {
    try {
      await Api.patch(`category_sets/${group?.id}`, {
        ...group,
        name: newName,
      })
    } catch {
      console.error('Failed to update the name')
    } finally {
      setRefresh(true)
    }
  }

  return (
    <tr
      className={classNames('', {
        dark: index % 2 === 0,
      })}
    >
      {!isEdit ? (
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name',
              text: name,
              max__length: 76,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p
            className="title__text"
            onClick={() => {
              setEdit(true)
            }}
          >
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={name}
            />
          </p>
        </td>
      ) : (
        <TableInput
          defaultValue={name}
          handleClose={() => {
            setEdit(false)
          }}
          handleSubmit={handleNameUpdate}
          inputWidth="350px"
        />
      )}
      <td className="icon__td">
        <div className="topic__info">
          <EditIcon
            color={variableColor}
            onClick={() => {
              stateService.go('app.categories.group_assign', {
                group: group?.id,
                previousPageNumber: page,
                previousSearch: search,
              })
            }}
          />
          <p className="topic__count">{categories?.length}</p>
        </div>
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={is_default}
            onChange={() => {
              setCheckmarkClicked(true)
            }}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
        {isCheckmarkClicked && (
          <GroupAssignModal
            defaultId={defaultId}
            groupId={group?.id}
            setRefresh={setRefresh}
            setPopUpVisible={() => {
              setCheckmarkClicked(false)
            }}
            requestUrl={'category_sets'}
          />
        )}
      </td>
      <td className="icon__td">
        <BinDeleteIcon
          color={variableColor}
          additionalStyles={css({
            marginTop: '5px',
          })}
          onClick={() => {
            setDeleteModalOpen(true)
          }}
        />
        {isDeleteModalOpen && (
          <ConfirmationModal
            action={'Delete Group'}
            title={name}
            handleClose={() => {
              setDeleteModalOpen(false)
            }}
            handleClick={handleDelete}
          />
        )}
      </td>
      {!isEdit && hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
