import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { TableInput } from '@/react/componentAssets/TableInput'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import classNames from 'classnames'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { DeleteQuestionModal } from './DeleteQuestionModal'

export const QuestionRow = ({
  question,
  search,
  index,
  isCustomCategory,
  setRefresh,
  page,
  category,
  isSupervisor,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { stateService } = useRouter()

  const [isEdit, setEdit] = useState(false)
  const [hoveredField, setHoveredField] = useState(null)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isDeleteQuestionModalOpen, setDeleteQuestionModalOpen] =
    useState(false)

  const handleOrderUpdate = async (newValue: string) => {
    try {
      await Api.patch(
        `client_app_questions_categories/${question?.clientappquestion_to_category[0].id}`,
        {
          id: question?.clientappquestion_to_category[0].id,
          order: newValue,
        },
      )
    } catch {
      console.error('Failed to update the name')
    } finally {
      setRefresh(true)
    }
  }

  const handleDelete = async () => {
    try {
      await Api.delete(`questions/${question?.id}`, {})

      setRefresh(true)
    } catch {
      console.error('Error on delete')
    }
  }

  const handleUnassign = async () => {
    try {
      await Api.patch(
        `client_app_questions/${question?.clientappquestion_to_category[0]?.clientappquestion}`,
        {
          id: question?.clientappquestion_to_category[0]?.clientappquestion,
          custom_categories: question?.custom_category_ids?.filter(
            (id) => id !== category?.id,
          ),
        },
      )

      setRefresh(true)
    } catch {
      console.error('Error on delete')
    }
  }

  const stripHtmlTags = (string) => {
    const doc = new DOMParser().parseFromString(string, 'text/html')
    return doc.body.textContent || ''
  }

  const questionName = stripHtmlTags(question?.name)

  return (
    <tr
      className={classNames('', {
        dark: index % 2 === 0,
      })}
    >
      <>
        {isCustomCategory && !isSupervisor ? (
          <>
            {!isEdit ? (
              <td
                className="title__td"
                onMouseOver={() => {
                  setHoveredField({
                    field__name: 'name',
                    text: name,
                    max__length: 76,
                  })
                }}
                onMouseOut={() => {
                  setHoveredField(null)
                }}
              >
                <p
                  className="title__text"
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  <Highlighter
                    highlightClassName={'highlighted__item'}
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={question?.order + ''}
                  />
                </p>
              </td>
            ) : (
              <TableInput
                defaultValue={question?.order}
                handleClose={() => {
                  setEdit(false)
                }}
                handleSubmit={handleOrderUpdate}
                inputWidth={'40px'}
                inputType={'number'}
              />
            )}
          </>
        ) : null}
      </>

      <td
        className="link__td question__td"
        style={{
          color:
            !isSupervisor && question?.client !== null ? variableColor : 'gray',
          cursor:
            !isSupervisor && question?.client !== null ? 'pointer' : 'default',
        }}
        onClick={() => {
          if (!isSupervisor && question?.client !== null) {
            stateService.go('app.questions.edit', {
              question: question?.id,
              previousPageNumber: page,
              previousSearch: search,
              previousCategory: category?.id,
            })
          }
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={questionName}
        />
      </td>
      {!isSupervisor ? (
        <td className="edit__td">
          <div className="edit__wrapper">
            <a
              href={`app/${stateService.params.app}/questions/${question?.id}/custom-categories?previousPageNumber=${page}&previousSearch=${search}&previousCategory=${category?.id}`}
            >
              <EditIcon height={'18px'} color={variableColor} />
            </a>
            <p className="edit__count">
              {question?.custom_category_ids?.length}
            </p>
          </div>
        </td>
      ) : null}

      <td className="icon__td">
        <BinDeleteIcon
          color={variableColor}
          additionalStyles={css({
            marginTop: '5px',
            color:
              !isSupervisor && question?.client !== null
                ? variableColor
                : 'gray',
            cursor:
              !isSupervisor && question?.client !== null
                ? 'pointer'
                : 'default',
          })}
          onClick={() => {
            if (!isSupervisor && question?.client !== null) {
              if (question?.custom_category_ids?.length > 1) {
                setDeleteQuestionModalOpen(true)
              } else {
                setDeleteModalOpen(true)
              }
            }
          }}
        />
        {isDeleteModalOpen && (
          <ConfirmationModal
            action={'Delete'}
            handleClose={() => {
              setDeleteModalOpen(false)
            }}
            handleClick={handleDelete}
          />
        )}
        {isDeleteQuestionModalOpen && (
          <DeleteQuestionModal
            setModalOpen={setDeleteQuestionModalOpen}
            categoriesCount={question?.custom_category_ids?.length}
            handleDeleteAll={handleDelete}
            handleDelete={handleUnassign}
          />
        )}
      </td>

      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
