import { useEffect, RefObject } from 'react'

const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  setVisible: (visible: boolean) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setVisible(false)
        document.body.style.overflow = 'scroll'
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setVisible])
}

export default useClickOutside
