import { InfoModal } from '@/react/componentAssets/InfoModal'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const CategoryRow = ({ category, index, search, setRefresh }) => {
  const { ApiSilent, CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const [hoveredField, setHoveredField] = useState(null)
  const [maxThresholdMessage, setMaxThresholdMessage] = useState(false)

  const { stateService } = useRouter()

  const handleAssignGroup = async () => {
    try {
      await ApiSilent.postV2WithParams(
        `projects/${stateService.params.project}/assign_category_sets`,
        [
          {
            id: category?.id,
            active: !category?.active,
          },
        ],
        {
          application: stateService.params.app,
        },
      )
    } catch (error) {
      if (error?.status === 400) {
        setMaxThresholdMessage(error?.data?.detail || 'Unknown error')
      }
    }

    setRefresh(true)
  }

  const handleAssign = async () => {
    try {
      await ApiSilent.postV2WithParams(
        `projects/${stateService.params.project}/assign_categories`,
        [
          {
            id: category?.id,
            active: !category?.active,
          },
        ],
        {
          application: stateService.params.app,
        },
      )
    } catch (error) {
      if (error.status === 400) {
        setMaxThresholdMessage(error.data.detail)
      }
    }

    setRefresh(true)
  }

  return (
    <tr
      className={classNames('category__row', {
        dark: index % 2 === 0,
        group__row: category?.source === 1,
        light: index % 2 === 0 && category?.source === 1,
      })}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: category?.name,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        {category?.client_id && category?.group_id === null ? (
          <div>
            <Highlighter
              highlightClassName="highlighted__text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={category?.name}
            />
            <span className="custom-label"> (custom)</span>
          </div>
        ) : (
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={category?.name}
          />
        )}
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={category?.active}
            onChange={category?.source === 1 ? handleAssignGroup : handleAssign}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
        {maxThresholdMessage ? (
          <InfoModal
            message={maxThresholdMessage}
            setModalOpen={setMaxThresholdMessage}
            left="-220px"
          />
        ) : null}
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
