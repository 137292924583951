import { encodeString } from '@/utils/additionalEncodeLogic'
import { getFilterParam } from '@/react/components'

export function buildCombinedQueryString(
  params: Record<string, any>,
  advancedFilters: Record<string, any> = {},
): string {
  const queryParts: string[] = []

  Object.entries(params).forEach(([key, value]) => {
    queryParts.push(`${key}=${encodeString(value, false)}`)
  })

  Object.entries(advancedFilters).forEach(([field, filterObj]) => {
    if (filterObj && filterObj.type && filterObj.string) {
      const filterExpression = getFilterParam(
        field,
        filterObj.type,
        filterObj.string,
        filterObj.secondType,
        filterObj.secondString,
      )
      queryParts.push(filterExpression)
    }
  })

  return queryParts.join('&')
}
