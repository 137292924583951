import { ReactWrapper } from '@components'
import { AddProject } from './AddProject'
import { BulkUploadProjects } from './AdminProjectBulkUpload'

//AdminCompanyProjectsConfig

export const AdminAddProjectComponent = ReactWrapper(
  AddProject,
  'adminAddProjectComponent',
)

export const AdminBulkUploadProjectsComponent = ReactWrapper(
  BulkUploadProjects,
  'adminBulkUploadProjectsComponent',
)
