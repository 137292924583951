import * as React from 'react'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import { singleHeaderTypes } from '@screens/components'
import {
  AdvancedFilter,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { TableBody } from './index'
import { css } from '@emotion/react'

const PAGE_LENGTH = 20

export const ReloadableElements = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const [listCount, setListCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(stateService.params.order || '-date')
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const [advancedFilters, setAdvancedFilters] = React.useState(
    getFilterStates(stateService),
  )

  const generalSettings = CurrentUser.getClientSettings()

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'date',
      name: 'Date',
      type: 'date',
      filterHeader: true,
      additionalStyles: {
        width: '15%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'job',
      name: generalSettings.project_language,
      type: 'job',
      filterHeader: true,
      additionalStyles: {
        width: 'auto',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'job'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'tm_number',
      name: 'T&M Number',
      type: 'tm_number',
      filterHeader: true,
      additionalStyles: {
        width: '15%',
        textAlign: 'center',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'tm_number'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'user_full_name',
      name: 'Supervisor',
      type: 'user_full_name',
      filterHeader: true,
      additionalStyles: {
        width: '15%',
        textAlign: 'center',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'grand_total',
      name: 'Total Amount',
      type: 'grand_total',
      filterHeader: true,
      additionalStyles: {
        width: '15%',
        textAlign: 'center',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
    {
      className: 'view',
      name: 'View',
      type: 'view',
      filterHeader: true,
      additionalStyles: {
        maxWidth: '75px',
        textAlign: 'center',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
    {
      className: 'pdf',
      name: 'PDF',
      type: 'pdf',
      filterHeader: false,
      additionalStyles: {
        maxWidth: '75px',
        textAlign: 'center',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
  ]

  return (
    <section css={baseStyle}>
      <div className="search-base">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
        />
      </div>
      <table className="dual-color-table">
        <TableHeaders
          headers={TABLE_HEADER_SETTINGS}
          popUpLocation={false}
          callback={setOrder}
        />
        <TableBody
          advancedFilters={advancedFilters}
          order={order}
          search={search}
          page={page}
          setListCount={setListCount}
        />
      </table>
      <div className="page-switcher-base">
        <PageSwitcher
          pages={PAGE_LENGTH}
          listCount={listCount}
          currentPage={page}
          callback={setPage}
        />
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  backgroundColor: 'white',
  marginLeft: 1,
  table: {
    margin: '20px 25px',
  },
  '.static-nav': {
    borderBottom: '2px solid rgb(229, 229, 229)',
  },
  '.search-base': {
    padding: '20px 25px',
  },
  '.page-switcher-base': {
    marginLeft: '20px',
  },
  '.dual-color-table': {
    marginTop: 0,
  },
})

function getFilterStates(stateService) {
  const filtersToReturn = {
    job: {},
    user_full_name: {},
    tm_number: {},
  }
  if (stateService.params.job)
    filtersToReturn.job = JSON.parse(stateService.params.job)
  if (stateService.params.user_full_name)
    filtersToReturn.user_full_name = JSON.parse(
      stateService.params.user_full_name,
    )
  if (stateService.params.tm_number)
    filtersToReturn.tm_number = JSON.parse(stateService.params.tm_number)
  return filtersToReturn
}
