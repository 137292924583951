import * as React from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'

import uploadIcon from '/src/assets/icons/svg-gray-icons/upload-icon-gray.svg'
import closeIcon from '@/assets/icons/svg-gray-icons/close-icon-gray.svg'
import FileIcon from '@/assets/icons/svg-gray-icons/file-gray-icon.svg'
import { useAngularServices } from '@/react/components'

type Props = {
  dropMessage: React.ReactNode
  hoverMessage: React.ReactNode
  addCallback?: () => void
  removeCallback?: () => void
  fileStorage: File | null
  fileSetStorage: (file: File | null) => void
  additionalStyles?: object
  accept?: string
  imageDisplay?: boolean
  customUploadIcon?: string
  uploadProgress: number
  setUploadProgress: (progress: number) => void
}

export const DropZoneV2 = ({
  dropMessage = <p />,
  hoverMessage = <p />,
  addCallback,
  removeCallback,
  fileStorage,
  fileSetStorage,
  additionalStyles = {},
  accept = 'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  imageDisplay = false,
  customUploadIcon,
  uploadProgress,
  setUploadProgress,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      addCallback?.()
      fileSetStorage(acceptedFiles[0])

      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentLoaded = Math.round((event.loaded / event.total) * 100)
          setUploadProgress(percentLoaded)
        }
      }

      reader.onloadend = () => {
        setUploadProgress(100)
      }

      reader.readAsArrayBuffer(file)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  function deleteFile() {
    removeCallback?.()
    fileSetStorage(null)
    setUploadProgress(0)
  }

  return (
    <div css={[fileUploadBase, additionalStyles]}>
      <div className="border-spread">
        <div css={fileDisplayBase()}>
          {fileStorage ? (
            imageDisplay ? (
              <div
                className="file-display"
                css={fileDisplay(true, variableColor)}
              >
                <div
                  className="single-alert-row"
                  css={css({ justifyContent: 'right' })}
                >
                  <img
                    src={closeIcon}
                    alt="close"
                    css={css({ height: 10, cursor: 'pointer' })}
                    onClick={deleteFile}
                  />
                </div>
                <div className="image-holder">
                  <img src={URL.createObjectURL(fileStorage)} />
                </div>
              </div>
            ) : (
              <div
                className="file-display"
                css={fileDisplay(false, variableColor)}
              >
                <div className="buttonContainer">
                  <button className="closeButton" onClick={deleteFile}>
                    <img src={closeIcon} />
                  </button>
                </div>
                <div className="fileContainer">
                  <div className="file-name">
                    <img className="fileIcon" src={FileIcon} />
                    <p>{fileStorage.name}</p>
                  </div>
                </div>
                <div className="progress-block">
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="progress-container">
                      <div
                        className="progress-bar"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            )
          ) : null}
        </div>
        <form
          encType="multipart/form-data"
          css={dropzoneBase}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {fileStorage ? (
            ''
          ) : (
            <div className="prop-name">
              <img
                src={customUploadIcon || uploadIcon}
                alt="UPLOAD"
                className="upload-icon"
              />
              {isDragActive ? dropMessage : hoverMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const fileUploadBase = css({
  marginTop: '20px',
  width: '98%',
  height: '100%',
  backgroundColor: '#F7F7F7',
  borderColor: 'rgba(229,230,231,0.5)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  '.border-spread': {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
})

const fileDisplayBase = () =>
  css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  })

const dropzoneBase = css({
  '@media(max-width: 990px)': {
    '.upload-icon': {
      marginBottom: '0px !important',
    },
  },
  zIndex: 100,
  width: '100%',
  height: '100%',
  backgroundImage: 'none',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '0',
  '.prop-name': {
    padding: '30px 26px 16px 26px',
    display: 'flex',
    gap: '15px',
    flexDirection: 'column',
    alignItems: 'center',
    '.upload-icon': {
      marginBottom: '33px',
      width: '145px !important',
    },
  },
})

const fileDisplay = (image: boolean = false, variableColor?: string) => {
  return css({
    display: 'grid',
    gridTemplateRows: '2rem 1fr',
    flexDirection: 'column',
    justifyContent: 'space-around',
    zIndex: 200,
    width: image ? 200 : 230,
    minHeight: '116px',
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '10px',
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
    },
    '.image-holder': {
      paddingLeft: 15,
      paddingRight: 15,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      maxHeight: 85,
      img: {
        maxWidth: '100%',
        maxHeight: 85,
      },
    },
    '.buttonContainer': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '-13px',
      marginTop: ' -15px',

      '.closeButton': {
        backgroundColor: '#ffffff00',
        border: '0',
      },
    },

    '.progress-block': {
      minHeight: '2rem',
    },
    ' .progress-container': {
      width: '100%',
      height: '8px',
      backgroundColor: ' #e0e0e0',
      borderRadius: '4px',
      overflow: 'hidden',
      marginTop: '8px',
    },

    '.progress-bar ': {
      height: '100%',
      backgroundColor: variableColor,
      transition: 'width 0.2s ease-in -out',
    },

    '.file-name': {
      marginTop: '-5px',
      display: 'grid',
      justifyItems: 'center',
      gridTemplateColumns: '35px 1fr',
      alignItems: 'center',
      padding: '5px 11px 5px 5px',
      backgroundColor: '#EBEBEB',
      width: '100%',
      minWidth: '203px',
      height: 'auto',
      minHeight: '57px',
      gap: '6px',

      p: {
        margin: 0,
        wordWrap: ' break-word',
        whiteSpace: 'normal',
        height: 'auto',
        maxWidth: '15rem',
      },
    },
  })
}
