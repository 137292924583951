import {
  TableHeaders,
  AdvancedFilter,
  useAngularServices,
} from '@/react/components'
import { DocumentationRow } from './DocumentationRow'
import actionIcon from '@/assets/icons/action-icon.svg'
import { useEffect, useRef, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../../components'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { useDateFilter } from '@/react/hooks/useDateFilter'
import { AdvancedDateFilter } from '@/react/components/AdvancedDateFilter'
import { buildCombinedQueryString } from '@/utils/queryBuilder'
import { CurrentUserType, Documentation } from '@/react/types/documentation'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

export const DocumentationTableBody = ({
  setOrder,
  page,
  setPage,
  search,
  order,
  setSelectedItems,
  selectedItems,
  setListCount,
  listCount,
  isChecked,
  setChecked,
  setSearch,
  isTrainedFilterApplied,
  setIsTrainedFilterApplied,
  isExpiringFilterApplied,
  setIsExpiringFilterApplied,
  filteredDocumentationList,
  setFilteredDocumentationList,
  setFilteredListCount,
}) => {
  const { stateService } = useRouter()
  const { Api, Notification, CurrentUser } = useAngularServices()
  const popupRef = useRef(null)
  const [isAllChecked, setAllChecked] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [deleteAction, setDeleteAction] = useState(false)
  const [isDeleteButtonClicked, setDeleteButtonClicked] = useState(false)
  const [isExportPDFAvailable, setExportPDFAvailable] = useState(false)
  const [isDeleteAvailable, setDeleteAvailable] = useState(false)
  const [advancedFilters, setAdvancedFilters] = useState(
    getFilterStates(stateService),
  )

  const [documentationList, setDocumentationList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [startDateTrained, setStartDateTrained] = useState('')
  const [endDateTrained, setEndDateTrained] = useState('')
  const [startDateExpiring, setStartDateExpiring] = useState('')
  const [endDateExpiring, setEndDateExpiring] = useState('')
  const [dateField, setDateField] = useState('')

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const { filteredData, filteredCount, fetchFilteredData } = useDateFilter({
    url: 'trainings/documentation',
  })

  useEffect(() => {
    if (!isTrainedFilterApplied && !isExpiringFilterApplied) return

    const debounceTimeout = setTimeout(() => {
      const activeFilter =
        dateField === 'date_trained'
          ? { startDate: startDateTrained, endDate: endDateTrained }
          : { startDate: startDateExpiring, endDate: endDateExpiring }

      if (activeFilter.startDate && activeFilter.endDate) {
        fetchFilteredData({
          startDate: activeFilter.startDate,
          endDate: activeFilter.endDate,
          dateField,
          page,
          advancedFilters,
          apiVersion: 'v2',
          search,
          order,
        }).catch((err) => console.error(err))
      }
    }, 300)

    return () => clearTimeout(debounceTimeout)
  }, [
    isTrainedFilterApplied,
    isExpiringFilterApplied,
    dateField,
    startDateTrained,
    endDateTrained,
    startDateExpiring,
    endDateExpiring,
    page,
    search,
    order,
  ])

  useEffect(() => {
    if (isTrainedFilterApplied || isExpiringFilterApplied) {
      setFilteredDocumentationList(filteredData)
      setFilteredListCount(filteredCount)
    }
  }, [
    filteredData,
    filteredCount,
    isTrainedFilterApplied,
    isExpiringFilterApplied,
  ])

  useEffect(() => {
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [])

  useEffect(() => {
    let isMounted = true

    if (!isTrainedFilterApplied && !isExpiringFilterApplied) {
      const getTrainingDocumentations = async (
        page: number,
        search: string,
      ) => {
        const params = {
          page: page,
          page_size: 20,
        }

        if (order) params.order = order
        if (search) params.search = search

        const queryString = buildCombinedQueryString(params, advancedFilters)

        const finalQueryString = `${queryString}&eq(deleted,false)`

        const { data: result } = await Api.getWithoutDeletedParamV2(
          'trainings/documentation',
          finalQueryString,
        )

        if (isMounted) {
          setListCount(result.count)
          setDocumentationList(result.results)
          if (isChecked) {
            setSelectedItems(result.results)
          }
        }
        setRefresh(false)
      }
      getTrainingDocumentations(page, search)
    }

    return () => {
      isMounted = false
    }
  }, [
    page,
    search,
    advancedFilters,
    order,
    deleteAction,
    refresh,
    isTrainedFilterApplied,
    isExpiringFilterApplied,
  ])

  useEffect(() => {
    setDeleteAvailable(
      CurrentUser.getRole() !== 'trainee' &&
        CurrentUser.getRole() !== 'client_user' &&
        CurrentUser.getRole() !== 'supervisor' &&
        CurrentUser.getRole() !== 'read_only',
    )
  }, [])

  const handleSelectAll = () => {
    const listToUse =
      isTrainedFilterApplied || isExpiringFilterApplied
        ? filteredData
        : documentationList

    if (!isAllChecked) {
      setSelectedItems(listToUse)
      setAllChecked(true)
    } else {
      setSelectedItems([])
      setAllChecked(false)
    }
  }

  const handleSelect = (item: Documentation) => {
    const selectedIds = selectedItems.map((el) => el.id)

    if (selectedIds.includes(item.id)) {
      setSelectedItems(selectedItems.filter((el) => el.id !== item.id))
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
  }

  const downloadFile = (fileUrl: string, fileName: string) => {
    const anchor = document.createElement('a')
    anchor.href = fileUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
  }

  useEffect(() => {
    setExportPDFAvailable(
      selectedItems.map((item) => item?.certificate?.id).length > 0 ||
        isChecked,
    )
  }, [selectedItems])

  const handleExportPDF = async () => {
    setActionsClicked(false)
    const certificateIds = selectedItems.map((item) => item?.certificate?.id)

    if (isAllChecked) {
      try {
        const { data: createFileResponse } = await Api.getWithoutDeletedParamV2(
          'trainings/documentation/export_all',
          {
            search,
          },
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.certificates_zip_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Certificates')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time + 10000)
      } catch {
        Notification.warning('Creating of zip file failed')
      }
    }

    if (certificateIds.length && !isAllChecked) {
      try {
        const { data: createFileResponse } = await Api.postV2(
          'trainings/documentation/export',
          {
            certificate_ids: certificateIds,
          },
        )

        setTimeout(async () => {
          try {
            const { data: fileResponseURL } =
              await Api.getWithoutDeletedParamV2(
                `file_upload/${createFileResponse?.certificates_zip_id}`,
                {},
              )

            downloadFile(fileResponseURL?.file, 'List of Certificates')
          } catch {
            Notification.warning('Failed to get file url for download')
          }
        }, createFileResponse.time + 1100)
      } catch {
        Notification.warning('Creating of zip file failed')
      }
    }
    setSelectedItems([])
    setChecked(false)
  }

  const handleDelete = async () => {
    setDeleteAction(true)
    setDeleteButtonClicked(false)

    if (isAllChecked) {
      const params = {
        search,
      }

      if (isTrainedFilterApplied || isExpiringFilterApplied) {
        params.start_date =
          dateField === 'date_trained' ? startDateTrained : startDateExpiring
        params.end_date =
          dateField === 'date_trained' ? endDateTrained : endDateExpiring
        params.date_field = dateField
      }

      const queryString = buildCombinedQueryString(params, advancedFilters)

      try {
        await Api.deleteV2('trainings/documentation/delete_all', queryString)
        setSelectedItems([])
        setChecked(false)
        setAllChecked(false)
        setRefresh(true)

        if (isTrainedFilterApplied || isExpiringFilterApplied) {
          fetchFilteredData({
            startDate:
              dateField === 'date_trained'
                ? startDateTrained
                : startDateExpiring,
            endDate:
              dateField === 'date_trained' ? endDateTrained : endDateExpiring,
            dateField,
            page,
            advancedFilters,
            apiVersion: 'v2',
            search,
            order,
          }).catch((err) => console.error(err))
        }
      } catch {
        Notification.danger('The selected documentation may not be deleted.')
      }

      setDeleteAction(false)
      return
    }

    const v1Items = selectedItems.filter((item) => item.api_version === 'v1')
    const v2Items = selectedItems.filter(
      (item) => item.api_version === 'v2' && item.editable,
    )

    const v1Ids = v1Items.map((item) => item.id)
    const v2Ids = v2Items.map((item) => item.training?.id || item.id)

    if (!v1Ids.length && !v2Ids.length && selectedItems.length) {
      Notification.danger('The selected documentation may not be deleted.')
      setDeleteAction(false)
      return
    }

    let deleteSuccess = false
    let deletedCount = 0

    for (const id of v1Ids) {
      try {
        await Api.Jobs.delete({ id })
        deleteSuccess = true
        deletedCount++
      } catch {
        Notification.danger(
          `Failed to delete training documentation with ID ${id}`,
        )
      }
    }

    for (const id of v2Ids) {
      try {
        await Api.TrainingsDocumentation.delete({ id })
        deleteSuccess = true
        deletedCount++
      } catch {
        Notification.danger(
          `Failed to delete training documentation with ID ${id}`,
        )
      }
    }

    if (deleteSuccess) {
      if (isTrainedFilterApplied || isExpiringFilterApplied) {
        setFilteredDocumentationList((prev) =>
          prev.filter(
            (doc) =>
              !v1Ids.includes(doc.id) &&
              !v2Ids.includes(doc.training?.id || doc.id),
          ),
        )
        setFilteredListCount((prev) => prev - deletedCount)

        fetchFilteredData({
          startDate:
            dateField === 'date_trained' ? startDateTrained : startDateExpiring,
          endDate:
            dateField === 'date_trained' ? endDateTrained : endDateExpiring,
          dateField,
          page,
          advancedFilters,
          apiVersion: 'v2',
          search,
          order,
        }).catch((err) => console.error(err))
      } else {
        setDocumentationList((prev) =>
          prev.filter(
            (doc) =>
              !v1Ids.includes(doc.id) &&
              !v2Ids.includes(doc.training?.id || doc.id),
          ),
        )
        setListCount((prev) => prev - deletedCount)
      }
    }

    setSelectedItems([])
    setChecked(false)
    setAllChecked(false)
    setRefresh(true)
    setDeleteAction(false)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDeleteButtonClicked(false)
      }
    }

    if (isDeleteButtonClicked) {
      setDeleteButtonClicked(true)
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isDeleteButtonClicked])

  const paginationReset = () => {
    setPage(1)
  }

  const deleteCount = isAllChecked
    ? isTrainedFilterApplied || isExpiringFilterApplied
      ? filteredCount
      : listCount
    : selectedItems.length

  const deleteMessage = `${deleteCount} ${
    deleteCount > 1 ? 'Trainings' : 'Training'
  }?`

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '60px',
        textAlign: 'left',
        paddingLeft: '12px',
        paddingRight: '10px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        paddingBottom: '4px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
        '.modal__wrapper': {
          right: '-200px',
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleSelectAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isDeleteAvailable && (
                <div
                  className="action__text"
                  onClick={() => {
                    setActionsClicked(false)
                    setDeleteButtonClicked(true)
                  }}
                >
                  Delete
                </div>
              )}
              {isExportPDFAvailable && (
                <div
                  className="action__text dark"
                  onClick={() => {
                    handleExportPDF()
                  }}
                >
                  Export PDF
                </div>
              )}
            </div>
          )}
          {isDeleteButtonClicked && (selectedItems.length || isChecked) && (
            <ConfirmationModal
              handleClick={handleDelete}
              handleClose={() => {
                setDeleteButtonClicked(false)
              }}
              action="Delete"
              title={deleteMessage}
              questionMark={false}
            />
          )}
          {isDeleteButtonClicked && !selectedItems.length && !isChecked && (
            <ConfirmationModal
              handleClick={() => setActionsClicked(false)}
              handleClose={() => {
                setDeleteButtonClicked(false)
              }}
              title="Please select at least one item to be deleted."
            />
          )}
        </div>
      ),
    },
    {
      className: 'table__header trainee',
      name: 'Trainee',
      type: 'name',
      additionalStyles: {
        width: '145px',
        minWidth: '145px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'ID',
      type: 'employee_id',
      additionalStyles: {
        width: '66px',
        minWidth: '66px',
        textAlign: 'left',
        paddingLeft: 0,
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'employee_id'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Topic',
      type: 'training_topic',
      additionalStyles: {
        width: '230px',
        minWidth: '230px',
        textAlign: 'left',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'training_topic'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trained',
      type: 'date_trained',
      additionalStyles: {
        width: '95px',
        minWidth: '95px',
        textAlign: 'center',
        paddingRight: '7px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        p: {
          display: 'flex',
        },
      },
      additionalInnerElement: (
        <AdvancedDateFilter
          setStartDate={setStartDateTrained}
          setEndDate={setEndDateTrained}
          setIsFilterApplied={setIsTrainedFilterApplied}
          isTrainedFilterApplied={isTrainedFilterApplied}
          isExpiringFilterApplied={isExpiringFilterApplied}
          setAllChecked={setAllChecked}
          setPage={setPage}
          dateField={dateField}
          setDateField={setDateField}
          dateFieldName="date_trained"
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Expiring',
      type: 'date_training_expires',
      filterHeader: true,
      additionalStyles: {
        minWidth: '95px',
        width: '95px',
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedDateFilter
          setStartDate={setStartDateExpiring}
          setEndDate={setEndDateExpiring}
          setIsFilterApplied={setIsExpiringFilterApplied}
          isTrainedFilterApplied={isTrainedFilterApplied}
          isExpiringFilterApplied={isExpiringFilterApplied}
          setAllChecked={setAllChecked}
          setPage={setPage}
          dateField={dateField}
          setDateField={setDateField}
          dateFieldName={'date_training_expires'}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trainer',
      type: 'trained_by',
      filterHeader: true,
      additionalStyles: {
        width: '148px',
        textAlign: 'center',
        paddingLeft: '0px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '45px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trained_by'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company',
      filterHeader: true,
      additionalStyles: {
        width: '178px',
        minWidth: '178px',
        textAlign: 'center',
        paddingLeft: '0',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '60px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
            marginRight: '5px',
          },
          '.alert-button-opening': {
            paddingLeft: '10px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'company'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
    {
      className: 'table__header',
      name: 'Trade',
      type: 'trade',
      filterHeader: true,
      additionalStyles: {
        width: '128px',
        minWidth: '128px',
        paddingLeft: 0,
        textAlign: 'center',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
        '.header-base': {
          p: {
            display: 'flex',
            width: '49px',
          },
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '5px',
          },
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'trade'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
          additionalFunction={paginationReset}
        />
      ),
    },
  ]

  const displayList =
    isTrainedFilterApplied || isExpiringFilterApplied
      ? filteredDocumentationList
      : documentationList

  return (
    <table className="documentation__table">
      <TableHeaders
        headers={TABLE_HEADER_SETTINGS}
        callback={setOrder}
        popUpLocation={false}
        updateUrl={false}
        order={order}
        setOrder={setOrder}
      />
      <tbody>
        {displayList.length > 0 ? (
          isTrainedFilterApplied || isExpiringFilterApplied ? (
            filteredDocumentationList.map((documentation, index) => (
              <DocumentationRow
                key={index}
                handleSelect={handleSelect}
                documentation={documentation}
                index={index}
                selectedItems={selectedItems}
                search={search}
                page={page}
              />
            ))
          ) : (
            documentationList.map((documentation, index) => (
              <DocumentationRow
                key={index}
                handleSelect={handleSelect}
                documentation={documentation}
                index={index}
                selectedItems={selectedItems}
                search={search}
                page={page}
              />
            ))
          )
        ) : (
          <tr>
            <td
              colSpan={TABLE_HEADER_SETTINGS.length}
              style={{ textAlign: 'center', padding: '20px' }}
            >
              No results found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

function getFilterStates(stateService) {
  return {
    name: JSON.parse(stateService.params.name || '{}'),
    company: JSON.parse(stateService.params.company || '{}'),
    training_topic: JSON.parse(stateService.params.training_topic || '{}'),
    trained_by: JSON.parse(stateService.params.trained_by || '{}'),
    employee_id: JSON.parse(stateService.params.employee_id || '{}'),
    trade: JSON.parse(stateService.params.trade || '{}'),
  }
}
