import {
  CommonPageHeader,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import React, { useEffect } from 'react'
import { singleHeaderTypes } from '../components'
import { BinDeleteIcon, EditIcon, ViewIcon } from '@/react/componentAssets'
import { CurrentUserType } from '@/react/types/documentation'
import { useStateServiceParams } from '@/react/hooks/useUrlParams'

const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
  {
    className: 'name',
    name: 'Name',
    listLocation: 'admin.service_accounts',
    type: 'name',
    filterHeader: true,
    additionalStyles: { width: '70%', textAlign: 'left' },
  },
  {
    className: '',
    name: 'View',
    listLocation: 'admin.service_accounts',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '10%', textAlign: 'center' },
  },
  {
    className: '',
    name: 'Setup',
    listLocation: 'admin.service_accounts',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '10%', textAlign: 'center' },
  },
  {
    className: '',
    name: 'Delete',
    listLocation: 'admin.service_accounts',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '10%', textAlign: 'center' },
  },
]

export function ServiceAccountsList() {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const { CurrentUser } = useAngularServices()

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const [order, setOrder] = React.useState(stateService.params.order || 'name')
  const [serviceAccountsData, setServiceAccountsData] = React.useState([])
  const [currentServiceAccount, setCurrentServiceAccount] = React.useState(null)
  const { page, setPage, search, setSearch, refresh, setRefresh } =
    useStateServiceParams()
  const fetchServiceAccounts = () => {
    Api.ServiceAccounts.get(
      {
        order,
        search: search || null,
      },
      (resp) => {
        setServiceAccountsData(resp.results)
        setRefresh(false)
      },
    )
  }

  useEffect(() => {
    fetchServiceAccounts()
  }, [page, search, order, refresh])

  const editServiceAccount = (id: number) => {
    stateService.go('admin.service_accounts_edit', {
      id,
      previousSearch: search,
      previousPageNumber: page,
    })
  }

  const deleteServiceAccount = () => {
    if (currentServiceAccount) {
      Api.ServiceAccounts.delete(currentServiceAccount, function (resp) {
        stateService.go('admin.service_accounts', {}, { reload: true })
      })
    }

    setCurrentServiceAccount(null)
  }

  const viewServiceAccount = (id: number) => {
    stateService.go('admin.service_accounts_view', {
      id,
      isShowSecret: false,
      previousSearch: search,
      previousPageNumber: page,
    })
  }

  const createServiceAccount = () => {
    stateService.go('admin.service_accounts_create', {})
  }

  return (
    <>
      <CommonPageHeader headerText="Service Accounts" />
      <section css={baseStyle} className="col-lg-6 col-md-6 col-sm-12">
        <div className="search-base">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
          />
          <a
            className="add-service-account ccs-link"
            onClick={createServiceAccount}
          >
            + Service Account
          </a>
        </div>
        <table className="dual-color-table">
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            popUpLocation={false}
            callback={setOrder}
          />
          {serviceAccountsData.length ? (
            <tbody>
              {serviceAccountsData.map((el) => (
                <tr key={el.id} className="table-row">
                  <td>{el.name}</td>
                  <td
                    className="center"
                    onClick={viewServiceAccount.bind(null, el.id)}
                  >
                    <ViewIcon height={'18px'} color={variableColor} />
                  </td>
                  <td
                    className="center"
                    onClick={editServiceAccount.bind(null, el.id)}
                  >
                    <EditIcon height={'18px'} color={variableColor} />
                  </td>
                  <td
                    className="center"
                    data-toggle="modal"
                    data-target="#deleteServiceAccount"
                    onClick={setCurrentServiceAccount.bind(null, el)}
                  >
                    <BinDeleteIcon height={'18px'} color={variableColor} />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td>No data</td>
              </tr>
            </tbody>
          )}
        </table>
      </section>

      <div
        css={modalStyle}
        className="modal fade"
        id="deleteServiceAccount"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="modalLabel">
                Delete?
              </h4>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-default-general btn-default-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-default-general btn-default-primary btn-ok"
                data-dismiss="modal"
                onClick={deleteServiceAccount}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  marginTop: '15px',
  table: {
    margin: '20px 0',
  },
  '.search-base': {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0',
  },
  '.add-service-account': {
    fontSize: '16px',
    fontWeight: 600,
    marginLeft: '15px',
  },
  '.dual-color-table': {
    marginTop: 0,
    thead: {
      borderBottom: '1px solid #e7eaec',
      height: '25px',
    },
    tbody: {
      tr: {
        background: '#fff',
      },
      'tr:nth-of-type(odd)': {
        background: '#f9f9f9',
      },
    },
  },
  svg: {
    cursor: 'pointer',
  },
  '.center': {
    textAlign: 'center',
  },
  '.table-row': {
    height: '30px',
  },
})

const modalStyle = css({
  '.modal-dialog': {
    height: '90%',
    paddingTop: '15%',
    maxWidth: '400px',
  },
  '.modal-title': {
    textAlign: 'center',
    marginTop: '10px',
  },
  '.modal-header': {
    border: 'none',
  },
  '.modal-body': {
    padding: '10px 30px',
  },
  '.modal-footer': {
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    '.btn-ok': {
      marginLeft: 10,
    },
  },
})
