import { EditIcon } from '@/react/componentAssets'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

interface Training {
  id: number
  active_users_count: number
}

interface TrainingRowProps {
  training: Training
  index: number
  search: string
  page: number
}

export const TrainingRow = ({
  training,
  index,
  search,
  page,
}: TrainingRowProps) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const { stateService } = useRouter()
  const [hoveredField, setHoveredField] = useState(null)

  return (
    <tr
      className={classNames('training__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__cell"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: training?.name,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={training?.name}
        />
      </td>
      <td>
        <div
          className="edit__cell"
          onClick={() => {
            stateService.go('trainings.assign.employee_assign', {
              training: training?.id,
              previousPageNumber: page,
              previousSearch: search,
            })
          }}
        >
          <EditIcon color={variableColor} height="17px" />
          <p className="projects__count">{training.active_users_count}</p>
        </div>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
