import { css } from '@emotion/react'
import { AssessmentResults } from './components/AssessmentResults'
import { useAngularServices } from '@/react/components'
import { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { Assessment } from '@/react/types/assessment'

export const FinalAssessment = () => {
  const { Api, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [assessment, setAssessment] = useState<Assessment | null>(null)

  useEffect(() => {
    const getAssessmentData = async () => {
      try {
        const { data } = await Api.getWithoutDeletedParam<Assessment>(
          `assessment/external_assessments/${stateService.params.client_object_key}`,
          {
            final_view: true,
          },
        )
        setAssessment(data)
      } catch (error) {
        Notification.clearNotification()
        setAssessment(error?.data)
      }
    }

    getAssessmentData()
  }, [])

  const variableColor =
    assessment?.client_settings?.general_settings?.colors.secondary_color ||
    '#2167AE'

  const styles = getStyles(variableColor, assessment?.error_message)

  return (
    <div
      className="external__wrapper"
      css={styles}
      style={{ paddingTop: assessment?.error_message ? '20px' : '0px' }}
    >
      {assessment?.template_name ? (
        <div className="logo__wrapper">
          <img
            className="logo"
            src={assessment?.client_settings?.company?.logo_url || ''}
            alt="Company Logo"
          />
        </div>
      ) : null}
      <div className="results_block">
        {assessment && <AssessmentResults assessment={assessment} />}
      </div>
    </div>
  )
}

const getStyles = (variableColor: string) =>
  css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: variableColor,
    height: '100svh',
    overflow: 'scroll',
    paddingBottom: '20px',
    '.logo__wrapper': {
      marginTop: '39px',
      marginBottom: '40px',
      maxWidth: '300px',
      maxHeight: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.pace-running': {
      background: variableColor,
    },
    '.logo': {
      maxWidth: '154px',
      maxHeight: '51px',
      height: 'auto',
      width: 'auto',
    },
  })
