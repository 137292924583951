import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { CheckboxProps } from '@/react/types'

const Checkbox = ({
  name,
  checked,
  onChange,
  variableColor,
}: CheckboxProps) => {
  return (
    <div className="checkmark__block">
      <p className="checkmark__title">{name}</p>
      <label className="checkbox-container">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark">
          <ThinCheckmark color={variableColor} />
        </span>
      </label>
    </div>
  )
}

export default Checkbox
