import { cloneDeep, filter, flatMap } from 'lodash'
import $ from 'jquery'
import {
  ApiService,
  DataWrap,
  GeneralSettings,
  GenericOption,
  GeneticTextResult,
  NoteType,
  ObservationBody,
  PaginatedGet,
  ReportAnswer,
  ReportQuestion,
  ReportSettings,
  SignatureType,
  UIRouterState,
} from '@/react/types'
import moment from 'moment'
import { generateUUID } from '@/utils'
import { Dispatch, SetStateAction } from 'react'

export const fetchData = async (
  Api: ApiService,
  requestParams: {
    deleted: string
    observation: number
    page_size: number
    application: number
  },
  observationID: string | number,
  userId: string | number,
  setPartyObservedOptions: (value: GenericOption[]) => void,
  setBaseObservationData: (value: any) => void,
  setQuestionData: (value: any) => void,
  setNotes: (value: any) => void,
  setSignatures: (value: any) => void,
  setMemoryState: (value: any) => void,
  setSettings: (value: any) => void,
  ANSWER: ReportAnswer,
  setWildcard: (value: string) => void,
  setWildcardId: (value: number) => void,
  setTitle: (value: string) => void,
  setTitleManuallyEdited: (value: boolean) => void,
) => {
  const [
    { data: observationData },
    { data: generalSettings },
    { data: reportSettings },
    { data: globalPO },
    { data: userPO },
  ]: [
    DataWrap<ObservationBody>,
    DataWrap<PaginatedGet<GeneralSettings[]>>,
    DataWrap<PaginatedGet<ReportSettings[]>>,
    DataWrap<PaginatedGet<GeneticTextResult[]>>,
    DataWrap<PaginatedGet<GeneticTextResult[]>>,
  ] = await Promise.all([
    Api.Observations.byIDWithoutDeletedParam(observationID),
    Api.GeneralSettings.get(),
    Api.ReportSettings.get(),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: false,
    }),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: true,
      user: parseInt(userId),
    }),
  ])

  const questions: ReportQuestion[] = []
  const answers: ReportAnswer[] = []
  const questionsPromises: Promise<DataWrap<PaginatedGet<ReportQuestion[]>>>[] =
    []

  const PAGE_REQUEST_SIZE = 1000
  const questionPagesToGet: number = Math.ceil(
    observationData.questions / PAGE_REQUEST_SIZE,
  )

  for (let i = 1; i < questionPagesToGet + 1; i++) {
    questionsPromises.push(
      Api.Questions.get({
        ...requestParams,
        page_size: PAGE_REQUEST_SIZE,
        page: i,
      }),
    )
  }

  const questionsGet: DataWrap<PaginatedGet<ReportQuestion[]>>[] =
    await Promise.all(questionsPromises)

  questionsGet.forEach((singleQuestionGet) => {
    singleQuestionGet.data.results.forEach((question) => {
      if (question.custom_categories.length) {
        question.custom_categories.forEach((category) => {
          const newQuestion = cloneDeep(question)
          newQuestion.category = category
          questions.push(newQuestion)
        })
      }
    })
  })

  const answerPagesToGet: number = Math.ceil(
    observationData.answers_count / PAGE_REQUEST_SIZE,
  )

  const answersPromises: Promise<DataWrap<PaginatedGet<ReportAnswer[]>>>[] = []

  for (let i = 1; i < answerPagesToGet + 1; i++) {
    answersPromises.push(
      Api.Answers.get({
        ...requestParams,
        page_size: PAGE_REQUEST_SIZE,
        page: i,
      }),
    )
  }

  const answersGet: DataWrap<PaginatedGet<ReportAnswer[]>>[] =
    await Promise.all(answersPromises)

  answersGet.forEach((singleAnswerGet) => {
    singleAnswerGet.data.results.forEach((answer) => {
      answers.push(answer)
    })
  })

  const optionsArray: GenericOption[] = []
  const optionIdToOrder: { [key: string | number]: number } = {}

  globalPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = idx
  })
  userPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = optionsArray.length + idx - 1
  })
  setPartyObservedOptions(optionsArray.sort(compare))

  const settingsData = {
    generalSettings: generalSettings.results[0],
    reportSettings: reportSettings.results[0],
  }

  if (
    observationData.observation_wildcards &&
    observationData.observation_wildcards.length
  ) {
    observationData.observation_wildcards.forEach((wildcard) => {
      if (!wildcard.deleted) {
        setWildcard(wildcard.text)
        setWildcardId(wildcard.id)
      }
    })
  }
  if (observationData.title) {
    setTitle(observationData.title)
    setTitleManuallyEdited(true)
  }
  setBaseObservationData(observationData)
  setQuestionData(questions)
  setNotes(notesProcessing(observationData.observation_notes))
  setSignatures(signaturesProcessing(observationData.signatures))

  setMemoryState(listProcessing(questions, answers, observationID, ANSWER))
  setSettings(settingsData)
}

export async function sendForm(
  memoryState: {
    [key: string]: ReportAnswer[]
  },
  Api: ApiService,
  signatures: SignatureType[],
  notes: NoteType[],
  observationID: number,
  stateService: UIRouterState,
  setDisableButton: (arg0: boolean) => void,
  wildcard: string,
  wildcardId: number,
  baseObservationData: ObservationBody,
  onAnswerEdit: boolean = false,
  titleManuallyEdited?: boolean,
) {
  const notFilledCA = $('.needed-ca-not-filled')
  if (!onAnswerEdit) {
    if (notFilledCA.length !== 0) {
      document.getElementById(notFilledCA[0].id).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
      return
    }
  }

  if (!onAnswerEdit) {
    await sendAnswers(Api, memoryState, observationID, '0')
  }

  const allowActivate =
    baseObservationData.client.report_settings.wildcard_required &&
    baseObservationData.client.report_settings.wildcard_active
      ? wildcard.length !== 0
      : true

  if (onAnswerEdit && titleManuallyEdited) {
    await Api.Observations.patch({
      title: baseObservationData.title,
      id: observationID,
      is_active: true,
    })
    if (!baseObservationData.is_active && allowActivate)
      await Api.put('observations/' + observationID + '/activate/', {})
    return
  }

  const initialData = flatMap(cloneDeep(memoryState))
  setDisableButton(true)
  const promiseList: Promise<DataWrap<any>>[] = []
  const signaturePromiseList: Promise<DataWrap<any>>[] = []

  const categoriesWithAnswers: number[] = []

  for (const answer of initialData) {
    if (
      answer &&
      answer?.answer !== 'n/a' &&
      !categoriesWithAnswers.includes(answer?.category)
    ) {
      categoriesWithAnswers.push(answer.category)
    }
  }

  if (!baseObservationData.title || (onAnswerEdit && !titleManuallyEdited)) {
    if (categoriesWithAnswers.length === 0) {
      baseObservationData.title =
        'No answers filled - ' +
        moment(
          baseObservationData.date_performed ||
            baseObservationData.date_created,
        ).format('MM/DD/YYYY')
    } else if (categoriesWithAnswers.length > 1) {
      const variousName = baseObservationData?.project?.name || 'Various'
      baseObservationData.title =
        variousName +
        ' - ' +
        moment(
          baseObservationData.date_performed ||
            baseObservationData.date_created,
        ).format('MM/DD/YYYY')
    } else if (categoriesWithAnswers.length === 1) {
      baseObservationData.categories.forEach((cat) => {
        if (cat.id === categoriesWithAnswers[0]) {
          baseObservationData.title =
            cat.name +
            ' - ' +
            moment(
              baseObservationData.date_performed ||
                baseObservationData.date_created,
            ).format('MM/DD/YYYY')
        }
      })
    }
  }

  if (onAnswerEdit) {
    await Api.Observations.patch({
      title: baseObservationData.title,
      id: observationID,
      is_active: true,
    })
    if (!baseObservationData.is_active && allowActivate) {
      await Api.put('observations/' + observationID + '/activate/', {})
      baseObservationData.is_active = true
    }
  } else {
    if (wildcardId && wildcard && allowActivate) {
      promiseList.push(
        Api.patch('observation_wildcards/' + wildcardId, {
          text: wildcard,
          observation: observationID,
          id: wildcardId,
        }),
      )
    } else if (wildcardId && allowActivate) {
      promiseList.push(
        Api.delete('observation_wildcards/' + wildcardId, {
          observation: observationID,
          id: wildcardId,
        }),
      )
    } else {
      if (wildcard && allowActivate) {
        promiseList.push(
          Api.post('observation_wildcards', {
            observation: observationID,
            text: wildcard,
          }),
        )
      }
    }

    signatures.forEach((signature) => {
      if (signature.deleted) {
        signaturePromiseList.push(Api.removeSignature(signature.id))
      } else if (signature.id) {
        signaturePromiseList.push(Api.Signatures.patch(signature))
      } else {
        signaturePromiseList.push(Api.Signatures.post(signature))
      }
    })
    notes.forEach((note) => {
      if ((note.deleted && note.id) || (note.text === '' && note.id)) {
        promiseList.push(Api.ObservationNotes.delete({ id: note.id }))
      } else if (note.id) {
        promiseList.push(Api.ObservationNotes.patch(note))
      } else if (note.deleted) {
        return
      } else if (note.text) {
        Api.ObservationNotes.post(note)
      }
    })

    await Promise.all([...promiseList])
    const signatureIdArray = []
    await Promise.all([...signaturePromiseList]).then((values) => {
      values.forEach((value) => {
        signatureIdArray.push(value.data.id)
      })
    })
    await Api.Observations.patch({
      title: baseObservationData.title,
      id: observationID,
      signatures: signatureIdArray,
      is_active: true,
    })
    await Api.put('observations/' + observationID + '/synchronised/', {
      synchronised: true,
    })
    if (allowActivate)
      await Api.put('observations/' + observationID + '/activate/', {})
    stateService.go('app.observations.list', {
      pageNumber: stateService.params.previousPageNumber,
      search: stateService.params.previousSearch,
      order: stateService.params.previousOrder,
      reverse: stateService.params.previousReverse,
    })
  }
  setDisableButton(false)
}

function listProcessing(questions, answers, observationID, ANSWER) {
  const questionsFormData = {}

  questions.forEach((question) => {
    if (question.custom_categories.length) {
      question.custom_categories.forEach((category) => {
        if (category)
          questionsFormData[question.id + '-' + category] = [
            {
              ...ANSWER,
              observation: observationID,
              question: question.id,
              category: category,
            },
          ]
      })
    }
  })

  answers.forEach((answer) => {
    const notes = []
    const corrective_actions = []
    const photos = []
    answer.notes.forEach((note) => {
      if (!note.deleted) {
        notes.push({
          id: note.id,
          text: note.text,
          fieldmeta: {
            text: {
              last_updated: answer.date_updated,
              last_updated_by_user_id: note?.user?.id,
            },
          },
        })
      }
    })
    answer.corrective_actions.forEach((corrective_action) => {
      if (!corrective_action.deleted) {
        corrective_actions.push({
          id: corrective_action.id,
          text: corrective_action.text,
          fieldmeta: {
            text: {
              last_updated: corrective_action.date_updated,
              last_updated_by_user_id: corrective_action?.user?.id,
            },
          },
        })
      }
    })
    answer.photos.forEach((photo) => {
      if (!photo.deleted) {
        photos.push({ id: photo.id, image: photo.image })
      }
    })
    questionsFormData[answer.question + '-' + answer.category][
      answer.copy_sequence_number
    ] = {
      ...ANSWER,
      observation: observationID,
      question: answer.question,
      answer: answer.answer,
      initialAnswer: answer.answer,
      reference: answer.reference,
      party_observed: answer.party_observed,
      severity: answer.severity,
      notes: notes,
      corrective_actions: corrective_actions,
      photos: photos,
      copy_sequence_number: answer.copy_sequence_number,
      answer_party_observed_answer_list:
        answer.answer_party_observed_answer_list,
      category:
        questionsFormData[answer.question + '-' + answer.category][0].category,
    }
    if (answer.id) {
      questionsFormData[answer.question + '-' + answer.category][
        answer.copy_sequence_number
      ].id = answer.id
    }
  })

  return questionsFormData
}

function signaturesProcessing(signatures) {
  const processedSignatures = []
  signatures.forEach((signature) => {
    if (!signature.deleted) {
      processedSignatures.push({
        id: signature.id,
        image: signature.image.id,
        url: signature.image.image,
        printed_name: signature.printed_name,
      })
    }
  })
  return processedSignatures
}

function notesProcessing(notes) {
  const processedNotes = []
  notes.forEach((note) => {
    if (!note.deleted) {
      processedNotes.push({
        id: note.id,
        text: note.text,
        observation: note.observation,
      })
    }
  })
  return processedNotes
}

export function compare(a: { label: string }, b: { label: string }) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1
  }
  return 0
}

export async function processSingleAnswer(
  changedFields: { field: string; value: any }[],
  Api,
  Notification,
  questionElementId: string,
  setMemoryState: Dispatch<
    SetStateAction<{ [key: string]: ReportAnswer[] } | null>
  >,
  copySequenceNumber: number,
  observationId,
) {
  let answer: ReportAnswer = {}
  let answers: ReportAnswer = []

  setMemoryState((prevState: { [key: string]: ReportAnswer[] }) => {
    if (!prevState) return
    const newState = cloneDeep(prevState)
    changedFields.forEach(({ field, value }) => {
      newState[questionElementId][copySequenceNumber][field] = value
      newState.edited = true
    })

    answer = newState[questionElementId][copySequenceNumber]
    answers = newState

    return newState
  })

  const { data: answersResponse } = await sendAnswers(
    Api,
    answers,
    observationId,
    questionElementId,
  )

  setMemoryState((prevState: { [key: string]: ReportAnswer[] }) => {
    if (!prevState) return
    const newState = cloneDeep(prevState)

    answersResponse.forEach((answer) => {
      newState[answer.question + '-' + answer.category].forEach(
        (answerLink) => {
          answerLink.edited = false
          if (answer.notes)
            answerLink.notes = combineLocalAndDataVariableFields(
              answerLink.notes,
              answer.notes,
            )
          if (answer.corrective_actions)
            answerLink.corrective_actions = combineLocalAndDataVariableFields(
              answerLink.corrective_actions,
              answer.corrective_actions,
            )
        },
      )
    })

    return newState
  })

  if (
    answer.answer === 'cls' &&
    !checkVariableFieldsForText(answer.corrective_actions)
  ) {
    Notification.danger('Corrective Action is Required.')
    return answer.id
  }
  return answer.id
}

const sendAnswers = async (
  Api,
  answers: { [key: string]: ReportAnswer[] },
  observationID,
  id: string,
) => {
  const promiseList: Promise<DataWrap<any>>[] = []
  const answerList: Promise<DataWrap<any>>[] = []

  flatMap(cloneDeep(answers))
    .filter((answer) => answer !== undefined)
    .forEach((answer) => {
      const questionElementId = answer.question + '-' + answer.category

      if (answer.edited && questionElementId !== id) {
        const allAreas = $('#' + questionElementId + ' textarea')
        allAreas.prop('readonly', true)

        if (answer.photos)
          answer.photos.forEach((photo, idx) => {
            if (photo.deleted) {
              promiseList.push(Api.removeImage(photo.id))
            }
          })

        const answerToSend: ReportAnswer = {
          id: answer.id,
          question: answer.question,
          category: answer.category,
          copy_sequence_number: answer.copy_sequence_number,
        }

        if (answer.notes && answer.notes.length) {
          answerToSend.notes = cloneDeep(answer.notes || []).filter((note) => {
            if (note.deleted && !note.id) return false
            if (note.deleted && note.id) {
              note.text = generateUUID()
              note.deleted = true
              return true
            }
            if (note.text.length) return true
            if (note.id) {
              note.text = generateUUID()
              note.deleted = true
              return true
            }
          })
        }
        if (answer.photos && answer.photos.length) {
          answerToSend.photos = cloneDeep(answer.photos || [])
            .filter((photo) => !photo.deleted)
            .map((photo) => photo.id)
        }
        if (answer.corrective_actions && answer.corrective_actions.length) {
          answerToSend.corrective_actions = cloneDeep(
            answer.corrective_actions,
          ).filter((correctiveAction) => {
            if (correctiveAction.deleted && !correctiveAction.id) return false
            if (correctiveAction.deleted && correctiveAction.id) {
              correctiveAction.text = generateUUID()
              correctiveAction.deleted = true
              return true
            }
            if (correctiveAction.text.length) return true
            if (correctiveAction.id) {
              correctiveAction.text = generateUUID()
              correctiveAction.deleted = true
              return true
            }
          })
        }
        answerToSend.reference = answer.reference
        answerToSend.answer = answer.answer
        answerToSend.severity = answer.severity
        answerList.push(answerToSend)
        allAreas.prop('readonly', false)
      }
    })
  if (!answerList.length) {
    return { data: [] }
  } else {
    await Promise.all([...promiseList])
    return await Api.post(`observations/` + observationID + `/answers`, {
      answers: answerList || [],
    })
  }
}

export async function initialSingleAnswer(answer, Api, observationID) {
  const {
    data: answerWithId,
  }: DataWrap<{ id: number; observation: number; category: number }> =
    await Api.post(`observations/` + observationID + `/answers`, {
      answers: [
        {
          answer: answer.answer === 'cls' ? 'n/a' : answer.answer,
          deleted: false,
          party_observed: [],
          severity: answer.severity,
          category: answer.category,
          answer_party_observed_answer_list: [],
          copy_sequence_number: 0,
          observation: answer.observation,
          question: answer.question,
          reference: '',
          notes: [],
          corrective_actions: [],
          photos: [],
          party_observed_easy_acces_data: {},
        },
      ],
    })
  answer.id = answerWithId[0].id
  return answerWithId[0].id
}

type field = {
  text: string
  id: number
  deleted: boolean
}

const combineLocalAndDataVariableFields = (
  localFields: field[],
  responseFields: field[],
) => {
  const usedIds: number[] = []
  return localFields
    .filter((field) => !!field)
    .map((field: field) => {
      if (field.text === '') return field
      if (field.id) return field
      if (field.deleted) return field
      let fieldToReturn
      responseFields.reverse().forEach((resField) => {
        if (resField.text === field.text && !usedIds.includes(resField.id)) {
          fieldToReturn = resField
          usedIds.push(resField.id)
        }
      })
      return fieldToReturn
    })
}

export const checkVariableFieldsForText = (fields: field[]) => {
  let isThereText = false
  for (const field of fields) {
    if (field.text && !field.deleted) {
      isThereText = true
      break
    }
  }
  return isThereText
}
