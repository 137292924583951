import {
  ButtonElement,
  CommonPageHeader,
  ImageWithPreview,
  useAngularServices,
} from '@/react/components'
import * as React from 'react'
import { css } from '@emotion/react'
import { useEffect, useState, useRef } from 'react'
import { useRouter } from '@/react/hooks'
import moment from 'moment'
import { Alert } from '@screens/ToolboxTalks/components/Alert'
import StringUtil from '@/utils/autolinks'
import { printPageWithFirefoxWarning } from '@/utils/printConnected'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const ViewInternalPage = () => {
  const { $rootScope, Api } = useAngularServices()
  const { stateService } = useRouter()
  const [data, setData] = useState(null)
  const [isSynchronized, setIsSynchronized] = useState(null)
  const [popUp, setPopUp] = useState<Element | null>(null)
  const [error, setError] = useState(null)

  const isToolboxSynchronised = () => {
    Api.ToolboxTalks.byID(stateService.params.toolbox, (res) => {
      setIsSynchronized(res.synchronised)
      if (res.synchronised) {
        setError(null)
      }
    })
  }

  const isSafari = checkIsSafari()

  const intervalRef = useRef(null)

  const waitForToolboxSync = () => {
    intervalRef.current = setInterval(() => {
      if (isSynchronized) {
        getToolboxTalksDataById()
        clearInterval(intervalRef.current)
      } else {
        isToolboxSynchronised()
      }
    }, 1000)
  }

  useEffect(() => {
    if (!isSynchronized) {
      waitForToolboxSync()
    } else {
      clearInterval(intervalRef.current)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isSynchronized])

  const getToolboxTalksDataById = () => {
    Api.ToolboxTalks.byIDWithoutDeletedParam(
      stateService.params.toolbox,
      async (res) => {
        const resData = res
        resData.signatures.map((signature) => {
          signature.isEditing = false
          return signature
        })

        setIsSynchronized(res.synchronised)
        setError(
          !res.synchronised
            ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
            : null,
        )

        await Api.ToolboxTopics.byIDWithoutDeletedParam(
          resData.topic,
          (res) => {
            resData.topic = res
          },
        )

        await Api.Projects.byID(resData.project, (res) => {
          resData.project = res
        })

        if (resData.user) {
          await Api.Users.byID(resData.user, (res) => {
            resData.user = res
          })
        }
        await Api.GeneralSettings.get({}, function (res) {
          if (res.results.length) {
            resData.settings = res.results[0]
          }
        })

        setData(resData)
      },
    )
  }

  useEffect(() => {
    getToolboxTalksDataById()
  }, [])

  const goBack = () => {
    const url =
      stateService.params.fromDeleted === 'true'
        ? 'app.toolboxes.deleted'
        : 'app.toolboxes.list'

    stateService.go(
      url,
      {
        app: $rootScope.$state.params.app,
        pageNumber: $rootScope.$state.params.previousPageNumber,
        order: $rootScope.$state.params.previousOrder,
        reverse: $rootScope.$state.params.previousReverse,
        search: $rootScope.$state.params.previousSearch,
        ...(stateService.params.deleted && { deleted: true }),
      },
      { reload: true },
      {
        pageNumber: stateService.params.previousPageNumber,
        search: stateService.params.previousSearch,
      },
    )
  }

  if (!data) return null

  return (
    <section css={css({ paddingBottom: 165 })}>
      {error && <Alert text={error} type="danger" />}
      <CommonPageHeader headerText="View" />
      <main css={baseStyle(isSafari)}>
        <div className="view-internal-base-page">
          <div className="header-base">
            <div className="logo-holder">
              {data.user && (
                <img
                  className="logo"
                  src={data.user.client.company.logo_url}
                  alt="logo"
                />
              )}
            </div>
            <div className="info-text-block-wrapper">
              <div className="sub-header">
                <div className="page-stat-title">TOPIC:</div>
                <div className="page-stat-desc page-stat-border-bottom">
                  {data.topic.name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">PROJECT:</div>
                <div className="page-stat-desc page-stat-border-bottom">
                  {data.project.name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">PERFORMED BY:</div>
                <div className="page-stat-desc">
                  {data.user?.first_name} {data.user?.last_name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">CREATED:</div>
                <div className="page-stat-desc">
                  {moment(data.date_created).format('MM/DD/YY - hh:mmA')}
                </div>
              </div>
            </div>
          </div>
          {data.topic.summary?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">SUMMARY</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(data.topic.summary),
                }}
              ></div>
            </div>
          )}
          {data.topic.importance?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">WHY IS IT IMPORTANT?</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(data.topic.importance),
                }}
              ></div>
            </div>
          )}
          {data.topic.consider?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">THINGS TO CONSIDER</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(data.topic.consider),
                }}
              ></div>
            </div>
          )}
          {data.topic.discussion?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">DISCUSSION ITEMS</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(data.topic.discussion),
                }}
              ></div>
            </div>
          )}
          {data.notes?.length > 0 && data.notes.some((note) => !note.deleted) && (
            <div className="content-block">
              <h3 className="content-block-title">NOTES</h3>
              <ul>
                {data.notes
                  .filter((note) => !note.deleted)
                  .map((note) => {
                    return (
                      <li key={note.id} className="note-item">
                        <div style={{ whiteSpace: 'pre-wrap', lineHeight: 1 }}>
                          <span>
                            {moment(data.date_created).format(
                              'MM/DD/YY - hh:mmA',
                            )}{' '}
                            {note.user.first_name} {note.user.last_name} -&nbsp;
                          </span>
                          <span>{note.text}</span>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          )}
          {data.photos?.length > 0 &&
            data.photos.some((photo) => !photo.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">PHOTOS</h3>
                <ul className="photo-holder">
                  {data.photos
                    .filter((photo) => !photo.deleted)
                    .map((photo) => {
                      return (
                        <li key={photo.id} className="photo-item">
                          <ImageWithPreview
                            photo={photo}
                            photoIdx={photo.id}
                            onlyImage={true}
                          />
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {data.signatures?.length > 0 &&
            data.signatures.some((signature) => !signature.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">SIGNATURES</h3>
                <ul className="signature-holder">
                  {data.signatures
                    .filter((signature) => !signature.deleted)
                    .map((signature) => {
                      return (
                        <li
                          key={signature.image.id}
                          className="signature-item-holder"
                        >
                          <div className="signature-item">
                            <img
                              className="s-img"
                              src={signature.image.image}
                              alt="signature"
                            />
                            <div className="signature-info">
                              <div className="signature-name">
                                {signature.printed_name &&
                                  signature.printed_name}
                              </div>
                              <div className="signature-company">
                                {signature.company_name}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {data.settings.disclaimer?.length > 0 && (
            <div
              className="content-block-text"
              style={{ marginTop: '35px', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: StringUtil.parseHyperlinks(data.settings.disclaimer),
              }}
            ></div>
          )}
          <div className="button-print content-block button-wrapper">
            <ButtonElement
              text="Print"
              width="77px"
              buttonType="neutral"
              functionToTrigger={() => {
                printPageWithFirefoxWarning(setPopUp)
              }}
            />
            <ButtonElement
              text="Back"
              width="75px"
              buttonType="neutral"
              functionToTrigger={goBack}
            />
          </div>
        </div>
      </main>
      {popUp}
    </section>
  )
}

const baseStyle = (isSafari: boolean) =>
  css({
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    marginTop: '2vw',
    '.header-base': {
      display: 'block',
      '.logo-holder': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 145,
        width: 145,
        marginRight: 39,
        verticalAlign: 'top',
        '.logo': {
          width: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
          height: 'auto',
        },
      },
      '.info-text-block-wrapper': {
        verticalAlign: 'top',
        display: 'inline-block',
        width: 'calc(90% - 145px)',
        '.sub-header': {
          display: 'inline-block',
          width: '50%',
          paddingBottom: 15,
          '@media (max-width: 553px)': {
            display: 'block',
          },
        },
      },
    },
    '.photo-holder': {
      display: isSafari ? 'block' : 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      '.photo-item': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 15,
        paddingRight: 15,
        width: 'calc(33.3333% - 30px)',
      },
    },
    '.signature-holder': {
      display: isSafari ? 'block' : 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      '.signature-item-holder': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 10,
        paddingRight: 10,
        '.signature-item': {
          display: 'inline-block',
          width: 135,
          '.s-img': {
            width: '100%',
            border: '1.5px solid lightgray',
          },
        },
      },
    },
    '.view-internal-base-page': {
      padding: '25px',
    },
    '.content-block': {
      padding: '15px 0 0',
      paddingTop: '25px',
      borderTop: '1px solid #f2f2f2',
    },
    '.content-block-title': {
      pageBreakAfter: 'auto',
      breakAfter: 'auto',
      pageBreakBefore: 'auto',
      breakBefore: 'auto',
      fontSize: '14px',
      fontWeight: 700,
      color: '#3980CE',
      lineHeight: '19px',
    },
    '.content-block-text': {
      color: '#7F8183',
      fontSize: '13px',
      lineHeight: '17.7px',
    },
    '.page-stat-title': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#a9a9a9',
      lineHeight: '19px',
      paddingBottom: '4px',
    },
    '.page-stat-desc': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#3980CE',
      lineHeight: '19px',
    },
    '.page-stat-border-bottom': {
      padding: '0 0 20px',
      '@media (min-width: 554px)': {
        borderBottom: '1px solid #eee',
      },
    },
    '.gray': {
      color: '#959595',
    },
    '.page-title': {
      fontSize: '24px',
      color: '#686A6C',
      textAlign: 'center',
      margin: '10px 0 0',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    li: {
      padding: '0',
    },
    '.note-item': {
      padding: '3px 0',
    },
    '.singleImage': {
      marginTop: '0',
      marginRight: '0',
      width: 'auto',
    },
    '.signature-info': {
      maxWidth: '135px',
      paddingTop: '10px',
      fontSize: '9px',
    },
    '.signature-name': {
      color: '#000',
    },
    '.signature-company': {
      color: '#808080',
    },
    '.button-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '25px 0 0',
    },
    '@media print': {
      '.button-print': {
        display: 'none',
      },
      '.signature-item-holder': {},
    },
  })
