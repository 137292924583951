import { ButtonElement, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'
import useClickOutside from '../hooks/useClickOutside'

export const GroupAssignModal = ({
  setPopUpVisible,
  defaultId,
  groupId,
  setRefresh,
  requestUrl,
}) => {
  const { Api } = useAngularServices()

  const popupRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(popupRef, setPopUpVisible)

  const handleClick = async () => {
    if (!defaultId || defaultId !== groupId) {
      await Api.patch(`${requestUrl}/${groupId}`, {
        id: groupId,
        is_default: true,
      })
      setRefresh(true)
    }
    if (defaultId === groupId) {
      await Api.patch(`${requestUrl}/${groupId}`, {
        id: groupId,
        is_default: false,
      })
      setRefresh(true)
    }

    setPopUpVisible(false)
  }

  return (
    <div css={baseStyle} ref={popupRef}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={() => {
          setPopUpVisible(false)
        }}
      />
      <p className="popup__action">
        {defaultId === groupId && 'Turn off Default Group?'}
        {!defaultId && 'Group Confirmation'}
        {defaultId && defaultId !== groupId && 'Update Default Group?'}
      </p>
      <div className="popup__title">
        {defaultId === groupId &&
          'This group will no longer be active for future projects.'}
        {!defaultId &&
          'Make this group active for all existing and future projects?'}
        {defaultId &&
          defaultId !== groupId &&
          'Only one default group can be active at a time. Existing default group will be removed.'}
      </div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '14px' })}
        functionToTrigger={() => {
          handleClick()
        }}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 200000,
  width: '275px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__action': {
    fontSize: '14px',
    color: '#666666',
    fontWeight: 600,
    margin: 0,
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    paddingTop: '10px',
    paddingInline: '44px',
    paddingBottom: '18px',
  },
})
