import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { NavBar } from '../LMSCommonComponents/NavBar'
import { Field, FieldArray, Formik } from 'formik'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import classNames from 'classnames'
import { TextInputStyle } from '@screens/components'
import { QuizQuestion } from './components/QuizQuestion'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import * as Yup from 'yup'
import { generateUUID } from '@/utils'
import { CurrentUserType } from '@/react/types/documentation'

const scheduleIdleCallback = (fn: () => void) => {
  if ('requestIdleCallback' in window) {
    return (window as any).requestIdleCallback(fn)
  } else {
    return window.setTimeout(fn, 200)
  }
}

const cancelScheduledIdleCallback = (id: number) => {
  if ('cancelIdleCallback' in window) {
    (window as any).cancelIdleCallback(id)
  } else {
    clearTimeout(id)
  }
}

export const TrainingQuizCreateEdit = () => {
  const { CurrentUser, Api } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const baseStyle = getStyles(variableColor)
  const { stateService } = useRouter()

  const [initialValues, setInitialValues] = useState(null)
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [title, setTitle] = useState('')
  const [visibleCount, setVisibleCount] = useState(0)
  const [isPristine, setIsPristine] = useState(true)

  const markChanged = () => {
    if (isPristine) setIsPristine(false)
  }

  const atLeastOneTrueAnswer = Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Option name is required'),
        answer: Yup.boolean().required(),
      })
    )
    .test(
      'at-least-one-true-answer',
      'At least one option must be marked as correct',
      (options) => options?.some((option) => option.answer === true)
    )
    .test(
      'no-duplicate-options',
      'Duplicate answer options are not allowed',
      (options) => {
        const names = options.map((opt) => opt.name?.trim().toLowerCase())
        return new Set(names).size === names.length
      }
    )

  const validationSchema = Yup.object().shape({
    quiz_name: Yup.string().required('Quiz name is required'),
    quiz_passing_score: Yup.string().required('Quiz passing score is required'),
    training_fields: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          options: atLeastOneTrueAnswer.required('Options are required'),
          order: Yup.string().required('Order is required'),
          required: Yup.boolean().required('Required field is required'),
          type: Yup.string()
            .oneOf(['multi', 'true_false'])
            .required('Type is required'),
        })
      )
      .required('Training fields are required')
  })

  useEffect(() => {
    const contentId = stateService.params?.contentId

    const getQuizData = async () => {
      const trainingId = stateService?.params?.trainingID

      const { data } = await Api.get(`trainings/${trainingId}`, {})

      setTitle(data?.name)

      if (contentId) {
        const { data } = await Api.get(`training_content/${contentId}`, {
          deleted: false,
        })

        setInitialValues({
          ...data,
          training_fields: data?.fields?.map((answer) => {
            return {
              ...answer,
              options: answer?.answer_options,
            }
          }),
        })
      } else {
        setInitialValues({
          quiz_name: stateService?.params?.quizName,
          quiz_passing_score: '',
          training_fields: [
            {
              name: '',
              options: [
                {
                  name: '',
                  weight: '',
                  id: generateUUID(),
                  answer: false,
                },
              ],
              order: 1,
              required: true,
              type: 'multi',
            },
          ],
        })
      }
    }

    getQuizData()
  }, [])

  useEffect(() => {
    if (initialValues?.training_fields?.length) {
      if (initialValues.training_fields.length > 20) {
        setVisibleCount(5)
      } else {
        setVisibleCount(initialValues.training_fields.length)
      }
    }
  }, [initialValues])

  useEffect(() => {
    if (
      initialValues?.training_fields?.length > 20 &&
      visibleCount < initialValues.training_fields.length
    ) {
      const idleCallbackId = scheduleIdleCallback(() => {
        setVisibleCount((prev) =>
          Math.min(prev + 5, initialValues.training_fields.length),
        )
      })

      return () => cancelScheduledIdleCallback(idleCallbackId)
    }
  }, [visibleCount, initialValues])

  const handleCreateEdit = async (values) => {
    const { quiz_name, quiz_passing_score, training_fields } = values
    const topicId = stateService?.params?.topicId
    const contentId = stateService?.params?.contentId
    const topicOrder = stateService?.params?.topicOrder

    setButtonDisabled(true)

    if (contentId) {
      await Api.delete(`training_content/${contentId}`)
    }

    const { data: contentResponse } = await Api.post('training_content', {
      content_type: 'quiz',
      completion_code: null,
      description: null,
      name: quiz_name,
      order: topicOrder,
      quiz_name,
      quiz_passing_score,
      topic: topicId,
    })

    training_fields?.map(async (field, index) => {
      const { data: fieldResponse } = await Api.post('training_fields', {
        ...field,
        objType: 'field',
        tempId: index + 1,
        content: contentResponse?.id,
      })

      field?.options?.map(async (option, index) => {
        await Api.post('training_field_options', {
          ...option,
          objType: 'option',
          order: index,
          field: fieldResponse?.id,
        })
      })
    })

    stateService.go('trainings.topic', {
      trainingID: stateService?.params?.trainingID,
    })
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="quiz__wrapper">
        <h3 className="training__name">{title}</h3>
        <div className="form__wrapper">
          {initialValues?.training_fields?.length ? (
            <Formik
              initialValues={initialValues}
              onSubmit={() => { }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                values,
                setFieldValue,
                isValid = false,
                touched,
                setFieldTouched,
                validateField
              }) => {
                const isQuestionValid = (q) => {
                  if (!q.name || !q.type || !q.options?.length) return false

                  const hasCorrect = q.options.some((opt) => opt.answer)
                  if (!hasCorrect) return false

                  const names = q.options.map((o) => o.name?.trim().toLowerCase())
                  const hasDuplicates = new Set(names).size !== names.length
                  if (hasDuplicates) return false

                  return names.every((n) => !!n)
                }

                const isFormValid = () => {
                  if (!values.quiz_name || !values.quiz_passing_score) return false
                  if (!Array.isArray(values.training_fields) || values.training_fields.length === 0) return false

                  return values.training_fields.every(isQuestionValid)
                }

                const shouldDisableButton = !isFormValid() || isButtonDisabled

                return (
                  <>
                    <div className="quiz__details">
                      <Field
                        type="text"
                        as={FormikAutoExpandedTextArea}
                        name="quiz_name"
                        className={classNames('input name', {
                          error: !values.quiz_name,
                        })}
                        placeholder="Quiz Name"
                        value={values.quiz_name}
                        onChange={(e) => {
                          markChanged()
                          setFieldValue('quiz_name', e.target.value)
                        }}
                      />
                      <Field
                        type="text"
                        name="quiz_passing_score"
                        className={classNames('input score', {
                          error: !values.quiz_passing_score,
                        })}
                        placeholder="Passing Score (0-100%)"
                        value={values.quiz_passing_score}
                        onChange={(e) => {
                          setFieldTouched('quiz_passing_score', true)
                          if (
                            Number(e.target.value) <= 100 &&
                            /^\d*$/.test(e.target.value)
                          ) {
                            markChanged()
                            setFieldValue('quiz_passing_score', e.target.value)
                          }
                        }}
                      />
                    </div>
                    <div className="questions__wrapper">
                      <div className="headers">
                        <p className="header__label order">Order</p>
                        <p className="header__label question">Question</p>
                        <p className="header__label type">Answer Type</p>
                      </div>
                      <FieldArray
                        name="training_fields"
                        render={(arrayHelpers) => {
                          return (
                            <div className="questions__list">
                              {values?.training_fields?.slice(0, visibleCount).map((field, index) => (
                                <QuizQuestion
                                  key={field.id || index}
                                  field={field}
                                  index={index}
                                  handleDelete={arrayHelpers.remove}
                                  markChanged={markChanged}
                                />
                              ))}

                              <div className="button__wrapper">
                                <ButtonElement
                                  functionToTrigger={async () => {
                                    const newQuestionIndex = values.training_fields.length
                                    markChanged()
                                    arrayHelpers.push({
                                      name: '',
                                      options: [
                                        {
                                          name: '',
                                          weight: '',
                                          id: generateUUID(),
                                          answer: false,
                                        },
                                      ],
                                      type: 'multi',
                                      order: newQuestionIndex + 1,
                                      required: true,
                                    })

                                    if (visibleCount < newQuestionIndex + 1) {
                                      setVisibleCount((prev) => prev + 1)
                                    }
                                    await setFieldTouched(`training_fields[${newQuestionIndex}].name`, true)
                                    await setFieldTouched(`training_fields[${newQuestionIndex}].options`, true)
                                    await setFieldTouched(`training_fields[${newQuestionIndex}].type`, true)
                                    await setFieldTouched(`training_fields[${newQuestionIndex}].order`, true)

                                    await validateField(`training_fields[${newQuestionIndex}]`)
                                  }}
                                  text="+ Question"
                                />
                              </div>
                            </div>
                          )
                        }}
                      />
                      <div className="button__functionality">
                        <ButtonElement
                          functionToTrigger={() => {
                            stateService.go('trainings.topic', {
                              trainingID: stateService?.params?.trainingID,
                            })
                          }}
                          text="Cancel"
                        />
                        <ButtonElement
                          buttonType="submit"
                          functionToTrigger={() => {
                            handleCreateEdit(values)
                          }}
                          text="Save"
                          disabled={shouldDisableButton || isButtonDisabled || isPristine} />
                      </div>
                    </div>
                  </>
                )
              }}
            </Formik>
          ) : null}
        </div>
      </div>
    </section>
  )
}

const getStyles = (variableColor: string) =>
  css({
    paddingBottom: '250px',
    '.quiz__wrapper': {
      background: '#fff',
      width: 'fit-content',
      paddingRight: '20px',
      paddingBottom: '20px',
      alignItems: 'center',
    },
    '.training__name': {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '20px',
      fontSize: '14px',
      color: '#686A6C',
      margin: 0,
      borderBottom: '1px solid #EEEFF3',
    },
    '.input': {
      ...TextInputStyle,
      textAlign: 'center',
      fontSize: '12px',
      height: '32px',
      border: '1px solid #CDCCCC',
      background: '#fff',

      '::placeholder': {
        fontSize: '12px',
        color: '#B1B1B1',
      },
    },
    '.error': {
      borderColor: '#C80404 !important',
    },
    '.name': {
      width: '603px',
      minWidth: '538px',
    },
    '.score': {
      width: '145px',
    },
    '.type': {
      minWidth: '210px',
      width: '210px',
    },
    '.quiz__details': {
      paddingTop: '20px',
      paddingLeft: '20px',
      display: 'flex',
      gap: '15px',
    },
    '.headers': {
      display: 'flex',
      marginTop: '21px',
      marginLeft: '20px',
      marginBottom: '5px',
    },
    '.header__label': {
      fontSize: '12px',
      color: '#676A6C',
      margin: 0,
      fontWeight: 700,
    },
    '.order': {
      marginRight: '20px',
      minWidth: '32px',
      width: '32px',
      fontWeight: 'bold',
    },
    '.question': {
      minWidth: '485px',
      width: '547px',
      marginRight: '15px',
      textAlign: 'left',
    },
    '.questions__list': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '.question__wrapper': {
      paddingLeft: '22px',
      display: 'flex',
    },
    '.button__wrapper': {
      marginLeft: '75px',
    },
    '.delete__wrapper': {
      marginLeft: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    '.button': {
      cursor: 'pointer',
      marginTop: '5px !important',
      marginBottom: '5px !important',
      marginLeft: '95px !important',
      color: variableColor,
      fontSize: '12px',
      margin: 0,
    },
    '.button__error': {
      color: '#C80404',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      padding: 0,
      margin: 0,
      height: '22px',
      width: '22px',
      marginTop: '4px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FAFAFA',
      border: '1px solid #B1B1B1',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      border: 'none',
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    svg: {
      cursor: 'pointer',
    },
    '.checkmark__wrapper': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
    },
    '.checkbox__text': {
      margin: 0,
      fontSize: '12px',
      color: '#676A6C',
      fontWeight: 400,
      marginTop: '3px',
    },
    '.checkboxes__wrapper': {
      display: 'flex',
      gap: '30px',
      marginLeft: '76px',
      marginTop: '10px',
    },
    '.button__functionality': {
      display: 'flex',
      gap: '10px',
      marginTop: '10px',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      minWidth: '780px',
      maxWidth: '878px',
    },
    'button:disabled': {
      background: '#888 !important',
      color: '#fff !important',
    },
    '@media(max-width: 1135px)': {
      '.quiz__wrapper': {
        width: '100%',
      },
      '.form__wrapper': {
        overflowX: 'scroll',
      },
    },
  })