import * as React from 'react'
import { useAngularServices, SignatureAndSketch } from '@/react/components'
import { SingleSignature } from './index'
import { css } from '@emotion/react'
import { AddSignatureIcon2 } from '@/react/componentAssets'

export function SignatureElement({ signatures }) {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [modalState, setModalState] = React.useState([])
  const [signatureElementList, setSignatureElementList] = React.useState([])

  async function fileProcessing(file) {
    const index = signatures.length
    const { data: fileResponse } = await Api.uploadImage(file, {})
    signatures[index] = {
      image: fileResponse.id,
      url: fileResponse.image,
    }
    addSignatureElement(signatures[index])
  }

  React.useEffect(() => {
    const initialFields = []
    signatures.forEach((inittial, idx) => {
      initialFields.push(
        <SingleSignature key={idx} signatureLink={signatures[idx]} />,
      )
    })
    setSignatureElementList(initialFields)
  }, [signatures])

  function addSignatureElement(signatureLink) {
    setSignatureElementList([
      ...signatureElementList,
      <SingleSignature signatureLink={signatureLink} />,
    ])
  }

  return (
    <section css={baseStyle}>
      {modalState}
      <b>
        <h3
          className="add-signature"
          css={css({ color: variableColor })}
        >
          <AddSignatureIcon2 onClick={() => {
            setModalState([
              <SignatureAndSketch
                hide={() => setModalState([])}
                onSubmit={fileProcessing}
              />,
            ])
          }}
            height={'30px'} color={variableColor} />
        </h3>
      </b>
      <div className="signature-holder">{signatureElementList}</div>
    </section>
  )
}

const baseStyle = css({
  marginTop: 20,
  '.signature-holder': {
    display: 'flex',
    flexWrap: 'wrap',
  },
})
