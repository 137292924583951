import * as React from 'react'
import { useRouter } from '@/react/hooks'

import { QuestionsList } from './templateParts'
import {
  BottomObservationNavigation,
  Signatures,
  TableSummary,
  TableTemplateHeader,
} from '../components'
import { TemplateBaseProps } from '@/react/types'

export function TemplateBaseTwo({
  baseStyle,
  imageBlockStyle,
  imageUrl,
  baseObservationData,
  processedObservationData,
  app,
  viewNaQuestions,
  viewNaCategories,
}: TemplateBaseProps) {
  const { stateService } = useRouter()
  const lists = {
    no: Object.values(processedObservationData.no),
    pr: Object.values(processedObservationData.pr),
    pa: Object.values(processedObservationData.pa),
    cls: Object.values(processedObservationData.cls),
    yes: Object.values(processedObservationData.yes),
    'n/a': Object.values(processedObservationData['n/a']),
  }

  return (
    <>
      {baseObservationData && (
        <div css={imageBlockStyle}>
          <img src={imageUrl} alt="image" />
        </div>
      )}
      <section css={baseStyle}>
        <div id="to__print">
          {baseObservationData ? (
            <TableTemplateHeader
              headerData={baseObservationData}
              app={app}
              imageUrl={imageUrl}
            />
          ) : null}
          {baseObservationData && processedObservationData ? (
            <TableSummary
              headerData={baseObservationData}
              lists={lists}
              app={app}
              viewNaQuestions={viewNaQuestions}
            />
          ) : null}
          {processedObservationData ? (
            <QuestionsList
              lists={lists}
              app={app}
              viewNaQuestions={viewNaQuestions}
              viewNaCategories={viewNaCategories}
            />
          ) : null}
          {baseObservationData.signatures.length > 0 ? (
            <Signatures signatures={baseObservationData.signatures} />
          ) : null}
          <div className="disclaimer">
            {baseObservationData.client.general_settings.disclaimer}
          </div>
        </div>
        {stateService.params.key ? null : (
          <BottomObservationNavigation deleted={baseObservationData.deleted} />
        )}
      </section>
    </>
  )
}
