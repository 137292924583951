import * as React from 'react'
import { css } from '@emotion/react'

import { BinDeleteIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

type Props = {
  id: number
  deleteRequest: any
  color: string
}

export const DeleteElement: React.FC<Props> = ({
  deleteRequest,
  id,
  color,
}: Props) => {
  const [alertActivity, setAlertActivity] = React.useState<boolean>(false)
  const [value, setValue] = React.useState(null)

  function closeAlert() {
    setAlertActivity(false)
  }
  function deletePO() {
    deleteRequest(id, value)
    closeAlert()
  }

  return (
    <div
      css={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <div css={css({ height: 0, width: 0, position: 'relative' })}>
        {alertActivity ? (
          <div
            css={css({
              left: 0,
              top: 0,
              height: '100vh',
              width: '100vw',
              position: 'fixed',
              zIndex: 100,
            })}
            onClick={closeAlert}
          />
        ) : null}
        {alertActivity ? (
          <ConfirmationModal
            action="Delete"
            handleClick={deletePO}
            handleClose={closeAlert}
          />
        ) : null}
      </div>
      <BinDeleteIcon
        color={color}
        additionalStyles={css({ cursor: 'pointer' })}
        onClick={(value) => {
          setAlertActivity(true)
          setValue(value)
        }}
      />
    </div>
  )
}
