import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import * as React from 'react'
import { EditIcon } from '@/react/componentAssets'
import { useRouter } from '@/react/hooks'
import { AlertSettingPopup } from '@screens/MobileForms/SettingsPage/components/AlertSettingPopup'
import { CustomEmailModal } from './CustomEmailModal'
import Highlighter from 'react-highlight-words'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { AddIcon } from '@/react/componentAssets/AddIcon'
import { checkIsSafari } from '@/utils/checkIsSafari'

interface Form {
  is_private: boolean
  show_na_questions: boolean
  id: string
  name: string
}
type Props = {
  idx: number
  rowData: Form
  search: string
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

export const TableRow: React.FC<Props> = ({
  rowData,
  idx,
  search,
  setRefresh,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const [privacySwitch, setPrivacySwitch] = React.useState(
    rowData.is_private || false,
  )
  const [naSwitch, setNASwitch] = React.useState(
    rowData.show_na_questions || false,
  )
  const [userListData, setUserListData] = React.useState(null)
  const [amountOfActiveUser, setAmountOfActiveUser] = React.useState(0)
  const [popUp, setPopUp] = React.useState(null)
  const [isModalVisible, setModalVisable] = React.useState(false)

  const variableColor = CurrentUser.getClientSettings()
    .web_primary_color as string

  const togglePrivacy = async (form: Form) => {
    await Api.post(`mobile_forms/${form.id}/set_privacy`, {
      is_private: !privacySwitch,
    })
    setPrivacySwitch(!privacySwitch)
  }

  const toggleNA = async (form: Form) => {
    setNASwitch(!form.show_na_questions)

    await Api.post(`mobile_forms/${form.id}/set_na_setting`, {
      show_na: !form.show_na_questions,
    })
  }

  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(idx, variableColor, isSafari)

  const getAssignedPeople = async () => {
    const { data: usersGet } = await Api.get(
      'users/alert_recipients/' + rowData.id,
      {
        page_size: '50000',
        is_active: 'true',
      },
    )

    setAmountOfActiveUser(
      usersGet.results.filter((user) => user.assigned_to_alerts === true)
        .length,
    )
  }

  React.useEffect(() => {
    getAssignedPeople()
  }, [])

  return (
    <>
    <tr key={rowData.id} css={baseStyle}>
      <td className="textAlignLeft">
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={rowData.name}
        />
      </td>
      <td className="email__td">
        {rowData?.email_settings ? (
          <EditIcon
            height={'18px'}
            color={variableColor}
            onClick={() => setModalVisable(true)}
          />
        ) : (
          <AddIcon
            height={'18px'}
            color={variableColor}
            onClick={() => setModalVisable(true)}
          />
        )}
      </td>
      <td>
        <div className="popup-holder">{popUp}</div>
        <div
          className="count-holder"
          onClick={() => {
            document.body.style.overflow = 'hidden'
            setPopUp(
              <AlertSettingPopup
                setPopup={setPopUp}
                setUsersList={setUserListData}
                usersList={userListData}
                setAmountOfActiveUser={setAmountOfActiveUser}
                rowData={rowData}
              />,
            )
          }}
        >
          <EditIcon height={'18px'} color={variableColor} />
          <span className="active-user-count">{amountOfActiveUser}</span>
        </div>
      </td>
      <td>
        <label className="switch">
          <input
            className="projectCheckbox"
            type="checkbox"
            checked={privacySwitch}
            onClick={togglePrivacy.bind(null, rowData)}
            name={rowData.id}
          />
          <span className="slider round" />
        </label>
      </td>
    </tr>
    {isModalVisible && (
        <CustomEmailModal
          form={rowData}
          handleClose={() => {
            setModalVisable(false)
          }}
          setRefresh={setRefresh}
          idx={idx}
        />
      )}
    </>
  )
}

function getBaseStyle(idx, variableColor) {
  return css({
    position: 'relative',
    backgroundColor: idx % 2 === 0 ? '#f2f2f2' : 'transparent',
    '.popup-holder': {
      width: 0,
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.count-holder': {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '.active-user-count': { marginLeft: 5 },
    },
    '.email__td': {
      position: 'relative',
      '& svg': {
        cursor: 'pointer',
      },
    },
  })
}
