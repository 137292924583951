import * as React from 'react'
import { useAngularServices } from '@components/index'
import { css } from '@emotion/react'
import pluralize from 'pluralize'
import { ApplicationConfig, ObservationBody } from '@/react/types'
import { CurrentUserType } from '@/react/types/documentation'

interface localInterface {
  headerData: ObservationBody
  app: ApplicationConfig
  imageUrl: string
}

export function TableTemplateHeader({
  headerData,
  app,
  imageUrl,
}: localInterface) {
  const { CurrentUser } = useAngularServices()

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const variableProjectsName = pluralize.singular(app.projects_display)
  const variableObservationPluralName = app.observations_display

  const clientInfoStyle = getClientInfoStyle(variableColor)
  const reportHeader = getReportHeader(variableColor)

  return (
    <section css={headerBaseStyle}>
      <div css={reportHeader}>
        <h2>{pluralize.singular(variableObservationPluralName)} Report</h2>
      </div>
      <div css={clientInfoStyle}>
        <div className="image-block">
          <img src={imageUrl} alt="image" className="image" />
        </div>
        <table className="header-fields-desktop">
          <tbody>
            <tr>
              <td className="header-field">
                <label>PERFORMED BY: </label>
                {headerData.author_name ? (
                  <span> {headerData.author_name}</span>
                ) : (
                  <span>
                    {headerData.user.first_name} {headerData.user.last_name}
                  </span>
                )}
              </td>
              <td className="header-field" colSpan={2}>
                <label>PERFORMED DATE:</label>
                <span>
                  {headerData.date_performed_range
                    ? new Date(
                        headerData.date_performed_range.split(' - ')[0],
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }) +
                      ' - ' +
                      new Date(
                        headerData.date_performed_range.split(' - ')[1],
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                    : new Date(
                        headerData.date_performed || headerData.date_created,
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                </span>
              </td>
            </tr>
            <tr>
              <td className="header-field">
                <label>{variableProjectsName.toUpperCase()} NAME: </label>
                <span>{headerData.project.name}</span>
              </td>
              <td className="header-field">
                <label>{variableProjectsName.toUpperCase()} ADDRESS:</label>
                <span>{headerData.project.address}</span>
              </td>
              <td className="header-field">
                <label>CONTRACTOR:</label>
                <span>{headerData.project.contractor}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="header-field header-field-span">
                <label>
                  TITLE:{' '}
                  <span>
                    {headerData.title ||
                      (headerData.categories.length === 1
                        ? headerData.categories[0].name
                        : 'Various')}
                  </span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="header-fields-mobile">
          <tbody>
            <tr className="header-field">
              <td className="table-header-label">Performed By:</td>
              <td>
                {' '}
                {headerData.author_name ? (
                  <span> {headerData.author_name}</span>
                ) : (
                  <span>
                    {headerData.user.first_name} {headerData.user.last_name}
                  </span>
                )}
              </td>
            </tr>
            <tr className="header-field">
              <td className="table-header-label">
                {' '}
                {pluralize.singular(variableObservationPluralName)} Date:
              </td>
              <td>
                {' '}
                {headerData.date_range
                  ? new Date(
                      headerData.date_range.split(' - ')[0],
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }) +
                    ' - ' +
                    new Date(
                      headerData.date_range.split(' - ')[1],
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                  : new Date(headerData.date_created).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                    )}
              </td>
            </tr>
            <tr className="header-field">
              <td className="table-header-label">
                {variableProjectsName} Name:{' '}
              </td>
              <td>{headerData.project.name}</td>
            </tr>
            <tr className="header-field">
              <td className="table-header-label">
                {variableProjectsName} Address:
              </td>
              <td>{headerData.project.address}</td>
            </tr>
            <tr className="header-field">
              <td className="table-header-label">Title:</td>
              <td>
                {headerData.title ||
                  (headerData.categories.length === 1
                    ? headerData.categories[0].name
                    : 'Various')}
              </td>
            </tr>
            <tr className="header-field">
              <td className="table-header-label">Contractor:</td>
              <td>{headerData.project.contractor}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  )
}

const headerBaseStyle = css({
  width: '100%',
})

function getClientInfoStyle(variableColor: string) {
  return css({
    width: '100%',
    display: 'inline-block',
    height: '200px',
    '.image-block': {
      verticalAlign: 'top',
      width: '25%',
      height: '168px',
      display: 'inline-block',
      textAlign: 'center',
      img: {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'contain',
      },
      '@media (max-width: 650px)': { display: 'none' },
      '@media print': { width: '25%' },
    },
    '.header-fields-desktop': {
      width: '75%',
      display: 'inline-block',
      paddingLeft: '1%',
      '@media (max-width: 650px)': {
        display: 'none',
      },
      '@media print': { display: 'inline-block' },
      tbody: {
        width: '100%',
        display: 'table',
      },
      tr: {
        width: '100%',
        '.header-field': {
          verticalAlign: 'top',
          padding: '2px',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: variableColor,
          borderCollapse: 'collapse',
          width: '33%',
          color: 'black !important',
          label: {
            display: 'block',
            fontSize: '18px',
            fontWeight: 600,
          },
          span: {
            fontSize: '18px',
            display: 'block',
          },
        },
        '.header-field-span': {
          label: {
            display: 'flex',
            gap: '5px',
            marginTop: '5px',
          },
          span: {
            fontWeight: 400,
          },
        },
        '.color-fill': {
          backgroundColor: variableColor + ' !important',
        },
      },
    },
    '.header-fields-mobile': {
      width: '100%',
      display: 'none',
      color: 'black',
      '@media (max-width: 650px)': {
        display: 'inline-block',
      },
      '@media print': { display: 'none' },
      tbody: {
        width: '100%',
        display: 'table',
      },
      tr: {
        width: '100%',
        td: {
          padding: '5px',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: variableColor,
          borderCollapse: 'collapse',
          width: 'auto',
        },
        '.table-header-label': {
          backgroundColor: '#E9F0FF',
          textAlign: 'right',
          fontWeight: 'bold',
          width: 180,
        },
      },
      '.color-fill': {
        backgroundColor: variableColor + ' !important',
      },
    },
  })
}

function getReportHeader(variableColor) {
  return css({
    width: '100%',
    backgroundColor: variableColor + ' !important',
    color: 'black !important',
    textAlign: 'center',
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    h2: {
      margin: 0,
      color: 'white !important',
    },
  })
}
