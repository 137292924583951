import { CommonPageHeader, useAngularServices } from '@/react/components'
import { NavBar } from '@/react/screens/TrainingsPages/LMSCommonComponents/NavBar'
import { css } from '@emotion/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { TopicElement } from './TopicElement'
import closeIcon from '@/assets/icons/X Gray.svg'
import { UISref } from '@/react/components/UISref'
import useClickOutside from '@/react/hooks/useClickOutside'

export const ViewTopic = () => {
  const baseStyle = getBaseStyle()
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const [job, setJob] = useState({})
  const [isJobCompleted, setJobCompleted] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const modalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const getJobData = async () => {
      const jobId = stateService.params.jobId
      const { data: jobData } = await Api.get(`jobs/${jobId}`, {
        user_filter: true,
      })
      setJob(jobData)
      setRefresh(false)
    }
    getJobData()
  }, [refresh])

  useEffect(() => {
    if (job?.status === 100) {
      setJobCompleted(true)
    }
  }, [job?.status])

  const sortedContentItems = useMemo(() => {
    const topics = job?.training?.topics || []
    return [...topics].sort((el1, el2) => el1.order - el2.order)
  }, [job])

  useClickOutside(modalRef, setJobCompleted)

  function getTimeLeftToExpiring(targetDate: string) {
    const target = new Date(targetDate)
    const now = new Date()

    const timeLeft = target.getTime() - now.getTime()

    const oneDay = 24 * 60 * 60 * 1000
    const oneMonth = 30 * oneDay
    const oneYear = 365 * oneDay

    const yearsLeft = Math.floor(timeLeft / oneYear)
    const monthsLeft = Math.floor((timeLeft % oneYear) / oneMonth)
    const daysLeft = Math.ceil((timeLeft % oneMonth) / oneDay)

    if (yearsLeft > 0 || monthsLeft > 12) {
      return `in ${yearsLeft} year(s).`
    } else if (monthsLeft > 0) {
      return `in ${monthsLeft} month.`
    } else {
      return `in ${daysLeft} day(s).`
    }
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="topic__wrapper">
        <h4 className="page__text">{job?.training?.name}</h4>
        {sortedContentItems?.map((topic, index) => (
          <TopicElement
            key={index}
            topic={topic}
            jobId={job.id}
            setRefresh={setRefresh}
          />
        ))}
      </div>
      {isJobCompleted && (
        <div className="congrats__modal" ref={modalRef}>
          <img
            src={closeIcon}
            alt="close"
            className="modal__x"
            onClick={() => {
              setJobCompleted(false)
            }}
          />
          <div className="info__wrapper">
            <h1 className="modal__title">Congratulations!</h1>
            <p className="modal__info">
              You have successfully completed the{' '}
              <strong>{job?.training?.name}</strong> training. You can find your
              certificate of completion on the{' '}
              <UISref to={'trainings.documentation'}>
                <a className="link"> Training Documentation</a>
              </UISref>{' '}
              page.
            </p>
            <p className="modal__info">
              Or click to download{' '}
              <a
                href={job?.certificate?.file}
                download
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
            {job?.date_training_expires && (
              <p className="modal__info">
                This training expires on {job?.date_training_expires}.
              </p>
            )}
          </div>
        </div>
      )}
    </section>
  )
}

function getBaseStyle() {
  return css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    '.topic__wrapper': {
      background: '#fff',
      marginBottom: '30px',
      width: '60%',
    },
    '@media(max-width: 800px)': {
      '.topic__wrapper': {
        width: '100%',
      },
    },
    '.page__text': {
      color: '#68696D',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      letterSpacing: ' -0.12px',
      marginTop: '10px',
      marginLeft: '15px',
      cursor: 'pointer',
      position: 'relative',
    },
    '.table__element': {
      display: 'flex',
      gap: '100px',
      height: '60px',
      borderStyle: 'solid none',
      borderColor: '#EEEFF3',
      borderWidth: '1px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.left__block': {
      display: 'flex',
      gap: '60px',
      marginRight: '25px',
    },
    '.element__title': {
      color: '#68696D',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      letterSspacing: '0.12px',
      marginLeft: '12px',
      marginBottom: 0,
    },
    '.percentage': {
      color: '#000',
      textAlign: 'center',
      fontSize: '12px',
      marginLeft: '55px',
    },
    '.icon': {
      cursor: 'pointer',
    },
    '.extra__info': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '13px',
      gap: '15px',
    },
    '.info__element': {
      display: 'flex',
      gap: '12px',
      marginBottom: '25px',
    },
    '.element__text': {
      color: '#575757',
      fontSize: '10px',
      margin: 0,
    },
    '.congrats__modal': {
      display: 'flex',
      position: 'absolute',
      top: '240px',
      left: '200px',
      flexDirection: 'column',
      background: '#fff',
      boxShadow: '2px 2px 7px 0px rgba(0, 0, 0, 0.25)',
      width: '447px',
    },
    '.modal__x': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginTop: '8px',
      marginRight: '9px',
      height: '12px',
      width: '12px',
      cursor: 'pointer',
    },
    '.info__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      marginBottom: '21px',
    },
    '.modal__title': {
      color: '#575757',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '12px',
      marginBottom: 0,
    },
    '.modal__info': {
      fontSize: '10px',
      margin: 0,
      color: '#575757',

      marginInline: '44px',
    },
    '@media(max-width: 800px)': {
      '.congrats__modal': {
        left: '80px',
        width: '247px',
      },
      '.modal__info': {
        marginInline: '22px',
      },
    },
  })
}
