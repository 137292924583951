import * as React from 'react'
import { css } from '@emotion/react'

import { QuestionField } from './componets'

const questionField = (app) => (item) =>
  <QuestionField key={item?.question?.id} questionFullData={item} app={app} />

export function QuestionsList({ lists, app, viewNaQuestions }: any) {
  return (
    <section css={questionListBaseStyle}>
      {lists['no'][0] ? (
        <div className="no">
          <h3 className="header-for-answer-type">
            Follow Up Required ({lists['no'].length})
          </h3>
          {lists['no'].map(questionField(app))}
        </div>
      ) : null}
      {lists['pr'][0] ? (
        <div className="pr">
          <h3 className="header-for-answer-type">
            Pending Review ({lists['pr'].length})
          </h3>
          {lists['pr'].map(questionField(app))}
        </div>
      ) : null}
      {lists['pa'][0] ? (
        <div className="pa">
          <h3 className="header-for-answer-type">
            Pending Approval ({lists['pa'].length})
          </h3>
          {lists['pa'].map(questionField(app))}
        </div>
      ) : null}
      {lists['cls'][0] ? (
        <div className="cls">
          <h3 className="header-for-answer-type">
            Issue Resolved ({lists['cls'].length})
          </h3>
          {lists['cls'].map(questionField(app))}
        </div>
      ) : null}
      {lists['yes'][0] ? (
        <div className="yes">
          <h3 className="header-for-answer-type">
            Yes ({lists['yes'].length})
          </h3>
          {lists['yes'].map(questionField(app))}
        </div>
      ) : null}
      {viewNaQuestions && lists['n/a'][0] ? (
        <div className="n/a">
          <h3 className="header-for-answer-type">
            N/A ({lists['n/a'].length})
          </h3>
          {lists['n/a'].map(questionField(app))}
        </div>
      ) : null}
    </section>
  )
}

const questionListBaseStyle = css({
  width: '100%',
  '.header-for-answer-type': {
    width: '100%',
    margin: 0,
    padding: '5px',
    textAlign: 'center',
    fontSize: '20px',
    paddingTop: '30px',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: '#eeeeee',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
})
