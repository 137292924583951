import { noop } from 'lodash'

angular
  .module('ccs')
  .factory('Api', ApiFactory)
  .factory('ApiSilent', ApiFactory)
  .factory('AuthInterceptor', AuthInterceptor)
  .factory('AuthInterceptorSilent', AuthInterceptorSilent)
  .config(($httpProvider) => {
    $httpProvider.interceptors.push('AuthInterceptor')
    $httpProvider.interceptors.push('AuthInterceptorSilent')
  })

function AuthInterceptorSilent(Auth, CurrentUser, $injector, $log) {
  return {
    request: (config) => {
      if (CurrentUser.getToken()) {
        config.headers.Authorization = Auth.getAuthHeader()
      }
      return config
    },

    responseError: (rejection) => {
      const INACTIVE = 'User inactive or deleted.'
      const NO_CREDENTIALS = 'Authentication credentials were not provided.'
      if (rejection.data.detail === INACTIVE) {
        Notification.danger('You have been logged out.')
        $injector.get('$state').go('logout') // Direct usage of $state service causes circular dependency error
      } else if (rejection.data.detail !== NO_CREDENTIALS) {
        // @TODO: special workarounds can be used here for different error types
        // @TODO: recognition of special status to prevent default handler
        // @TODO: refactor auth logic completely, suppress auth errors for now
        $log.warn('Request error:', rejection.data.detail || rejection.data)
      }
      rejection.config.silent = true

      return Promise.reject(rejection)
    },
  }
}

function AuthInterceptor(Auth, CurrentUser, Notification, $injector, $log) {
  return {
    request: (config) => {
      // Adds auth token to every request
      // @TODO: ensure getToken() check is needed
      // @TODO: check if request address is ApiHost
      if (CurrentUser.getToken()) {
        config.headers.Authorization = Auth.getAuthHeader()
      }
      return config
    },
    responseError: (rejection) => {
      const INACTIVE = 'User inactive or deleted.'
      const NO_CREDENTIALS = 'Authentication credentials were not provided.'

      if (rejection.config.silent) {
        return Promise.reject(rejection)
      }

      if (rejection.data.detail === INACTIVE) {
        Notification.danger('You have been logged out.')
        $injector.get('$state').go('logout') // Direct usage of $state service causes circular dependency error
      } else if (rejection.data.detail !== NO_CREDENTIALS) {
        // @TODO: special workarounds can be used here for different error types
        // @TODO: recognition of special status to prevent default handler
        // @TODO: refactor auth logic completely, suppress auth errors for now
        $log.warn('Request error:', rejection.data.detail || rejection.data)
        if (!rejection.data.password && !rejection.data.password_confirm) {
          Notification.danger(rejection.data.detail || rejection.data)
        }
      }

      return Promise.reject(rejection)
    },
  }
}

function ApiFactory($http, Configuration) {
  const backEndUrlV1 = Configuration.ApiHost + '/api/v1/'
  const backEndUrlV2 = Configuration.ApiHost + '/api/v2/'

  function makeRest(endpoint, v2) {
    const backEndUrl = v2 ? backEndUrlV2 : backEndUrlV1

    return {
      get: (params, onSuccess, onError) =>
        getRequest(backEndUrl + endpoint, params, onSuccess, onError),
      getWithoutDeletedParam: (params, onSuccess, onError) =>
        getRequestWithoutDeletedParam(
          backEndUrl + endpoint,
          params,
          onSuccess,
          onError,
        ),
      byID: (id, onSuccess, onError) =>
        getRequest(backEndUrl + endpoint + '/' + id, null, onSuccess, onError),
      byIDWithoutDeletedParam: (id, onSuccess, onError) =>
        getRequestWithoutDeletedParam(
          backEndUrl + endpoint + '/' + id,
          null,
          onSuccess,
          onError,
        ),
      post: (data, onSuccess, onError) =>
        postRequest(backEndUrl + endpoint, data, {}, onSuccess, onError),
      // Temporary postWithParams function, until all onSuccess/onError handlers will be removed
      // in lieu of Promise based solution
      postWithParams: (data, params) =>
        postRequest(backEndUrl + endpoint, data, params || {}),
      put: (data, onSuccess, onError) =>
        putRequest(backEndUrl + endpoint, data, onSuccess, onError),
      patch: (data, onSuccess, onError) =>
        patchRequest(
          backEndUrl + endpoint + '/' + data.id,
          data,
          onSuccess,
          onError,
        ),
      delete: (params, onSuccess, onError) =>
        deleteRequest(
          backEndUrl + endpoint + '/' + params.id,
          onSuccess,
          onError,
        ),
    }
  }

  function getRequest(fullEndpoint, params, onSuccess = noop, onError = noop) {
    let pathParam = '/'
    let objParam = {}

    if (params) {
      if (typeof params === 'string') {
        pathParam += '?' + params
      } else {
        objParam = params
      }
    }
    return $http
      .get(fullEndpoint + pathParam, {
        params: { deleted: false, ...objParam },
      })
      .success(onSuccess)
      .error(onError)
  }

  function getRequestWithoutDeletedParam(
    fullEndpoint,
    params,
    onSuccess = noop,
    onError = noop,
  ) {
    let pathParam = '/'
    let objParam = {}

    if (params) {
      if (typeof params === 'string') {
        pathParam += '?' + params
      } else {
        objParam = params
      }
    }

    return $http
      .get(fullEndpoint + pathParam, { params: { ...objParam } })
      .success(onSuccess)
      .error(onError)
  }

  function getRequestWithoutDeletedParamV2(
    fullEndpoint,
    params,
    onSuccess = noop,
    onError = noop,
  ) {
    // If the fullEndpoint already contains a "?", don't add a slash.
    let pathParam = fullEndpoint.includes('?') ? '' : '/'
    let objParam = {}

    if (params) {
      if (typeof params === 'string' && params.trim() !== '') {
        pathParam += '?' + params
      } else {
        objParam = params
      }
    }

    return $http
      .get(fullEndpoint + pathParam, { params: { ...objParam } })
      .success(onSuccess)
      .error(onError)
  }

  function postRequest(
    fullEndpoint,
    data,
    params,
    onSuccess = noop,
    onError = noop,
  ) {
    let pathParam = fullEndpoint.includes('?') ? '' : '/'

    return $http
      .post(fullEndpoint + pathParam, data, {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
        params,
      })
      .success(onSuccess)
      .error(onError)
  }

  function putRequest(fullEndpoint, data, onSuccess = noop, onError = noop) {
    return $http
      .put(fullEndpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
      })
      .success(onSuccess)
      .error(onError)
  }

  function putRequestWithParams(
    fullEndpoint,
    data,
    params = {},
    onSuccess = noop,
    onError = noop,
  ) {
    return $http
      .put(fullEndpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
        params,
      })
      .success(onSuccess)
      .error(onError)
  }
  function patchRequestWithParams(
    fullEndpoint,
    data,
    params = {},
    onSuccess = noop,
    onError = noop,
  ) {
    return $http
      .patch(fullEndpoint, data, {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
        params,
      })
      .success(onSuccess)
      .error(onError)
  }

  function patchRequest(fullEndpoint, data, onSuccess = noop, onError = noop) {
    return $http
      .patch(fullEndpoint + '/', data, {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
      })
      .success(onSuccess)
      .error(onError)
  }

  function deleteRequest(fullEndpoint, onSuccess = noop, onError = noop) {
    return $http
      .delete(fullEndpoint + '/')
      .success(onSuccess)
      .error(onError)
  }

  function deleteRequestWithParams(
    fullEndpoint,
    params,
    onSuccess = noop,
    onError = noop,
    removeEndSlash = false,
  ) {
    return $http
      .delete(fullEndpoint + (removeEndSlash ? '' : '/'), {
        headers: {
          'Content-Type': 'application/json',
          'real-referrer': window.location.href, // Captured for debug purposes
        },
        params,
      })
      .success(onSuccess)
      .error(onError)
  }

  // Non-standard API functions

  function uploadForm(
    endpoint,
    file,
    options,
    onSuccess = noop,
    onError = noop,
    v2,
  ) {
    const backEndUrl = v2 ? backEndUrlV2 : backEndUrlV1

    const formData = new FormData()
    formData.append(options.fieldName, file)
    delete options.fieldName // This param should not be passed further
    formData.append('in_loading_delete_id', options.onLoadDeleteId)

    return $http
      .post(backEndUrl + endpoint, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined },
      })
      .success(onSuccess)
      .error(onError)
  }

  function uploadBulk(
    endpoint,
    file,
    onSuccess = noop,
    onError = noop,
    params = {},
  ) {
    const backEndUrl = params.v2 ? backEndUrlV2 : backEndUrlV1

    const formData = new FormData()
    formData.append('file', file)

    return $http
      .post(backEndUrl + endpoint, formData, {
        transformRequest: angular.identity, // @TODO check if needed
        headers: { 'Content-Type': params.contentType },
      })
      .success(onSuccess)
      .error(onError)
  }

  function postImage(endpoint, data, onSuccess, onError = noop) {
    return $http
      .post(backEndUrlV1 + endpoint + '/', data, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .success(onSuccess)
      .error(onError)
  }

  return {
    // Generic calls
    get: (endpoint, params, onSuccess, onError, v2) => {
      const backEndUrl = v2 ? backEndUrlV2 : backEndUrlV1
      return getRequest(backEndUrl + endpoint, params, onSuccess, onError)
    },
    getWithoutDeletedParam: (endpoint, params, onSuccess, onError) =>
      getRequestWithoutDeletedParam(
        backEndUrlV1 + endpoint,
        params,
        onSuccess,
        onError,
      ),
    getWithoutDeletedParamV2: (endpoint, params, onSuccess, onError) =>
      getRequestWithoutDeletedParamV2(
        backEndUrlV2 + endpoint,
        params,
        onSuccess,
        onError,
      ),
    post: (endpoint, data, onSuccess, onError) =>
      postRequest(backEndUrlV1 + endpoint, data, {}, onSuccess, onError),
    // Temporary postWithParams function, until all onSuccess/onError handlers will be removed
    // in lieu of Promise based solution
    postWithParams: (endpoint, data, params) =>
      postRequest(backEndUrlV1 + endpoint, data, params || {}),
    postV2: (endpoint, data, onSuccess, onError) =>
      postRequest(backEndUrlV2 + endpoint, data, {}, onSuccess, onError),
    postV2WithParams: (endpoint, data, params) =>
      postRequest(backEndUrlV2 + endpoint, data, params || {}),
    // Notice that Api.patch is different from REST patch method!
    // It requires full endpoint as a first param while any REST.patch method uses id param instead.
    patch: (endpoint, data, onSuccess, onError) =>
      patchRequest(backEndUrlV1 + endpoint, data, onSuccess, onError),
    patchV2: (endpoint, data, onSuccess, onError) =>
      patchRequest(backEndUrlV2 + endpoint, data, onSuccess, onError),
    putWithParams: (
      endpoint,
      data,
      params = {},
      v2 = false,
      onSuccess,
      onError,
    ) =>
      putRequestWithParams(
        (v2 ? backEndUrlV2 : backEndUrlV1) + endpoint,
        data,
        params,
        onSuccess,
        onError,
      ),
    patchWithParams: (
      endpoint,
      data,
      params = {},
      v2 = false,
      onSuccess,
      onError,
    ) =>
      patchRequestWithParams(
        (v2 ? backEndUrlV2 : backEndUrlV1) + endpoint,
        data,
        params,
        onSuccess,
        onError,
      ),
    delete: (
      endpoint,
      params = {},
      v2 = false,
      deleteId = '',
      onSuccess,
      onError,
    ) => {
      let pathParam = '/'
      let objParam = {}

      if (params) {
        if (typeof params === 'string') {
          pathParam += '?' + params
        } else {
          objParam = params
        }
      }
      const backEndUrl = v2 ? backEndUrlV2 : backEndUrlV1
      return deleteRequestWithParams(
        backEndUrl +
          endpoint +
          deleteId +
          (deleteId === '' ? '' : '/') +
          (pathParam === '/' ? '' : pathParam),
        { ...objParam },
        onSuccess,
        onError,
        pathParam !== '/',
      )
    },
    deleteV2: (endpoint, params = {}, deleteId = '', onSuccess, onError) => {
      let pathParam = '/'
      let objParam = {}

      if (params) {
        if (typeof params === 'string') {
          pathParam += '?' + params
        } else {
          objParam = params
        }
      }

      return deleteRequestWithParams(
        backEndUrlV2 +
          endpoint +
          deleteId +
          (deleteId === '' ? '' : '/') +
          (pathParam === '/' ? '' : pathParam),
        { ...objParam },
        onSuccess,
        onError,
        pathParam !== '/',
      )
    },
    put: (endpoint, data, onSuccess, onError) =>
      putRequest(backEndUrlV1 + endpoint, data, onSuccess, onError),

    // Bigger calls
    postImage,
    uploadFile: (file, options, onSuccess, onError) =>
      uploadForm(
        'file_upload/',
        file,
        { ...options, fieldName: 'file' },
        onSuccess,
        onError,
      ),
    uploadImage: (file, options, onSuccess, onError) =>
      uploadForm(
        'image_upload/',
        file,
        { ...options, fieldName: 'image' },
        onSuccess,
        onError,
      ),
    uploadImageV2: (file, options, onSuccess, onError) =>
      uploadForm(
        'image_upload/',
        file,
        { ...options, fieldName: 'image' },
        onSuccess,
        onError,
        true,
      ),
    uploadBulkTraining: (file, onSuccess, onError, params) =>
      uploadBulk('trainings/documentation/', file, onSuccess, onError, {
        ...params,
        v2: true,
      }),
    uploadBulkCostCodes: (file, onSuccess, onError, params) =>
      uploadBulk('cost_codes/', file, onSuccess, onError, params),
    uploadBulkProjects: (file, onSuccess, onError, params) =>
      uploadBulk('projects/', file, onSuccess, onError, params),
    uploadBulkPartyObserved: (file, onSuccess, onError, params) =>
      uploadBulk('answer_party_observed/', file, onSuccess, onError, params),
    uploadBulkUsersV2: (file, onSuccess, onError, params) =>
      uploadBulk('users/', file, onSuccess, onError, { ...params, v2: true }),

    // Wrapped calls
    createReportFieldToFieldOption: (obj, onSuccess) =>
      postRequest(
        backEndUrlV1 + 'report_fields_to_field_options',
        obj,
        {},
        onSuccess,
      ),
    currentUser: (onSuccess) =>
      getRequest(backEndUrlV1 + 'users/me', null, onSuccess),
    getFileDataForDownload: (id, onSuccess, onError) =>
      getRequest(
        backEndUrlV1 + 'file_upload/download',
        { id },
        onSuccess,
        onError,
      ),
    login: (data) => postRequest(backEndUrlV1 + 'api-token-auth', data),
    removeExternalFile: (id, onSuccess) =>
      putRequest(
        backEndUrlV1 + 'file_upload/' + id + '/external_delete/',
        undefined,
        onSuccess,
      ),
    removeExternalImage: (id, onSuccess) =>
      putRequest(
        backEndUrlV1 + 'image_upload/' + id + '/external_delete/',
        undefined,
        onSuccess,
      ),
    removeExternalSignature: (id, onSuccess) =>
      putRequest(
        backEndUrlV1 + 'signatures/' + id + '/external_delete/',
        undefined,
        onSuccess,
      ),
    removeExternalSketch: (id, onSuccess) =>
      putRequest(
        backEndUrlV1 + 'sketches/' + id + '/external_delete/',
        undefined,
        onSuccess,
      ),
    removeFile: (id, onSuccess) =>
      deleteRequest(backEndUrlV1 + 'file_upload/' + id, onSuccess),
    removeImage: (id, onSuccess) =>
      deleteRequest(backEndUrlV1 + 'image_upload/' + id, onSuccess),
    removeSignature: (id, onSuccess) =>
      deleteRequest(backEndUrlV1 + 'signatures/' + id, onSuccess),
    removeSketch: (id, callback) =>
      deleteRequest(backEndUrlV1 + 'sketches/' + id, callback),
    updateReportFieldToFieldOption: (id, obj, onSuccess) =>
      patchRequest(
        backEndUrlV1 + 'report_fields_to_field_options/' + id,
        obj,
        onSuccess,
      ),
    SDSAssignProject: (id, data) =>
      postRequest(
        backEndUrlV1 + 'data_sheets/' + id + '/assign_projects',
        data,
      ),
    SDSArchiveAction: (id, data) =>
      putRequest(backEndUrlV1 + 'data_sheets/' + id + '/archive_sheet/', data),
    getSDSFormChoices: () =>
      getRequest(backEndUrlV1 + 'data_sheets/get_data_sheets_choices'),

    // REST calls
    AnswerNotes: makeRest('answer_notes'),
    Answers: makeRest('answers'),
    AppClients: makeRest('app_clients'),
    AppProjects: makeRest('app_projects'),
    Apps: makeRest('apps'),
    BatchReports: makeRest('batch_reports'),
    CabinetFiles: makeRest('cabinet_files'),
    Cabinets: makeRest('cabinets'),
    Categories: makeRest('categories'),
    CategorySets: makeRest('category_sets'),
    ClientAppQuestions: makeRest('client_app_questions'),
    ClientAppQuestionsCategories: makeRest('client_app_questions_categories'),
    ClientProcedures: makeRest('client_procedures'),
    ClientProcessFlows: makeRest('client_process_flows'),
    Companies: makeRest('companies'),
    CompanyNews: makeRest('news'),
    CorrectiveActions: makeRest('corrective_actions'),
    EmployeeDataDownloadV2: makeRest('users/employee_data_download', true),
    EmployeesGroupList: makeRest('user_sets'),
    EmployeesList: makeRest('users/trainings'),
    ExportAllReports: makeRest('reports/export'),
    ExportMobileFormsList: makeRest('mobile_forms/drop_down'),
    ExportXlsFile: makeRest('reports/xls'),
    FieldOptions: makeRest('field_options'),
    Fields: makeRest('fields'),
    FormSets: makeRest('client_form_sets'),
    GeneralSettings: makeRest('general_settings'),
    Groups: makeRest('groups'),
    JobAssign: makeRest('assign'),
    Jobs: makeRest('jobs'),
    Lessons: makeRest('lessons'),
    MobileForms: makeRest('mobile_forms'),
    ObservationNotes: makeRest('observation_notes'),
    Observations: makeRest('observations'),
    Procedures: makeRest('procedures'),
    ProcessFlows: makeRest('process_flows'),
    Projects: makeRest('projects'),
    ProjectsV2: makeRest('projects', true),
    Questions: makeRest('questions'),
    ReportFields: makeRest('report_fields'),
    ReportSettings: makeRest('report_settings'),
    Reports: makeRest('reports'),
    ReportTemp: makeRest('report_tmp'),
    SDSForm: makeRest('data_sheets'),
    Signatures: makeRest('signatures'),
    Sketches: makeRest('sketches'),
    Themes: makeRest('themes'),
    TopicContent: makeRest('training_content'),
    Topic: makeRest('training_topic'),
    Topics: makeRest('training_topics'),
    TrainingCopy: makeRest('training_copy'),
    TrainingGroupList: makeRest('training_sets'),
    TrainingsDocumentationV1: makeRest('trainings/documentation'),
    TrainingsDocumentation: makeRest('trainings/documentation', true),
    TrainingNewCopy: makeRest('training_new_copy'),
    TrainingQuizFieldOptions: makeRest('training_field_options'),
    TrainingQuizFields: makeRest('training_fields'),
    Trainings: makeRest('trainings'),
    TrainingsList: makeRest('assign_trainings'),
    ToolboxTalks: makeRest('toolbox_talks'),
    ToolboxTopics: makeRest('toolbox_topics'),
    ToolboxTopicSets: makeRest('toolbox_topic_sets'),
    Quiz: makeRest('quiz'),
    Users: makeRest('users'),
    UsersV2: makeRest('users', true),
    ServiceAccounts: makeRest('service_accounts', true),
    ServiceAccountPermissions: makeRest('service_account_permissions', true),
    Timecards: makeRest('timecards'),
    TimecardSettingsTimecard: makeRest('timecards_settings'),
    TimecardSettingsMarkup: makeRest('tm_settings'),
    TimecardSettingsTerms: makeRest('tm_terms'),
    TimecardSettingsCrewCategory: makeRest('crew_category'),
    TimecardSettingsNotes: makeRest('timecards_notes'),
    TimecardDailyReports: makeRest('daily_reports'),
    CostCodes: makeRest('cost_codes'),
  }
}
