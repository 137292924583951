import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { CommonPageHeader, useAngularServices } from '@/react/components'
import { ConfigNavBar } from '../AddProject/components/ConfigNavBar'
import uploadIconRounded from '../../../../assets/icons/svg-gray-icons/upload-icon-gray-rounded.svg'
import { DropZoneV2 } from './components/DropzonV2'
import * as XLSX from 'xlsx'
import { Department } from '../AddProject/AddProject'
import { useRouter } from '@/react/hooks'

export const BulkUploadProjects = () => {
  const [departments, setDepartments] = useState<Department[]>([])
  const [deletedDepartments, setDeletedDepartments] = useState<Department[]>([])
  const [errors, setErrors] = useState<
    Array<{ message: string; line?: number }>
  >([])
  const router = useRouter()
  const { Api, CurrentUser, Notification } = useAngularServices()
  const role = CurrentUser.getRole()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const projectTitile = CurrentUser.getClientSettings().projects_language
  const [fileErrors, setFileErrors] = useState<string[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [summaryData, setSummaryData] = useState<{
    totalProjects: number | null
    validProjects: number | null
    newDepartments: string[]
  } | null>(null)
  const [extractedProjects, setExtractedProjects] = useState<any[]>([])
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isInfoBlockVisible, setIsInfoBlockVisible] = useState<boolean>(false)
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const uploadIntervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (role !== 'client_admin') {
      router.stateService.go('admin.projects.list')
    }
  }, [])

  const fetchDepartments = async () => {
    try {
      const { data } = await Api.get(
        'client_departments',
        {},
        undefined,
        undefined,
        true,
      )
      setDepartments(data)
    } catch (error) {
      Notification.error('Error fetching departments')
    }
  }

  const fetchDeletedDepartments = async () => {
    try {
      const { data } = await Api.get(
        'client_departments',
        { deleted: true },
        undefined,
        undefined,
        true,
      )
      setDeletedDepartments(data)
    } catch (error) {
      Notification.error('Error fetching deleted departments')
    }
  }

  const restoreDepartment = async (departmentId: number) => {
    try {
      const updatedDepartment = { deleted: false }
      const { data } = await Api.patchV2(
        `client_departments/${departmentId}`,
        updatedDepartment,
      )
      setDepartments((prev) => [...prev, data])
      setDeletedDepartments((prev) =>
        prev.filter((dep) => dep.id !== departmentId),
      )
      setSummaryData((prev) => ({
        totalProjects: prev?.totalProjects ?? null,
        validProjects: prev?.validProjects ?? null,
        newDepartments: [...(prev?.newDepartments ?? []), data.name],
      }))

      Notification.success('Department restored successfully!')
      return true
    } catch (error) {
      Notification.error('Failed to restore department')
      return false
    }
  }

  const createDepartment = async (departmentName: string) => {
    if (!departmentName.trim()) {
      Notification.error('Department name cannot be empty')
      return null
    }

    try {
      const newDepartment = {
        name: departmentName,
        client: CurrentUser.getClientId(),
      }
      const { data } = await Api.postV2WithParams(
        'client_departments',
        newDepartment,
      )
      setDepartments((prev) => [...prev, data])
      setSummaryData((prev) => ({
        totalProjects: prev?.totalProjects ?? null,
        validProjects: prev?.validProjects ?? null,
        newDepartments: [...(prev?.newDepartments ?? []), departmentName],
      }))

      Notification.success(
        `Department "${departmentName}" created successfully!`,
      )
      return data
    } catch (error) {
      Notification.error(`Failed to create department: ${departmentName}`)
      return null
    }
  }

  const validateFile = async (file: File) => {
    setIsChecking(true)
    setErrors([])
    setSummaryData(null)
    setIsButtonDisable(false)

    const reader = new FileReader()

    for (let i = 20; i < 90; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      setUploadProgress(i)
    }

    reader.onload = async (e) => {
      const data = e.target?.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      let jsonData: any[] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: false,
      })

      const filteredData = jsonData.filter((row) =>
        row.some((cell) => cell !== undefined && cell !== ''),
      )
      const totalProjects = Math.max(filteredData.length - 1, 0)

      if (totalProjects > 2000) {
        setErrors([
          {
            message: `Maximum allowed rows is 2000, you have ${totalProjects}.`,
          },
        ])
        setUploadProgress(100)
        setIsChecking(false)
        return
      }
      const fileColumns = jsonData[0] as string[]

      const requiredHeaders = [
        'Project Name*',
        'Project Number',
        'Street',
        'City',
        'State',
        'Zip Code',
        'Department',
        'Industry',
        'Company Representative',
        `Client's Name`,
        'Supervisor',
        'Safety Manager',
        'General Contractor',
        'Project Manager',
        'Contact Name',
        'Notice to Proceed Date (MM/DD/YYYY)',
        'Substantial Completion Date (MM/DD/YYYY)',
        'Final Completion Date (MM/DD/YYYY)',
        'Effective Date (MM/DD/YYYY)',
        'Notes'
      ]

      const headersMatch = requiredHeaders.length === fileColumns.length &&
        requiredHeaders.every((header, index) => header === fileColumns[index])

      if (!headersMatch) {
        setErrors([
          {
            message: 'Cannot validate file. Please use the correct column headers in row one from the example document.',
          },
        ])
        setUploadProgress(100)
        setIsChecking(false)
        return
      }

      let validProjects = 0
      const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/ // MM/DD/YYYY

      const columnMap: Record<string, string> = {}
      fileColumns.forEach((col) => {
        const cleaned = col.replace(/\s*\(.*?\)\s*/g, '').trim()
        columnMap[cleaned] = col
      })

      const dateFields = Object.keys(columnMap).filter((col) =>
        col.toLowerCase().includes('date'),
      )

      const projectNameIndex = Object.keys(columnMap).indexOf('Project Name*')
      if (projectNameIndex === -1) {
        setErrors((prev) => [
          ...prev,
          { message: 'Missing required column: Project Name*', line: 1 },
        ])
        validProjects = 0
      }

      const processedProjects = await Promise.all(
        filteredData.slice(1).map(async (row, rowIndex) => {
          let project: any = { line: rowIndex + 2 }
          Object.keys(columnMap).forEach((cleanedCol, index) => {
            project[cleanedCol] =
              row[fileColumns.indexOf(columnMap[cleanedCol])] || ''
          })

          dateFields.forEach((field) => {
            const originalField = columnMap[field];
            let dateValue = project[field];

            if (!dateValue) return;
            dateValue = dateValue.toString().trim();

            if (!dateRegex.test(dateValue)) {
              setErrors((prev) => [
                ...prev,
                {
                  message: `${originalField} - Date has wrong format. Use this format instead: MM/DD/YYYY`,
                  line: rowIndex + 2,
                },
              ]);
            }
          });

          if (
            !project['Project Name*'] ||
            project['Project Name*'].toString().trim() === 'NaN'
          ) {
            setErrors((prev) => {
              const newErrors = [
                ...prev,
                { message: 'Missing Project Name*', line: rowIndex + 2 },
              ]
              return newErrors
            })

            return null
          }

          return project
        }),
      )

      setExtractedProjects(processedProjects.filter((p) => p !== null))
      setSummaryData((prev) => ({
        totalProjects: totalProjects || 0,
        validProjects: validProjects || 0,
        newDepartments: prev?.newDepartments ?? [],
      }))
      setUploadProgress(100)
      setIsChecking(false)
    }
    reader.readAsBinaryString(file)
  }

  const handleRemoveFile = () => {
    setSelectedFile(null)
    setSummaryData({ totalProjects: 0, validProjects: 0, newDepartments: [] })
    setErrors([])
    setExtractedProjects([])
    setIsButtonDisable(false)
    setIsInfoBlockVisible(false)
    setIsButtonDisable(true)
  }

  const startUploadProgressAnimation = () => {
    setUploadProgress(10)
    uploadIntervalRef.current = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 95) {
          clearInterval(uploadIntervalRef.current!)
          return prev
        }
        return prev + 1
      })
    }, 100)
  }

  const uploadProjectsPromise = (file: File) => {
    return new Promise((resolve, reject) => {
      Api.uploadBulkProjects(
        file,
        (resp: any) => resolve(resp),
        (err: any) => reject(err),
      )
    })
  }

  const handleCreateProjects = async () => {
    setUploadProgress(0)
    startUploadProgressAnimation()

    try {
      setIsButtonDisable(true)

      const validProjects = extractedProjects.filter(
        (p) => !errors.some((err) => err.line === p.line)
      )
      if (validProjects.length === 0) {
        Notification.error('No valid projects to upload.')
        setIsInfoBlockVisible(true)
        return
      }

      const departmentCache: Record<string, Department> = {}
      const uniqueDepartmentNames = Array.from(
        new Set(validProjects.map((p) => p['Department'].trim().toLowerCase()))
      )

      for (const departmentName of uniqueDepartmentNames) {
        if (departmentCache[departmentName]) continue

        let department = departments.find(
          (dep) => dep.name.toLowerCase() === departmentName
        )

        if (!department) {
          department = deletedDepartments.find(
            (dep) => dep.name.toLowerCase() === departmentName
          )

          if (department) {
            department = await restoreDepartment(department.id)
          }
        }

        if (!department) {
          department = await createDepartment(departmentName)
        }

        if (department) {
          departmentCache[departmentName] = department
        }
      }

      const resp = await uploadProjectsPromise(selectedFile)
      const newErrors: { message: string; line?: number }[] = []

      if (Array.isArray(resp?.message)) {
        resp.message.forEach((msgObj) => {
          if (msgObj.row && msgObj.error) {
            Object.entries(msgObj.error).forEach(([field, messages]) => {
              newErrors.push({
                message: `${field}: ${messages.join(', ')}`,
                line: msgObj.row,
              })
            })
          } else if (msgObj.message) {
            newErrors.push({ message: msgObj.message })
          }
        })

        setErrors((prev) => [...prev, ...newErrors])
        setIsInfoBlockVisible(true)
      }
    } catch (error: any) {
      setIsButtonDisable(true)

      Notification.error('Error! Failed to upload projects.')

      if (Array.isArray(error)) {
        const lineErrors: { message: string; line?: number }[] = []

        error.forEach((err) => {
          if (err.startsWith('Line')) {
            lineErrors.push({ message: err })
          } else {
            Notification.fileError(err)
          }
        })

        setErrors((prev) => [...prev, ...lineErrors])
      }
      const summaryText = error?.message[1].message
      const matched = summaryText?.match(/(\d+) out of (\d+) projects/)

      if (matched) {
        const uploadedCount = parseInt(matched[1])
        const totalCount = parseInt(matched[2])

        if (uploadedCount > 0) {
          setSummaryData((prev) => ({
            ...prev,
            validProjects: uploadedCount,
            totalProjects: totalCount,
          }))
        }
      }

      setIsInfoBlockVisible(true)
      Notification.clearNotification()

      clearInterval(uploadIntervalRef.current!)
      setUploadProgress(100)
      setTimeout(() => setUploadProgress(0), 800)
    } finally {
      clearInterval(uploadIntervalRef.current!)
      setUploadProgress(100)

      setTimeout(() => setUploadProgress(0), 800)
      if (errors.length < 1) {
        router.stateService.go('admin.projects.list')
      }
    }
  }

  useEffect(() => {
    fetchDepartments()
    fetchDeletedDepartments()
    if (selectedFile) {
      validateFile(selectedFile)
    }
  }, [selectedFile])

  const textForDropzone = {
    dropMessage: (
      <>
        {' '}
        <p
          css={css({
            color: '#B3B3B3',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.24px',
            margin: 0,
          })}
        >
          Drop your .XLS file or drag it here
        </p>
        <p
          css={css({
            color: '#B3B3B3',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.12px',
            margin: '0',
          })}
        >
          Please keep bulk uploads under 2,000 rows
        </p>
      </>
    ),
    hoverMessage: (
      <>
        <p
          css={css({
            color: '#B3B3B3',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.24px',
            margin: 0,
          })}
        >
          Click to choose a .XLS file or drag it here
        </p>
        <p
          css={css({
            color: '#B3B3B3',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.12px',
            margin: '0',
          })}
        >
          Please keep bulk uploads under 2,000 rows
        </p>
      </>
    ),
  }

  return (
    <section css={baseStyle(variableColor)}>
      <CommonPageHeader headerText={projectTitile} />
      <ConfigNavBar />
      <div className="form-container">
        <div className="download-links">
          <a
            download
            href="/assets/project_bulk_upload_template_3-2025.xlsx"
            target="_self"
          >
            <u className="ccs-link">Example Document</u>
          </a>
        </div>

        <div className="dropzone-block">
          <DropZoneV2
            dropMessage={textForDropzone.dropMessage}
            hoverMessage={textForDropzone.hoverMessage}
            addCallback={() => console.log('File added')}
            removeCallback={handleRemoveFile}
            fileStorage={selectedFile}
            fileSetStorage={setSelectedFile}
            customUploadIcon={uploadIconRounded}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
            accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>

        {fileErrors.length > 0 && (
          <div className="error-message">
            <span className="control-label">
              Please keep bulk uploads under 2000 Records
            </span>
            <ul>
              {fileErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="button">
          <div className="infoBlock">
            {isInfoBlockVisible && selectedFile ? (
              <>
                {
                  summaryData &&
                  (
                    <div className="summary-section">
                      {(summaryData?.newDepartments.length > 0 || summaryData?.validProjects > 0) && (
                        <>
                          <h4>SUMMARY</h4>
                          {summaryData?.validProjects! > 0 && (
                            <>
                              <p className="summaryText">
                                {summaryData.validProjects?.toLocaleString('en-US')} out
                                of {summaryData.totalProjects?.toLocaleString('en-US')}{' '}
                                projects uploaded successfully!
                              </p>
                            </>
                          )}

                          {summaryData.newDepartments.length > 0 && (
                            <p>
                              {summaryData.newDepartments.length}{' '}
                              Departments created
                            </p>
                          )}</>
                      )}
                    </div>
                  )}
                {errors.length > 0 && (
                  <div className="error-list">
                    <h4 style={{ color: '#C80404' }}>Errors:</h4>
                    <ul style={{ marginBottom: '0' }}>
                      {errors.map((err, index) => (
                        <li style={{ color: '#C80404' }} key={index}>
                          {err.line
                            ? `Line ${err.line}: ${err.message}`
                            : err.message}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <button
            onClick={handleCreateProjects}
            disabled={isChecking || isButtonDisable}
            type="submit"
            className="create-button ml-auto btn-default-general btn-default-primary"
          >
            Save
          </button>
        </div>
      </div>
    </section>
  )
}

const baseStyle = (variableColor: string) =>
  css({
    paddingBottom: 'calc(4rem + 100px)',
    margin: '0 auto',

    '@media(max-width: 990px)': {
      '.form-container': {
        paddingRight: '15px !important',
        paddingLeft: '15px !important',
      },

      '.infoBlock': {
        maxWidth: '200px',
      },
    },

    '.form-container': {
      marginRight: '23px',
      maxWidth: '740px',
      marginLeft: '11px',
      backgroundColor: '#fff',
      padding: '16px 30px 20px 30px',
    },

    '.dropzone-block': {
      height: '312px',
      width: '102%',
    },
    '.download-links': {
      marginBottom: '-18px',
      textAlign: 'right',
    },
    '.dropzone': {
      marginLeft: '11px',
      border: '0',
      padding: '20px',
      textAlign: 'center',
      cursor: 'pointer',
      borderRadius: '0',
    },
    '.error-message': {
      color: 'red',
      marginTop: '10px',
    },
    h4: {
      margin: '0 !important',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
    },
    li: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
    },
    'li, ul': {
      listStyleType: 'none',
      paddingLeft: 0,
      marginLeft: 0,
    },

    '.summary-section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '.summaryText': {
        margin: 0,
      },
    },
    '.button': {
      paddingTop: '20px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',

      '.infoBlock': {
        maxWidth: '550px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '.btn-primary': {
        width: '75px',
        background: variableColor,
        color: 'white',
        padding: '8px 15px',
        cursor: 'pointer',
      },
    },
  })
