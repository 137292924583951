import { InfoModal } from '@/react/componentAssets/InfoModal'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const CategoryRow = ({
  index,
  search,
  category,
  assignedList,
  clientAppQuestionId,
  setRefresh,
}) => {
  const [hoveredField, setHoveredField] = useState('')

  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [error, setError] = useState(false)

  const handleAssign = async () => {
    try {
      await Api.patch(`client_app_questions/${clientAppQuestionId}`, {
        id: clientAppQuestionId,
        custom_categories: !assignedList?.includes(category?.id)
          ? [...assignedList, category?.id]
          : assignedList.filter((id) => id !== category?.id),
      })
    } catch (error) {
      Notification.clearNotification()
      if (error.status === 400) {
        setError(error.data.detail)
      }
    }

    setRefresh(true)
  }

  return (
    <tr
      className={classNames('category__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: category?.name,
            max__length: 110,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={category?.name}
        />
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={assignedList?.includes(category?.id)}
            onChange={handleAssign}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>

        {error && (
          <InfoModal left="-187px" message={error} setModalOpen={setError} />
        )}
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
