import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  NodeSelection,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import {
  getToolbarSettings,
  quickToolbarSettings,
} from '../assets/toolbarVariables'
import { ButtonElement, useAngularServices } from '@/react/components'
import { useEffect, useRef, useState } from 'react'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'
import { ExternalLinkModal } from './ExternalLinkModal'
import moment from 'moment'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const CustomEmailModal = ({ form, handleClose, setRefresh, idx }) => {
  let selection = new NodeSelection()
  let range

  const modalRef = useRef()
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [emailData, setEmailData] = useState(form?.email_settings)
  const [isModalOpen, setModalOpen] = useState(false)
  const [saveSelection, setSaveSelection] = useState()
  const [rteObj, setRteObj] = useState()
  const isSafari = checkIsSafari()

  const styles = getStyles(variableColor, idx, isSafari)

  const handleOutsideClick = (e) => {
    const quickToolbar = e.target.closest('.e-rte-elements')

    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !quickToolbar
    ) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleDelete = async () => {
    const emailId = form?.email_settings?.id

    if (emailId) {
      await Api.delete(`mobile_form/email_settings/${emailId}`)
    }

    setRefresh(true)
    handleClose()
  }

  const handleSubmit = async () => {
    if (!form?.email_settings) {
      await Api.post('mobile_form/email_settings', {
        form: form?.id,
        email_subject: emailData?.email_subject,
        email_body_html: emailData?.email_body_html,
      })
    } else {
      await Api.put(`mobile_form/email_settings/${form?.email_settings?.id}/`, {
        form: form?.id,
        email_subject: emailData?.email_subject,
        email_body_html: emailData?.email_body_html,
      })
    }

    setRefresh(true)
    handleClose()
  }

  const handleExternalIconClick = () => {
    setModalOpen(true)
  }

  const selectionSave = () => {
    range = selection.getRange(document) // get the range
    setSaveSelection(selection.save(range, document))
    if (rteObj.formatter.getUndoRedoStack().length === 0) {
      rteObj.formatter.saveData()
    }
  }

  const OnCreated = () => {
    document
      .getElementById('custom_tbarbtn_3')
      .addEventListener('mousedown', selectionSave.bind(this))
  }

  const handleExternalLink = (displayText: string) => {
    setModalOpen(false)
    saveSelection.restore()

    if (rteObj.formatter.getUndoRedoStack().length === 0) {
      rteObj.formatter.saveData()
    }
    saveSelection.restore()
    rteObj.executeCommand(
      'insertHTML',
      `<a href="{{ share_link }}">${displayText}</a>`,
    )
    rteObj.formatter.saveData()
    rteObj.formatter.enableUndo(rteObj)
  }


  document.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
    })
  })

  const toolbarSettings = getToolbarSettings(handleExternalIconClick)

  const formattedDate = moment().format('MMMM D, YYYY [at] hh:mm:ss A')

  const handleBeforeDialogOpen = (args) => {
    if (args.target === 'InsertLink') {
      setTimeout(() => {
        const openInNewTabCheckbox = document.querySelector(
          'input.e-rte-linkTarget',
        )
        if (openInNewTabCheckbox) {
          openInNewTabCheckbox.checked = true
        }
      }, 100)
    }
  }

  const beforeQuickToolbarOpen = (args) => {
    const selection = window.getSelection()
    if (!selection.rangeCount) {
      return
    }

    const range = selection.getRangeAt(0)
    const parentElement = range.commonAncestorContainer.parentElement

    if (parentElement && parentElement.tagName === 'A') {
      if (!parentElement.title) {
        args.cancel = true
      }
      if (parentElement.href === parentElement.title) {
        args.cancel = true
      }
    }
  }

  return (
    <div className="modal__wrapper" css={styles} ref={modalRef}>
      <img
        src={closeIcon}
        alt="close"
        className="modal__x"
        onClick={handleClose}
      />
      <div className="titles__wrapper">
        <h4 className="modal__title">Customize Email</h4>
        <p className="form__name">{form?.name}</p>
      </div>

      <div className="modal__functionality">
        <div className="input__wrapper">
          <label className="input__label">Subject</label>
          <AutoExpandTextArea
            className="modal__input"
            placeholder={`${form?.name} Submission Confirmed`}
            name="email_subject"
            value={emailData?.email_subject}
            handleChange={(e) => {
              setEmailData({
                ...emailData,
                [e.target.name]: e.target.value,
              })
            }}
          />
        </div>

        <div className="input__wrapper">
          <label className="input__label">Body</label>
          <RichTextEditorComponent
            placeholder={`${form?.name} was saved on ${formattedDate}`}
            id="defaultRTE"
            ref={(richtexteditor) => {
              setRteObj(richtexteditor)
            }}
            toolbarSettings={toolbarSettings}
            style={{ fontSize: '12px', width: '550px' }}
            saveInterval={1}
            quickToolbarSettings={{
              link: ['Open', 'Edit', 'UnLink'],
            }}
            beforeQuickToolbarOpen={beforeQuickToolbarOpen}
            beforeDialogOpen={handleBeforeDialogOpen}
            value={emailData?.email_body_html}
            change={(e) => {
              setEmailData({
                ...emailData,
                email_body_html: e.value,
              })
            }}
            created={OnCreated.bind(this)}
          >
            <Inject
              services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
            />
          </RichTextEditorComponent>
          {isModalOpen ? (
            <ExternalLinkModal
              handleExternalLink={handleExternalLink}
              handleClose={() => {
                setModalOpen(false)
              }}
            />
          ) : null}
        </div>

        <div className="buttons__wrapper">
          <ButtonElement text="Delete" functionToTrigger={handleDelete} />
          <ButtonElement
            text="Save"
            buttonType="submit"
            functionToTrigger={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

function getStyles(variableColor, idx, isSafari) {
  return css({
    position: 'absolute',
    background: '#fff',
    zIndex: 1,
    paddingInline: '46px',
    left: '200px',
    boxShadow: '0px 7px 20px 10px rgba(0, 0, 0, 0.10)',
    '.modal__x': {
      position: 'absolute',
      right: '10px',
      top: '8px',
      height: '15px',
      cursor: 'pointer',
    },
    '.titles__wrapper': {
      paddingTop: '21px',
      display: 'flex',
      flexDirection: 'column',
      gap: '3px',
      alignItems: 'center',
    },
    '.modal__title': {
      margin: 0,
      fontSize: '14px',
      fontWeight: 600,
      color: '#656565',
    },
    '.form__name': {
      fontSize: '14px',
      color: '#656565',
      fontWeight: 400,
      maxWidth: '550px',
      marginBottom: '10px',
    },
    '.modal__functionality': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
    '.input__wrapper': {
      position: 'relative',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      alignItems: 'flex-start',
      width: '550px',
      textAlign: 'left',
    },
    '.input__label': {
      color: '#686A6C',
      fontSize: '13px',
      fontWeight: 300,
      margin: 0,
    },
    '.buttons__wrapper': {
      display: 'flex',
      gap: '10px',
      paddingTop: '10px',
      paddingBottom: '20px',
    },
    '.modal__input': {
      padding: '10px 14px',
      height: '32px',
      width: '550px',
      fontSize: '13px',
      fontWeight: 400,
      outline: 'none',
      color: '#000',
      border: '1px solid #CDCCCC',
      maxHeight: '85px',

      '&::placeholder': {
        color: '#CDCCCC',
      },
    },
    '.e-input:focus': {
      borderBottom: `1px solid ${variableColor} !important`,
    },
    '.e-check': {
      backgroundColor: `${variableColor} !important`,
    },
    '.e-insertLink': {
      color: `${variableColor} !important`,
    },
    '.rte-placeholder': {
      opacity: 1,
      color: '#CDCCCC',
      fontWeight: 300,
    },
    '.e-content': {
      maxHeight: '392px',
    },
    '@media(max-width: 1070px)': {
      left: isSafari ? '90px' : '200px',
    },
    '@media(max-width: 1300px)': {
      bottom: idx >= 10 && !isSafari ? '10px' : '',
    },
  })
}
