import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { CheckboxProps } from '@/react/types'

const Checkbox = ({
  name,
  checked,
  onChange,
  variableColor,
}: CheckboxProps) => {
  const formatName = (text: string) => {
    const parts = text.split(/(Questions|Categories)/)

    return parts.map((part, index) => {
      if (part === 'Questions' || part === 'Categories') {
        return (
          <em key={index} style={{ fontStyle: 'italic' }}>
            {part}
          </em>
        )
      }
      return <span key={index}>{part}</span>
    })
  }

  return (
    <div className="checkmark__block">
      <label className="checkbox-container">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark">
          <ThinCheckmark color={variableColor} />
        </span>
      </label>
      <p className="checkmark__title">{formatName(name)}</p>
    </div>
  )
}


export default Checkbox
