import classNames from 'classnames'
import moment from 'moment'
import { useState } from 'react'
import { EditForPartyObserved } from './EditForPartyObserved'
import Highlighter from 'react-highlight-words'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { BinDeleteIcon } from '@/react/componentAssets'

interface PartyRowProps {
  party: any
  idx: number
  handleChecked: any
  handleDelete: any
  search: string
  variableColor: string
  setRefresh: any
}

export const PartyRow = ({
  party,
  idx,
  handleChecked,
  handleDelete,
  search,
  variableColor,
  setRefresh,
}: PartyRowProps) => {
  const { id, name, date_created, date_updated, is_active } = party

  const dateCreated = moment(date_created).format('MM/DD/YYYY')
  const dateUpdated = moment(date_updated).format('MM/DD/YYYY')

  const [isEditing, setEditing] = useState(false)
  const [hoveredField, setHoveredField] = useState(null)

  return (
    <tr
      key={party.id}
      className={classNames('table__row', {
        dark: idx % 2 === 0,
      })}
    >
      {isEditing ? (
        <td className="link__cell">
          <EditForPartyObserved
            initialText={name}
            id={id}
            handleClose={() => {
              setEditing(false)
              setRefresh(true)
            }}
          />
        </td>
      ) : (
        <td
          className="link__cell"
          onClick={() => {
            setEditing(true)
          }}
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name',
              text: name,
              max__length: 56,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <Highlighter
            highlightClassName="highlighted__item"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={name}
          />
        </td>
      )}
      <td>
        <Highlighter
          highlightClassName="highlighted__item"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={dateCreated}
        />
      </td>
      <td>
        <Highlighter
          highlightClassName="highlighted__item"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={dateUpdated}
        />
      </td>
      <td>
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={is_active}
            onChange={() => {
              handleChecked(id, !is_active)
            }}
          />
          <span className="checkmark">
            <TrainingsCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      <td className="delete__cell">
        <BinDeleteIcon
          color={variableColor}
          onClick={() => {
            handleDelete(party.id)
          }}
        />
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && !isEditing && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
