import { css } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'
import useClickOutside from '@/react/hooks/useClickOutside'

export function PhotoModal({ photoUrl, setIsModalOpen }) {
  const baseStyle = getBaseStyles()

  const modalRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(modalRef, setIsModalOpen)

  return (
    <div css={baseStyle} className="modal-photo" ref={modalRef}>
      <div className="modal__content">
        <img className="modal__image" src={photoUrl} alt="" />
        <img
          className="modal__deleteCross"
          src={deleteCross}
          alt=""
          onClick={() => {
            dismiss()
          }}
        />
      </div>
    </div>
  )
}

function getBaseStyles() {
  return css({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: '-400px',
    width: '100%',
    height: '100%',
    '.modal__content': {
      position: 'relative',
      backgroundColor: '#fefefe',
      width: '642px',
      margin: 'auto',
      marginTop: '100px',
      borderRadius: '20px',
      padding: '20px',
      border: '1px solid #888',
    },
    '.modal__image': {
      width: '100%',
      height: '600px',
      objectFit: 'contain',
    },
    '.modal__deleteCross': {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer',
      width: '20px',
      height: '20px',
    },
  })
}
