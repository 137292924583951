import React, { useEffect, useState } from 'react'
import { NavBar } from '../commonComponents/Navbar'
import {
  CommonPageHeader,
  PageSwitcher,
  useAngularServices,
} from '@/react/components'
import { Search } from '@/react/components'
import { css } from '@emotion/react'
import { DocumentationTableBody } from './DocumentationTableBody'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { CurrentUserType, Documentation } from '@/react/types/documentation'

export const DocumentationPage = React.memo(() => {
  const [page, setPage] = useState(1)
  const [filteredListCount, setFilteredListCount] = useState(0)
  const [listCount, setListCount] = useState(0)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('name')
  const [selectedItems, setSelectedItems] = useState([])
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const [isAnalyticsIconVisible, setAnalyticsIconVisible] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)
  const [filteredDocumentationList, setFilteredDocumentationList] = useState<
    Documentation[]
  >([])
  const [isTrainedFilterApplied, setIsTrainedFilterApplied] = useState(false)
  const [isExpiringFilterApplied, setIsExpiringFilterApplied] = useState(false)

  useEffect(() => {
    const role = CurrentUser.getRole()
    if (role === 'client_admin') {
      setAnalyticsIconVisible(true)
    }
  }, [])

  const effectiveListCount =
    isTrainedFilterApplied || isExpiringFilterApplied
      ? filteredListCount
      : listCount

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={'Training Documentation'}
        iconLink="https://rtrspro.knowledgetransfer.us/#/views/TrainingDashboard-AS/TrainingDashboard?:iid=4"
        isAnalyticsIconVisible={isAnalyticsIconVisible}
      />
      <NavBar />
      <div className="documentation__wrapper">
        <div className="search__block">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
            onClear={() => {
              setChecked(false)
            }}
          />
        </div>
        <div className="table__container">
          <DocumentationTableBody
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            search={search}
            order={order}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            setListCount={setListCount}
            listCount={listCount}
            isChecked={isChecked}
            setChecked={setChecked}
            setSearch={setSearch}
            filteredDocumentationList={filteredDocumentationList}
            setFilteredDocumentationList={setFilteredDocumentationList}
            isTrainedFilterApplied={isTrainedFilterApplied}
            setIsTrainedFilterApplied={setIsTrainedFilterApplied}
            isExpiringFilterApplied={isExpiringFilterApplied}
            setIsExpiringFilterApplied={setIsExpiringFilterApplied}
            setFilteredListCount={setFilteredListCount}
          />
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={effectiveListCount}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
})

function getBaseStyle(variableColor: string, isSafari: boolean) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    paddingBottom: '300px',
    '@media(max-width: 1365px)': {
      '.table__container': {
        overflowX: 'scroll',
        minHeight: '600px',
      },
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    h2: {
      position: 'relative',
    },
    '.cell': {
      margin: 0,
      maxHeight: '40px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.trainee': {
      maxWidth: '135px',
    },
    '.id': {
      maxWidth: '56px',
    },
    '.topic': {
      display: 'flex',
      gap: '5px',
      maxWidth: '220px',
    },
    '.date': {
      maxWidth: '85px',
    },
    '.trained': {
      maxWidth: '138px',
    },
    '.company': {
      maxWidth: '168px',
    },
    '.trade': {
      maxWidth: '118px',
    },
    '.documentation__wrapper': {
      backgroundColor: 'white',
      maxWidth: '1180px',
      padding: '0 20px',
    },
    '.search__block': {
      paddingTop: '20px',
      paddingBottom: '15px',
    },
    '.documentation__row': {
      color: '#575757',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox__td': {
      paddingRight: '15px',
      paddingLeft: '12px',
    },
    '.link__cell': {
      display: 'flex',
      alignItems: 'center',
      color: variableColor,
      cursor: 'pointer',
    },
    '.page-switcher-base': {
      marginBottom: '50px',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      cursor: 'pointer',
      marginLeft: '3px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '#search': {
      width: '264px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.topic__cell': {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '5px',
    },
    '.topic__text': {
      margin: 0,
      display: 'inline-block',
      maxHeight: '54px',
      maxWidth: '230px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.nav__wrapper': {
      marginLeft: '5px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.trainee__fullname': {
      left: '50px',
    },
    '.id__fullname': {
      left: '200px',
    },
    '.topic__fullname': {
      left: '270px',
    },
    '.trained__fullname': {
      left: '650px',
    },
    '.company__fullname': {
      left: '800px',
    },
    '.trade__fullname': {
      left: '890px',
    },
    '@media(max-width: 1380px)': {
      '.full__name': {
        display: 'none',
      },
    },
  })
}
