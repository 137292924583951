import { ButtonElement, useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import React, { useEffect, useRef, useState } from 'react'
import { ContentDropZone } from './ContentDropZone'
import { MenuItem, Select } from '@mui/material'
import { VideoIcon } from '@/react/componentAssets/VideoIcon'
import { PdfTrainingsIcon } from '@/react/componentAssets/PdfTrainingsIcon'
import { PptIcon } from '@/react/componentAssets/PptIcon'
import { HandsOnModalIcon } from '@/react/componentAssets/HandsOnModalIcon'
import { QuizIcon } from '@/react/componentAssets/QuizIcon'
import closeIcon from '@/assets/icons/X Gray.svg'
import classNames from 'classnames'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'
import checkVideoCompatibility from './video-processor/videoCompatibilityChecker'

export const AddEditContentModal = ({
  handleShowModal,
  topicId,
  setRefresh,
  trainingId,
  contentEditData,
  maxContentOrder,
  isQuizInTheTopic,
}) => {
  const [file, setFile] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [isLoading, setFileLoading] = useState(false)
  const [fileError, setFileError] = useState('')
  const modalRef = useRef()
  const { stateService } = useRouter()

  const [acceptedTypes, setAcceptedTypes] = useState<string[] | string>('')
  const [contentInfo, setContentInfo] = useState({
    content_name: '',
    content_type: 'placeholder',
    order: maxContentOrder,
    description: '',
    completion_code: '',
  })
  const { Api, Notification, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  useEffect(() => {
    if (contentEditData) {
      setContentInfo(contentEditData)
      setFile(contentEditData?.file)
    }
  }, [contentEditData])

  const handleContentChange = (e) => {
    if (e.target.name === 'order') {
      if (e.target.value > maxContentOrder) {
        e.target.value = maxContentOrder
      }
      if (isNaN(e.target.value)) {
        return
      }
    }

    setContentInfo({
      ...contentInfo,
      [e.target.name]: e.target.value,
    })
  }

  async function fileAddCallback(file) {
    switch (contentInfo.content_type) {
      case 'pdf':
        if (file.size > 104857600) {
          setFileError('File exceeds 100 MB')
          setFileLoading(false)
        } else {
          setFileLoading(true)
          setFileError('')
          try {
            const { data: fileForSubmit } = await Api.uploadFile(file)
            setFile(fileForSubmit)
          } catch (error) {
            setButtonDisabled(true)
            Notification.clearNotification()
            console.log(error, 'err')
          } finally {
            setFileLoading(false)
          }
        }
        break
      case 'video':
        if (file.size > 786432000) {
          setFileError('File exceeds 750 MB')
          setFileLoading(false)
        } else {
          setFileError('')
          setFileLoading(true)
          try {
            const result = await checkVideoCompatibility(file);

            if (result.valid) {
              const { data: fileForSubmit } = await Api.uploadFile(file)
              setFile(fileForSubmit)
            } else {
              setFileError(result.reason)
            }
          } catch (error) {
            setButtonDisabled(true)
            Notification.clearNotification()
            console.log(error, 'err')
          } finally {
            setFileLoading(false)
          }
        }
        break
      case 'ppt':
        if (file.size > 26214400) {
          setFileError('File exceeds 25 MB')
          setFileLoading(false)
        } else {
          setFileLoading(true)
          setFileError('')
          try {
            const { data: fileForSubmit } = await Api.uploadFile(file)
            setFile(fileForSubmit)
          } catch (error) {
            setButtonDisabled(true)
            Notification.clearNotification()
            console.log(error, 'err')
          } finally {
            setFileLoading(false)
          }
        }
        break
    }
  }

  function fileRemoveCallback() {
    setButtonDisabled(false)
    setFile(null)
  }

  const SELECT_OPTIONS = [
    {
      svg: <VideoIcon color={variableColor} />,
      type: 'video',
      label: 'Video',
    },
    {
      svg: <PdfTrainingsIcon color={variableColor} />,
      type: 'pdf',
      label: 'PDF',
    },
    {
      svg: <PptIcon color={variableColor} />,
      type: 'ppt',
      label: 'Powerpoint',
    },
    {
      svg: <HandsOnModalIcon color={variableColor} />,
      type: 'ho',
      label: 'Hands On',
    },
    {
      svg: <QuizIcon color={variableColor} />,
      type: 'quiz',
      label: 'Quiz',
    },
  ]

  useEffect(() => {
    switch (contentInfo.content_type) {
      case 'pdf':
        setAcceptedTypes('application/pdf')
        break
      case 'ppt':
        setAcceptedTypes(
          'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
        )
        break
      case 'video':
        setAcceptedTypes('video/*')
        break
    }
  }, [contentInfo.content_type])

  const handleAddContent = async () => {
    setButtonDisabled(true)
    if (!contentEditData) {
      try {
        await Api.post('training_content', {
          attachment: file ? file?.id : null,
          attachment_info: file
            ? { client: CurrentUser.getClientId(), file: file }
            : { client: CurrentUser.getClientId() },
          completion_code: contentInfo.completion_code
            ? contentInfo.completion_code
            : null,
          content_type: contentInfo?.content_type,
          description: contentInfo.description ? contentInfo.description : null,
          name: contentInfo?.content_name,
          order: contentInfo?.order,
          quiz_name: null,
          topic: topicId,
        })
        setRefresh(true)
        handleShowModal()
      } catch (error) {
        console.log('error')
      }
    } else {
      try {
        await Api.patch(`training_content/${contentInfo?.content_id}`, {
          attachment: file ? file?.id : null,
          attachment_info: file
            ? { client: CurrentUser.getClientId(), file: file }
            : { client: CurrentUser.getClientId() },
          completion_code: contentInfo.completion_code
            ? contentInfo.completion_code
            : null,
          content_type: contentInfo?.content_type,
          description: contentInfo.description ? contentInfo.description : null,
          name: contentInfo?.content_name,
          order: contentInfo?.order,
          quiz_name: null,
          topic: topicId,
        })
        setRefresh(true)
        handleShowModal()
      } catch (error) {
        console.log('error')
      }
    }
  }

  const handleAddQuiz = () => {
    if (isQuizInTheTopic) {
      Notification.warning('Sorry, you can add only one quiz for one topic.')
    } else {
      stateService.go('trainings.quiz.create', {
        topicId: topicId,
        trainingID: trainingId,
        quizName: contentInfo?.content_name,
        topicOrder: contentInfo?.order,
      })
    }
  }

  const selectError = contentInfo.content_type === 'placeholder'

  const isButtonDisabled =
    contentInfo.content_type !== 'ho'
      ? !contentInfo?.content_name ||
      isLoading ||
      selectError ||
      !file ||
      fileError ||
      !contentInfo?.order ||
      buttonDisabled
      : !contentInfo?.content_name ||
      !contentInfo?.description ||
      !contentInfo?.completion_code ||
      !contentInfo?.order ||
      buttonDisabled

  const handleOutsideClick = (e) => {
    console.log(e.target.classList)

    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !e.target.classList.contains('MuiMenuItem-root') &&
      !e.target.classList.contains('MuiSelect-root') &&
      !e.target.classList.contains('MuiList-root') &&
      !e.target.classList.contains('css-vk2efy') &&
      !e.target.classList.contains('MuiBackdrop-root') &&
      !e.target.classList.contains('select__item') &&
      !e.target.classList[0].includes('AddEditContentModal') &&
      !e.target.classList[0].includes('Icon')
    ) {
      handleShowModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div
      css={baseStyle}
      onMouseOver={(e) => {
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      ref={modalRef}
    >
      <img
        className="modal__close"
        src={closeIcon}
        alt="close"
        onClick={handleShowModal}
      />
      <div className="single__input center">
        <label className="input__name">Content Name: *</label>
        <AutoExpandTextArea
          name="content_name"
          placeholder="Enter Name"
          handleChange={handleContentChange}
          className="long__input"
          value={contentInfo.content_name}
        />
      </div>
      <div className="inputs__row">
        <div className="single__input">
          <label className="input__name">Select Content Type: *</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="content_type"
            value={contentInfo.content_type}
            label="Duration"
            onChange={(e) => {
              handleContentChange(e)
              setFile(null)
            }}
            MenuProps={{
              sx: {
                '&& .Mui-selected': {
                  backgroundColor: variableColor,
                  color: '#fff',
                  '& p': {
                    color: '#fff',
                  },
                  svg: {
                    fill: '#fff',
                  },
                  path: {
                    fill: '#fff !important',
                  },
                },
              },
            }}
            sx={{
              width: '195px',
              height: '30px',
              borderRadius: '0px',
              fontSize: '12px',
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
              {
                border: selectError ? '1px solid #C80404' : '1px solid #CCC',
              },
              '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                paddingLeft: '10px',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: selectError ? '1px solid #C80404' : '1px solid #CCC',
              },
              fieldset: {
                border: selectError ? '1px solid #C80404' : '1px solid #CCC',
              },
              '&. legend': {
                width: '0.5%',
              },
            }}
          >
            <MenuItem
              value="placeholder"
              disabled
              sx={{
                color: '#B3B3B3',
                fontSize: '12px',
                borderTop: '1px solid #E5E6E7',
                borderBottom: '1px solid #E5E6E7',
              }}
            >
              <p
                className="select__placeholder"
                css={{
                  margin: 0,
                }}
              >
                Select...
              </p>
            </MenuItem>
            {SELECT_OPTIONS.map((option) => (
              <MenuItem
                value={option.type}
                key={option.type}
                sx={{
                  fontWeight: '600',
                  color: '#676A6C',
                  fontSize: '12px',
                  borderBottom: '1px solid #E5E6E7',
                }}
              >
                <div
                  className="select__item"
                  css={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  {option.svg}
                  <p css={{ margin: 0, color: 'black' }}>{option.label}</p>
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="single__input center">
          <label className="input__name">Order: *</label>
          <input
            className={classNames('small__input', {
              error: !contentInfo.order,
            })}
            placeholder="Order"
            name="order"
            value={contentInfo.order}
            onChange={handleContentChange}
          />
        </div>
      </div>
      {contentInfo.content_type === 'ho' && (
        <div className="handson__inputs">
          <div className="single__input">
            <label className="input__name">Description: *</label>
            <AutoExpandTextArea
              className="long__input"
              placeholder="Enter Description"
              name="description"
              value={contentInfo.description}
              handleChange={handleContentChange}
            />
          </div>
          <div className="single__input">
            <label className="input__name">Completion Code: *</label>
            <AutoExpandTextArea
              className="long__input"
              placeholder="Enter Completion Code"
              name="completion_code"
              value={contentInfo.completion_code}
              handleChange={handleContentChange}
            />
          </div>
        </div>
      )}
      {contentInfo?.content_type !== 'placeholder' &&
        contentInfo?.content_type !== 'quiz' &&
        contentInfo.content_type !== 'ho' && (
          <ContentDropZone
            fileSetStorage={setFile}
            type={contentInfo.content_type}
            fileStorage={file}
            addCallback={fileAddCallback}
            removeCallback={fileRemoveCallback}
            isLoading={isLoading}
            accept={acceptedTypes}
            fileError={fileError}
          />
        )}
      <div className="button__wrapper">
        {contentInfo?.content_type === 'quiz' ? (
          <ButtonElement
            text={contentEditData ? 'Save' : 'Create'}
            disabled={!contentInfo?.content_name}
            functionToTrigger={handleAddQuiz}
          />
        ) : (
          <ButtonElement
            text={contentEditData ? 'Save' : 'Create'}
            disabled={isButtonDisabled}
            functionToTrigger={handleAddContent}
          />
        )}
      </div>
    </div>
  )
}

const baseStyle = css({
  background: '#fff',
  paddingInline: '54px',
  paddingTop: '33px',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.25)',
  gap: '15px',
  top: 0,
  left: '190px',
  zIndex: 1,
  '@media(max-width: 1024px)': {
    left: '100px',
  },
  '.single__input': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  '.input__name': {
    fontSize: '12px',
    fontWeight: 600,
    color: '#575757',
    margin: 0,
  },
  '.long__input': {
    border: '1px solid #C6C6C6',
    height: '30px',
    width: '301px',
    outline: 'none',
    color: '#000',
    padding: '5px',
    '&::placeholder': {
      color: '#B3B3B3',
      fontSize: '12px',
    },
  },
  '.center': {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
  '.small__input': {
    border: '1px solid #C6C6C6',
    height: '30px',
    padding: '5px',
    color: '#000',
    textAlign: 'center',
    width: '94px',
    outline: 'none',
    '&::placeholder': {
      color: '#B3B3B3',
      fontSize: '12px',
    },
  },
  '.error': {
    borderColor: '#C80404',
  },
  '.inputs__row': {
    display: 'flex',
    gap: '10px',
  },
  '.button__wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  '.value__text': {
    margin: 0,
    color: 'black',
  },
  '.handson__inputs': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  legend: {
    width: '0.01px',
  },
  '.modal__close': {
    position: 'absolute',
    width: '12px',
    height: '12px',
    right: '10px',
    top: '8px',
    cursor: 'pointer',
  },
})
