import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import React, { useState } from 'react'
import paperClip from '@/assets/icons/paper-clip.svg'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { useAngularServices } from '@/react/components'
import Highlighter from 'react-highlight-words'

export const DeletedRow = ({
  documentation,
  handleChecked,
  selectedItems,
  search,
  index,
  page,
  isAllChecked,
}) => {
  const selectedIds = selectedItems.map((el) => el.id)
  const isChecked = selectedIds.includes(documentation.id)
  const { CurrentUser } = useAngularServices()
  const [hoveredField, setHoveredField] = useState(null)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { training } = documentation
  return (
    <>
      <tr
        className={classNames('documentation__row', {
          dark: index % 2 === 0,
        })}
        key={documentation.id}
      >
        <td className="checkbox__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked || isChecked}
              onChange={() => handleChecked(documentation)}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        {documentation?.editable ? (
          <td>
            <p className="cell trainee">
              <Highlighter
                highlightClassName={'highlighted__item'}
                textToHighlight={
                  documentation?.user?.first_name
                    ? `${documentation?.user?.first_name} ${documentation?.user?.last_name}`
                    : training.name
                }
                onMouseOver={() => {
                  setHoveredField({
                    field__name: 'trainee__fullname',
                    text: documentation?.user?.first_name
                      ? `${documentation?.user?.first_name} ${documentation?.user?.last_name}`
                      : training.name,
                    max__length: 19,
                  })
                }}
                onMouseOut={() => {
                  setHoveredField(null)
                }}
                searchWords={[search]}
                autoEscape={true}
              />
            </p>
          </td>
        ) : (
          <td>
            <p className="cell trainee">
              <Highlighter
                highlightClassName={'highlighted__item'}
                textToHighlight={
                  documentation?.user?.first_name
                    ? `${documentation?.user?.first_name} ${documentation?.user?.last_name}`
                    : training.name
                }
                searchWords={[search]}
                autoEscape={true}
              />
            </p>
          </td>
        )}
        <td>
          <p
            className="cell id"
            onMouseOver={() => {
              setHoveredField({
                field__name: 'id__fullname',
                text: training.employee_id,
                max__length: 6,
              })
            }}
            onMouseOut={() => {
              setHoveredField(null)
            }}
          >
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={training.employee_id}
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
        <td
          className="topic__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'topic__fullname',
              text: training.training_topic,
              max__length: 33,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="cell topic">
            {training.attachment && <img src={paperClip} alt="" />}
            <p className="topic__text">
              <Highlighter
                highlightClassName={'highlighted__item'}
                textToHighlight={training.training_topic}
                searchWords={[search]}
                autoEscape={true}
              />
            </p>
          </p>
        </td>
        <td>
          <p className="cell date">
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={training.date_trained}
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
        <td>
          <p className="cell date">
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={training.date_training_expires || ''}
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'trained__fullname',
              text: training.trained_by,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="cell trained">
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={training.trained_by}
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: documentation?.user?.company_name || training.company,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="cell company">
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={
                documentation?.user?.company_name || training.company
              }
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'trade__fullname',
              text: training.trade,
              max__length: 15,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="cell trade">
            <Highlighter
              highlightClassName={'highlighted__item'}
              textToHighlight={training.trade}
              searchWords={[search]}
              autoEscape={true}
            />
          </p>
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
