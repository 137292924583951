import {
  ButtonElement,
  DateInputField,
  useAngularServices,
} from '@/react/components'
import { FileUploadTrainings } from '../commonComponents/FileUploadTrainings'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import moment from 'moment'
import { TextInputStyle } from '../../components'
import { CurrentUserType } from '@/react/types/documentation'

export const CreateEditBase = ({ isEdit = false, handleSubmit }) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const trainingId = stateService.params.trainingID
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [attachmentId, setAttachmentId] = useState()
  const [trainingData, setTrainingData] = useState({})
  const [dateTrained, setDateTrained] = useState('')
  const [dateExpires, setDateExpires] = useState('')
  const [safeToLoadEdit, setSafeToLoadEdit] = useState<boolean>(false)
  const [attachmentFromServer, setAttachmentFromServer] = useState({
    name: '',
    file: '',
  })
  const [dataToAdd, setDataToAdd] = useState({
    name: '',
    company: '',
    training_topic: '',
    employee_id: '',
    trade: '',
    date_trained: '',
    date_training_expires: '',
    trained_by: '',
    attachment: attachmentId,
  })
  const [dateTrainedError, setDateTrainedError] = useState(false)
  const baseStyle = getBaseStyle(dateTrainedError)

  useEffect(() => {
    setDateTrainedError(!dateTrained)
  }, [])

  useEffect(() => {
    const getTrainingData = async () => {
      const { data: trainingDataResponse } = await Api.getWithoutDeletedParamV2(
        `trainings/documentation/${trainingId}`,
        {},
      )
      setTrainingData(trainingDataResponse)
      setDataToAdd(trainingDataResponse)
      setDateTrained(trainingDataResponse.date_trained)
      setDateTrainedError(false)
      setDateExpires(trainingDataResponse.date_training_expires)

      if (trainingDataResponse.attachment) {
        setAttachmentFromServer({
          ...trainingDataResponse.attachment,
          name: trainingDataResponse.attachment.file.split(/[/]+/).pop(),
        })
        setAttachmentId(trainingDataResponse.attachment.id)
      }
      setSafeToLoadEdit(true)
    }
    if (isEdit) {
      getTrainingData()
    }
  }, [])

  const onDataFieldChange =
    (fieldName: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      setDataToAdd({ ...dataToAdd, [fieldName]: evt.target.value })
    }

  const handleDateTrained = (evt: moment.Moment | null) => {
    if (evt === null) {
      setDateTrainedError(true)
      setDateTrained('')
    } else {
      setDateTrained(evt.format('MM/DD/YYYY'))
      setDateTrainedError(!evt)
    }
  }

  const handleDataExpires = (evt: moment.Moment) => {
    setDateExpires(evt.format('MM/DD/YYYY'))
  }

  const { name, company, training_topic, trained_by, employee_id, trade } =
    dataToAdd

  const submitButtonFunction = () => {
    handleSubmit(dataToAdd, dateTrained, dateExpires, attachmentId)
  }

  const saveButtonDisabledCondition =
    !name ||
    !company ||
    !training_topic ||
    !dateTrained ||
    !trained_by ||
    isButtonDisabled

  if (isEdit && !safeToLoadEdit) return null

  return (
    <div css={baseStyle}>
      <div className="input__wrapper">
        <div className="input__line">
          <div className="single__input">
            <label>Name*</label>
            <input
              className={classNames('big__input', { error: !name })}
              onChange={onDataFieldChange('name')}
              value={name}
            />
          </div>
          <div className="inputs__row">
            <div className="single__input">
              <label>Employee ID</label>
              <input
                className="small__input"
                onChange={onDataFieldChange('employee_id')}
                value={employee_id}
              />
            </div>
            <div className="single__input">
              <label>Trade</label>
              <input
                className="small__input"
                onChange={onDataFieldChange('trade')}
                value={trade}
              />
            </div>
          </div>
        </div>
        <div className="input__line">
          <div className="single__input">
            <label>Company*</label>
            <input
              className={classNames('big__input', { error: !company })}
              onChange={onDataFieldChange('company')}
              value={company}
            />
          </div>
          <div className="inputs__row">
            <div className="single__input">
              <label>Date Trained*</label>
              <div
                className={classNames('date__input', {
                  error: dateTrainedError,
                })}
              >
                <DateInputField
                  ohChange={handleDateTrained}
                  defDate={moment(dateTrained)}
                  width={'164px'}
                />
              </div>
            </div>
            <div className="single__input">
              <label>Date Expiring</label>
              <div className="date__input">
                <DateInputField
                  ohChange={handleDataExpires}
                  defDate={moment(dateExpires)}
                  width={'164px'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input__line">
          <div className="single__input">
            <label>Training Topic*</label>
            <input
              className={classNames('big__input', { error: !training_topic })}
              onChange={onDataFieldChange('training_topic')}
              value={training_topic}
            />
          </div>
          <div className="single__input">
            <label>Trained By *</label>
            <input
              className={classNames('big__input', { error: !trained_by })}
              onChange={onDataFieldChange('trained_by')}
              value={trained_by}
            />
          </div>
        </div>
        <div className="input__line last">
          <div className="file-upload__block">
            <FileUploadTrainings
              setAttchmentId={setAttachmentId}
              attachmentFromServer={attachmentFromServer}
            />
            {isEdit && (
              <ButtonElement
                text={'Cancel'}
                functionToTrigger={() => {
                  stateService.go('trainings.documentation', {
                    search: isEdit ? stateService.params.previousSearch : '',
                    pageNumber: isEdit
                      ? stateService.params.previousPageNumber
                      : '',
                  })
                }}
              />
            )}
          </div>
          <div className="right__button">
            <ButtonElement
              text={'Save'}
              buttonType={'submit'}
              functionToTrigger={() => {
                submitButtonFunction()
                setButtonDisabled(true)
              }}
              disabled={saveButtonDisabledCondition}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function getBaseStyle(dateTrainedError: boolean) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    '@media(max-width: 990px)': {
      '.input__wrapper': {
        width: '367px !important',
      },
      '.inputs__row': {
        flexDirection: 'column',
      },
      '.input__line': {
        flexDirection: 'column',
      },
      '.second_inputs_row': {
        marginLeft: '13px',
      },
      '.small__input': {
        width: '341px !important',
      },
      '.date__input': {
        width: '341px !important',
      },
      '.icon': {
        paddingRight: '22px !important',
        paddingLeft: '10px !important',
      },
      '.right__button': {
        marginRight: '13px !important',
      },
    },
    'input[type="tel"]': {
      width: '128px',
      ...TextInputStyle,
      border: false ? '0.5px solid #C80404' : '',
    },
    '.date__input': {
      outline: 'none',
    },
    '.input__line': {
      display: 'flex',
      gap: '25px',
      marginLeft: '13px',
    },
    '.last': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '18px',
      marginBottom: '28px',
      flexDirection: 'row',
    },
    '.required': {
      border: dateTrainedError ? '1px solid #C80404' : '1px solid #e5e6e7',
    },
    '.input__wrapper': {
      display: 'flex',
      gap: '10px',
      paddingTop: '18px',
      background: 'white',
      width: '746px',
      flexDirection: 'column',
    },
    '.big__input': {
      ...TextInputStyle,
      width: '341px',
    },
    '.small__input': {
      ...TextInputStyle,
      width: '164px',
    },
    '.error': {
      border: '1px solid #C80404',
    },
    '.first_inputs_row': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginLeft: '14px',
    },
    '.second_inputs_row': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    '.single__input': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.inputs__row': {
      display: 'flex',
      gap: '13px',
    },
    '.inputs-holder': {
      position: 'relative',
      display: ' inline-block',
    },
    '.icon': {
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      pointerEvents: 'none',
      borderLeft: '1px solid #e5e6e7',
      marginTop: '1px',
      marginBottom: '1px',

      svg: {
        background: 'white',
      },
    },
    '.date-picker-holder': {
      height: 0,
      width: 0,
      position: 'relative',
    },
    '.background': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      right: 0,
      top: 0,
      zIndex: 500,
    },
    '.file-upload__block': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '.right__button': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '25px',
      alignSelf: 'flex-end',
    },
  })
}
