import {
  ButtonElement,
  CustomCheckbox,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { singleHeaderTypes } from '@screens/components'
import closeIcon from '@/assets/icons/close-icon.svg'
import * as React from 'react'
import { Dispatch, SetStateAction, useState } from 'react'
import { css } from '@emotion/react'
import classNames from 'classnames'
import { cloneDeep, difference, remove } from 'lodash'
import { INIT_ROLES } from './constants'

type Props = {
  setPopUp: Dispatch<SetStateAction<boolean>>
  onChange: Dispatch<SetStateAction<any[]>>
  startingValues: string[]
  isProjects?: boolean
  projectsVariable?: string
}

type ProjectMin = {
  name: string
  value: string | number
  id: string | number
}

type GetReq = {
  data: {
    count: number
    results: ProjectMin[]
  }
}

type TableType = { label: string; value: string | number; selected: boolean }

export const RoleAndProjectPicker = ({
  setPopUp,
  onChange,
  startingValues,
  isProjects = false,
  projectsVariable = 'Projects',
}: Props) => {
  const { Api, CurrentUser } = useAngularServices()

  const [search, setSearch] = useState<string>('')
  const [order, setOrder] = useState<string>('name')
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(isProjects ? 0 : INIT_ROLES.length)
  const [tableOptions, setTableOptions] = useState<TableType[]>([])
  const [allProjectsSearched, setAllProjectsSearched] = useState<
    Array<string | number>
  >([])
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedValues, setSelectedValues] = useState<Array<string | number>>([
    ...startingValues,
  ])

  const setTableOptionsSelection = React.useCallback((selection: boolean) => {
    setTableOptions((prevState: TableType[]) => {
      const newState = [...prevState]
      newState.forEach((row) => {
        row.selected = selection
      })
      return newState
    })
  }, [])

  const setRowOptionSelection = React.useCallback(
    (selection: boolean, idx: number) => {
      setTableOptions((prevState: TableType[]) => {
        const newState = [...prevState]
        newState[idx] = { ...newState[idx], selected: selection }
        return newState
      })
    },
    [],
  )

  const allCheckedStatus = (
    checkWithAll = false,
    selectedValues: Array<string | number>,
    allProjectsSearched: Array<string | number>,
  ): void => {
    const status = checkWithAll
      ? difference(allProjectsSearched, selectedValues).length === 0
      : count === selectedValues.length
    setAllChecked(status)
  }

  const fetchProjectIds = async (
    clientId: string | number,
    order: string,
    count: number,
    searchCheck: boolean = false,
  ): Promise<Array<string | number>> => {
    const oldSelected: Array<string | number> = [...selectedValues]
    const allProjectsIds: Array<string | number> = []
    for (let i = 0; i < count / 400; i++) {
      const { data: pageGet }: GetReq = await Api.get('projects', {
        assigned: false,
        client: clientId,
        search,
        deleted: false,
        order,
        is_active: 'True',
        page_size: 400,
        page: i + 1,
      })
      pageGet.results.forEach((project) => {
        allProjectsIds.push(project.id)
        if (!searchCheck) {
          if (allChecked) {
            remove(oldSelected, (n) => n === project.id)
          } else if (!oldSelected.includes(project.id)) {
            oldSelected.push(project.id)
          }
        }
      })
    }
    allCheckedStatus(true, oldSelected, allProjectsIds)
    setAllProjectsSearched(allProjectsIds)
    return oldSelected
  }

  const handleAllChecked = async (order: string) => {
    setLoading(true)
    const clientId: string | number = CurrentUser.getClientId()

    let oldSelected: Array<string | number> = []
    if (isProjects) {
      if (!allChecked || search) {
        oldSelected = await fetchProjectIds(clientId, order, count)
        setTableOptionsSelection(!allChecked)
      } else {
        oldSelected = []
        setTableOptionsSelection(false)
      }
    } else {
      if (allChecked) {
        oldSelected = []
        setTableOptionsSelection(false)
      } else {
        tableOptions.forEach((row) => {
          oldSelected.push(row.value)
        })
        setTableOptionsSelection(true)
      }
    }

    setAllChecked(!allChecked)
    setSelectedValues(oldSelected)
    setLoading(false)
  }

  const getProjectOptions = async (order: string): Promise<TableType[]> => {
    const clientId: string | number = CurrentUser.getClientId()
    const { data: projectsFromServer }: GetReq = await Api.get('projects', {
      assigned: false,
      client: clientId,
      search,
      deleted: false,
      order,
      is_active: 'True',
      page_size: 20,
      page,
    })
    setCount(projectsFromServer.count)
    if (projectsFromServer.count === selectedValues.length && !search)
      setAllChecked(true)
    if (search) {
      await fetchProjectIds(clientId, order, projectsFromServer.count, true)
    }
    return projectsFromServer.results.map((row): TableType => {
      return { label: row.name, value: row.id, selected: false }
    })
  }

  const onCheckboxChange = (row: TableType, index: number) => {
    const newSelection = !row.selected
    if (newSelection) {
      selectedValues.push(row.value)
      allCheckedStatus(
        search === '' ? false : isProjects,
        selectedValues,
        allProjectsSearched,
      )
    } else {
      remove(selectedValues, function (n) {
        return n === row.value
      })
      setAllChecked(false)
    }
    setSelectedValues(selectedValues)
    setRowOptionSelection(newSelection, index)
  }

  React.useEffect(() => {
    const dataProcessing = async (): Promise<void> => {
      setLoading(true)
      const possibleTable: TableType[] = isProjects
        ? await getProjectOptions(
            ['name', '-name'].includes(order) ? order : 'name',
          )
        : cloneDeep(INIT_ROLES)
      possibleTable.forEach((tableRow) => {
        if (selectedValues.includes(tableRow.value)) tableRow.selected = true
      })
      if ('-checked' === order) {
        possibleTable.sort((a, b) => Number(a.selected) - Number(b.selected))
      } else if ('checked' === order) {
        possibleTable.sort((a, b) => Number(b.selected) - Number(a.selected))
      }
      if (!isProjects) setAllChecked(count === selectedValues.length)
      setTableOptions(possibleTable)
      setLoading(false)
    }

    dataProcessing()
  }, [search, order, page])

  return (
    <section css={baseStyle}>
      <div
        className="background"
        onClick={() => {
          setPopUp(false)
        }}
      />
      <div className="alert-holder">
        <div
          className="single-alert-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 10,
              cursor: 'pointer',
            })}
            onClick={() => {
              setPopUp(false)
            }}
          />
        </div>
        <div className="popup-header">
          Assign to {isProjects ? projectsVariable : 'Roles'}
        </div>
        <div className="content__wrapper">
          {isProjects && (
            <div className="search__block">
              <Search
                search={search}
                searchCallback={setSearch}
                pageCallback={setPage}
                updateUrl={false}
                width={264}
              />
            </div>
          )}
          <div className="table__wrapper">
            <table>
              <TableHeaders
                headers={makeTableSettings(
                  <div css={css({ width: 20 })}>
                    <CustomCheckbox
                      reversedColor={true}
                      isDefaultChecked={allChecked}
                      onChange={handleAllChecked}
                    />
                  </div>,
                )}
                popUpLocation={false}
                updateUrl={false}
                order={order}
                callback={setOrder}
                setOrder={setOrder}
              />
              <tbody>
                {tableOptions.map((row, index) => {
                  return (
                    <tr
                      className={classNames('view-row', {
                        dark: index % 2 === 0,
                      })}
                    >
                      <td className="checkmark-holder-td">
                        <div className="checkmark-holder">
                          <CustomCheckbox
                            reversedColor={true}
                            isDefaultChecked={row.selected}
                            onChange={() => {
                              onCheckboxChange(row, index)
                            }}
                          />
                        </div>
                      </td>
                      <td>{row.label}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="page-switcher-base">
            <PageSwitcher
              pageLength={20}
              listCount={count}
              currentPage={page}
              callback={setPage}
              updateUrl={false}
            />
          </div>
          {loading && (
            <div
              css={css({
                width: 'calc(100% + 20px)',
                backgroundColor: 'rgba(0,0,0,0.02)',
                height: count < 20 ? 225 : 285,
                marginTop: count < 20 ? -235 : -295,
                marginBottom: -10,
                marginLeft: -10,
                marginRight: -10,
                zIndex: 2000,
                position: 'relative',
              })}
            ></div>
          )}
        </div>
        <div className="button-row">
          <ButtonElement
            buttonType={'submit'}
            disabled={loading}
            text={'OK'}
            functionToTrigger={() => {
              onChange(selectedValues)
              setPopUp(false)
            }}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  tbody: {
    display: 'block',
    height: 190,
    overflow: 'auto',
  },
  'thead, tbody tr': {
    display: 'table',
    width: '100%',
    'table-layout': 'fixed' /* even columns width , fix width of table too*/,
  },
  thead: {
    width:
      'calc( 100% - 1em )' /* scrollbar is average 1em/16px width, remove it from thead width */,
  },
  '.checkmark-holder-td': {
    width: '11.6%',
  },
  '.background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 500,
  },
  '.alert-holder': {
    top: '30%',
    position: 'fixed',
    boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
    padding: 20,
    width: 510,
    zIndex: 1000,
    '.search__block': {
      position: 'absolute',
      width: 264,
      right: 20,
      top: 91,
    },
    '.popup-header': {
      width: '100%',
      textAlign: 'center',
      fontSize: '14px',
      marginBottom: 25,
    },
    '.error-text': {
      marginTop: 10,
      color: 'red',
    },
    backgroundColor: 'white',
    '.header-row': {
      textAlign: 'center',
      marginBottom: 15,
    },
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
      marginBottom: 10,
    },
    '.second-dropdown': {
      marginTop: 20,
    },
    '.button-row': {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    table: {
      width: '100%',
    },
    '.view-row': {
      color: '#575757',
      '.checkmark-holder': { width: 40 },
      td: {
        padding: 5,
        height: 31,
      },
    },
  },
  '.dark': {
    background: '#F2F3F5',
    borderBottom: '1px solid #E7EAEC',
    borderTop: '1px solid #E7EAEC',
  },
})

const makeTableSettings = (checkbox: React.ReactNode) => [
  {
    className: 'checked-header',
    name: '',
    type: 'checked',
    filterHeader: true,
    alternativePosition: true,
    additionalStyles: {
      textAlign: 'left',
      width: '12%',
    },
    additionalInnerElement: checkbox,
  },
  {
    className: 'name',
    name: 'Name',
    type: 'name',
    additionalStyles: {
      width: '88%',
      textAlign: 'left',
    },
  },
]
