import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import viewIcon from '@/assets/icons/view-icon.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import uploadIcon from '@/assets/icons/upload-icon.svg'
import { useRouter, useSref } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import { useAngularServices } from '@/react/components'
import { navigationStyles } from '@/react/components/commonStyles'

// @ts-ignore
export const QuestionNavBar = ({}) => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const [isTabsVisable, setTabsVisable] = useState(false)

  useEffect(() => {
    setTabsVisable(
      CurrentUser.getRole() !== 'trainee' &&
        CurrentUser.getRole() !== 'client_user' &&
        CurrentUser.getRole() !== 'supervisor' &&
        CurrentUser.getRole() !== 'read_only',
    )
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={navigationStyles}>
        <UISref to={'app.questions.list'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.questions.my' ||
                stateService.current.name === 'app.questions.edit' ||
                stateService.current.name === 'app.questions.custom_categories',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>

        <UISref to={'app.questions.add'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'app.questions.add',
            })}
          >
            <img className="nav__icon" src={addIcon} alt="add nav__icon" />
            <p>Add</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}
