import * as React from 'react'
import { css } from '@emotion/react'
import { parseDate } from '@/utils/parseDate'
import { fullUsername } from '@/utils/fullUsername'

import { ImageSection, deletedFieldsFiltering } from '../../../components'

import { ApplicationConfig, ReportField, ReportTheme } from '@/react/types'
import { useRouter } from '@/react/hooks'
import parse from 'html-react-parser'

const fullAnswers = {
  yes: <span className="yes"> Yes </span>,
  no: <span className="no"> Follow Up Required </span>,
  pr: <span className="pr"> Pending Review </span>,
  pa: <span className="pa"> Pending Approval </span>,
  cls: <span className="cls"> Issue Resolved </span>,
}

type Props = {
  questionFullData: ReportField
  app: ApplicationConfig
  theme: ReportTheme
}

export function QuestionField({
  questionFullData,
  app,
  theme,
  viewNaQuestions,
}: Props) {
  const { stateService } = useRouter()

  const {
    question,
    answer: answerBeforeChanges,
    answeredFieldOrder,
    order,
  } = questionFullData
  const [answer, setAnswer] = React.useState({})

  React.useEffect(() => {
    deletedFieldsFiltering(answerBeforeChanges, setAnswer)
  }, [])

  if (!answer.answer) return null
  const questionHeader = (
    <span className="question-header danger-html">
      Q{viewNaQuestions ? order : answeredFieldOrder}: {question?.project_name}{' '}
      (<span class="variable-color-text">{question.category.name || ''}</span>)
      - {parse(question.name)}
    </span>
  )

  const severityDisplay = {
    1: app.observation_low_severity,
    2: app.observation_medium_severity,
    3: app.observation_high_severity,
  }

  const severityLevelElements = {
    1: (
      <span className="severity-low">
        {severityDisplay[answer.severity || 1]}
      </span>
    ),
    2: (
      <span className="severity-med">
        {severityDisplay[answer.severity || 1]}
      </span>
    ),
    3: (
      <span className="severity-high">
        {severityDisplay[answer.severity || 1]}
      </span>
    ),
  }

  if (answer.answer === 'n/a')
    return <div css={questionStyle}>{questionHeader} </div>

  return (
    <div css={questionStyle}>
      {questionHeader}
      <div className="sub-field">
        <span className="answer-field">
          <label>A: </label>
          {fullAnswers[answer.answer]}
        </span>
        {answer.answer !== 'n/a' && answer.answer !== 'yes' ? (
          <span className="sub-field">
            <label> Severity:</label>
            {severityLevelElements[answer.severity || 1]}
          </span>
        ) : null}
      </div>
      {answer.notes.length > 0 &&
        answer.notes.some((note) => note.is_visible) ? (
        <div className="sub-field sub-field-with-multiples">
          <label>{app.observation_note_header}:</label>
          <div className="multiple-answer-body">
            {Object.values(answer.notes)
              .filter((note) => note.is_visible)
              .map((note, idx) => (
                <div key={idx} className="single-answer">
                  <span className="date">
                    {' ' +
                      parseDate(note.date_created) +
                      ' - ' +
                      fullUsername(note.user ? note.user : note.external_contact) +
                      ' - '}
                  </span>
                  <span>{note.text}</span>
                </div>
              ))}
          </div>
        </div>
      ) : null}


      {answer.corrective_actions.length > 0 &&
        answer.corrective_actions.some((action) => action.is_visible) ? (
        <div className="sub-field sub-field-with-multiples corrective-actions-multiple">
          <div className="multiple-answer-body">
            {Object.values(answer.corrective_actions)
              .filter((action) => action.is_visible)
              .map((correctiveAction, idx) => (
                <div key={idx} className="single-answer">
                  <label>{app.observation_corrective_action_header}: </label>

                  <span className="date">
                    {' ' +
                      parseDate(correctiveAction.date_created) +
                      ' - ' +
                      fullUsername(
                        correctiveAction.user
                          ? correctiveAction.user
                          : correctiveAction.external_contact,
                      ) +
                      ' - '}
                  </span>
                  <span className="view__text">{correctiveAction.text}</span>
                </div>
              ),
              )}
          </div>
        </div>
      ) : null}
      {answer?.internal_assignees?.length ||
        answer?.external_assignees?.length ? (
        <div className="sub-field sub-field-with-multiples">
          <label>Assigned To: </label>
          <div className="multiple-answer-body">
            {answer.external_assignees.map((assignee, idx) => {
              return (
                <div key={idx} className="single-answer">
                  <span key={idx} className="date">
                    {parseDate(assignee.date_created)}{' '}
                    {assignee.external_contact.first_name
                      ? assignee.external_contact.first_name + ' '
                      : null}
                    {assignee.external_contact.last_name
                      ? assignee.external_contact.last_name
                      : null}
                    {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                      ? null
                      : '; '}
                    {assignee.external_contact.company_name}
                    {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                      ? assignee.external_contact.email
                      : null}
                  </span>
                </div>
              )
            })}
            {answer.internal_assignees.map((assignee, idx: number) => {
              return (
                <div key={idx} className="single-answer">
                  <span key={idx} className="date">
                    {parseDate(assignee.date_created)}{' '}
                    {fullUsername(assignee.full_assignee)}
                    {'; '}
                    {assignee.full_assignee.company_name}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
      <div className="sub-field-row">
        {answer.answer_party_observed_answer_list.length ? (
          <div className="sub-field party-observed-multiple sub-field-with-multiples">
            {stateService.params.key ? (
              <label>
                {app.observation_party_observed}
                {': '}
              </label>
            ) : null}
            <div className="multiple-answer-body">
              {answer.answer_party_observed_answer_list.map((po, idx) => {
                if (!po.deleted) {
                  return (
                    <div key={idx} className="single-answer">
                      {!stateService.params.key && idx === 0 ? (
                        <label>
                          {app.observation_party_observed}
                          {': '}
                        </label>
                      ) : null}
                      <span> {po.party_observed.name}</span>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        ) : null}
        {answer.reference ? (
          <div className="sub-field">
            <label>{app.observation_reference}:</label>
            <span className="date"> {parseDate(answer.date_created)} </span>
            <span>{answer.reference}</span>
          </div>
        ) : null}
      </div>
      {answer.photos.length > 0 &&
        answer.photos.some((photo) => photo.is_visible) ? (
        <ImageSection images={answer.photos.filter((photo) => photo.is_visible)} />
      ) : null}
    </div>
  )
}

const questionStyle = css({
  paddingBottom: 12,
  borderBottom: '1px solid #eeeeef',
  '.question-header': {
    marginTop: '5px',
    color: 'black !important',
    textDecoration: 'underline',
    fontSize: '17px',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
    p: {
      textDecoration: 'underline',
    },
  },
  '.question-name': {
    whiteSpace: 'pre',
  },
  '.sub-field': {
    paddingTop: 5,
    fontSize: 17,
    fontWeight: 500,
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
    span: {
      display: 'contents',
      whiteSpace: 'pre-wrap',
      marginLeft: '4px',
      color: '#707070 !important',
    },
    label: {
      marginRight: '4px',
      fontWeight: 500,
      color: 'black !important',
    },
    '.answer-field': {
      width: '48%',
      marginRight: '4%',
      display: 'inline-block',
    },
    '.date': {
      color: '#4ca990 !important',
    },
    '.severity-low': {
      color: 'green !important',
    },
    '.severity-med': {
      color: 'orange !important',
    },
    '.severity-high': {
      color: 'red !important',
    },
    '.yes': {
      color: 'green !important',
    },
    '.no': { color: 'red !important' },
    '.pr': { color: 'orange !important' },
    '.pa': { color: 'yellow !important' },
    '.cls': { color: 'blue !important' },
  },
  '.sub-field-with-multiples': {
    width: '100%',
    label: {
      verticalAlign: 'top',
      display: 'inline-block',
    },
    '.multiple-answer-body': {
      width: '90%',
      display: 'inline-block',
      '.single-answer': {
        display: 'flex',
        flexDirection: 'column',
        label: {
          color: 'transparent !important',
        },
      },
      '.single-answer:first-child': {
        label: {
          color: 'black !important',
        },
      },
    },
  },
  '.view__text': {
    display: 'contents',
    whiteSpace: 'pre-wrap',
  },
  '.sub-field-row': {
    verticalAlign: 'top',
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
    '.sub-field': {
      verticalAlign: 'top',
      width: '48%',
      display: 'inline-block',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
    },
    '.left-field': {
      marginRight: '4%',
    },
    '@media (max-width: 650px)': {
      '.sub-field': {
        width: '100%',
      },
      '.left-field': {
        marginRight: 0,
      },
    },
  },
  '.variable-color-text': {
    color: 'orange',
  },
})
