import { useRouter } from '@/react/hooks'
import { CreateEditQuestionComponent } from '../CreateEditQuestionPage/CreateEditQuestionComponent'
import { useAngularServices } from '@/react/components'
import { useState } from 'react'

export const ObservationCustomQuestions = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser, Notification } = useAngularServices()

  const [isMaxThresholdModal, setMaxThresholdModal] = useState(false)

  const handleQuestionAdd = async (selectedCategory: any, editorContent: any) => {
    try {
      const { data: question } = await Api.post('questions', {
        category: selectedCategory?.id,
        name: editorContent,
      })

      await Api.post('client_app_questions', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        custom_categories: [selectedCategory?.id],
        is_active: true,
        question: question?.id,
      })

      stateService.go('app.questions.list', {
        categoryId: selectedCategory.id,
        pageNumber: stateService.params.previousPageNumber,
        search: stateService.params.previousSearch,
      })
    } catch (error) {
      Notification.clearNotification()

      if (error.status === 400) {
        console.log(error)

        setMaxThresholdModal(error.data.detail)
      }
    }
  }

  const handleQuestionAnotherAdd = async (selectedCategory: any, editorContent: any) => {
    try {
      const { data: question } = await Api.post('questions', {
        category: selectedCategory?.id,
        name: editorContent,
      })

      await Api.post('client_app_questions', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        custom_categories: [selectedCategory?.id],
        is_active: true,
        question: question?.id,
      })

    } catch (error) {
      Notification.clearNotification()

      if (error.status === 400) {
        console.log(error)
        setMaxThresholdModal(error.data.detail)
      }
    }
  }
  return (
    <CreateEditQuestionComponent
      isMaxThresholdModal={isMaxThresholdModal}
      setMaxThresholdModal={setMaxThresholdModal}
      handleQuestionAdd={handleQuestionAdd}
      handleQuestionAnotherAdd = {handleQuestionAnotherAdd}
    />
  )
}
