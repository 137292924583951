import manageIcon from '@/assets/icons/lms-manage.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import viewIcon from '@/assets/icons/view-icon.svg'
import { UISref } from '@/react/components/UISref'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { css } from '@emotion/react'
import draftIcon from '@/assets/icons/draft-icon.svg'
import { useAngularServices } from '@/react/components'

export const AssessmentNavBar = () => {
  const { stateService } = useRouter()

  const { CurrentUser } = useAngularServices()
  const clientId = CurrentUser.getClientId()

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref
          to={'assessment.assessment_manage'}
          params={{
            clientId: clientId,
          }}
        >
          <a
            className={classNames('tab', {
              active:
                stateService.current.name ===
                  'assessment.assessment_score_key' ||
                stateService.current.name === 'assessment.assessment_manage' ||
                stateService.current.name === 'assessment.assessment_edit',
            })}
          >
            <img className="icon" src={manageIcon} alt="view icon" />
            <p>Manage</p>
          </a>
        </UISref>

        <UISref
          to={'assessment.assessment_create'}
          params={{
            clientId: clientId,
          }}
        >
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'assessment.assessment_create',
            })}
          >
            <img className="icon" src={addIcon} alt="add icon" />
            <p>Create</p>
          </a>
        </UISref>
        <UISref to={'assessment.assessment_completed'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'assessment.assessment_completed',
            })}
          >
            <img className="icon" src={viewIcon} alt="add icon" />
            <p>Completed</p>
          </a>
        </UISref>
        <UISref to={'assessment.assessment_draft'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'assessment.assessment_draft',
            })}
          >
            <img className="icon" src={draftIcon} alt="view nav__icon" />
            <p>Draft</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },

  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.icon': {
      marginLeft: '25px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '25px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
