import React from 'react'
import { css } from '@emotion/react'

import { QuestionField } from './componets'
import { sortByAnswer } from '@/utils/data/SortByAnswer'
import { useAngularServices } from '@/react/components'
import { CurrentUserType } from '@/react/types/documentation'

export function QuestionsList({
  list,
  app,
  theme,
  viewNaQuestions,
  viewNaCategories,
}: any) {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const questionListBaseStyle = getBaseStyle(variableColor)

  const answeredCategoryNames = list
    .filter((item) => item.answer.answer !== 'n/a')
    .map((item) => item.question.category.name)

  const filteredList = viewNaQuestions
    ? list
        .map((item) => {
          if (item.answer.answer === 'n/a') {
            return viewNaCategories
              ? answeredCategoryNames.length > 0
                ? item
                : null
              : answeredCategoryNames.includes(item.question.category.name)
              ? item
              : null
          }
          return item
        })
        .filter(Boolean)
    : list.filter((item) => item.answer.answer !== 'n/a')

  const sortedList = sortByAnswer(filteredList)

  const groupedQuestions = sortedList.reduce((acc, item) => {
    const categoryName = item.question.category.name
    if (!acc[categoryName]) {
      acc[categoryName] = []
    }
    acc[categoryName].push(item)
    return acc
  }, {})

  const sortedCategoryNames = Object.keys(groupedQuestions).sort()

  return (
    <section css={questionListBaseStyle}>
      {sortedCategoryNames.map((categoryName) => (
        <div key={categoryName}>
          <div className="header-base">
            <h2>{categoryName}</h2>
          </div>
          {groupedQuestions[categoryName].map((item: any) => (
            <QuestionField
              key={item?.question?.id}
              questionFullData={item}
              app={app}
              theme={theme}
              viewNaQuestions={viewNaQuestions}
            />
          ))}
        </div>
      ))}
    </section>
  )
}

const getBaseStyle = (variableColor: string) =>
  css({
    width: '100%',
    '.header-base': {
      backgroundColor: `${variableColor} !important`,
      width: '100%',
      borderBottom: '1px solid #eeeeef',
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0px 10px 0px',
      marginBottom: '10px',
      h2: {
        fontSize: '20px',
        fontWeight: 400,
        color: 'white !important',
        letterSpacing: '-0.13px',
        margin: 0,
      },
    },
  })
