import React, { useEffect, useState } from 'react'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'

export function ServiceAccountView() {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const [currentServiceAccount, setCurrentServiceAccount] = useState(null)

  const currentServiceAccountId = stateService.params.id
  const isShowSecret = stateService.params.isShowSecret

  useEffect(() => {
    if (currentServiceAccountId) {
      Api.ServiceAccounts.byID(currentServiceAccountId, (resp) => {
        setCurrentServiceAccount(resp)
      })
    }
  }, [])

  const deleteHandler = () => {
    Api.ServiceAccounts.delete(currentServiceAccount, function (resp) {
      stateService.go('admin.service_accounts', {}, { reload: true })
    })
  }

  const resetHandler = () => {
    Api.ServiceAccounts.patch(
      {
        name: currentServiceAccount?.name,
        id: currentServiceAccount?.id,
        refresh_client_secret: true,
      },
      function (resp) {
        if (resp) {
          stateService.go(
            'admin.service_accounts_view',
            { id: currentServiceAccount?.id, isShowSecret: true },
            { reload: true },
          )
        }
      },
    )
  }

  return (
    <>
      <CommonPageHeader headerText="View Service Account" />
      <section css={baseStyle}>
        <div className="top-wrapper">
          <h3 className="title">{currentServiceAccount?.name}</h3>
          <div className="buttons-row">
            <button
              className="btn-default-general btn-default-secondary"
              data-toggle="modal"
              data-target="#resetSecret"
            >
              Reset Secret
            </button>
            <button
              className="btn-default-general btn-default-secondary delete"
              data-toggle="modal"
              data-target="#deleteServiceAccount"
            >
              Delete
            </button>
            <ButtonElement
              text="Done"
              buttonType="submit"
              functionToTrigger={() => {
                stateService.go('admin.service_accounts', {
                  pageNumber: stateService.params.previousPageNumber,
                  search: stateService.params.previousSearch,
                })
              }}
            />
          </div>
        </div>
        {isShowSecret && (
          <div className="warning">
            <div className="icon">
              <span
                className="glyphicon glyphicon-warning-sign ccs-link"
                aria-hidden="true"
              ></span>
            </div>
            <div className="text-wrapper">
              <h3 className="title">Warning</h3>
              <div className="warning-text">
                This is the only time will be able to view your Client Secret.
                Please save it securely for client authentication.
              </div>
            </div>
          </div>
        )}
        <div className="client-data">
          <div className="client-id">
            <div className="data-label">Client ID</div>
            <div className="bold-text">
              {currentServiceAccount?.client_uuid}
            </div>
          </div>
          {isShowSecret && (
            <div className="client-secret">
              <div className="data-label">Client Secret</div>
              <div className="bold-text">
                {currentServiceAccount?.client_secret}
              </div>
            </div>
          )}
        </div>

        <div
          className="modal fade"
          id="resetSecret"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="modalLabel">
                  Warning!
                </h4>
              </div>
              <div className="modal-body">
                Resetting the client secret for this service account will break
                existing integration. Be sure to update the client secret within
                your third party application.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-default-general btn-default-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-default-general btn-default-primary btn-ok"
                  data-dismiss="modal"
                  onClick={resetHandler}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteServiceAccount"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="modalLabel">
                  Delete?
                </h4>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-default-general btn-default-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-default-general btn-default-primary btn-ok"
                  data-dismiss="modal"
                  onClick={deleteHandler.bind(null, currentServiceAccount?.id)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  marginTop: '15px',
  padding: '15px 15px 25px 15px',
  '.top-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    '.title': {
      fontSize: '16px',
      margin: 0,
      fontWeight: 600,
    },
  },
  '.warning': {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid #337ab7',
    padding: '5px',
    borderRadius: '3px',
    marginBottom: '15px',
    '.title': {
      margin: 0,
    },
    '.icon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: '50px',
      height: '50px',

      span: {
        color: '#fff',
        fontSize: '24px',
      },
    },
  },
  '.client-id': {
    background: '#f9f9f9',
    display: 'flex',
    padding: '7px',
    fontSize: '14px',
    borderTop: '1px solid #e7eaec',
    borderBottom: '1px solid #e7eaec',
  },
  '.client-secret': {
    display: 'flex',
    padding: '7px',
    fontSize: '14px',
    borderBottom: '1px solid #e7eaec',
  },
  '.data-label': {
    width: '20%',
    fontWeight: 500,
  },
  '.modal-dialog': {
    height: '90%',
    paddingTop: '15%',
    maxWidth: '400px',
  },
  '.modal-title': {
    textAlign: 'center',
    marginTop: '10px',
  },
  '.modal-header': {
    border: 'none',
  },
  '.modal-body': {
    padding: '10px 30px',
  },
  '.modal-footer': {
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    '.btn-ok': {
      marginLeft: 10,
    },
  },
  '.bold-text': {
    fontWeight: 600,
  },
  '.buttons-row': {
    display: 'flex',
    gap: '20px',
    '.delete': {
      height: '28px',
    },
  },
  '@media(max-width: 650px)': {
    '.top-wrapper': {
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'flex-start',
    },
    '.title': {
      marginRight: '10px',
    },
    '.client-id': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '.client-secret': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '.bold-text': {
      wordWrap: 'break-word',
    },
    '.buttons-row': {
      gap: '11px',
    },
  },
})
