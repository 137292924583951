import React from 'react'
import { css } from '@emotion/react'

import { QuestionField } from './componets'
import { sortByAnswer } from '@/utils/data/SortByAnswer'

export function QuestionsList({
  list,
  app,
  theme,
  viewNaQuestions,
  viewNaCategories,
}: any) {
  const answeredCategoryNames = list
    .filter((item) => item.answer.answer !== 'n/a')
    .map((item) => item.question.category.name)

  const filteredList = viewNaQuestions
    ? list
        .map((item) => {
          if (item.answer.answer === 'n/a') {
            return viewNaCategories
              ? answeredCategoryNames.length > 0
                ? item
                : null
              : answeredCategoryNames.includes(item.question.category.name)
              ? item
              : null
          }
          return item
        })
        .filter(Boolean)
    : list.filter((item) => item.answer.answer !== 'n/a')

  return (
    <section css={questionListBaseStyle}>
      <div className="header-base">
        <h2>{app?.observations_display}</h2>
      </div>
      <div css={css({ height: 20, width: '100%' })}></div>
      {sortByAnswer(filteredList).map((item) => (
        <QuestionField
          key={item?.question?.id}
          questionFullData={item}
          app={app}
          theme={theme}
          viewNaQuestions={viewNaQuestions}
        />
      ))}
    </section>
  )
}

function order(a, b) {
  if (a.answeredFieldOrder < b.answeredFieldOrder) {
    return -1
  }
  if (a.answeredFieldOrder > b.answeredFieldOrder) {
    return 1
  }
  return 0
}

const questionListBaseStyle = css({
  width: '100%',
  '.header-for-answer-type': {
    color: 'black !important',
    backgroundColor: 'darkorange',
    fontSize: '24px',
    fontWeight: 100,
    width: '350px',
    margin: 0,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 80,
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto',
    pageBreakAfter: 'auto',
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
  '.header-base': {
    height: 63,
    paddingTop: 32,
    paddingBottom: 15,
    width: '100%',
    borderBottom: '1px solid #eeeeef',
    display: 'flex',
    justifyContent: 'center',
    h2: {
      height: 22,
      width: 'fit-content',
      padding: '0 10px',
      fontSize: '20px',
      fontWeight: 400,
      color: 'black !important',
      backgroundColor: 'white !important',
    },
  },
})
