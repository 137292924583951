import * as React from 'react'
import moment from 'moment'
import { css, SerializedStyles } from '@emotion/react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { createTheme } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ThemeProvider, IconButton } from '@mui/material'
import { useAngularServices } from './ReactAngularContext'

type Props = {
  ohChange: (evt?: moment.Moment, value?: moment.Moment) => void
  defDate?: string | moment.Moment
  width?: string
  height?: string
  minDate?: null | moment.Moment
  maxDate?: null | moment.Moment
  error?: boolean
  icon: React.ReactNode
}

export const DateInputField = ({
  ohChange,
  defDate = '',
  width = '300px',
  height = '',
  minDate = null,
  maxDate = null,
  error = false,
  icon,
}: Props) => {
  const [date, setDate] = React.useState(
    typeof defDate === 'string' ? moment(defDate) : defDate,
  )
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color


  React.useEffect(() => {
    if (defDate) {
      setDate(typeof defDate === 'string' ? moment(defDate) : defDate)
    }
  }, [defDate])

  const newTheme = createTheme({
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            '&.Mui-selected': {
              backgroundColor: '#E3E6EF',
              color: 'black',
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: '#D6DAE5',
              },
            },
            '&:hover': {
              backgroundColor: '#F0F1F4',
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: '16px',
            fontWeight: 'bold',
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: 'black',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#444',
            textTransform: 'uppercase',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: '#cccccc',
            },
            transition: 'none',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            border: '0 !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent !important',
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: 'transparent !important',
            },
            '&:focusVisible': {
              backgroundColor: 'transparent !important',
            },
            transition: 'none',
            outline: 'none',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent !important',
            },
            '&:focus': {
              backgroundColor: 'transparent !important',
            },
            '&:focusVisible': {
              backgroundColor: 'transparent !important',
            },
            transition: 'none',
            outline: 'none',
          },
        },
      },
    },
  })
  const baseStyle: SerializedStyles = css({
    width: width,
    height: height,
    border: error ? '1px solid #C80404' : '',

    '@media (max-width: 1150px)': {
      '.MuiInputBase-input': {
        padding: '8px 0px 8px 10px',
      },
    },

    '.css-1fqigv-MuiButtonBase-root-MuiIconButton-root': {
      padding: '0',
    },

    '.clearButton': {
      display: 'none',
    },

    '.MuiInputBase-root': {
      borderRadius: 0,
      height: 33,
      borderColor: '#cccccc',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize: 12,
      transition: 'none',
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  })

  const defaultIcon = (
    <svg
      fill={variableColor}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CalendarIcon"
      style={{
        height: '20px',
        width: '18px',
        pointerEvents: 'none',
      }}
    >
      <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
    </svg>
  )

  return (
    <div css={baseStyle}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={newTheme}>
          <DesktopDatePicker
            sx={{
              maxHeight: '33px',
              width: '100%',
              '& .MuiOutlinedInput-root:hover': {
                backgroundColor: 'transparent',
              },
              '& .MuiOutlinedInput-root': {
                transition: 'none',
              },
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                size: 'small',
                InputProps: {
                  sx: {
                    '&::placeholder': {
                      color: '#B1B1B1',
                      opacity: 1,
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: '#B1B1B1',
                      opacity: 1,
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    transition: 'none',
                  },
                },
              },
            }}
            value={date}
            onChange={(value) => {
              ohChange(value)
              setDate(value)
            }}
            minDate={minDate}
            maxDate={maxDate}
            slots={{
              openPickerIcon: () => (
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                    '&:active': { backgroundColor: 'transparent !important' },
                    '&:focus': { backgroundColor: 'transparent !important' },
                    '&:focusVisible': {
                      backgroundColor: 'transparent !important',
                    },
                    transition: 'none',
                    outline: 'none',
                    padding: '5px',
                  }}
                >
                  <div
                    style={{
                      width: '1px',
                      height: '33px',
                      backgroundColor: '#cccccc',
                      marginRight: '12px',
                    }}
                  />
                  {icon ?? defaultIcon}
                </IconButton>
              ),
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}
