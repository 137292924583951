import { css } from '@emotion/react'
import moment from 'moment'
import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { useAngularServices } from './ReactAngularContext'
import { FilterIcon } from '../componentAssets'
import { ButtonElement } from './ButtonElement'

export const AdvancedDateFilter = ({ setStartDate, setEndDate, fieldName }) => {
  const { CurrentUser } = useAngularServices()
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [tempRange, setTempRange] = useState([
    {
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(0, 0, 0, 0),
      key: 'selection',
    },
  ])
  const [appliedRange, setAppliedRange] = useState([
    {
      startDate: new Date().setHours(0, 0, 0, 0),
      endDate: new Date().setHours(0, 0, 0, 0),
      key: 'selection',
    },
  ])

  const handleDateSelection = (date) => {
    const { selection } = date
    setTempRange([selection])
  }

  const handleApply = () => {
    setAppliedRange(tempRange)
    setStartDate({
      date: moment(tempRange[0]?.startDate).format('YYYY-MM-DD'),
      fieldName: fieldName,
    })
    setEndDate({
      date: moment(tempRange[0]?.endDate).format('YYYY-MM-DD'),
      fieldName: fieldName,
    })
    setCalendarVisible(false)
  }

  const handleClear = () => {
    const today = new Date().setHours(0, 0, 0, 0)
    const initialRange = [
      {
        startDate: today,
        endDate: today,
        key: 'selection',
      },
    ]
    setTempRange(initialRange)
    setAppliedRange(initialRange)
    setStartDate({
      date: null,
      fieldName: fieldName,
    })
    setEndDate({
      date: null,
      fieldName: fieldName,
    })
    setCalendarVisible(false)
  }

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const baseStyle = getBaseStyle(variableColor)

  const isDateFiltered =
    appliedRange[0]?.startDate !== new Date().setHours(0, 0, 0, 0)

  return (
    <div css={baseStyle}>
      <div
        className="alert-button-opening"
        onClick={() => {
          setCalendarVisible(!isCalendarVisible)
        }}
      >
        {!isDateFiltered ? (
          [
            <div key="1-line" className="line" />,
            <div key="2-line" className="line" />,
            <div key="3-line" className="line" />,
          ]
        ) : (
          <div className="filtered-icon">
            <FilterIcon height="16px" width="16px" color={variableColor} />
          </div>
        )}
      </div>
      {isCalendarVisible && (
        <div className="date__wrapper">
          <DateRangePicker
            onChange={(item) => {
              handleDateSelection(item)
            }}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={tempRange}
            direction="horizontal"
            color={variableColor}
            rangeColors={[variableColor]}
          />
          <div className="buttons__wrapper">
            <ButtonElement text="Clear" functionToTrigger={handleClear} />
            <ButtonElement
              text="Apply"
              buttonType="submit"
              functionToTrigger={handleApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    position: 'relative',
    '.alert-button-opening': {
      paddingTop: 1,
      marginLeft: '5px !important',
      cursor: 'pointer',
      '.line': {
        width: 16,
        transition: '500ms',
      },
      '.filtered-icon': {
        width: 16,
        img: {
          width: 16,
        },
      },
    },
    '.date__wrapper': {
      position: 'absolute',
      right: '-105px',
      top: '20px',
      zIndex: 1000,
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
    },
    '.rdrDefinedRangesWrapper': {
      display: 'none',
    },
    '.rdrStaticRangeSelected': {
      color: `${variableColor} !important`,
    },
    '.rdrDateDisplay': {
      color: `${variableColor} !important`,
    },
    '.rdrDateDisplayWrapper': {
      background: '#fff',
    },
    '.buttons__wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#fff',
      padding: '10px',
    },
  })
