import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { useAngularServices } from '@components/ReactAngularContext'
import { debounce } from 'lodash'

type Props = {
  type?: 'submit' | 'button' | 'reset'
  text: string
  functionToTrigger?: () => void
  buttonType?: 'neutral' | 'submit' | 'danger' | 'gray'
  fontSize?: string
  height?: string
  width?: string
  disabled?: boolean
  minWidth?: string
  additionalStyles?: SerializedStyles
  possibleVariableColor?: string
  debounceTimeout?: number
  children?: any
}

export const ButtonElement: React.FC<Props> = ({
  type = 'submit',
  text = '',
  functionToTrigger = () => { },
  buttonType = 'neutral',
  fontSize = '12px',
  height = '28px',
  width = 'fit-content',
  minWidth = '75px',
  disabled = false,
  additionalStyles = css({}),
  children,
  possibleVariableColor = '',
  debounceTimeout = 0,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = possibleVariableColor
    ? possibleVariableColor
    : CurrentUser.getClientSettings().web_primary_color

  const variableStyle = {
    neutral: css({
      borderColor: variableColor,
      color: variableColor,
      ':hover': {
        color: '#FEFEFF',
        backgroundColor: variableColor,
        borderColor: variableColor,
      },
    }),
    submit: css({
      color: '#FEFEFF',
      backgroundColor: variableColor,
      borderColor: variableColor,
    }),
    danger: css({ color: 'red', borderColor: 'red' }),
    gray: css({ color: 'gray', borderColor: 'gray' }),
  }
  React.useEffect(() => { }, [disabled])

  const debouncedClick = React.useMemo(
    () => debounce(functionToTrigger, debounceTimeout),
    [functionToTrigger, debounceTimeout],
  )

  return (
    <button
      type={type || 'submit'}
      disabled={disabled}
      aria-disabled={disabled}
      css={[
        baseStyle,
        variableStyle[buttonType],
        css({ height, width, fontSize, minWidth }),
        additionalStyles,
      ]}
      onClick={(el) => {
        if (debounceTimeout === 0) {
          functionToTrigger()
        } else {
          debouncedClick()
        }
      }}
      onMouseUp={(el) => {
        el.target.blur()
      }}
      onMouseLeave={(el) => {
        el.target.blur()
      }}
    >
      {text}
      {children ? children : null}
    </button>
  )
}
const baseStyle = css({
  borderStyle: 'solid',
  borderWidth: 1,
  padding: '3px 5px',
  borderRadius: 0,
  backgroundColor: 'transparent',
  fontFamily: "'Open Sans', Arial, sans-serif",
  fontWeight: 'normal',
  paddingLeft: 15,
  paddingRight: 15,
  ':disabled': {
    color: 'white !important',
    borderColor: 'gray !important',
    backgroundColor: 'gray !important',
  },
  ':focus': {
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.13)',
  },
})
