import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { ConfigNavBar } from '../components/ConfigNavBar'
import { css } from '@emotion/react'
import { Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react'
import { BillingSelect } from './components/BillingSelect'
import { TextInputStyle } from '@screens/components'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'
import classNames from 'classnames'

export const CompanyBilling = () => {
  const { Api, CurrentUser } = useAngularServices()
  const [codeList, setCodeList] = useState([{ id: null, name: 'NA' }])
  const [selectedCode, setSelectedCode] = useState('')
  const [userInfo, setUserInfo] = useState({})
  const [listPage, setListPage] = useState(1)
  const [refresh, setRefresh] = useState(false)

  const [customerSupport, setCustomerSupport] = useState({
    email: '',
    phone: '',
  })

  useEffect(() => {
    const getUserInfo = async () => {
      const clientId = await CurrentUser.getClientId()
      const { data: userInfoResponse } = await Api.get(`clients/${clientId}`)

      if (userInfoResponse.sic_code) {
        setSelectedCode(userInfoResponse.sic_code.name)
      }

      setCustomerSupport({
        email: userInfoResponse?.support_email,
        phone: userInfoResponse?.support_phone,
      })

      setUserInfo(userInfoResponse)
      setRefresh(false)
    }
    getUserInfo()
  }, [refresh])

  useEffect(() => {
    const getSICCodes = async () => {
      const { data: codeListResponse } = await Api.getWithoutDeletedParam(
        'sic_codes',
        {
          order: 'name',
          page: listPage,
        },
      )

      if (codeListResponse.next) {
        setListPage(listPage + 1)
      }

      setCodeList((prevOptions) => [
        ...prevOptions,
        ...codeListResponse.results,
      ])
    }

    getSICCodes()
  }, [listPage])

  const handleSave = async () => {
    const fullSelectedCode = codeList.find(
      (code) => code?.name === selectedCode,
    )

    try {
      await Api.patch(`clients/${userInfo?.id}`, {
        ...userInfo,
        sic_code: fullSelectedCode?.id,
        support_phone: customerSupport?.phone,
        support_email: customerSupport?.email,
      })
      setRefresh(true)
    } catch {
      console.log('error')
    }
  }

  const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, '')

    let formattedValue = digits

    if (digits.length > 3) {
      formattedValue = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}`
    }
    if (digits.length > 6) {
      formattedValue += `-${digits.slice(6, 10)}`
    }

    return formattedValue
  }

  const handleCustomerSupportChange = (e) => {
    if (e.target.name !== 'phone') {
      setCustomerSupport({
        ...customerSupport,
        [e.target.name]: e.target.value,
      })
    } else {
      setCustomerSupport({
        ...customerSupport,
        [e.target.name]: formatPhoneNumber(e.target.value),
      })
    }
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const emailError = Boolean(
    customerSupport?.email.length && !emailRegex.test(customerSupport?.email),
  )

  const phoneError =
    customerSupport?.phone?.length &&
    customerSupport.phone.replace(/\D/g, '').length < 10

  return (
    <section css={baseStyle} className="sic__wrapper">
      <CommonPageHeader headerText="Settings" />
      <ConfigNavBar />
      <div className="code__wrapper">
        <div className="sic__title">
          <h3 className="title__text">SIC Code</h3>
        </div>
        <div className="select__wrapper">
          <BillingSelect
            options={codeList}
            setValue={setSelectedCode}
            value={selectedCode}
          />
        </div>
      </div>
      <div className="code__wrapper">
        <div className="sic__title">
          <h3 className="title__text">Customer Support</h3>
        </div>
        <div className="support__inputs">
          <div className="input__wrapper">
            <label className="input__label">Email</label>
            <AutoExpandTextArea
              placeholder=""
              name="email"
              className="input"
              handleChange={handleCustomerSupportChange}
              value={customerSupport?.email}
              errorCondition={emailError}
            />
          </div>
          <div className="input__wrapper">
            <label className="input__label">Phone</label>
            <input
              name="phone"
              type="tel"
              className={classNames('input', {
                error: phoneError,
              })}
              onChange={handleCustomerSupportChange}
              value={customerSupport?.phone}
            />
          </div>
        </div>
        <div className="button__wrapper">
          <ButtonElement
            text="Save"
            buttonType="submit"
            functionToTrigger={handleSave}
            disabled={
              phoneError ||
              emailError ||
              (userInfo?.sic_code?.name === selectedCode &&
                userInfo?.support_email === customerSupport?.email &&
                userInfo.support_phone === customerSupport?.phone)
            }
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  '.page__wrapper': {
    paddingRight: '0 !important',
  },
  '.code__wrapper': {
    marginLeft: '5px',
    background: '#fff',
    width: '624px',
  },
  '.sic__title': {
    borderBottom: '1px solid #E7EAEC',
  },
  '.title__text': {
    fontSize: '14px',
    color: '#676A6C',
    fontWeight: 650,
    padding: '15px 0 10px 20px',
    margin: 0,
  },
  '.select__wrapper': {
    marginTop: '16px',
    paddingLeft: '20px',
  },
  '.button__wrapper': {
    marginTop: '25px',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '28px',
  },
  '.support__inputs': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  '.input__wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
  },
  '.input': {
    ...TextInputStyle,
    maxWidth: '576px',
    height: '34px',

    '&__label': {
      color: '#676A6C',
      fontSize: '12px',
      fontWeight: 600,
      margin: 0,
    },
  },
  '.error': {
    borderColor: '#C80404',
  },
  '@media(max-width: 900px)': {
    '.code__wrapper': {
      width: '365px',
    },
    '.input': {
      width: '317px',
    },
  },
})
