/**
 * Utility for checking video file compatibility with mobile devices
 *
 * Checks:
 * - File size (750MB limit)
 * - Faststart optimization (moov atom location)
 * - Container format (MP4 compatible formats)
 * - Video codec (H.264 presence)
 * - H.264 profile (High vs Main/Baseline)
 * - H.264 level (high level issues)
 * - Color space (standard yuv420p vs non-standard)
 */

/**
 * Checks video file for compatibility with mobile devices
 * @param {File} file - File object to check
 * @returns {Promise<Object>} Result of check with compatibility information
 */
export const checkVideoCompatibility = async (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = async function (event) {
            const issues = [];
            const data = new Uint8Array(event.target.result);
            const textDecoder = new TextDecoder('utf-8', {
                fatal: false
            });
            let text;

            try {
                text = textDecoder.decode(data);
            } catch (e) {
                console.error('❌File decoding error');
                text = '';
            }

            console.log('🔍 Checking the video file before uploading...');
            console.log(`📂 File size: ${(file.size / 1048576).toFixed(2)} MB`);
            console.log(`📄 File type: ${file.type}`);

            if (file.size > 750 * 1024 * 1024) {
                issues.push('The video exceeds the recommended size 750MB');
            }

            const hasMoov = text.includes('moov');
            const hasFtyp = text.includes('ftyp');
            const hasMdat = text.includes('mdat');
            const moovAfterMdat = text.indexOf('moov') > text.indexOf('mdat') && hasMdat && hasMoov;

            console.log(`📌 moov atom at the beginning of the file: ${hasMoov && !moovAfterMdat ? '✅ THERE ARE' : '❌ Missing or not optimized'}`);

            if (!hasMoov) {
                issues.push('Moov Atom missing');
            } else if (moovAfterMdat) {
                issues.push('Video is not optimized for quick start (moov atom is located after mdat)');
            }

            const containerFormats = ['isom', 'mp42', 'avc1', 'mp41', 'M4V', 'M4A', 'mp4'];
            let supportedContainer = false;
            let detectedFormat = 'Unknown';

            for (const format of containerFormats) {
                if (text.includes(format)) {
                    supportedContainer = true;
                    detectedFormat = format;
                    break;
                }
            }

            console.log(`📦 Container format: ${detectedFormat}`);

            if (!supportedContainer) {
                issues.push('Detected non-standard formats (isom, mp42, avc1, mp41, etc.)');
            }

            const isH264 = text.includes('avc1') || text.includes('avc3');
            console.log(`🎬 H.264 codec: ${isH264 ? '✅ Detected' : '❌ Not detected'}`);

            if (!isH264) {
                issues.push('H.264 codec is required to view on mobile devices.');
            }
            


            const highProfileMarkers = ['High', 'high'];
            let isHighProfile = false;

            for (const marker of highProfileMarkers) {
                if (text.includes(marker)) {
                    isHighProfile = true;
                    break;
                }
            }

            const mainOrBaselineMarkers = ['Main', 'main', 'Baseline', 'baseline'];
            let isMainOrBaseline = false;

            for (const marker of mainOrBaselineMarkers) {
                if (text.includes(marker)) {
                    isMainOrBaseline = true;
                    break;
                }
            }

            console.log(`🔑 H.264 Profile: ${isMainOrBaseline ? '✅ Main/Baseline (compatible)' : isHighProfile ? '⚠️ High (possible issues)' : '❓ Not defined'}`);

            if (isHighProfile) {
                issues.push('High H.264 profile detected, which is not compatible on some mobile devices');
            }

            const highLevelMarkers = ['5.1', '5.2', '6.0', '6.1', '6.2'];
            let hasHighLevel = false;
            let detectedLevel = 'Not defined';

            for (const level of highLevelMarkers) {
                if (text.includes(`level ${level}`) || text.includes(`Level ${level}`)) {
                    hasHighLevel = true;
                    detectedLevel = level;
                    break;
                }
            }

            console.log(`📊 H.264 Level: ${detectedLevel} ${hasHighLevel ? '⚠️ High (possible problems)' : ''}`);

            if (hasHighLevel) {
                issues.push(`High H.264 level detected (${detectedLevel}), which may be incompatible with older devices`);
            }

            const standardColorspace = text.includes('yuv420p');
            const nonStandardColorspace = text.includes('yuvj420p') || text.includes('yuv444p') || text.includes('yuv422p');

            console.log(`🎨 Color space: ${standardColorspace ? '✅ yuv420p (compatible)' : nonStandardColorspace ? '⚠️ Non-standard' : '❓ Not defined'}`);

            if (nonStandardColorspace) {
                issues.push('A non-standard color space has been detected, which may cause problems on some devices.');
            }

            if (issues.length === 0) {
                console.log('✅ The video file has passed all checks and should play normally on mobile devices!');
                resolve({
                    valid: true,
                    details: {
                        container: detectedFormat,
                        h264: isH264,
                        faststart: hasMoov && !moovAfterMdat,
                        profile: isHighProfile ? 'High' : isMainOrBaseline ? 'Main/Baseline' : 'Not defined',
                        level: detectedLevel,
                        colorspace: standardColorspace ? 'yuv420p' : nonStandardColorspace ? 'non-standard' : 'Not defined',
                        size: (file.size / 1048576).toFixed(2) + ' MB'
                    }
                });
            } else {
                console.error('⚠️ Issues identified that may affect playback on mobile devices:');
                issues.forEach(issue => console.error(`- ${issue}`));

                resolve({
                    valid: false,
                    reason: issues.join('; '),
                    details: {
                        container: detectedFormat,
                        h264: isH264,
                        faststart: hasMoov && !moovAfterMdat,
                        profile: isHighProfile ? 'High' : isMainOrBaseline ? 'Main/Baseline' : 'Not defined',
                        level: detectedLevel,
                        colorspace: standardColorspace ? 'yuv420p' : nonStandardColorspace ? 'non-standard' : 'Not defined',
                        size: (file.size / 1048576).toFixed(2) + ' MB',
                        issues: issues
                    }
                });
            }
        };

        reader.onerror = function () {
            console.error('❌ Cannot read file');
            resolve({
                valid: false,
                reason: 'Cannot read file'
            });
        };

        reader.readAsArrayBuffer(file.slice(0, 4096));
    });
};

export default checkVideoCompatibility;