import {
    ReactWrapper
} from '@components';
import {
    AddProject
} from './AddProject';

//AdminCompanyProjectsConfig

export const AdminAddProjectComponent = ReactWrapper(
    AddProject,
    'adminAddProjectComponent',
);