import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'
import { AssignTemplateRow } from './components/AssignTemplateRow'
import { hexToRGBA, hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import sortArrows from '@/assets/icons/sort-arrows.svg'
import sortArrowsActiveFirst from '@/assets/icons/sort-arrows-active.svg'
import sortArrowsActiveSecond from '@/assets/icons/sort-arrows-inactive.svg'
import { checkIsSafari } from '@/utils/checkIsSafari'
import {
  DataWrap,
  GenericAppProject,
  GenericProject,
  PaginatedGet,
} from '@/react/types'

type tablePoint = {
  id: number
  name: string
  count: number
}

export const AssignProject = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [order, setOrder] = useState<string>('name')
  const [project, setProject] = useState<GenericProject | {}>({})
  const [templateList, setTemplateList] = useState<tablePoint[]>([])
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const projectId: string = stateService.params.project || ''
  const [page, setPage] = useState(1)
  const [isAllChecked, setAllChecked] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [count, setCount] = useState<number>(20)
  const [toggleAllId, setToggleAllId] = useState<number>(0)
  const [refresh, setRefresh] = useState<boolean>(false)

  const [projectsGot, setProjectsGot] = useState<boolean>(false)

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const handleCheckedAll = async () => {
    setAllChecked(!isAllChecked)
    try {
      await Api.post(`app_projects/${toggleAllId}/assign_all_toolbox_topics`, {
        id: toggleAllId,
        on: !isAllChecked,
        search,
      })
    } catch {
      console.log('error in select all')
    }
    setRefresh(true)
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'template',
      name: 'Templates',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '18px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '46px',
        minWidth: '46px',
        paddingLeft: 0,
        textAlign: 'left',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        p: {
          width: '0px',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '13px',
          alignItems: 'center',
        },
      },
      additionalInnerElement: (
        <div className="select__wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            src={
              order === 'active'
                ? sortArrowsActiveSecond
                : order === '-active'
                ? sortArrowsActiveFirst
                : sortArrows
            }
            className="sort__icon"
            alt="sort icon"
            onClick={() => {
              setOrder(order === '-active' ? 'active' : '-active')
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getData = async () => {
      if (!projectsGot) {
        const {
          data: projectResponse,
        }: DataWrap<PaginatedGet<GenericAppProject[]>> = await Api.get(
          'app_projects',
          {
            app: stateService.params.app,
            project: stateService.params.project,
          },
        )

        if (projectResponse.results[0]?.project)
          setProject(projectResponse.results[0].project)
        if (projectResponse.results[0]?.id)
          setToggleAllId(projectResponse.results[0].id)

        if (!projectResponse.results.length) {
          await Api.post('app_projects', {
            app: stateService.params.app,
            client: CurrentUser.getClientId(),
            project: stateService.params.project,
          })
        }

        setProjectsGot(true)
      }
      const { data }: DataWrap<PaginatedGet<tablePoint[]>> = await Api.get(
        'toolbox_topic_sets/combined_project_assign',
        {
          project: projectId,
          application: stateService.params.app,
          search,
          page,
          order,
          is_active: true,
        },
        () => {},
        () => {},
        true,
      )

      setTemplateList(data?.results)
      setCount(data.count)

      setRefresh(false)
    }
    getData()
  }, [search, page, order, refresh])

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)
  return (
    <div css={baseStyle}>
      <CommonPageHeader
        headerText={`Assign ${configurableNamesList.projects_display} to Template`}
      />
      <ToolboxTemplatesNavBar />
      <section className="project__assign__wrapper">
        <div className="top__wrapper">
          <h4 className="project__name">{project?.name}</h4>
          <ButtonElement
            buttonType="submit"
            text="Done"
            functionToTrigger={() => {
              stateService.go('app.toolboxes.toolbox_to_project', {
                pageNumber: stateService.params.previousPageNumber,
                search: stateService.params.previousSearch,
              })
            }}
          />
        </div>
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
          onClear={() => {
            setAllChecked(false)
          }}
        />
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <tbody>
            {templateList?.map((template, index) => (
              <AssignTemplateRow
                template={template}
                index={index}
                search={search}
                projectId={projectId}
                setRefresh={setRefresh}
                key={index + '_' + template?.id}
              />
            ))}
          </tbody>
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getBaseStyle(variableColor, isSafari) {
  return css({
    paddingBottom: '200px',
    '.project__assign__wrapper': {
      background: '#fff',
      paddingLeft: '20px',
      width: '834px',
      marginLeft: '5px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.sort__icon': {
      cursor: 'pointer',
      height: '17px',
    },
    '#search': {
      width: '264px',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingBottom: '20px',

      button: {
        minWidth: 'auto !important',
      },
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.project__name': {
      fontSize: '13px',
      margin: 0,
    },
    table: {
      width: '791px',
    },
    '.project__row': {
      height: '40px',
    },
    '.name__td': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.group__row': {
      height: '40px',

      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.1),
    },
    '.checkmark__td': {
      textAlign: 'center',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '.name__cell': {
      paddingLeft: '16px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.template__row': {
      height: '40px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
      whiteSpace: 'pre-wrap',
    },
    '@media(max-width: 1050px)': {
      '#search': {
        width: '251px',
      },
      '.project__name': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '.top__wrapper': {
        paddingRight: 0,
      },
      '.project__assign__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__td': {
        maxWidth: '204px',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
}
