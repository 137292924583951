import classNames from 'classnames'
import React, { useRef, useState } from 'react'

type Props = {
  className: string
  value: string
  handleChange: (e: any) => void
  name: string
  placeholder: string
  errorCondition?: boolean
  defaultHeight?: string
  handleKeyDown?: any
}

export const AutoExpandTextArea = ({
  className = '',
  value,
  name,
  handleChange,
  placeholder,
  errorCondition = !value,
  defaultHeight = '',
  handleKeyDown,
}: Props) => {
  const [textareaHeight, setTextareaHeight] = useState(defaultHeight)
  const textAreaRef = useRef()

  const adjustTextareaHeight = () => {
    if (textAreaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textAreaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(`${rows * lineHeight}px`)
    }
  }
  return (
    <textarea
      className={classNames(className, {
        error: errorCondition,
      })}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      onInput={adjustTextareaHeight}
      ref={textAreaRef}
      style={{ height: `${textareaHeight}`, resize: 'vertical' }}
      onFocus={adjustTextareaHeight}
      onScroll={adjustTextareaHeight}
      onMouseEnter={adjustTextareaHeight}
      onKeyDown={handleKeyDown}
    />
  )
}
