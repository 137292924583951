import React, { useState } from 'react'
import { ButtonElement } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { useEffect, useRef } from 'react'

export const UnsavedChangesDialog = ({
  handleClick,
  handleClose,
  title = '',
  top = '28px',
  left = '290px',
  right = '0',
  bottom = 'auto',
}) => {
  const modalRef = useRef()
  const styles = baseStyle(top, left, right, bottom)

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: '0',
          left: '0',
          pointerEvents: 'auto',
          zIndex: '100'
        }}
        className="modal-overlay"
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <div css={styles} className="modal__wrapper" ref={modalRef}>
        <img
          src={closeIcon}
          alt="cross-icon"
          className="close__icon"
          onClick={() => {
            handleClose()
          }}
        />
        <div className="modal__info">
          <p className="message__name">{title}</p>
          <div className="modal__action__buttons">
            <ButtonElement
              text="Leave without saving"
              buttonType="submit"
              functionToTrigger={() => {
                handleClick('leave')
                handleClose()
              }}
            />
            <ButtonElement
              text="Save changes"
              buttonType="submit"
              functionToTrigger={() => {
                handleClick('save')
                handleClose()
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const baseStyle = (top, left, bottom, right) =>
  css({
    '@media(max-width: 1150px)': {
      left: '80px',
      top: '52rem',
    },
    '@media(max-width: 550px)': {
      left: '26px',
    },
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
    marginTop: '-27px',
    right: right,
    top: top,
    left: left,
    bottom: bottom,
    height: '121px',
    zIndex: 10000,

    '.close__icon': {
      position: 'absolute',
      right: '4px',
      top: '5px',
      width: '13px',
      height: '13px',
      cursor: 'pointer',
    },
    '.modal__message': {
      color: '#666',
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginTop: '7px',
      marginBottom: '10px',
      textAlign: 'center',
      width: 'auto',
      fontWeight: 400,
    },
    '.message__name': {
      textAlign: 'center',
      width: '246px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 14,
      margin: 0,
      maxHeight: '38px',
    },
    '.delete__title': {
      marginBottom: '14px',
    },
    '.delete__subtitle': {
      color: '#666',
      textAlign: 'center',
      fontSsize: '14px',
      margin: 0,
      marginBottom: '25px',
      width: '206px',
    },
    '.message__email': {
      margin: 0,
      fontWeight: 600,
    },
    '.modal__info': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '23px',
    },

    '.modal__action__buttons': {
      margin: '10px 18px 0px 18px',
      gap: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
