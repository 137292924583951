import { css } from '@emotion/react'
import viewIcon from '@/assets/icons/view-icon.svg'
import deleteIcon from '@/assets/icons/delete-nav-icon.svg'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import { useEffect } from 'react'
import { useAngularServices } from '@/react/components'

export const ToolboxTalksNavBar = () => {
  const { stateService } = useRouter()

  const { CurrentUser } = useAngularServices()
  const role = CurrentUser.getRole()

  useEffect(() => {
    if (role === 'supervisor' || role === 'client_user') {
      stateService.go('app.toolboxes.list')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'app.toolboxes.list'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'app.toolboxes.list',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        {role !== 'supervisor' && role !== 'client_user' && (
          <UISref to={'app.toolboxes.deleted'}>
            <a
              className={classNames('tab', {
                active: stateService.current.name === 'app.toolboxes.deleted',
              })}
            >
              <img className="nav__icon" src={deleteIcon} alt="add nav__icon" />
              <p>Deleted</p>
            </a>
          </UISref>
        )}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  marginLeft: '5px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.nav__icon': {
      marginLeft: '25px',
      marginRight: '10px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: 0,
      marginRight: '25px',
      marginBottom: 0,
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
