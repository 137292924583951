import { useState } from 'react'
import moment from 'moment'

export interface DateFilter {
  start: string | null
  end: string | null
}

export interface DateFilters {
  [key: string]: DateFilter
}

export const useDateFilters = (initialFields: string[]) => {
  // Create initial state object from field names
  const initialState = initialFields.reduce((acc, fieldName) => {
    acc[fieldName] = { start: null, end: null }
    return acc
  }, {} as DateFilters)

  const [dateFilters, setDateFilters] = useState<DateFilters>(initialState)

  const handleStartDate = ({
    date,
    fieldName,
  }: {
    date: string
    fieldName: string
  }) => {
    setDateFilters((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], start: date },
    }))
  }

  const handleEndDate = ({
    date,
    fieldName,
  }: {
    date: string
    fieldName: string
  }) => {
    setDateFilters((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], end: date },
    }))
  }

  const filterByDates = <T extends { [key: string]: any }>(items: T[]): T[] => {
    return items.filter((item) => {
      let showItem = true

      Object.entries(dateFilters).forEach(([fieldName, { start, end }]) => {
        if (start && end) {
          // Handle both nested and direct date properties
          const dateValue = item.training?.[fieldName] || item[fieldName]
          if (!dateValue) {
            showItem = false
            return
          }

          const itemDate = moment(dateValue)
          const startDate = moment(start)
          const endDate = moment(end)

          if (!itemDate.isBetween(startDate, endDate, 'day', '[]')) {
            showItem = false
          }
        }
      })

      return showItem
    })
  }

  return {
    dateFilters,
    setDateFilters,
    handleStartDate,
    handleEndDate,
    filterByDates,
  }
}
