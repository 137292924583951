import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { TableInput } from '@/react/componentAssets/TableInput'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const EmployeeGroupRow = ({ group, index, search, setRefresh }) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [isEdit, setEdit] = useState(false)

  const [hoveredField, setHoveredField] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)

  const handleDelete = async () => {
    await Api.delete(`user_sets/${group?.id}`, {})

    setModalOpen(false)
    setRefresh(true)
  }

  const handleNameUpdate = async (newName: string) => {
    try {
      await Api.patch(`user_sets/${group?.id}`, {
        name: newName,
      })
    } catch {
      console.error('Failed to update the name')
    } finally {
      setRefresh(true)
    }
  }

  return (
    <tr
      className={classNames('', {
        dark: index % 2 === 0,
      })}
    >
      {!isEdit ? (
        <td
          className="group__td"
          onClick={() => {
            setEdit(true)
          }}
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name',
              text: group?.name,
              max__length: 67,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={group?.name}
            />
          </p>
        </td>
      ) : (
        <TableInput
          defaultValue={group?.name}
          handleClose={() => {
            setEdit(false)
          }}
          handleSubmit={handleNameUpdate}
          inputWidth="350px"
        />
      )}
      <td className="icon__td">
        <div className="topic__info">
          <EditIcon
            color={variableColor}
            onClick={() => {
              stateService.go('trainings.assign.employee_groups_assign', {
                groupID: group?.id,
              })
            }}
          />
          <p className="topic__count">{group?.users?.length}</p>
        </div>
      </td>
      <td className="icon__td bin">
        <BinDeleteIcon
          color={variableColor}
          onClick={() => {
            setModalOpen(true)
          }}
        />
        {isModalOpen && (
          <ConfirmationModal
            action={'Delete Group'}
            title={group?.name}
            handleClose={() => {
              setModalOpen(false)
            }}
            handleClick={handleDelete}
          />
        )}
      </td>
      {!isEdit && hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
