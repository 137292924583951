import viewIcon from '@/assets/icons/view-icon.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import { useAngularServices } from '@/react/components'
import { navigationStyles } from '@/react/components/commonStyles'

export const QuestionNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  return (
    <div>
      <div className="tab-button-list" css={navigationStyles}>
        <UISref to={'app.questions.list'} options={{ reload: true }}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.questions.my' ||
                stateService.current.name === 'app.questions.edit' ||
                stateService.current.name ===
                  'app.questions.custom_categories' ||
                stateService.current.name === 'app.questions.list',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        {CurrentUser.getRole() !== 'supervisor' && (
          <UISref to={'app.questions.add'} options={{ reload: true }}>
            <a
              className={classNames('tab', {
                active: stateService.current.name === 'app.questions.add',
              })}
            >
              <img className="nav__icon" src={addIcon} alt="add nav__icon" />
              <p>Add</p>
            </a>
          </UISref>
        )}
      </div>
    </div>
  )
}
