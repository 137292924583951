import {
  CommonPageHeader,
  dropdownFiledTheme,
  dropdownOptionStyles,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { QuestionNavBar } from '../components/QuestionsNavBar'
import { default as ReactSelect } from 'react-select'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../../components'
import { QuestionRow } from './components/QuestionRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import * as React from 'react'
import { CurrentUserType } from '@/react/types/documentation'
import { useStateServiceParams } from '@/react/hooks/useUrlParams'
import BackButton from '@/react/components/common/BackButton'

export const ObservationQuestionsList = () => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [categories, setCategories] = useState([])
  const [questions, setQuestions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState<{
    label: string
    value: number
  } | null>(null)
  const [order, setOrder] = useState('order')
  const [count, setCount] = useState(20)
  const [isSupervisor, setSupervisor] = useState(false)
  const [categoryIdFromUrl, setCategoryIdFromUrl] = useState<number | null>(
    null,
  )
  const [showBackButton, setShowBackButton] = useState<string>()
  const [previousSearch, setPreviousSearch] = useState<string>()
  const [previousPageNumber, setPreviousPageNumber] = useState<number>()
  const { page, setPage, search, setSearch, refresh, setRefresh } =
    useStateServiceParams()

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const isCustomCategory = questions?.every(
    (q) => q?.clientappquestion_to_category?.length > 0,
  )

  useEffect(() => {
    if (!selectedCategory?.value) return

    const getQuestions = async () => {
      try {
        const { data } = await Api.get('questions/category_questions_list', {
          application: stateService.params.app,
          app_questions: true,
          category: selectedCategory.value,
          is_active: true,
          deleted: false,
          order,
          page,
          search,
        })

        const formattedQuestions = data.results.map((q) => ({
          ...q,
          searchText: stripHtml(q.name),
        }))

        setQuestions(formattedQuestions)
        setRefresh(false)
        setCount(data.count)
      } catch {
        Notification.clearNotification()
      }
    }

    getQuestions()
  }, [selectedCategory?.value, search, page, refresh, order])

  useEffect(() => {
    const role = CurrentUser.getRole()

    if (role === 'supervisor') {
      setSupervisor(true)
    }

    if (role === 'client_user') {
      stateService.go('app.company_news.list')
    } else if (
      role === 'read_only' ||
      role === 'trainee' ||
      role === 'trainee_manager'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [CurrentUser])

  useEffect(() => {
    const idFromUrl = stateService.params.categoryId
    const showBackButton = stateService.params.showBackButton
    const previousSearch = stateService.params.previousSearch
    const previousPageNumber = stateService.params.previousPageNumber

    if (idFromUrl) {
      setCategoryIdFromUrl(+idFromUrl)
    }

    if (showBackButton) {
      setShowBackButton(showBackButton)
    }

    if (previousSearch) {
      setPreviousSearch(previousSearch)
    }

    if (previousPageNumber) {
      setPreviousPageNumber(+previousPageNumber)
    }
  }, [])

  useEffect(() => {
    if (categoryIdFromUrl !== null) return

    const getCategories = async () => {
      let hasNextPage = true
      let page = 1
      let allCategories = []

      while (hasNextPage) {
        const { data } = await Api.get('categories', {
          application: stateService.params.app,
          order: 'name',
          deleted: false,
          is_active: true,
          page_size: 1000,
          page: page,
        })

        allCategories = [...allCategories, ...data.results]
        hasNextPage = !!data.next
        page += 1
      }

      setCategories(
        allCategories.map((category) => ({
          ...category,
          value: category.id,
          label: category?.client ? (
            <span>
              {category?.name}
              <span className="custom-label"> (custom)</span>
            </span>
          ) : (
            category?.name
          ),
        })),
      )
    }

    getCategories()
  }, [categoryIdFromUrl])

  useEffect(() => {
    if (categories.length === 0) return

    const idFromUrl = stateService.params.categoryId
    const selected = idFromUrl
      ? categories.find((cat) => cat.id === +idFromUrl)
      : categories[0]

    if (selected) {
      setSelectedCategory({
        value: selected.id,
        label: selected?.client ? (
          <span>
            {selected?.name}
            <span className="custom-label"> (custom)</span>
          </span>
        ) : (
          selected?.name
        ),
      })
    }
  }, [categories])

  const handleBackNavigation = () => {
    stateService.go('app.categories.custom', {
      search: previousSearch,
      pageNumber: previousPageNumber,
    })
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'topic',
      name: 'Order',
      type: 'order',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        maxWidth: '80px',
        width: '80px',
        minWidth: '80px',
        height: '30px',
        padding: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          paddingLeft: '10px',
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: 0,
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
    },
    {
      className: 'originator',
      name: 'Questions',
      type: '',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        width: '500px',
        minWidth: '500px',
        textAlign: 'center',
        height: '30px',
        paddingLeft: '15px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'originator',
      name: 'Custom Categories',
      type: 'is_default',
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '140px',
        minWidth: '140px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
    },
    {
      className: 'originator',
      name: 'Delete',
      type: '',
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '80px',
        minWidth: '80px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
          width: '100%',
          paddingLeft: '10px',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
      additionalInnerElement: (
        <div className="info__wrapper">
          <InfoIcon
            color={variableColor}
            handleClick={() => setInfoModalVisible((prev) => !prev)}
          />
          {isInfoModalVisible ? (
            <InfoModal
              setModalOpen={setInfoModalVisible}
              message="You can only delete custom questions"
              right="-50px"
            />
          ) : null}
        </div>
      ),
    },
  ]

  const stripHtml = (htmlString: string) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = htmlString
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  const filteredQuestions = questions.filter((q) =>
    q.searchText.toLowerCase().includes(search.toLowerCase()),
  )

  const styles = getStyles(variableColor)

  const visibleHeaders = useMemo(() => {
    return TABLE_HEADER_SETTINGS.filter((header) => {
      if (!isCustomCategory) {
        if (header.name === 'Order') {
          return false
        }
      }

      if (isSupervisor) {
        if (header.name === 'Custom Categories' || header.name === 'Order') {
          return false
        }
      }

      return true
    })
  }, [isCustomCategory, isSupervisor, isInfoModalVisible])

  return (
    <div css={styles}>
      <CommonPageHeader headerText="Questions" />
      <QuestionNavBar />
      <main className="questions__wrapper">
        <div className="top__section">
          <div className="search__container">
            <p className="question__title">Category</p>
            {!selectedCategory ? null : (
              <ReactSelect
                defaultValue={selectedCategory}
                options={categories}
                onChange={(value) => {
                  setSelectedCategory(value)
                }}
                styles={dropdownOptionStyles(
                  variableColor,
                  '38px',
                  false,
                  '400px',
                )}
                theme={(theme) => dropdownFiledTheme(theme, variableColor)}
              />
            )}
          </div>
          {showBackButton === 'true' && (
            <BackButton color={variableColor} onClick={handleBackNavigation} />
          )}
        </div>
        <div className="search__wrapper">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={visibleHeaders}
              setOrder={setOrder}
              order={order}
              updateUrl={false}
              popUpLocation={false}
              callback={setOrder}
            />
            <tbody>
              {filteredQuestions.map((question, index) => (
                <QuestionRow
                  key={question.id}
                  question={question}
                  search={search}
                  index={index}
                  isCustomCategory={isCustomCategory}
                  setRefresh={setRefresh}
                  page={page}
                  category={categories.find(
                    (category) => category.id === selectedCategory?.value,
                  )}
                  isSupervisor={isSupervisor}
                  showBackButton={showBackButton}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </div>
  )
}

const getStyles = (variableColor: string) =>
  css({
    paddingBottom: '250px',
    '.questions__wrapper': {
      background: '#fff',
      marginLeft: '5px',
      paddingInline: '16px',
      width: 'fit-content',
      maxWidth: '832px',
    },
    tr: {
      height: '40px',
    },
    td: {
      height: '40px',
    },
    '.top__section': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingTop: '20px',
    },
    '.search__container': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    '.question__title': {
      fontWeight: 700,
    },
    '.search__wrapper': {
      marginTop: '15px',
    },
    '#search': {
      width: '264px',
    },
    '.table__wrapper': {
      marginTop: '15px',
    },
    '.title__td': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: variableColor,
    },
    '.title__text': {
      width: 'fit-content',
      margin: 0,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: variableColor,
      maxWidth: '426px',
    },
    '.icon__td': {
      textAlign: 'center',
      position: 'relative',
    },
    '.link__td': {
      color: variableColor,
    },
    '.edit__td': {
      textAlign: 'center',
    },
    '.question__td': {
      paddingLeft: '15px',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    '.edit__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '3px',

      svg: {
        cursor: 'pointer',
      },
      a: {
        height: '18px',
      },
    },
    '.edit__count': {
      margin: 0,
      width: '20px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    p: {
      margin: 0,
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.switch__wrapper': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '.info__wrapper': {
      height: '21px',
      display: 'flex',
      alignItems: 'flex-start',
      position: 'relative',
      svg: {
        cursor: 'pointer',
      },
    },
    '@media(max-width: 900px)': {
      '.questions__wrapper': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
    },
    '.custom-label': {
      opacity: '50%',
    },
  })
