import { CommonPageHeader, useAngularServices } from '@/react/components'
import { ConfigNavBar } from './components/ConfigNavBar'
import { css } from '@emotion/react'
import React, { useEffect, useRef, useState } from 'react'
import { CreateEditResultType } from '../../AssessmentsConfig/AssessmentScoreKey/components/CreateEditResultType'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { useRouter } from '@/react/hooks'
import { UnsavedChangesDialog } from './components/UnsavedChanges'
import { DateInputField } from '../../../components/DateInputField'
import { DepartmentsSelect } from './components/DepartmentsSelect'
import moment from 'moment'
import { isEqual } from 'lodash'
import { CalendarIconDepartment } from '@/react/componentAssets/CalendarIconDepartment'

export interface Department {
  id: string
  name: string
}

export const AddProject = () => {
  const router = useRouter()
  const { stateService } = useRouter()
  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const projectName = CurrentUser.getClientSettings().project_language
  const projectTitile = CurrentUser.getClientSettings().projects_language
  const [loading, setLoading] = useState(false)
  const projectID = stateService.params.projectID
  const defaultDate = moment().format('MM/DD/YYYY')

  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSupplementalBlockOpen, setIsSupplementalBlockOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const [departments, setDepartments] = useState<Department[]>([])
  const [selectedDepartment, setSelectedDepartment] = useState<Department>()
  const [resultDepartentEditData, setResultDepartentEditData] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [isOpenUnsavedChangesDialog, setIsOpenUnsavedChangesDialog] =
    useState(false)

  const [leveWithSaveOrUnsave, setLeveWithSaveOrUnsave] = useState('')
  const [pendingRoute, setPendingRoute] = useState<{
    to: string
    params?: any
    options?: any
  } | null>(null)
  const limit: number = 255

  const [projectData, setProjectData] = useState<{
    name: string
    number: string
    address: string
    city: string
    state: string
    zip_code: string
    industry: string
    company_representative: string
    client_name: string
    superintendent: string
    safety_manager: string
    project_manager: string
    contact_name: string
    proceed_date: string | null
    substantial_date: string | null
    end_date: string | null
    effective_date: string | null
    department: string | null
    department_object: any | null
    certified: boolean
    client: number
    comment: string
    contractor: string
    is_active: boolean
    sync_changes_to_procore: boolean
  }>({
    name: '',
    number: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    industry: '',
    company_representative: '',
    client_name: '',
    superintendent: '',
    safety_manager: '',
    project_manager: '',
    contact_name: '',
    proceed_date: null,
    substantial_date: null,
    end_date: null,
    effective_date: null,
    department: '',
    department_object: {},
    certified: false,
    client: 173,
    comment: '',
    contractor: '',
    is_active: true,
    sync_changes_to_procore: false,
  })

  const initialProjectData = useRef<typeof projectData | null>(null)

  useEffect(() => {
    if (!projectID) return
    setIsEditMode(true)
    setLoading(true)
    fetchData()
  }, [projectID])

  useEffect(() => {
    document.querySelectorAll('textarea.input-field').forEach((el) => {
      const textarea = el as HTMLTextAreaElement
      textarea.style.height = '33px'
      textarea.style.height = `${textarea.scrollHeight}px`
    })
  }, [projectData, isSupplementalBlockOpen])

  useEffect(() => {
    if (!leveWithSaveOrUnsave || !pendingRoute) return

    const params = pendingRoute.params?.app
      ? { app: pendingRoute.params.app }
      : {}

    router.stateService.go(pendingRoute.to, params, pendingRoute.options)

    if (leveWithSaveOrUnsave === 'save') {
      setIsFormDirty(false)

      if (!projectID && projectData.name.trim()) {
        handleCreateProject().then(() => {
          router.stateService.go(pendingRoute.to, params)
          setPendingRoute(null)
        })
      } else {
        handleUpdateProject().then(() => {
          router.stateService.go(pendingRoute.to, params)
          setPendingRoute(null)
        })
      }
    } else {
      setIsFormDirty(false)
      setTimeout(() => {
        router.stateService.go(pendingRoute.to, params)
        setPendingRoute(null)
      }, 0)
    }
  }, [leveWithSaveOrUnsave])

  useEffect(() => {
    const originalGo = router.stateService.go

    router.stateService.go = (to: any, params: any, options: any) => {
      if (isFormDirty) {
        setPendingRoute({ to, params, options })
        setIsOpenUnsavedChangesDialog(true)
        return
      }

      originalGo(to, params, options)
    }

    return () => {
      router.stateService.go = originalGo
    }
  }, [isFormDirty])

  useEffect(() => {
    if (!initialProjectData.current) return

    const cleanedProjectData = JSON.parse(JSON.stringify(projectData))
    const cleanedInitialData = JSON.parse(
      JSON.stringify(initialProjectData.current),
    )

    if (isEqual(cleanedProjectData, cleanedInitialData)) {
      setIsFormDirty(false)
    } else {
      setIsFormDirty(true)
    }
  }, [projectData])

  useEffect(() => {
    if (!projectID && initialProjectData.current === null) {
      initialProjectData.current = { ...projectData }
    }
  }, [projectData, projectID])

  useEffect(() => {
    if (!selectedDepartment || projectData.department === selectedDepartment.id)
      return

    if (selectedDepartment) {
      setProjectData((prevData) => ({
        ...prevData,
        department: selectedDepartment.id,
        department_object: selectedDepartment,
      }))
    }
  }, [selectedDepartment])

  useEffect(() => {
    if (
      selectedDepartment &&
      !departments.find((dep) => dep.id === selectedDepartment.id)
    ) {
      setSelectedDepartment(null)
      setProjectData((prevData) => ({
        ...prevData,
        department: null,
        department_object: null,
      }))
    }
  }, [departments, selectedDepartment])

  useEffect(() => {
    fetchDepartments()
  }, [])

  const fetchData = async () => {
    try {
      const response: any = await Api.get(`projects/${projectID}`)
      setProjectData(response.data)

      initialProjectData.current = { ...response.data }

      const deps = await fetchDepartments()

      const departmentObject = deps.find(
        (dep: { id: string }) => dep.id === response.data.department,
      )

      if (!selectedDepartment) {
        setSelectedDepartment(departmentObject ?? null)
      }
    } catch (error) {
      Notification.error('Error loading project data')
    } finally {
      setLoading(false)
    }
  }

  const fetchDepartments = async () => {
    try {
      const { data } = await Api.get(
        `client_departments`,
        {},
        undefined,
        undefined,
        true,
      )

      const sortedDepartments = data.sort((a: Department, b: Department) =>
        a.name.localeCompare(b.name),
      )

      setDepartments(sortedDepartments)
      return sortedDepartments
    } catch (error) {
      Notification.error('Error fetching departments:')
      return []
    }
  }

  const createDepartment = async (departmentName: string) => {
    if (!departmentName.trim()) {
      Notification.error('Department name cannot be empty')
      return
    }

    try {
      const newDepartment = {
        name: departmentName,
        client: CurrentUser.getClientId(),
      }

      setLoading(true)

      const { data } = await Api.postV2WithParams(
        'client_departments',
        newDepartment,
      )

      setDepartments((prev) => [...prev, data])
      setSelectedDepartment(data)

      Notification.success(
        `Department "${departmentName}" created successfully!`,
      )
    } catch (error) {
      Notification.error('Failed to create department')
    } finally {
      setLoading(false)
    }
  }

  const handleEditDepartment = (department: any) => {
    setResultDepartentEditData(department)
    setModalOpen(true)
  }

  const updateDepartment = async (department: any, updatedName: any) => {
    try {
      setLoading(true)
      const updatedDepartment = { name: updatedName }
      const { data } = await Api.patchV2(
        `client_departments/${department.id}`,
        updatedDepartment,
      )

      Notification.success(`Department updated successfully!`)

      setDepartments((prev) =>
        prev.map((dep) =>
          dep.id === department.id ? { ...dep, ...data } : dep,
        ),
      )

      setModalOpen(false)
      setResultDepartentEditData(null)
    } catch (error) {
      Notification.error('Failed to update department')
    } finally {
      setLoading(false)
    }
  }

  const handleCreateProject = async () => {
    setDisableButton(true)
    setLoading(true)
    setIsFormDirty(false)
    try {
      const response = await Api.post('projects', projectData)

      Notification.success('Project Successfully Created!')
      handleCancelProject()
    } catch (error) {
      Notification.error('Error! Failed to create the project.')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateProject = async () => {
    if (!projectID) return
    setIsFormDirty(false)
    setLoading(true)
    try {
      await Api.patch(`projects/${projectID}`, projectData)
      Notification.success('Project Successfully Updated!')
      handleCancelProject()
    } catch (error) {
      Notification.error('Error! Failed to update the project.')
    } finally {
      setLoading(false)
    }
  }

  const handleDateChange = (name: string, value: any) => {
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value ? value.format('MM/DD/YYYY') : null,
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setIsFormDirty(true)
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCancelProject = () => {
    stateService.go('admin.projects.list')
  }

  return (
    <section css={baseStyle(variableColor)}>
      <CommonPageHeader headerText={projectTitile} />
      <ConfigNavBar />

      <div className="form-container">
        <div className="buttonAndTitle-container">
          <h2 className="section-title">General Information</h2>

          {isEditMode ? (
            <>
              <div className="edit-buttons-container">
                <button
                  onClick={handleCancelProject}
                  type="submit"
                  className="cancel-button create-button ml-auto btn-default-general btn-default-primary"
                >
                  Back
                </button>
                <button
                  onClick={handleUpdateProject}
                  disabled={!projectData.name || !isFormDirty}
                  type="submit"
                  className="create-button ml-auto btn-default-general btn-default-primary"
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={handleCreateProject}
                disabled={disableButton || !projectData.name}
                type="submit"
                className="create-button ml-auto btn-default-general btn-default-primary"
              >
                Create
              </button>
            </>
          )}
        </div>

        <hr></hr>

        <div>
          {isOpenUnsavedChangesDialog ? (
            <UnsavedChangesDialog
              left="36%"
              top="17.3rem"
              title={'Unsaved changes on this page'}
              handleClick={(action: any) => {
                setLeveWithSaveOrUnsave(action)
                setIsOpenUnsavedChangesDialog(false)
              }}
              handleClose={() => {
                setIsOpenUnsavedChangesDialog(false)
              }}
            />
          ) : (
            <></>
          )}
        </div>

        <form className="project-form">
          <div className="form-row">
            <div className="form-group">
              <label>{projectName} Name *</label>
              <textarea
                name="name"
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                value={projectData.name}
                className={`input-field ${projectData.name.length > 0 ? '' : 'warmInput'
                  }`}
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.name?.length || 0)}</div>
            </div>
            <div className="form-group">
              <label>{projectName} Number</label>
              <textarea
                name="number"
                value={projectData.number}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                className="input-field"
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.number?.length || 0)}</div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Street</label>
              <textarea
                name="address"
                value={projectData.address}
                onChange={handleChange}
                className="input-field"
                style={{
                  minHeight: '33px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
            </div>
            <div className="form-group" style={{ marginBottom: '20px' }}>
              <div className="city_state_zip">
                <div className="form-group">
                  <label>City</label>
                  <textarea
                    name="city"
                    value={projectData.city}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value.length <= limit) {
                        handleChange(e)
                      }
                    }}
                    maxLength={limit}
                    className="input-field"
                    style={{
                      minHeight: '33px',
                      maxHeight: '300px',
                      overflowY: 'hidden',
                      resize: 'none',
                      boxSizing: 'border-box',
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement
                      target.style.height = '33px'
                      target.style.height = `${target.scrollHeight - 2}px`
                    }}
                  ></textarea>
                </div>

                <div className="state_zip">
                  <div className="form-group">
                    <label>State</label>
                    <textarea
                      name="state"
                      value={projectData.state}
                      onChange={(e) => {
                        const value = e.target.value
                        if (value.length <= limit) {
                          handleChange(e)
                        }
                      }}
                      maxLength={limit}
                      className="input-field small"
                      style={{
                        minHeight: '33px',
                        overflowY: 'hidden',
                        resize: 'none',
                        boxSizing: 'border-box',
                      }}
                      onInput={(e) => {
                        const target = e.target as HTMLTextAreaElement
                        target.style.height = '33px'
                        target.style.height = `${target.scrollHeight - 2}px`
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>ZIP</label>
                    <textarea
                      name="zip_code"
                      value={projectData.zip_code}
                      onChange={(e) => {
                        const value = e.target.value
                        if (value.length <= limit) {
                          handleChange(e)
                        }
                      }}
                      maxLength={limit}
                      className="input-field small"
                      style={{
                        minHeight: '33px',
                        overflowY: 'hidden',
                        resize: 'none',
                        boxSizing: 'border-box',
                      }}
                      onInput={(e) => {
                        const target = e.target as HTMLTextAreaElement
                        target.style.height = '33px'
                        target.style.height = `${target.scrollHeight - 2}px`
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group" style={{ position: 'relative' }}>
              <div className="department-title">
                <label>Department</label>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setModalOpen(true)
                  }}
                  color={variableColor}
                  className="addDepartment"
                >
                  + Department
                </button>
              </div>
              {(isModalOpen || resultDepartentEditData !== null) && (
                <CreateEditResultType
                  maxLength={512}
                  left="111px"
                  top="-5rem"
                  error_message="Cannot create duplicate departments"
                  title="Department"
                  resultTypes={departments}
                  editData={resultDepartentEditData}
                  handleClose={() => {
                    setResultDepartentEditData(null)
                    setModalOpen(false)
                  }}
                  handleClick={(newDepartment: any) => {
                    if (resultDepartentEditData) {
                      updateDepartment(resultDepartentEditData, newDepartment)
                    } else {
                      createDepartment(newDepartment)
                    }
                    setModalOpen(false)
                  }}
                />
              )}
              <DepartmentsSelect
                options={departments}
                setValue={(val) => {
                  setSelectedDepartment(val)
                  if (!val) {
                    setProjectData((prev) => ({
                      ...prev,
                      department: null,
                      department_object: null,
                    }))
                    setIsFormDirty(true)
                  }
                }}
                value={departments.find(
                  (dep) => dep.id === selectedDepartment?.id,
                )}
                width={'auto'}
                onEdit={handleEditDepartment}
                setOptions={setDepartments}
              />
            </div>
            <div className="form-group">
              <label>Industry</label>
              <textarea
                name="industry"
                value={projectData.industry}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                className="input-field"
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.industry?.length || 0)}</div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Company Representative</label>
              <textarea
                name="company_representative"
                value={projectData.company_representative}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                className="input-field"
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.company_representative?.length || 0)}</div>
            </div>
            <div className="form-group">
              <label>Client’s Name</label>
              <textarea
                name="client_name"
                value={projectData.client_name}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                className="input-field"
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.client_name?.length || 0)}</div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Supervisor</label>
              <textarea
                name="superintendent"
                value={projectData.superintendent}
                onChange={handleChange}
                className="input-field"
                style={{
                  minHeight: '33px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Safety Manager</label>
              <textarea
                name="safety_manager"
                value={projectData.safety_manager}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length <= limit) {
                    handleChange(e)
                  }
                }}
                maxLength={limit}
                className="input-field"
                style={{
                  minHeight: '33px',
                  maxHeight: '300px',
                  overflowY: 'hidden',
                  resize: 'none',
                  boxSizing: 'border-box',
                }}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement
                  target.style.height = '33px'
                  target.style.height = `${target.scrollHeight - 2}px`
                }}
              ></textarea>
              <div className="symbols-remains">{limit - (projectData.safety_manager?.length || 0)}</div>
            </div>
          </div>

          {/* Supplemental Information */}
          <div className="supplemental-container">
            <div
              className="supplemental-container-title"
              onClick={() => {
                setIsSupplementalBlockOpen((prev) => {
                  return !prev
                })
              }}
            >
              <h2 className="section-title">Supplemental Information</h2>
              <button
                type="button"
                className={`OpenOrCloseButton ${isSupplementalBlockOpen ? 'open' : ''
                  }`}
              >
                <svg
                  className="arrow-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="11"
                  viewBox="0 0 18 11"
                  fill={variableColor}
                >
                  <path d="M1.60909 0L0 1.67781L9 11L18 1.66838L16.3909 0L9 7.66324L1.60909 0Z" />
                </svg>
              </button>
            </div>

            <div
              className={`supplemental-container-open-or-closed ${isSupplementalBlockOpen ? 'open' : ''
                }`}
            >
              <div className="form-row">
                <div className="form-group">
                  <label>General Contractor</label>
                  <textarea
                    name="contractor"
                    value={projectData.contractor}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value.length <= limit) {
                        handleChange(e)
                      }
                    }}
                    maxLength={limit}
                    className="input-field"
                    style={{
                      minHeight: '33px',
                      maxHeight: '300px',
                      overflowY: 'hidden',
                      resize: 'none',
                      boxSizing: 'border-box',
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement
                      target.style.height = '33px'
                      target.style.height = `${target.scrollHeight - 2}px`
                    }}
                  ></textarea>
                  <div className="symbols-remains">{limit - (projectData.contractor?.length || 0)}</div>
                </div>
                <div className="form-group">
                  <label>{projectName} Manager</label>
                  <textarea
                    name="project_manager"
                    value={projectData.project_manager}
                    onChange={handleChange}
                    className="input-field"
                    style={{
                      minHeight: '33px',
                      overflowY: 'hidden',
                      resize: 'none',
                      boxSizing: 'border-box',
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement
                      target.style.height = '33px'
                      target.style.height = `${target.scrollHeight - 2}px`
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Contact Name</label>
                  <textarea
                    name="contact_name"
                    value={projectData.contact_name}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value.length <= limit) {
                        handleChange(e)
                      }
                    }}
                    maxLength={limit}
                    className="input-field"
                    style={{
                      minHeight: '33px',
                      maxHeight: '300px',
                      overflowY: 'hidden',
                      resize: 'none',
                      boxSizing: 'border-box',
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement
                      target.style.height = '33px'
                      target.style.height = `${target.scrollHeight - 2}px`
                    }}
                  ></textarea>
                  <div className="symbols-remains">{limit - (projectData.contact_name?.length || 0)}</div>
                </div>
              </div>

              <div className="time-form-row">
                <div className="form-group date-group">
                  <label>Notice to Proceed Date</label>
                  <DateInputField
                    defDate={projectData.proceed_date}
                    ohChange={(value) =>
                      handleDateChange('proceed_date', value)
                    }
                    width="100%"
                    height="100%"
                    icon={
                      <CalendarIconDepartment
                        height="20px"
                        width="18px"
                        color={variableColor}
                      />
                    }
                  />
                </div>
                <div className="form-group date-group">
                  <label className="substantial-completion-date">
                    Substantial Completion Date
                  </label>
                  <DateInputField
                    defDate={projectData.substantial_date}
                    ohChange={(value) =>
                      handleDateChange('substantial_date', value)
                    }
                    width="100%"
                    height="100%"
                    icon={
                      <CalendarIconDepartment
                        height="20px"
                        width="18px"
                        color={variableColor}
                      />
                    }
                  />
                </div>
                <div className="form-group date-group">
                  <label>Final Completion Date</label>
                  <DateInputField
                    defDate={projectData.end_date}
                    ohChange={(value) => handleDateChange('end_date', value)}
                    width="100%"
                    height="100%"
                    icon={
                      <CalendarIconDepartment
                        height="20px"
                        width="18px"
                        color={variableColor}
                      />
                    }
                  />
                </div>
                <div className="form-group date-group">
                  <label>Effective Date</label>
                  <DateInputField
                    defDate={projectData.effective_date}
                    ohChange={(value) =>
                      handleDateChange('effective_date', value)
                    }
                    width="100%"
                    height="100%"
                    icon={
                      <CalendarIconDepartment
                        height="20px"
                        width="18px"
                        color={variableColor}
                      />
                    }
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    name="comment"
                    value={projectData.comment}
                    onChange={handleChange}
                    className="input-field notes-field"
                    ref={(el) => {
                      if (el) {
                        el.style.height = '50px'
                        el.style.height = `${el.scrollHeight}px`
                      }
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement
                      target.style.height = '50px'
                      target.style.height = `${target.scrollHeight}px`
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

const baseStyle = (variableColor: string) =>
  css({
    paddingBottom: 'calc(5rem + 100px)',

    '@media(max-width: 1150px)': {
      ' .form-row, .time-form-row': {
        gap: '15px !important',
      },
      '.form-group, .city_state_zip, .form-row ': {
        width: '100%',
        flexDirection: 'column !important',
      },
      '.state_zip, .input-field.small': {
        maxWidth: '100% !important',
        width: '100%',
      },
      '.time-form-row': {
        display: 'flex !important',
        flexDirection: 'row !important',
        flexWrap: 'wrap',
      },
      '.date-group ': {
        minWidth: '16rem',
        maxHeight: '6.5rem',
      },
      '.department-title': {
        marginTop: '10px'
      },
    },

    '.buttonAndTitle-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '28px',
    },

    '.edit-buttons-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
    },

    '.cancel-button': {
      backgroundColor: '#ffffff !important',
      color: `${variableColor} !important`,
      border: `1px solid ${variableColor} !important`,
    },
    '.create-button': {
      minWidth: '87px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: '400',
      width: '87px',
      height: '28px',
    },

    '.section-title': {
      paddingLeft: '0',
      margin: '0px',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      letterSpacing: '-0.28px',
    },

    hr: {
      width: 'calc(100% + 40px)',
      marginLeft: '-20px',
      marginRight: '-20px',
      marginTop: '12px',
    },

    '.form-container': {
      marginLeft: '11px',
      background: '#fff',
      marginRight: '42px',
      padding: '13px 20px 20px 20px',
    },

    '.project-form': {
      display: 'flex',
      flexDirection: 'column',
      // gap: '20px',
    },

    '.department-title': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alingItems: 'center',
      justifyContent: 'start',
    },

    '.addDepartment': {
      border: '0',
      color: variableColor,
      marginBottom: '3px',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.24px',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      paddingTop: '0',
      paddingBottom: '0',
    },

    '.city_state_zip': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '10px',
    },

    '.state_zip': {
      gap: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '.form-row': {
      display: 'flex',
      gap: '30px',
    },

    '.form-group': {
      marginBottom: '0px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    '.small-group': {
      maxWidth: '100px',
    },

    '.warmInput': {
      borderColor: '#C80404 !important',
    },

    label: {
      fontSize: '12px',
      fontWeight: '600',
      marginBottom: '3px',
      letterSpacing: '-0.24px',
      fontFamily: 'Open Sans',
    },

    '.input-field': {
      resize: 'none',
      overflowY: 'hidden',
      padding: '10px',
      paddingTop: '5px',
      border: '1px solid #ccc',
      fontSize: '14px',
      width: '100%',
      color: '#000',
      fontFamily: 'Open Sans',
      fontSizeAdjust: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.13px',
    },

    '.input-field:focus': {
      outline: 'none',
    },

    '.input-field.small': {
      maxWidth: '94px',
    },

    '.supplemental-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },

    '.OpenOrCloseButton': {
      transform: 'rotate(180deg)',
      transition: 'transform 0.3s ease',
      border: '0',
      backgroundColor: '#ffffff00',
    },

    '.arrow-icon': {
      transition: 'transform 0.3s ease',
    },

    '.OpenOrCloseButton.open .arrow-icon': {
      transform: 'rotate(180deg)',
    },

    '.supplemental-container-title': {
      marginTop: '20px',
      height: '45px',
      width: 'calc(100% + 40px)',
      marginLeft: '-20px',
      marginRight: '-20px',
      backgroundColor: `${variableColor}1A`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '15px',
      paddingRight: '20px',
      gap: '20px',
    },

    '.date-group': {
      flex: 1,
      position: 'relative',
    },

    '.notes-field': {
      minHeight: '50px',
      height: 'auto',
      resize: 'none',
    },

    '.supplemental-container-open-or-closed': {
      display: 'none',
      flexDirection: 'column',
      gap: '20px',
    },

    '.departments-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginRight: '5px',
    },

    '.actions button': {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },

    '.supplemental-container-open-or-closed.open': {
      display: 'flex !important',
    },

    '.time-form-row': {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr 1fr 1fr 1fr',
      gap: '30px',
    },
    '.symbols-remains': {
      textAlign: 'end'
    },
  })

// function getSelectedStyles(
//   variableColor: string,
//   selected: boolean,
//   index: number,
//   isHighlighted: boolean,
// ) {
//   return css({
//     borderTop: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
//     borderBottom: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
//     backgroundColor: selected ? variableColor : 'white',
//     color: selected ? 'white' : 'black',
//     border: selected ? `1px solid ${variableColor}` : '1px solid #ccc',
//     zIndex: selected ? 2 : 1,
//     height: 'auto',

//     ':hover': {
//       backgroundColor: variableColor,
//       color: 'white',
//       border: `1px solid ${variableColor}`,
//       zIndex: 2,
//     },
//     svg: {
//       color: '#fff',
//     },

//     '& + .dropdown-item': selected ? { borderTop: '1px solid #ccc' } : {},
//   })
// }
