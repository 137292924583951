import * as React from 'react'
import { useAngularServices, SignatureAndSketch } from '@/react/components'
import { SingleSignature } from './index'
import { css } from '@emotion/react'

export function SignatureElement({ signatures }) {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isCanvasVisible, setCanvasVisible] = React.useState(false)
  const [signatureElementList, setSignatureElementList] = React.useState([])

  async function fileProcessing(file) {
    const index = signatures.length
    const { data: fileResponse } = await Api.uploadImage(file, {})
    signatures[index] = {
      image: fileResponse.id,
      url: fileResponse.image,
    }
    addSignatureElement(signatures[index])
  }

  React.useEffect(() => {
    const initialFields = []
    signatures.forEach((inittial, idx) => {
      initialFields.push(
        <SingleSignature key={idx} signatureLink={signatures[idx]} />,
      )
    })
    setSignatureElementList(initialFields)
  }, [signatures])

  function addSignatureElement(signatureLink) {
    setSignatureElementList([
      ...signatureElementList,
      <SingleSignature signatureLink={signatureLink} />,
    ])
  }

  return (
    <section css={baseStyle}>
      {isCanvasVisible ? (
        <SignatureAndSketch
          setCanvasVisible={setCanvasVisible}
          onSubmit={fileProcessing}
        />
      ) : null}
      <b>
        <h3
          className="add-signature"
          css={css({ color: variableColor })}
          onClick={() => {
            setCanvasVisible(true)
          }}
        >
          + Add Signature
        </h3>
      </b>
      <div className="signature-holder">{signatureElementList}</div>
    </section>
  )
}

const baseStyle = css({
  '.add-signature': {
    cursor: 'pointer',
  },
  '.signature-holder': {
    display: 'flex',
    flexWrap: 'wrap',
  },
})
