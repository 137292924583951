import * as React from 'react'
import { css } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Gray.svg'
import { TextInput } from '@/react/components'
import { PhotoModal } from './PhotoModal'

export function SingleFile({
  fileUrl,
  removeFunction,
  id,
  fileName,
  signatureOrSketch,
  signatureOrSketchTextCallback,
  isSignature,
}) {
  const [isDeleted, setIsDeleted] = React.useState(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  if (isDeleted) return null

  function deletePhoto() {
    removeFunction(id)
    setIsDeleted(true)
  }

  const baseStyle = getBaseStyles()

  return (
    <>
      {isModalOpen && isModalOpen && (
        <PhotoModal photoUrl={fileUrl} setIsModalOpen={setIsModalOpen} />
      )}
      <div css={baseStyle}>
        <div className="image-holder">
          <img
            src={fileUrl}
            alt=""
            onClick={() => {
              setIsModalOpen(true)
            }}
          />
          <div
            className="delete-button"
            onClick={() => {
              setIsDeleted(true)
              deletePhoto()
            }}
          >
            <img src={deleteCross} />
          </div>
          {!signatureOrSketch ? null : (
            <TextInput
              valueCallback={(value: string) => {
                signatureOrSketchTextCallback(value, id)
              }}
              noDelayValueCallback={(value: string) => {
                signatureOrSketchTextCallback(value, id, true)
              }}
              defaultValue={fileName}
              width={'400px'}
              waitTime={200}
              placeholder={isSignature ? 'Enter Name' : 'Enter sketch name'}
              autoFocus={true}
            />
          )}
        </div>
      </div>
    </>
  )
}
function getBaseStyles() {
  return css({
    position: 'relative',
    marginBottom: 20,
    marginRight: '2%',
    width: '30%',
    maxHeight: 300,
    '@media (max-width: 900px)': {
      width: '48%',
    },
    '@media (max-width: 820px)': {
      width: '100%',
      marginRight: 0,
    },
    '.image-holder': {
      position: 'relative',
      height: '100%',
      maxWidth: '100%',
      width: 'fit-content',
      marginRight: '3%',
      img: {
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
    '.delete-button': {
      cursor: 'pointer',
      position: 'absolute',
      right: 10,
      top: 10,
      borderRadius: '50%',
      backgroundColor: 'rgb(101,101,101)',
      color: 'white',
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        filter: 'brightness(1.5)',
        width: 15,
        height: 15,
      },
    },
  })
}
