import { useAngularServices } from '@/react/components'
import { DeletedRow } from './DeletedRow'
import { CurrentUserType } from '@/react/types/documentation'

export const TableBody = ({
  handleChecked,
  selectedItems,
  deletedList,
  isAllChecked,
  search,
  page,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const selectedIds = selectedItems.map((el) => el.id)

  return (
    <tbody>
      {!deletedList.length && (
        <tr css={{ height: '40px', width: '40px' }}>
          <td></td>
        </tr>
      )}
      {deletedList.map((item, index) => (
        <DeletedRow
          isAllChecked={isAllChecked}
          item={item}
          index={index}
          handleChecked={handleChecked}
          variableColor={variableColor}
          selectedIds={selectedIds}
          search={search}
          page={page}
        />
      ))}
    </tbody>
  )
}
