import { EnvolopeIcon } from '@/react/componentAssets/EnvelopeIcon'
import classNames from 'classnames'
import React, { useState } from 'react'
import { ConfirmationModal } from './ConfirmationModal'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import moment from 'moment'

export const TableRow = ({ report, index, color }) => {
  const {
    form,
    date_created,
    project,
    fields,
    client_object_key,
    external_contacts,
  } = report
  const { Api, Notification } = useAngularServices()
  const [hoveredField, setHoveredField] = useState(null)
  const [isModalVisible, setModalVisible] = useState(false)

  const handleLinkResend = async () => {
    try {
      await Api.post(`external_reports/${client_object_key}/resend_link`, {})
    } catch {
      Notification.error('Failed to resend the email')
    } finally {
      setModalVisible(false)
    }
  }
  return (
    <>
      <tr
        className={classNames('', {
          dark: index % 2 == 0,
        })}
      >
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'title__fullname',
              text: fields[0].text || form.name,
              max__length: 48,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to={'app.forms.reports_view'}
            params={{
              report: report.id,
            }}
          >
            <p className="title__text title">{fields[0].text || form.name}</p>
          </UISref>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'template__fullname',
              text: form.name,
              max__length: 31,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text template">{form.name}</p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name__fullname',
              text: external_contacts[0]?.external_contact
                ? `${external_contacts[0].external_contact.first_name} ${external_contacts[0].external_contact.last_name}`
                : '',
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text name">
            {external_contacts[0]?.external_contact &&
              `${external_contacts[0].external_contact.first_name} ${external_contacts[0].external_contact.last_name}`}
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: external_contacts[0]?.external_contact
                ? external_contacts[0].external_contact.company_name
                : '',
              max__length: 21,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text company">
            {external_contacts[0]?.external_contact &&
              external_contacts[0].external_contact.company_name}
          </p>
        </td>
        <td>
          <p
            className="title__text project"
            onMouseOver={() => {
              setHoveredField({
                field__name: 'project__fullname',
                text: project.name,
                max__length: 11,
              })
            }}
            onMouseOut={() => {
              setHoveredField(null)
            }}
          >
            {project.name}
          </p>
        </td>
        <td className="created">{moment(date_created).format('MM/DD/YYYY')}</td>
        <td
          className="envelope__td"
          onClick={() => {
            setModalVisible(true)
          }}
        >
          <EnvolopeIcon color={color} />
        </td>
      </tr>
      {isModalVisible && (
        <ConfirmationModal
          email={
            external_contacts[0]?.external_contact
              ? external_contacts[0].external_contact.email
              : ''
          }
          handleLinkResend={handleLinkResend}
          setModalVisible={setModalVisible}
        />
      )}
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
