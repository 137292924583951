import * as React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { flatMap } from 'lodash'
import { css } from '@emotion/react'

export function MapWithMarkers({ list, viewNaQuestions, width, height }) {
  const markers = flatMap(
    list.map((question, locIdx) => {
      return question.answer.photos
        .map(({ lat, lon, deleted }, photoIdx) => {
          if (deleted || !lat || !lon) return false
          const marker = {
            lat,
            lng: lon + photoIdx / 10000,
            markerImage: getImageMarker(question.answer),
            locIdx,
            label:
              String(viewNaQuestions ? question.order : locIdx + 1) +
              String.fromCharCode('A'.charCodeAt(0) + photoIdx),
          }
          return marker
        })
        .filter(Boolean)
    }),
  )
  return markers.length ? (
    <MapComponent markers={markers} width={width} height={height} />
  ) : null
}

function MapComponent({ markers, width, height }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBNQR4B4YEncw3axeFaMKHBBnciuxOND7g',
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    setMap(map)

    markers.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
      map.fitBounds(bounds)
    })
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  const mapVariables = css({
    width: width || 577,
    height: height || 350,
  })

  return isLoaded ? (
    <section css={baseStyle}>
      <div css={mapVariables} className="map-base">
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {markers.map(({ lat, lng, label, markerImage }) => (
            <Marker
              key={label}
              position={{ lat, lng }}
              label={label}
              icon={markerImage}
            />
          ))}
        </GoogleMap>
      </div>
    </section>
  ) : null
}

function getImageMarker({ answer, severity }) {
  if (answer === 'yes') return 'assets/map-markers-circles/map-circle-white.png'

  if (answer !== 'yes' && answer !== 'n/a') {
    switch (severity) {
      case 1:
        return 'assets/map-markers-circles/map-circle-green.png'
      case 2:
        return 'assets/map-markers-circles/map-circle-yellow.png'
      case 3:
        return 'assets/map-markers-circles/map-circle-red.png'
    }
  }
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

const baseStyle = css({
  marginBottom: '20px',
  textAlign: 'center',
  '.map-base': {
    display: 'inline-block',
    maxWidth: '100%',
    margin: '0px auto',
  },
  '.header-base': {
    height: '32px',
    paddingTop: 20,
    paddingBottom: 32,
    width: '100%',
    borderBottom: '1px solid #eeeeef',
    display: 'flex',
    justifyContent: 'center',
    '@media print': {
      display: 'none',
    },
    h2: {
      height: '60px',
      width: 'fit-content',
      padding: '0 10px',
      fontSize: '20px',
      fontWeight: 400,
      color: 'black !important',
      backgroundColor: 'white !important',
    },
  },
})
