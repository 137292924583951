import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'
import sortArrows from '@/assets/icons/sort-arrows.svg'
import sortArrowsActiveFirst from '@/assets/icons/sort-arrows-active.svg'
import sortArrowsActiveSecond from '@/assets/icons/sort-arrows-inactive.svg'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { ToolboxRow } from './components/ToolboxRow'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { useToolboxTitle } from '@/react/hooks/useToolboxTitle'
import { CurrentUserType } from '@/react/types/documentation'

export const AssignToolboxToGroup = () => {
  const { Api, CurrentUser } = useAngularServices()
  const [order, setOrder] = useState('name')
  const [toolboxList, setToolboxList] = useState([])
  const [assignedList, setAssignedList] = useState([])
  const [groupInfo, setGroupInfo] = useState(null)
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const { stateService } = useRouter()
  const groupId = stateService.params.group
  const [page, setPage] = useState(1)
  const [isAllChecked, setAllChecked] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)
  const toolboxTitle = useToolboxTitle()

  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(variableColor, isSafari)

  const handleCheckedAll = async () => {
    setAllChecked(!isAllChecked)

    await Api.post(`toolbox_topic_sets/${groupId}/assign_all_toolbox_topics`, {
      id: groupId,
      on: !isAllChecked,
      search: search,
    })

    setRefresh(true)
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'template',
      name: 'Toolbox Talks',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '18px',
        width: '711px',
        minWidth: '711px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          display: 'flex',
          gap: '4px',
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          position: 'relative',
        },
      },
    },
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '80px',
        minWidth: '80px',
        paddingLeft: 0,
        textAlign: 'left',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        p: {
          width: '0px',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '13px',
        },
      },
      additionalInnerElement: (
        <div className="select__wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            src={
              order === '-active_for_toolbox_group'
                ? sortArrowsActiveSecond
                : order === 'active_for_toolbox_group'
                ? sortArrowsActiveFirst
                : sortArrows
            }
            className="sort__icon"
            alt="sort icon"
            onClick={() => {
              setOrder(
                order === 'active_for_toolbox_group'
                  ? '-active_for_toolbox_group'
                  : 'active_for_toolbox_group',
              )
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getEmployeesToGroup = async () => {
      const { data } = await Api.get('toolbox_topics', {
        annotate_for_toolbox_group: groupId,
        application: stateService.params.app,
        order,
        search,
        page,
        is_active: true,
        deleted: false,
      })

      const { data: group } = await Api.get(`toolbox_topic_sets/${groupId}`, {})

      setToolboxList(data?.results)
      setAssignedList(group?.toolbox_topics)
      setCount(data.count)
      setRefresh(false)
      setGroupInfo(group)
    }

    getEmployeesToGroup()
  }, [order, page, search, refresh])

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={`${toolboxTitle} Templates`} />
      <ToolboxTemplatesNavBar />
      <div className="assign-employee-to-group">
        <div className="top__wrapper">
          <h4 className="project__name">Group: {groupInfo?.name}</h4>
          <ButtonElement
            buttonType="submit"
            text="Done"
            functionToTrigger={() => {
              stateService.go('app.toolboxes.groups', {
                pageNumber: stateService.params.previousPageNumber,
                search: stateService.params.previousSearch,
              })
            }}
            additionalStyles={css({ padding: 0 })}
          />
        </div>
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
          onClear={() => {
            setAllChecked(false)
          }}
        />
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {toolboxList?.map((toolbox, index) => (
                <ToolboxRow
                  key={toolbox?.id}
                  toolbox={toolbox}
                  assignedList={assignedList}
                  index={index}
                  search={search}
                  setRefresh={setRefresh}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor: string, isSafari: boolean) {
  return css({
    paddingBottom: '200px',
    '.assign-employee-to-group': {
      background: '#fff',
      paddingLeft: '20px',
      width: '834px',
      marginLeft: '5px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '814px',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingBottom: '10px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.project__name': {
      fontSize: '13px',
      margin: 0,
      maxHeight: '30px',
    },
    table: {
      width: '791px',
    },
    '.project__row': {
      position: 'relative',
      height: '40px',
    },
    '.name__td': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.group__row': {
      position: 'relative',
      height: '40px',
      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.1),
    },
    '.checkmark__td': {
      textAlign: 'center',
      minWidth: '80px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '.name__cell': {
      paddingLeft: '16px',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.template__row': {
      height: '40px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBAWithOpacity(variableColor, 0.2),
    },
    '.info__wrapper': {
      position: 'absolute',
      right: '15px',
      top: '-3px',
    },
    '.info__icon': {
      cursor: 'pointer',
    },
    '.sort__icon': {
      cursor: 'pointer',
      height: '17px',
    },
    '#search': {
      width: '264px',
    },
    '@media(max-width: 1050px)': {
      '.assign-employee-to-group': {
        width: '100%',
        paddingRight: '23px',
        overflowX: 'scroll',
      },
      table: {
        width: '100%',
      },
      '.name__td': {
        maxWidth: '204px',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
}
