import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const AddIcon = ({
  height = '20px',
  width,
  color = '#2268AF',
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M11.9 8.07146H9.6V5.76777C9.6 5.56411 9.51923 5.3688 9.37545 5.22479C9.23167 5.08078 9.03667 4.99988 8.83333 4.99988C8.63 4.99988 8.435 5.08078 8.29122 5.22479C8.14744 5.3688 8.06667 5.56411 8.06667 5.76777V8.07146H5.76667C5.56333 8.07146 5.36833 8.15236 5.22455 8.29637C5.08077 8.44038 5 8.6357 5 8.83935C5 9.04301 5.08077 9.23833 5.22455 9.38234C5.36833 9.52635 5.56333 9.60725 5.76667 9.60725H8.06667V11.8986C8.06667 12.1023 8.14744 12.2976 8.29122 12.4416C8.435 12.5856 8.63 12.6665 8.83333 12.6665C9.03667 12.6665 9.23167 12.5856 9.37545 12.4416C9.51923 12.2976 9.6 12.1023 9.6 11.8986V9.60725H11.9C12.1033 9.60725 12.2983 9.52635 12.4421 9.38234C12.5859 9.23833 12.6667 9.04301 12.6667 8.83935C12.6667 8.6357 12.5859 8.44038 12.4421 8.29637C12.2983 8.15236 12.1033 8.07146 11.9 8.07146Z"
        fill={color}
      />
      <rect
        x="0.45"
        y="0.45"
        width="17.1"
        height="17.1"
        rx="3.55"
        stroke={color}
        stroke-width="0.9"
      />
    </svg>
  )
}
