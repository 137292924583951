import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import moment from 'moment'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const CompletedRow = ({
  isAllChecked,
  assessment,
  index,
  handleChecked,
  selectedIds,
  setRefresh,
  search,
}) => {
  const [hoveredField, setHoveredField] = useState(null)
  const { CurrentUser, Api } = useAngularServices()

  const { external_contact } = assessment
  const { email, full_name, company_name } = external_contact

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const handlePublic = async () => {
    await Api.put(`assessment/assessments/${assessment?.id}/`, {
      is_sharing_allowed: !assessment?.is_sharing_allowed,
      template: assessment?.template,
    })
    setRefresh(true)
  }

  return (
    <tr
      className={classNames('', {
        dark: index % 2 === 0,
      })}
    >
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={selectedIds.includes(assessment.id) || isAllChecked}
            onChange={() => {
              handleChecked(assessment?.id)
            }}
          />
          <span className="checkmark">
            <TrainingsCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      <td
        className="link__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'template',
            text: assessment?.template_name,
            max__length: 27,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <UISref
          to="assessment_final_view"
          params={{
            client_object_key: assessment?.client_object_key,
          }}
          target="_blank"
        >
          <Highlighter
            highlightClassName={'highlighted__item'}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={assessment?.template_name}
          />
        </UISref>
      </td>
      <td
        className="result__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'result',
            text: assessment?.result,
            max__length: 20,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={assessment?.result}
        />
      </td>
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name',
            text: full_name,
            max__length: 19,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={full_name}
        />
      </td>
      <td
        className="email__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'email',
            text: email,
            max__length: 21,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={email}
        />
      </td>
      <td
        className="company__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'company',
            text: company_name,
            max__length: 26,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={company_name}
        />
      </td>
      <td>
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={moment(assessment?.date_completed).format(
            'MM/DD/YYYY',
          )}
        />
      </td>
      <td className="public__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={assessment?.is_sharing_allowed}
            onChange={handlePublic}
          />
          <span className="checkmark">
            <TrainingsCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
