import { EditIcon } from '@/react/componentAssets'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

interface Template {
  id: number
  count: number
  name: string
}

interface TemplateRowProps {
  template: Template
  index: number
  search: string
  page: number
}

export const TemplateRow = ({
  template,
  index,
  search,
  page,
}: TemplateRowProps) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const { stateService } = useRouter()
  const [hoveredField, setHoveredField] = useState({})

  return (
    <tr
      className={classNames('template__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__cell"
        onMouseOver={() => {
          setHoveredField({
            field_name: 'title__fullname',
            text: template?.name,
            max_length: 105,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={template?.name}
        />
        {hoveredField?.text?.length > hoveredField?.max_length && (
          <div className={`full__name ${hoveredField?.field_name}`}>
            {hoveredField?.text}
          </div>
        )}
      </td>
      <td>
        <div
          className="edit__cell"
          onClick={() => {
            stateService.go('app.toolboxes.assign_projects', {
              toolbox: template?.id,
              previousSearch: search,
              previousPageNumber: page,
            })
          }}
        >
          <EditIcon color={variableColor} height="17px" />
          <p className="projects__count">{template?.count}</p>
        </div>
      </td>
    </tr>
  )
}
