import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { NavBar } from '../TrainingsPages/commonComponents/Navbar'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import { CurrentUserType } from '@/react/types/documentation'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'

export const TrainingSettings = () => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const [isActive, setActive] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [userId, setUserId] = useState('')
  const [hasClicked, setHasClicked] = useState(false)

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const baseStyle = getBaseStyle(variableColor)
  const role = CurrentUser.getRole()

  const handleSettingsChange = async () => {
    try {
      await Api.patch(`general_settings/${userId}`, {
        hide_old_trainings: isActive,
      })
      setHasClicked(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getSettings = async () => {
      const { data } = await Api.get('general_settings', { deleted: false })
      setActive(data?.results[0].hide_old_trainings)
      setUserId(data?.results[0].id)
    }
    getSettings()
  }, [])

  useEffect(() => {
    if (role !== 'client_admin' && role !== 'trainee_manager') {
      stateService.go('trainings.documentation', {})
    }
  }, [])

  return (
    <section css={baseStyle}>
      <CommonPageHeader
        headerText={'Training Documentation'}
        iconLink="https://rtrspro.knowledgetransfer.us/#/views/TrainingDashboard-AS/TrainingDashboard?:iid=4"
      />
      <NavBar />
      <div className="settings__wrapper">
        <div className="top__wrapper">
          <div className="top__header">
            <ButtonElement
              buttonType="submit"
              text="Save"
              functionToTrigger={handleSettingsChange}
              disabled={!hasClicked}
              additionalStyles={css`
                &:disabled {
                  color: #ffffff !important;
                  background-color: #888888 !important;
                }
              `}
            />
          </div>
        </div>
        <div className="body__wrapper">
          <div className="body__container">
            <div className="info__block">
              <p className="input__limit">Hide Old Training</p>
              <InfoIcon
                color={variableColor}
                width="8"
                height="8"
                handleClick={() => {
                  setModalOpen(true)
                }}
              />
              {isModalOpen ? (
                <InfoModal
                  message="Hide older trainings on the employee public training page (QR Code) when a new training topic with the same name has been uploaded to the same employee."
                  setModalOpen={setModalOpen}
                  top="-55px"
                  right="-160px"
                />
              ) : null}
            </div>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isActive}
                onChange={() => {
                  setActive(!isActive)
                  setHasClicked(true)
                }}
              />
              <span className="checkmark">
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
          </div>
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor: string) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '300px',
    '.settings__wrapper': {
      backgroundColor: 'white',
      maxWidth: '567px',
      '@media (max-width: 768px)': {
        maxWidth: '383px',
      },
    },
    '.top__wrapper': {
      display: 'flex',
      padding: '20px',
      borderBottom: '1px solid #EEEFF3',
    },
    '.top__header': {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
    },
    '.header__text': {
      fontSize: '14px',
      margin: 0,
    },
    '.body__wrapper': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '20px',
    },
    '.body__container': {
      padding: '12px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: `${variableColor}10`,
    },
    '.input__limit': {
      color: '#676A6C',
      fontSize: '14px',
      margin: 0,
    },
    '.info__block': {
      position: 'relative',
      display: 'flex',
      gap: '2px',

      svg: {
        cursor: 'pointer',
      },
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      margin: 0,
      paddingLeft: 20,
      height: '22px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
  })
}
