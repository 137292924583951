import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { AddEditTrainingModal } from './AddEditTrainingModal'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useAngularServices } from '@/react/components'
import { ConfirmationModal } from './ConfirmationModal'
import { RightArrowIcon } from '@/react/componentAssets/RightArrowIcon'
import { CopyIcon } from '@/react/componentAssets/CopyIcon'
import { CurrentUserType } from '@/react/types/documentation'

export const SingleModule = ({
  module,
  index,
  setRefresh,
  page,
  setPage,
  listLength,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState(false)
  const [isHovered, setHovered] = useState(false)
  const [isCopyClicked, setCopyClicked] = useState(false)

  const handleCopy = async () => {
    await Api.post('training_new_copy', {
      training: module?.id,
    })
    setConfirmationModalVisible(false)
    setRefresh(true)
  }

  const handleDelete = async () => {
    await Api.delete(`trainings/${module.id}`, {})
    setConfirmationModalVisible(false)

    if (listLength === 1 && page !== 1) {
      setPage((prev) => prev - 1)
    }

    setRefresh(true)
  }

  return (
    <div className="row__wrapper">
      <UISref
        to={'trainings.topic'}
        params={{
          trainingID: module.id,
        }}
      >
        <div
          className={classNames('training', {
            dark: index % 2 !== 0,
            hovered: isHovered,
          })}
          key={index}
          onMouseEnter={() => {
            setHovered(true)
          }}
          onMouseLeave={() => {
            setHovered(false)
          }}
        >
          <p className="training__title">{module.name}</p>
          <div className="training__buttons">
            <EditIcon
              color={variableColor}
              onClick={(e) => {
                e.stopPropagation()
                setEditModalVisible(true)
              }}
            />
            <CopyIcon
              color={isHovered ? 'white' : variableColor}
              onClick={(e) => {
                e.stopPropagation()
                setCopyClicked(true)
                setConfirmationModalVisible(true)
              }}
            />
            <BinDeleteIcon
              color={variableColor}
              onClick={(e) => {
                e.stopPropagation()
                setCopyClicked(false)
                setConfirmationModalVisible(true)
              }}
            />
            <UISref
              to={'trainings.topic'}
              params={{
                trainingID: module.id,
              }}
            >
              <RightArrowIcon color={'#8B8B8B'} />
            </UISref>
          </div>
        </div>
      </UISref>
      {isEditModalVisible && (
        <AddEditTrainingModal
          isEdit={true}
          setModalVisible={setEditModalVisible}
          startingData={module}
          setRefresh={setRefresh}
        />
      )}
      {isConfirmationModalVisible && (
        <ConfirmationModal
          setModalVisible={setConfirmationModalVisible}
          module={module.name}
          handleClick={isCopyClicked ? handleCopy : handleDelete}
          isCopyClicked={isCopyClicked}
        />
      )}
    </div>
  )
}
