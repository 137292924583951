import * as React from 'react'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'

import { QuestionsList, TemplateHeader, Summery } from './templateParts'
import { BottomObservationNavigation, Signatures } from '../components'

export function TemplateBaseOne({
  baseStyle,
  imageUrl,
  baseObservationData,
  processedObservationData,
  app,
  viewNaQuestions,
  viewNaCategories,
  address,
}: any) {
  const { stateService } = useRouter()

  const lists = {
    no: Object.values(processedObservationData.no),
    pr: Object.values(processedObservationData.pr),
    pa: Object.values(processedObservationData.pa),
    cls: Object.values(processedObservationData.cls),
    yes: Object.values(processedObservationData.yes),
    'n/a': Object.values(processedObservationData['n/a']),
  }

  return (
    <section css={baseStyle}>
      <div id="to__print">
        {baseObservationData ? (
          <TemplateHeader
            headerData={baseObservationData}
            app={app}
            imageUrl={imageUrl}
          />
        ) : null}
        {baseObservationData && processedObservationData ? (
          <Summery
            headerData={baseObservationData}
            lists={lists}
            app={app}
            viewNaQuestions={viewNaQuestions}
          />
        ) : null}
        {processedObservationData ? (
          <QuestionsList
            lists={lists}
            app={app}
            viewNaQuestions={viewNaQuestions}
            viewNaCategories={viewNaCategories}
          />
        ) : null}
        {baseObservationData.signatures.length > 0 ? (
          <Signatures signatures={baseObservationData.signatures} />
        ) : null}
        <hr
          css={css({
            paddingTop: 10,
            paddingBottom: 9,
          })}
        />
        <div>{baseObservationData.client.general_settings.disclaimer}</div>
      </div>
      {stateService.params.key ? null : (
        <BottomObservationNavigation
          deleted={baseObservationData.deleted}
          observationData={baseObservationData}
        />
      )}
    </section>
  )
}
