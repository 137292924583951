import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'
import useClickOutside from '../hooks/useClickOutside'

export const DownloadInfoPopUp = ({ setPopUpVisible }) => {
  const popupRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(popupRef, setPopUpVisible)

  return (
    <div className="info__modal" css={baseStyle} ref={popupRef}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={() => {
          setPopUpVisible(false)
        }}
      />
      <div className="popup__title">
        Your download is in progress. Please do not refresh or exit the page
        until your download has completed.
      </div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '25px' })}
        functionToTrigger={() => {
          setPopUpVisible(false)
        }}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 1,
  width: '275px',
  left: '250px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginTop: '8px',
    marginBottom: '11px',
    paddingInline: '20px',
  },
})
