import * as React from 'react'
import { css } from '@emotion/react'
import { flatMap } from 'lodash'
import { useAngularServices } from '@/react/components'
import { WildCardsAndNotes } from './index'
import { CurrentUserType } from '@/react/types/documentation'

interface TableSummaryProps {
  headerData: any
  lists: any
  showGrade?: boolean
}

export function TableSummary({
  headerData,
  lists,
  showGrade = true,
}: TableSummaryProps) {
  const theme = headerData.client.report_settings.theme
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const baseStyle = getBaseStyle(variableColor)
  const headerStyle = getHeaderStyle(variableColor)

  return (
    <section css={baseStyle}>
      {(showGrade && theme === 2) || theme === 5 ? null : (
        <div className="grade-score">Grade: {getGradePerPercentage(lists)}</div>
      )}
      {showGrade && theme === 5 ? (
        <div className="grade-score">Grade: {getPercentageGrade(lists)}</div>
      ) : null}
      <div className="table-holder">
        <h3 css={headerStyle} className="header-style">
          Summary
        </h3>
        <table>
          <thead>
            <tr>
              <th className="category-th" rowSpan="2">
                {theme === 2 || theme === 3 ? null : 'CATEGORY'}
              </th>
              <th rowSpan="2" className="text-center count-th">
                COUNT
              </th>
              <th colSpan="3" className="text-center severity-th">
                SEVERITY
              </th>
            </tr>
            <tr className="text-center">
              <td className={theme === 3 ? ' ' : 'low'}>Low</td>
              <td className={theme === 3 ? ' ' : 'medium'}>Medium</td>
              <td className={theme === 3 ? ' ' : 'high'}>High</td>
            </tr>
          </thead>
          <tbody>
            {lists['no'][0] ? (
              <tr>
                <td className="first-word">Follow Up Required</td>
                <td className="text-center">{lists['no'].length}</td>
                <td className={'text-center' + (theme === 3 ? ' low' : ' ')}>
                  {getAmountBySeverity(lists['no'], 1)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' medium' : ' ')}>
                  {getAmountBySeverity(lists['no'], 2)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' high' : ' ')}>
                  {getAmountBySeverity(lists['no'], 3)}
                </td>
              </tr>
            ) : null}
            {lists['pr'][0] ? (
              <tr>
                <td className="first-word">Pending Review</td>
                <td className="text-center">{lists['pr'].length}</td>
                <td className={'text-center' + (theme === 3 ? ' low' : ' ')}>
                  {getAmountBySeverity(lists['pr'], 1)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' medium' : ' ')}>
                  {getAmountBySeverity(lists['pr'], 2)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' high' : ' ')}>
                  {getAmountBySeverity(lists['pr'], 3)}
                </td>
              </tr>
            ) : null}
            {lists['pa'][0] ? (
              <tr>
                <td className="first-word">Pending Approval</td>
                <td className="text-center">{lists['pa'].length}</td>
                <td className={'text-center' + (theme === 3 ? ' low' : ' ')}>
                  {getAmountBySeverity(lists['pa'], 1)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' medium' : ' ')}>
                  {getAmountBySeverity(lists['pa'], 2)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' high' : ' ')}>
                  {getAmountBySeverity(lists['pa'], 3)}
                </td>
              </tr>
            ) : null}
            {lists['cls'][0] ? (
              <tr>
                <td className="first-word">Issue Resolved</td>
                <td className="text-center">{lists['cls'].length}</td>
                <td className={'text-center' + (theme === 3 ? ' low' : ' ')}>
                  {getAmountBySeverity(lists['cls'], 1)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' medium' : ' ')}>
                  {getAmountBySeverity(lists['cls'], 2)}
                </td>
                <td className={'text-center' + (theme === 3 ? ' high' : ' ')}>
                  {getAmountBySeverity(lists['cls'], 3)}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="first-word">
                {headerData.application.observation_yes}
              </td>
              <td className="text-center">{lists['yes'].length}</td>
              <td colSpan={3} className="white-fill" />
            </tr>
          </tbody>
        </table>
      </div>
      {headerData.observation_notes &&
      checkNoteAmount(headerData.observation_notes) ? (
        <WildCardsAndNotes
          headerData={headerData}
          tableStyle={true}
          variableColor={variableColor}
          wildcard={false}
        />
      ) : null}
      {headerData.observation_wildcards &&
      checkNoteAmount(headerData.observation_wildcards) ? (
        <WildCardsAndNotes
          headerData={headerData}
          tableStyle={true}
          variableColor={variableColor}
          wildcard={true}
        />
      ) : null}
    </section>
  )
}

function getBathProjectNameForNote(id, observationArray) {
  let returnString
  observationArray.forEach((observation) => {
    if (observation.id === id) returnString = observation.project.name
  })
  return returnString
}

function getAmountBySeverity(array, value) {
  let count = 0
  array.forEach((question) => {
    if (question.answer.severity === value) {
      count += 1
    }
  })
  if (count === 0) {
    return ''
  } else {
    return count
  }
}

function checkNoteAmount(noteArray) {
  let count = 0
  noteArray.forEach((note) => {
    if (!note.deleted) count += 1
  })
  return count > 0
}

function getPercentageGrade(lists) {
  const totalQuestionAnsweredLength =
    lists['cls'].length +
    lists['pa'].length +
    lists['no'].length +
    lists['pr'].length +
    lists['yes'].length

  if (!lists['yes'].length) {
    return '0%'
  } else {
    return (
      ((lists['yes'].length / totalQuestionAnsweredLength) * 100).toFixed(1) +
      '%'
    )
  }
}

function getGradePerPercentage(lists) {
  const maxScore =
    (lists['no'].length +
      lists['cls'].length +
      lists['yes'].length +
      lists['pa'].length +
      lists['pr'].length) *
    10
  let sumSeverity = 0

  flatMap(lists).forEach((question) => {
    if (question.answer.answer === 'n/a' || question.answer.answer === 'yes') {
    } else {
      switch (question.answer.severity) {
        case 1:
          sumSeverity += 10
          break
        case 2:
          sumSeverity += 25
          break
        case 3:
          sumSeverity += 50
          break
      }
    }
  })
  const percentage = (sumSeverity / maxScore) * 100
  if (percentage >= 40) {
    return 'F'
  }
  if (percentage > 30) {
    return 'D'
  }
  if (percentage > 20) {
    return 'C'
  }
  if (percentage > 10) {
    return 'B'
  }
  return 'A'
}

function getBaseStyle(variableColor: string) {
  return css({
    marginBottom: '20px',
    color: 'black !important',
    '@media print': {
      paddingTop: 10,
      paddingBottom: 10,
    },
    th: {
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: variableColor,
      borderCollapse: 'collapse',
    },
    '.category-th': {
      paddingLeft: '10px',
      width: '35%',
    },
    '.count-th': {
      width: '15%',
    },
    '.severity-th': {
      width: '50%',
    },
    td: {
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: variableColor,
      borderCollapse: 'collapse',
      fontSize: '18px',
      fontWeight: 100,
      width: 0,
    },
    '.table-holder': {
      width: '800px',
      maxWidth: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    '.content__text': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    table: {
      width: '100%',
      '@media print': {
        width: '100%',
      },
      '.low': {
        backgroundColor: 'yellowgreen !important',
      },
      '.medium': {
        backgroundColor: 'yellow !important',
      },
      '.high': {
        backgroundColor: 'red !important',
      },
      thead: {
        width: '100%',
        th: {
          fontSize: '18px',
          fontWeight: 600,
        },
      },
      tbody: {
        '.low:empty, .medium:empty, .high:empty': {
          backgroundColor: 'white !important',
        },
      },
    },
    '.grade-score': {
      width: '100%',
      textAlign: 'center',
      fontSize: '36px',
      color: variableColor + ' !important',
    },
    '.first-word': {
      paddingLeft: '10px',
    },
    '.text-center': {
      textAlign: 'center',
    },
    '.white-fill': {
      backgroundColor: '#ffffff',
    },
  })
}

function getHeaderStyle(variableColor: string) {
  return css({
    width: '100%',
    backgroundColor: variableColor + ' !important',
    color: 'white !important',
    textAlign: 'center',
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    marginBottom: 0,
    fontSize: '24px',
    fontWeight: 100,
    '-webkit-print-color-adjust': 'exact',
    h2: {
      margin: 0,
    },
  })
}
