import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import viewIcon from '@/assets/icons/view-icon.svg'
import submitIcon from '@/assets/icons/submit-nav-icon.svg'
import uploadIcon from '@/assets/icons/upload-icon.svg'
import { useRouter } from '@/react/hooks'
import { useAngularServices } from '@/react/components'
import { useEffect } from 'react'

export const ConfigNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const role = CurrentUser.getRole()


  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'admin.projects.list'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.projects.list' || stateService.current.name === 'admin.projects.edit',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        {role === 'client_admin' && (
          <UISref to={'admin.projects.add'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'admin.projects.add',
              })}
            >
              <img
                className="nav__icon"
                src={submitIcon}
                alt="view nav__icon"
              />
              <p>Add</p>
            </a>
          </UISref>
        )}

        <UISref to={'admin.projects.bulkUpload'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.projects.bulkUpload',
            })}
          >
            <img className="nav__icon" src={uploadIcon} alt="view nav__icon" />
            <p>Upload</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  marginLeft: '11px',

  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginLeft: '18px !important',
        marginRight: '18px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginRight: '0 !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
    textAlign: 'center',
    height: '40px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '25px',
      width: '14px',
      height: '14px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '24px',
      marginBottom: '0px',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.13px'
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
