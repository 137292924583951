import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'

import { TextInputStyle } from '@screens/components'
import classNames from 'classnames'
import { BinDeleteIcon } from '@/react/componentAssets'
import { cloneDeep, debounce } from 'lodash'
import { generateUUID } from '@/utils'
import { ReportAnswer } from '@/react/types'

export function SingleVariableAmountField({
  fieldId,
  wholeAnswerLink,
  answersLink,
  errorRef,
  maxLength,
  typeOfField,
  setWholeAnswerLink,
}) {
  const { Api } = useAngularServices()

  const [isDeleted, setIsDeleted] = React.useState(
    answersLink[fieldId].deleted || false,
  )
  const [fieldState, setFieldState] = React.useState(answersLink[fieldId])
  const [textareaId, setTextareaId] = React.useState(generateUUID())
  const [error, setError] = React.useState(false)
  const [blocker, setBlocker] = React.useState(false)
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const handleChangeField = React.useRef(
    debounce(async (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setBlocker(true)
      const restOfData = {
        id: wholeAnswerLink.id,
        deleted: false,
        category: wholeAnswerLink.category,
        copy_sequence_number: wholeAnswerLink.copy_sequence_number,
        question: wholeAnswerLink.question,
      }
      const value = evt.target?.value
      const uuid = generateUUID()
      let getId = false
      if ((!value || value === '') && !fieldState.id) {
        fieldState.text = ''
        answersLink[fieldId].text = ''
        setBlocker(false)
        return
      } else if (value && value !== '' && fieldState.id) {
        fieldState.text = value
      } else if ((!value || value === '') && fieldState.id) {
        answersLink[fieldId].text = ''
        fieldState.text = uuid
        fieldState.deleted = true
      } else {
        fieldState.text = value
        getId = true
      }
      if (getId) {
        const toSend = { ...restOfData }
        toSend[typeOfField === 'note' ? 'notes' : 'corrective_actions'] = [
          {
            text: uuid,
          },
        ]
        const getIdResponse = await Api.post(
          `observations/` + wholeAnswerLink.observation + `/answers`,
          {
            answers: [toSend],
          },
        )
        getIdResponse.data[0][
          typeOfField === 'note' ? 'notes' : 'corrective_actions'
        ].forEach((field) => {
          if (field.text === uuid) {
            fieldState.id = field.id
          }
        })
        console.log(
          'we getting an ID',
          wholeAnswerLink.question +
            '-' +
            wholeAnswerLink.category +
            '-' +
            fieldId +
            '-' +
            fieldState.id,
        )
      }
      const toSend = { ...restOfData }
      toSend[typeOfField === 'note' ? 'notes' : 'corrective_actions'] = [
        fieldState,
      ]
      const fieldResponse = await Api.post(
        `observations/` + wholeAnswerLink.observation + `/answers`,
        {
          answers: [toSend],
        },
      )

      fieldResponse.data[0][
        typeOfField === 'note' ? 'notes' : 'corrective_actions'
      ].forEach((field) => {
        if (field.id === fieldState.id) {
          setFieldState(field)
          answersLink[fieldId] = field
          setWholeAnswerLink((prevState: { [key: string]: ReportAnswer[] }) => {
            if (!prevState) return
            const newState = prevState
            newState[wholeAnswerLink.question + '-' + wholeAnswerLink.category][
              wholeAnswerLink.copy_sequence_number
            ][typeOfField === 'note' ? 'notes' : 'corrective_actions'][
              fieldId
            ] = field
            return newState
          })
        }
      })
      setTimeout(() => {
        setBlocker(false)
      }, 100)
    }, 1250),
  ).current

  const deleteHandler = async () => {
    setBlocker(true)
    const restOfData = {
      id: wholeAnswerLink.id,
      deleted: false,
      category: wholeAnswerLink.category,
      copy_sequence_number: wholeAnswerLink.copy_sequence_number,
      question: wholeAnswerLink.question,
    }
    const toSend = { ...restOfData }
    toSend[typeOfField === 'note' ? 'notes' : 'corrective_actions'] = [
      {
        id: fieldState.id,
        text: generateUUID,
        deleted: true,
      },
    ]
    await Api.post(`observations/` + wholeAnswerLink.observation + `/answers`, {
      answers: [toSend],
    })
    setIsDeleted(true)
  }

  if (!answersLink[fieldId] || !answersLink[fieldId].text) {
    answersLink[fieldId] = {
      id: answersLink[fieldId] ? answersLink[fieldId].id : '',
      text: '',
      deleted: false,
    }
  }

  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }
  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
    // if (!answersLink[fieldId].text) {
    //   setError(true)
    // }
  }, [])

  if (isDeleted || answersLink[fieldId].deleted) return null

  return (
    <div
      css={baseStyle}
      className={'corrective_action'}
      key={
        wholeAnswerLink.question +
        '-' +
        wholeAnswerLink.category +
        '-' +
        fieldId +
        '-' +
        textareaId
      }
    >
      <textarea
        key={
          wholeAnswerLink.question +
          '-' +
          wholeAnswerLink.category +
          '-' +
          fieldId +
          '-' +
          textareaId
        }
        id={
          wholeAnswerLink.question +
          '-' +
          wholeAnswerLink.category +
          '-' +
          fieldId +
          '-' +
          textareaId
        }
        ref={textareaRef}
        className={classNames('form__textarea', {
          error:
            typeOfField === 'note'
              ? false
              : checkIfCAFirst({
                  answersLink,
                  fieldId,
                })
              ? checkCA({ wholeAnswerLink })
              : false,
        })}
        onFocus={adjustTextareaHeight}
        onInput={adjustTextareaHeight}
        style={{ height: `${textareaHeight}px` }}
        defaultValue={fieldState.text || ''}
        maxLength={maxLength}
        onChange={(value) => {
          setError(!value.target.value)
          handleChangeField(value)
        }}
        readOnly={blocker}
      />
      <BinDeleteIcon
        color={variableColor}
        onClick={deleteHandler}
        additionalStyles={css({ cursor: 'pointer' })}
      />
    </div>
  )
}

const checkCA = ({ wholeAnswerLink }): boolean => {
  if (wholeAnswerLink.answer !== 'cls') return false
  let filledCA = false
  wholeAnswerLink.corrective_actions.forEach((ca) => {
    if (ca.text && !ca.deleted) filledCA = true
  })
  return !filledCA
}
const checkIfCAFirst = ({ answersLink, fieldId }): boolean => {
  if (fieldId === 0 && !answersLink[fieldId].deleted) return true
  let isFirst = false
  answersLink.every(function (element, index) {
    if (!element.deleted) {
      return false
    }
    if (index === fieldId) {
      isFirst = true
      return false
    } else return true
  })
  return isFirst
}

const baseStyle = css({
  display: 'flex',
  marginBottom: 20,
  alignItems: 'center',
  svg: {
    paddingLeft: 5,
  },
  input: {
    ...TextInputStyle,
    width: '90%',
  },
  textarea: {
    ...TextInputStyle,
    resize: 'none',
    overflow: 'hidden',
    height: '34.5px',
  },
  '.delete-btn': {
    borderColor: '#e5e6e7',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    width: '34px',
    fontSize: '34px',
    img: {
      color: 'red',
      margin: 0,
      width: '12px',
      height: '12px',
      cursor: 'pointer',
    },
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
  '.error': {
    borderColor: 'red',
  },
})
