import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function costCodesConfig($stateProvider) {
  $stateProvider
    .state('admin.cost_codes_create', {
      url: '/cost_codes_create',
      template: '<create-cost-codes/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.cost_codes_update', {
      url: '/cost_codes_edit/:id?previousPageNumber&previousSearch',
      template: '<create-cost-codes/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.cost_codes_bulk', {
      url: '/cost_codes_bulk',
      template: '<bulk-cost-codes/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.cost_codes_list', {
      url: '/cost_codes_list?pageNumber&order&search&name&costcode_code&project_display',
      params: {
        order: { dynamic: true, value: 'name' },
      },
      template: '<list-cost-codes/>',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
}
