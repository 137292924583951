import { useState, useCallback } from 'react'
import {
  DateFilterReturn,
  useDateFiltersProps,
} from '../types/AdvancedDateFilter/filter'
import { useAngularServices } from '../components'
import { buildCombinedQueryString } from '@/utils/queryBuilder'

interface FetchFilteredDataParams {
  startDate: string
  endDate: string
  page: number
  advancedFilters: Record<string, any>
  apiVersion: 'v1' | 'v2'
  search: string
  order: string
  dateField?: string
  application?: string
  isDraft?: boolean
  tableParams?: boolean
  nonEmpty?: boolean
}

export const useDateFilter = ({
  url,
}: useDateFiltersProps): DateFilterReturn => {
  const { Api } = useAngularServices()
  const [filteredData, setFilteredData] = useState([])
  const [filteredCount, setFilteredCount] = useState(0)

  const fetchFilteredData = useCallback(
    async ({
      startDate,
      endDate,
      page,
      advancedFilters,
      apiVersion,
      search,
      order,
      dateField,
      application,
      isDraft,
      tableParams,
      nonEmpty,
    }: FetchFilteredDataParams) => {
      try {
        const params = {
          page: page,
          page_size: 20,
          start_date: startDate,
          end_date: endDate,
          deleted: false,
        }

        if (search) params.search = search
        if (order) params.order = order
        if (application) params.application = application
        if (isDraft === false) params.is_draft = isDraft
        if (dateField) params.date_field = dateField

        if (tableParams && nonEmpty) {
          params.table = tableParams
          params.non_empty = nonEmpty
        }

        const queryString = buildCombinedQueryString(params, advancedFilters)

        const finalQueryString = `${queryString}&eq(deleted,false)`

        let response

        switch (apiVersion) {
          case 'v1':
            response = await Api.getWithoutDeletedParam(url, finalQueryString)
            break

          case 'v2':
            response = await Api.getWithoutDeletedParamV2(url, finalQueryString)
            break

          default:
            response = await Api.getWithoutDeletedParamV2(url, finalQueryString)
        }

        const data = response.data.results
        const count = response.data.count

        setFilteredData(data)
        setFilteredCount(count)
      } catch (err) {
        console.log(err)
      }
    },
    [url],
  )

  return { filteredData, filteredCount, fetchFilteredData }
}

export default useDateFilter
