import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { QuestionNavBar } from '../components/QuestionsNavBar'
import { SelectWithSearch } from '../../components/SelectWithSearch'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../../components'
import { QuestionRow } from './components/QuestionRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { InfoModal } from '@/react/componentAssets/InfoModal'

export const ObservationQuestionsList = () => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [categories, setCategories] = useState([])
  const [questions, setQuestions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('order')
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [count, setCount] = useState(20)
  const [isSupervisor, setSupervisor] = useState(false)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isCustomCategory = questions?.every(
    (q) => q?.clientappquestion_to_category?.length > 0,
  )

  useEffect(() => {
    const role = CurrentUser.getRole()

    if (role === 'supervisor') {
      setSupervisor(true)
    }

    if (role === 'client_user') {
      stateService.go('app.company_news.list')
    } else if (
      role === 'read_only' ||
      role === 'trainee' ||
      role === 'trainee_manager'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [CurrentUser])

  useEffect(() => {
    const categoryIdFromUrl = stateService.params.categoryId;

    if (!categoryIdFromUrl) {
      return;
    }

    if (categories.length > 0) {
      const foundCategory = categories.find(category => +category.id === +categoryIdFromUrl);
      if (foundCategory && selectedCategory !== foundCategory.name) {
        setSelectedCategory(foundCategory.name);
      }
    }
  }, [stateService.params.categoryId, categories]);


  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'topic',
      name: 'Order',
      type: 'order',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        maxWidth: '80px',
        width: '80px',
        minWidth: '80px',
        height: '30px',
        padding: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          paddingLeft: '10px',
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: 0,
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
    },
    {
      className: 'originator',
      name: 'Questions',
      type: '',
      filterHeader: false,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        width: '500px',
        minWidth: '500px',
        textAlign: 'center',
        height: '30px',
        paddingLeft: '15px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-start',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'originator',
      name: 'Custom Categories',
      type: 'is_default',
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '140px',
        minWidth: '140px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
    },
    {
      className: 'originator',
      name: 'Delete',
      type: '',
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        paddingLeft: '0px',
        width: '80px',
        minWidth: '80px',
        textAlign: 'center',
        height: '30px',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        },
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
      additionalInnerElement: (
        <div className="info__wrapper">
          <InfoIcon
            width="10"
            height="10"
            handleClick={() => {
              setInfoModalVisible(true)
            }}
          />
          {isInfoModalVisible ? (
            <InfoModal
              setModalOpen={setInfoModalVisible}
              message="You can only delete custom questions"
              right="-50px"
            />
          ) : null}
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getCategories = async () => {
      let hasNextPage = true
      let page = 1

      while (hasNextPage) {
        const { data } = await Api.get('categories', {
          application: stateService.params.app,
          order: 'name',
          deleted: false,
          is_active: true,
          page_size: 1000,
          page: page,
        })

        if (data.next) {
          page += 1
        } else {
          hasNextPage = false
        }

        if (!categories?.length) {
          setSelectedCategory(data?.results[0]?.name)
        }

        setCategories([...categories, ...data.results])
      }
    }

    getCategories()
  }, [])

  useEffect(() => {
    if (!selectedCategory || categories.length === 0) {
      return;
    }

    const getQuestions = async () => {
      const selectedCategoryObject = categories.find(category => category?.name === selectedCategory);

      try {
        const { data } = await Api.get('questions/category_questions_list', {
          application: stateService.params.app,
          app_questions: true,
          category: selectedCategoryObject?.id,
          is_active: true,
          deleted: false,
          order,
          page,
          search,
        });

        setQuestions(data?.results);
        setRefresh(false);
        setCount(data.count);
      } catch {
        Notification.clearNotification();
      }
    };

    getQuestions();
  }, [selectedCategory, search, page, refresh, categories, order]);


  const styles = getStyles(variableColor)

  const visibleHeaders = useMemo(() => {
    return TABLE_HEADER_SETTINGS.filter((header) => {
      if (!isCustomCategory) {
        if (header.name === 'Order') {
          return false
        }
      }

      if (isSupervisor) {
        if (header.name === 'Custom Categories' || header.name === 'Order') {
          return false
        }
      }

      return true
    })
  }, [isCustomCategory, isSupervisor, isInfoModalVisible])

  return (
    <div css={styles}>
      <CommonPageHeader headerText="Questions" />
      <QuestionNavBar />
      <main className="questions__wrapper">
        <div className="top__section">
          <p className="question__title">Category</p>
          <SelectWithSearch
            width="351px"
            title=""
            options={categories}
            value={selectedCategory}
            setValue={setSelectedCategory}
            resetFunction={() => {
              setPage(1)
              setSearch('')
            }}
          />
        </div>
        <div className="search__wrapper">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={visibleHeaders}
              setOrder={setOrder}
              order={order}
              updateUrl={false}
              popUpLocation={false}
              callback={setOrder}
            />
            <tbody>
              {questions?.map((question, index) => (
                <QuestionRow
                  key={question?.id}
                  question={question}
                  search={search}
                  index={index}
                  isCustomCategory={isCustomCategory}
                  setRefresh={setRefresh}
                  page={page}
                  category={categories.find(
                    (category) => category?.name === selectedCategory,
                  )}
                  isSupervisor={isSupervisor}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </div>
  )
}

const getStyles = (variableColor: string) =>
  css({
    paddingBottom: '250px',
    '.questions__wrapper': {
      background: '#fff',
      marginLeft: '5px',
      paddingInline: '16px',
      width: 'fit-content',
      maxWidth: '832px',
    },
    tr: {
      height: '40px',
    },
    td: {
      height: '40px',
    },
    '.top__section': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      paddingTop: '20px',
    },
    '.question__title': {
      fontWeight: 700,
    },
    '.search__wrapper': {
      marginTop: '15px',
    },
    '#search': {
      width: '264px',
    },
    '.table__wrapper': {
      marginTop: '15px',
    },
    '.title__td': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: variableColor,
    },
    '.title__text': {
      width: 'fit-content',
      margin: 0,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: variableColor,
      maxWidth: '426px',
    },
    '.icon__td': {
      textAlign: 'center',
      position: 'relative',
    },
    '.link__td': {
      color: variableColor,
    },
    '.edit__td': {
      textAlign: 'center',
    },
    '.question__td': {
      paddingLeft: '15px',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    '.edit__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '3px',

      svg: {
        cursor: 'pointer',
      },
      a: {
        height: '18px',
      },
    },
    '.edit__count': {
      margin: 0,
      width: '20px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    p: {
      margin: 0,
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.switch__wrapper': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '.info__wrapper': {
      height: '21px',
      display: 'flex',
      paddingLeft: '4px',
      alignItems: 'flex-start',
      position: 'relative',
      svg: {
        cursor: 'pointer',
      },
    },
    '@media(max-width: 900px)': {
      '.questions__wrapper': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
    },
  })