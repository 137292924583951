import { ReactWrapper } from '@components'

import { CompanyInfo } from './CompanyInfo'
import { CompanyBilling } from './CompanyBilling'
import { CompanyLogo } from './CompanyLogo'
import { CompanyDisclaimer } from './CompanyDisclaimer'

//AdminCompanyConfig

export const AdminCompanyInfoComponent = ReactWrapper(
  CompanyInfo,
  'adminCompanyInfoComponent',
)

export const AdminCompanyBillingComponent = ReactWrapper(
  CompanyBilling,
  'adminCompanyBillingComponent',
)

export const AdminCompanyLogoComponent = ReactWrapper(
  CompanyLogo,
  'adminCompanyLogoComponent',
)

export const AdminCompanyDisclaimer = ReactWrapper(
  CompanyDisclaimer,
  'adminCompanyDisclaimerComponent',
)
