import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { InfoPopUp } from '@screens/MobileForms/SettingsPage/components'
import { CurrentUserType } from '@/react/types/documentation'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'
import { useState } from 'react'

type Props = {
  mobileForm: string
  project: string
  alertType: string
}

export const PopupBaseAndOpener = ({
  mobileForm,
  project,
  alertType = 'privacy',
}: Props) => {
  const { CurrentUser } = useAngularServices()

  const [popup, setPopup] = useState(false)

  const handleTogglePopup = () => {
    setPopup((prev) => !prev)
  }

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  return (
    <div css={baseStyle}>
      <InfoIcon color={variableColor} handleClick={handleTogglePopup} />
      {popup && (
        <InfoPopUp
          onClose={handleTogglePopup}
          mobileForm={mobileForm}
          project={project}
          alertType={alertType}
        />
      )}
    </div>
  )
}

function baseStyle() {
  return css({
    display: 'flex',
    marginLeft: '5px',
  })
}
