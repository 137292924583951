import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import logoIcon from '@/assets/icons/logo-nav-icon.svg'
import infoIcon from '@/assets/icons/info-nav-icon.svg'
import checkmarkInCircleIcon from '@/assets/icons/checkmark-in-circle-nav-icon.svg'
import { useRouter } from '@/react/hooks'
import { useAngularServices } from '@/react/components'
import { useEffect } from 'react'

export const ConfigNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const role = CurrentUser.getRole()

  useEffect(() => {
    if (role !== 'client_admin') {
      stateService.go('admin.company.logo')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'admin.company.logo'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.company.logo',
            })}
          >
            <img className="nav__icon" src={logoIcon} alt="view nav__icon" />
            <p>Logo</p>
          </a>
        </UISref>
        {role === 'client_admin' && (
          <UISref to={'admin.company.disclaimer'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'admin.company.disclaimer',
              })}
            >
              <img
                className="nav__icon"
                src={checkmarkInCircleIcon}
                alt="view nav__icon"
              />
              <p>Disclaimer</p>
            </a>
          </UISref>
        )}

        <UISref to={'admin.company.billing'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.company.billing',
            })}
          >
            <img className="nav__icon" src={infoIcon} alt="view nav__icon" />
            <p>Company Information</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  marginLeft: '5px',

  '@media(max-width: 800px)': {
    marginTop: '20px !important',

    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginLeft: '18px !important',
        marginRight: '18px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginRight: '0 !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
    textAlign: 'center',
    height: '40px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '25px',
      width: '14px',
      height: '14px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '25px',
      marginBottom: '0px',
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.13px'
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
