import { useState, useEffect } from 'react'
import { useRouter } from '@/react/hooks'

export const useStateServiceParams = (initialPage = 1, initialSearch = '') => {
  const [page, setPage] = useState(initialPage)
  const [search, setSearch] = useState(initialSearch)
  const [refresh, setRefresh] = useState(false)
  const { stateService } = useRouter()

  useEffect(() => {
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }

    const timeoutId = setTimeout(() => {
      setRefresh(true)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [stateService.params])

  return { page, setPage, search, setSearch, refresh, setRefresh }
}
