import * as React from 'react'
import { css } from '@emotion/react'
import {
  useAngularServices,
  CustomCheckbox,
  dropdownFiledTheme,
} from '@/react/components'
import { default as ReactSelect } from 'react-select'
import { TextInputStyle } from '@screens/components'
import { compare } from '@screens/Observations/CreateEditPage/components/dataLogic'

import { components } from 'react-select'
import { percentageShadeBlendColor } from '@/utils'
import { checkIndex } from '@components/commonStyles'
import { BinDeleteIcon } from '@/react/componentAssets'
import { DataWrap, ReportAnswer } from '@/react/types'
import { difference } from 'lodash'
import { DeleteModal } from '@/react/screens/TrainingsPages/TrainingTopic/components/DeleteModal'

const Option = (props: any) => {
  const { data, openDeleteModal } = props
  const secondarColor = data.secondarColor || '#10148A'

  return (
    <div>
      <components.Option {...props}>
        <div
          css={css({
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
          })}
        >
          <CustomCheckbox isFake={true} isDefaultChecked={props.isSelected} />{' '}
          <div
            className="hider"
            css={css({ width: 30, height: 27, marginLeft: -30, zIndex: 10000 })}
          />
          <label
            css={css({
              height: '22px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '22px',
              marginLeft: 10,
              marginBottom: '0 !important',
            })}
          >
            {props.label}
          </label>
          {props.data.user !== null && (
            <BinDeleteIcon
              color={props.isSelected ? 'white' : secondarColor}
              onClick={(e: any) => {
                e.stopPropagation()
                openDeleteModal(props.data)
              }}
            />
          )}
        </div>
      </components.Option>
    </div>
  )
}

type Props = {
  debouncedSend?: (value: ReportAnswer) => void
  answerState?: ReportAnswer
  options: { value: number; label: string }[]
  setOptions: (arg0: { label: string; value: string }[]) => void
  setGlobalPO: (arg0: any[]) => void
  partyObservedVariableFiled: string
  width?: string
}

export const PartyObservedField: React.FC<Props> = ({
  answerState,
  options,
  setOptions,
  partyObservedVariableFiled,
  setGlobalPO,
  width,
}: Props) => {
  const { Api, CurrentUser } = useAngularServices()
  const userId = CurrentUser.getId()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const secondaryColor = CurrentUser.getClientSettings().colors.secondary_color

  const [selectedOptions, setSelectedOptions] = React.useState<
    { value: string; label: string; id: number }[]
  >([])
  const [addPOString, setAddPOString] = React.useState<string>('')
  const [dropdownVisibility, setDropdownVisibility] = React.useState(false)
  const [input, setInput] = React.useState('')

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)
  const [deleteOption, setDeleteOption] = React.useState<any | null>(null)

  const openDeleteModal = (option: any) => {
    setDeleteOption(option)
    setIsDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setDeleteOption(null)
  }

  if (answerState) {
    answerState.party_observed = selectedOptions
  }

  function makePODataObject(startingArr) {
    if (!startingArr.length) return
    const preSelectedOption = []
    startingArr.forEach((po) => {
      if (!po.deleted && !po.deleted !== null) {
        preSelectedOption.push({
          value: po.party_observed.id,
          label: po.party_observed.name,
          id: po.id,
        })
      }
    })
    setSelectedOptions(preSelectedOption)
  }

  React.useEffect(() => {
    makePODataObject(
      answerState ? answerState.answer_party_observed_answer_list : [],
    )
  }, [])

  React.useEffect(() => {
    if (setGlobalPO) setGlobalPO(selectedOptions)
  }, [selectedOptions])

  const handleDeleteAdHocPartyObserved = async () => {
    if (!deleteOption) return

    try {
      await Api.patch(`answer_party_observed/${deleteOption.value}`, {
        deleted: true,
      })

      setOptions((prev) => prev.filter((o) => o.value !== deleteOption.value))
      setSelectedOptions((prev) =>
        prev.filter((o) => o.value !== deleteOption.value),
      )
    } catch (error) {
      console.error('Error deleting Party Observed:', error)
    } finally {
      closeDeleteModal()
    }
  }

  async function addUserPartyObserved(text: string) {
    if (text === '') return
    let alreadyExists = false
    options.forEach((option) => {
      if (option.label.toLowerCase() === text.toLowerCase())
        alreadyExists = true
    })
    if (alreadyExists) return
    const { data: response } = await Api.post('answer_party_observed', {
      name: text,
      user: userId,
      has_user: true,
    })
    setOptions([...options, { value: response.id, label: text }].sort(compare))
    handlePoAmountChange([
      ...selectedOptions,
      { value: response.id, label: text },
    ])
    setDropdownVisibility(false)
  }

  const handleInputChange = (inputValue) => {
    setAddPOString(inputValue)
  }

  const handlePoAmountChange = async (
    values: { value: string; label: string; id?: number }[],
  ) => {
    if (setGlobalPO) {
      setSelectedOptions(values)
      return
    }
    const add: boolean = selectedOptions.length < values.length
    const diff = add
      ? difference(values, selectedOptions)
      : difference(selectedOptions, values)
    if (add) {
      const {
        data: POAnswer,
      }: DataWrap<{ id: number; party_observed: { id: number } }> =
        await Api.post(`answer_party_observed_answer`, {
          answer: answerState.id,
          party_observed: diff[0].value,
        })
      values.forEach((po) => {
        if (po.value === POAnswer.party_observed.id) po.id = POAnswer.id
      })
    } else {
      await Api.patch(`answer_party_observed_answer/` + diff[0].id, {
        answer: answerState.id,
        party_observed: diff[0].value,
        deleted: true,
      })
    }
    setSelectedOptions(values)
  }

  const enhancedOptions = options.map((option) => ({
    ...option,
    secondarColor: secondaryColor || '#10148A',
  }))

  const baseStyle = getBaseStyle(variableColor, width)

  return (
    <div css={baseStyle}>
      <div
        className="input-focus-block"
        onClick={(e) => {
          if (!dropdownVisibility) setDropdownVisibility(true)
        }}
      >
        <label>
          {answerState ? '' : 'Global '}
          {partyObservedVariableFiled}:{' '}
          <a css={css({ color: variableColor, paddingLeft: 15 })}>
            {' '}
            + {partyObservedVariableFiled}
          </a>
        </label>
        {isDeleteModalOpen && deleteOption && (
          <div
            css={css({
              position: 'absolute',
              marginTop: '-3rem',
              left: '62.3vw',
              zIndex: '10000000',
            })}
          >
            <DeleteModal
              module={`Remove from list? ${deleteOption.label}`}
              handleClick={() => {
                console.log('11', closeDeleteModal)

                handleDeleteAdHocPartyObserved()
                closeDeleteModal
              }}
              handleClose={closeDeleteModal}
            />
          </div>
        )}
        {dropdownVisibility || selectedOptions.length === 0 ? (
          <div className="select-holder">
            <ReactSelect
              autoFocus
              placeholder={'Type or Choose from List'}
              onBlur={(e) => {
                if (dropdownVisibility) setDropdownVisibility(false)
                addUserPartyObserved(addPOString)
                setInput('')
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addUserPartyObserved(addPOString)
                }
              }}
              inputValue={input}
              onInputChange={(value, action) => {
                handleInputChange(value)
                // only set the input when the action that caused the
                // change equals to "input-change" and ignore the other
                // ones like: "set-value", "input-blur", and "menu-close"
                if (action.action === 'input-change') setInput(value) // <---
              }}
              components={{
                Option: (props) => (
                  <Option
                    {...props}
                    removeOption={handleDeleteAdHocPartyObserved}
                    openDeleteModal={openDeleteModal}
                  />
                ),
              }}
              defaultValue={selectedOptions}
              menuIsOpen={dropdownVisibility || selectedOptions.length !== 0}
              options={enhancedOptions}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(values) => {
                handlePoAmountChange(values)
              }}
              styles={selectStyle(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
            />
            <div className="button-holder">
              <div className="po-toggle-holder">{'>'}</div>
            </div>
          </div>
        ) : null}
      </div>
      {selectedOptions ? (
        <div>
          {selectedOptions.map((selectedOption, index) => (
            <div className="single-po" key={index}>
              <div className="single-po-text">{selectedOption.label}</div>
              <BinDeleteIcon
                color={variableColor}
                onClick={(e) => {
                  e.stopPropagation()
                  const newSelected = selectedOptions.filter(
                    (obj) => obj.value !== selectedOption.value,
                  )
                  handlePoAmountChange(newSelected)
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

const selectStyle = (variableColor) => {
  return {
    multiValue: (styles) => {
      return {
        ...styles,
        display: 'none',
      }
    },
    placeholder: (styles) => {
      return {
        ...styles,
      }
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        display: 'none',
      }
    },
    menu: (styles) => {
      return {
        ...styles,
        margin: 0,
      }
    },
    option: (styles, state) => {
      return {
        ...styles,
        color: 'inherit',
        cursor: 'pointer',
        backgroundColor: state.isSelected
          ? percentageShadeBlendColor(0.1, variableColor) + ' !important'
          : state.isFocused
          ? percentageShadeBlendColor(0.4, variableColor) + ' !important'
          : checkIndex(state.options, state.value) % 2 === 0
          ? 'white !important'
          : '#F2F3F5 !important',
        border: '1px solid #E6E6E6',
        '*': {
          cursor: 'pointer',
        },
        ':hover > div > svg': {
          color: 'white',
        },
        'label:last-of-type': {
          width: '95%',
          overflow: 'hidden',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: state.isSelected ? 'white' : 'inherit !important',
        },
      }
    },
  }
}

function getBaseStyle(variableColor, width = '100%') {
  return css({
    width: width,
    a: {
      fontWeight: 'normal',
    },
    '.party-observed-dropdown-trigger': {
      cursor: 'pointer',
      color: variableColor + ' !important',
      marginLeft: '10px',
    },
    '.po-toggle-holder': {
      fontSize: 14,
      color: '#797979',
      transform: 'rotate(90deg) scale(1,2)',
    },
    '.single-po': {
      display: 'flex',
      marginBottom: 5,
      alignItems: 'center',
      cursor: 'pointer',
      '.single-po-text': {
        ...TextInputStyle,
        border: 'none',
        wordBreak: 'break-word',
      },
    },
    '.select-holder': {
      position: 'relative',
      cursor: 'pointer',
      '.button-holder': {
        position: 'absolute',
        right: '2%',
        top: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        img: {
          height: '40%',
          cursor: 'pointer',
        },
        '.submit-po': {
          marginBottom: 0,
          a: {
            color: variableColor,
            textDecoration: 'none',
            marginBottom: 0,
            fontSize: '17px',
          },
        },
      },
    },
  })
}
