import { useEffect, useRef } from 'react'
import closeSvg from '@/assets/icons/close-icon.svg'

import { css } from '@emotion/react'
import useClickOutside from '@/react/hooks/useClickOutside'

export const LoginPopUp = ({ body, onClose }) => {
  const baseStyle = getBaseStyle()
  const modalRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(modalRef, onClose)

  return (
    <div css={baseStyle} ref={modalRef}>
      <div className="popup">
        <div className="popup__wrapper">
          <div className="popup__close" onClick={() => onClose(false)}>
            <img src={closeSvg} alt="close" />
          </div>
          <div className="popup__body">{body}</div>
        </div>
      </div>
    </div>
  )
}

function getBaseStyle() {
  return css({
    '.popup': {
      position: 'absolute',
      width: '462px',
      left: '40px',
      top: '119px',
    },

    '.popup__wrapper': {
      boxSizing: 'border-box',
      padding: '18px 53px 0px 53px',
      backgroundColor: '#fff',
      height: '100%',
      background: '#ffffff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: '20px',
    },

    '.popup__close': {
      position: 'absolute',
      right: '20px',
    },

    '@media(max-width: 500px)': {
      '.popup': {
        width: '100%',
        left: '0',
        padding: '0 15px',
      },
      '.popup__close': {
        top: '10px',
        right: '32px',
      },
      '.popup__wrapper': {
        padding: '5px 49px',
      },
    },

    '.popup__close img': {
      cursor: 'pointer',
    },
  })
}
