import { useEffect, useState } from 'react'
import { NavBar } from '../commonComponents/Navbar'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { DropZone } from './components/DropZone'
import { CurrentUserType } from '@/react/types/documentation'

export const BulkUploadPage = () => {
  const [file, setFile] = useState('')
  const [errorLog, setErrorLog] = useState([])
  const [messageLog, setMessageLog] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [isLoading, setFileLoading] = useState(false)
  const { stateService } = useRouter()

  const { Api, Notification, CurrentUser } = useAngularServices()

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  async function fileAddCallback(file) {
    const errorMessagesArray = []
    const messagesArray = []

    if (file) {
      setFileLoading(true)
      try {
        await Api.uploadBulkTraining(file)
      } catch (error) {
        setButtonDisabled(true)
        Notification.clearNotification()
        if (error?.data?.length === 1) {
          errorMessagesArray.push(<div>{error.data[0]}</div>)
          setErrorLog(errorMessagesArray)
          return
        }

        error?.data?.message?.forEach(({ error, row, message }) => {
          if (error) {
            const rowMsg = row ? `Line ${row}:` : ''

            const entriesArray = Object.entries(error).map(
              ([key, value]) => `${key}: ${value}`,
            )

            if (typeof error === 'object') {
              errorMessagesArray.push(
                <p className="message__info red">
                  {`${rowMsg} `}
                  {entriesArray.map((error) => (
                    <p className="error__text">{error}</p>
                  ))}{' '}
                </p>,
              )
            } else {
              errorMessagesArray.push(
                <p className="message__info red">{`${rowMsg} ${error}`}</p>,
              )
            }
          }

          if (message) {
            messagesArray.push(<p className="message__info">{message}</p>)
          }
        })
        setErrorLog([...errorMessagesArray])
        setMessageLog([...messagesArray])
      } finally {
        setFileLoading(false)
      }
    }
  }

  function fileRemoveCallback() {
    setErrorLog([])
    setMessageLog([])
    setButtonDisabled(false)
  }

  function sendFile() {
    stateService.go('trainings.documentation')
  }

  useEffect(() => {
    fileAddCallback(file)
  }, [file])

  return (
    <div css={baseStyle}>
      <CommonPageHeader headerText={'Training Documentation'} />
      <NavBar></NavBar>
      <div className="dropzone__wrapper">
        <a
          className="example"
          target="_blank"
          rel="noreferrer"
          href={'/assets/Training_Bulk_Upload_Template.csv'}
        >
          Example Document
        </a>
        <DropZone
          fileSetStorage={setFile}
          accept="text/csv"
          fileStorage={file}
          addCallback={fileAddCallback}
          removeCallback={fileRemoveCallback}
          isLoading={isLoading}
          additionalStyles={{
            margin: '0px',
            width: '686px',
            height: '312px',
            '.prop-name': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
            '.border-spread': {
              width: '686px',
              height: '312px',
            },
            '@media(max-width: 990px)': {
              width: '312px',
              height: '312px',
              '.border-spread': {
                width: '312px',
                height: '312px',
              },
            },
          }}
        />
        <div className="button__block">
          <ButtonElement
            text="Done"
            functionToTrigger={sendFile}
            buttonType="submit"
            disabled={!file}
          />
        </div>
        <div className="bulk__info">
          {messageLog.length ? (
            <div className="message-box">
              <label className="box__label">SUMMARY</label>
              {messageLog}
            </div>
          ) : null}
          {errorLog.length ? (
            <div className="error-box">
              <label className="box__label red">ERRORS</label>
              {errorLog}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const getBaseStyle = (variableColor: string) =>
  css({
    '.dropzone__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: '#fff',
      width: '746px',
    },
    '.button__block': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '92%',
      alignSelf: 'flex-end',
      marginTop: '14px',
      marginRight: '27px',
    },
    '.example': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
      margin: 0,
      marginTop: '14px',
      marginBottom: '5px',
      marginRight: '27px',
      color: variableColor,
      textDecorationLine: 'underline',
    },
    '.bulk__info': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '30px',
      marginBottom: '25px',
      alignSelf: 'flex-start',
    },
    '.message-box': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
    },
    '.error-box': {
      marginTop: '10px',
      marginBottom: '30px',
    },
    '.message__info': {
      color: '#575757',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      margin: 0,
      marginBottom: '10px',
    },
    '.box__label': {
      color: '#575757',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
      margin: 0,
    },
    '.red': {
      color: '#C80404',
      border: 'none',
    },
    '@media(max-width: 990px)': {
      '.dropzone__wrapper': {
        width: '336px',
      },
      '.button__block': {
        marginRight: '12px',
        gap: '55px',
      },
      '.example': {
        marginRight: '15px',
      },
      '.bottom__message': {
        fontSize: '12px',
      },
    },
    '.bottom__message': {
      display: 'flex',
      alignItems: 'center',
      color: '#C80404',
      margin: 0,
    },
    '.error__text': {
      margin: 0,
    },
  })
