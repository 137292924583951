import { ReactWrapper } from '@components'

import { ViewBase } from './ViewTemplates'
import { AssignedExternal, AnswerReadOnly } from './FullyExternalPages'
import { CreateEditBase } from './CreateEditPage'
import { PartyObservedCreate } from './PartyObservedCreate'
import { PartyObservedList } from './PartyObservedList'
import { PartyObservedBulk } from './PartyObservedBulk'
import { ObservationsList } from './ObservationsList'
import { DeletedObservationsList } from './DeletedObservationsList'
import { AddObservation } from './AddObservations'
import { ObservationCustomQuestions } from './ObservationCustomQuestions'
import { ObservationEditQuestion } from './ObservationEditQuestion'
import { CombinedObservationsFinalize } from './CombinedObservationsFinalize'
import { ObservationSettings } from './ObservationSettings'
import { CategoryGroups } from './Categories/CategoryGroups'
import { BatchObservations } from './BatchObservations'
import { AssignCategoriesToGroup } from './Categories/AssignCategoriesToGroup'
import { AssignProjectsToCategoriesList } from './Categories/AssignProjectsToCategoriesList'
import { AssignCategoriesToProjectList } from './Categories/AssignCategoriesToProjectList'
import { AssignCategoriesToProject } from './Categories/AssignCategoriesToProject'
import { AssignProjectsToCategory } from './Categories/AssignProjectsToCategory'
import { AssignQuestionToCustomCategories } from './AssignQuestionToCustomCategories'
import { ManageCategories } from './Categories/ManageCategories'

export const ViewBaseComponent = ReactWrapper(ViewBase, 'viewBase')
export const ViewEditBase = ReactWrapper(CreateEditBase, 'editBase')
export const ViewAssignedExternalComponent = ReactWrapper(
  AssignedExternal,
  'assignedExternal',
)
export const ViewAnswerReadOnly = ReactWrapper(AnswerReadOnly, 'answerReadOnly')
export const ViewPartyObservedCreate = ReactWrapper(
  PartyObservedCreate,
  'createPartyObserved',
)
export const ViewPartyObservedList = ReactWrapper(
  PartyObservedList,
  'listPartyObserved',
)
export const ViewPartyObservedBulk = ReactWrapper(
  PartyObservedBulk,
  'bulkPartyObserved',
)
export const ObservationsListComponent = ReactWrapper(
  ObservationsList,
  'observationsListComponent',
)
export const DeletedObservationsListComponent = ReactWrapper(
  DeletedObservationsList,
  'deletedObservationsListComponent',
)
export const ObservationAddComponent = ReactWrapper(
  AddObservation,
  'observationAddComponent',
)
export const ObservationCustomQuestionsComponent = ReactWrapper(
  ObservationCustomQuestions,
  'observationCustomQuestionsComponent',
)
export const ObservationEditQuestionComponent = ReactWrapper(
  ObservationEditQuestion,
  'observationEditQuestionComponent',
)
export const observationSettingsComponent = ReactWrapper(
  ObservationSettings,
  'observationSettingsComponent',
)
export const categoryGroupsComponent = ReactWrapper(
  CategoryGroups,
  'categoryGroupsComponent',
)

export const AssignProjectsToCategoriesListComponent = ReactWrapper(
  AssignProjectsToCategoriesList,
  'assignProjectsToCategoriesListComponent',
)

export const AssignCategoriesToProjectListComponent = ReactWrapper(
  AssignCategoriesToProjectList,
  'assignCategoriesToProjectListComponent',
)

export const AssignCategoriesToProjectComponent = ReactWrapper(
  AssignCategoriesToProject,
  'assignCategoriesToProjectComponent',
)

export const AssignProjectsToCategoryComponent = ReactWrapper(
  AssignProjectsToCategory,
  'assignProjectsToCategoryComponent',
)

export const batchObservationsComponent = ReactWrapper(
  BatchObservations,
  'batchObservationsComponent',
)
export const combinedFinalizeComponent = ReactWrapper(
  CombinedObservationsFinalize,
  'combinedFinalizeComponent',
)
export const AssignCategoriesToGroupComponent = ReactWrapper(
  AssignCategoriesToGroup,
  'assignCategoriesToGroupComponent',
)

export const AssignQuestionToCustomCategoriesComponent = ReactWrapper(
  AssignQuestionToCustomCategories,
  'assignQuestionToCustomCategoriesComponent',
)
export const ManageCategoriesComponent = ReactWrapper(
  ManageCategories,
  'manageCategoriesComponent',
)
