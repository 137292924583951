import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  useAngularServices,
} from '@/react/components'
import { NavBar } from '../LMSCommonComponents/NavBar'
import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { AddEditTrainingModal } from './components/AddEditTrainingModal'
import { SingleModule } from './components/SingleModule'

export const TrainingManage = () => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [modulesList, setModulesList] = useState([])
  const [count, setCount] = useState(20)

  const [isModalVisible, setModalVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const PAGE_SIZE = 20

  useEffect(() => {
    const getTrainingModules = async () => {
      try {
        const { data } = await Api.get('trainings', {
          app: 10,
          deleted: 'false',
          order: 'name',
          page: page,
          search: search,
          page_size: PAGE_SIZE,
          table: 'true',
        })

        setModulesList(data.results)
        setCount(data.count)
      } catch {
        console.log('Error')
      }
      setRefresh(false)
    }

    getTrainingModules()
  }, [refresh, search, page])

  const baseStyle = getBaseStyle(variableColor)

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="manage__wrapper">
        <div className="top__row">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
          <p
            className="module__button"
            onClick={() => {
              setModalVisible(true)
            }}
          >
            + Training Module
          </p>
          {isModalVisible && (
            <AddEditTrainingModal
              setModalVisible={setModalVisible}
              isEdit={false}
              setRefresh={setRefresh}
            />
          )}
        </div>
        <div className="trainings__list">
          {modulesList.map((module, index) => (
            <SingleModule
              key={index}
              module={module}
              index={index}
              setRefresh={setRefresh}
              listLength={modulesList.length}
              page={page}
              setPage={setPage}
            />
          ))}
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor: string) {
  return css({
    paddingBottom: '300px',
    '.manage__wrapper': {
      backgroundColor: '#fff',
    },
    '@media(min-width: 1010px)': {
      '.manage__wrapper': {
        width: '773px',
      },
    },
    '@media(max-width: 800px)': {
      '.top__row': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start !important',
      },
    },
    '#search': {
      width: '264px',
    },
    '.top__row': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      paddingTop: '15px',
      paddingLeft: '15px',
    },
    '.module__button': {
      color: variableColor,
      margin: 0,
      fontSize: '12px',
      cursor: 'pointer',
    },
    '.row__wrapper': {
      position: 'relative',
    },
    '.training': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingInline: '15px',
      borderTop: '1px solid #EEEFF3',
      borderBottom: '1px solid #EEEFF3',
      height: '45px',
    },
    '.dark': {
      background: '#FAFAFA',
      border: 'none',
    },
    '.hovered': {
      backgroundColor: variableColor,
      '.training__title': {
        color: 'white',
      },
      svg: {
        color: 'white',
      },
      img: {
        color: 'white',
      },
    },
    '.training__title': {
      margin: 0,
      color: '#575757',
      maxWidth: '600px',
      paddingRight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.trainings__list': {
      marginTop: '15px',
      paddingBottom: '20px',
    },
    '.training__buttons': {
      display: 'flex',
      gap: '25px',
    },
    '.arrow__icon': {
      paddingLeft: '10px',
      cursor: 'pointer',
    },
    svg: {
      height: '20px',
      width: '20px',
      cursor: 'pointer',
    },
    '.page-switcher-base': {
      paddingBottom: '1px',
    },
  })
}
