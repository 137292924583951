import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function clientAssessmentConfig($stateProvider) {
  $stateProvider
    .state('assessment', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      resolve: {
        ...resolvers.adminOnlyViewResolver,
        ...resolvers.appsResolver,
      },
    })
    .state('assessment.assessment_create', {
      url: '/assessment/create',
      template: `<create-assessment-component></create-assessment-component>`,
      controller: function () {},
    })
    .state('assessment.assessment_edit', {
      url: '/assessment/:assessmentTemplateId/edit',
      template: `<create-assessment-component></create-assessment-component>`,
      controller: function () {},
    })
    .state('assessment.assessment_manage', {
      url: '/assessment/manage',
      template: `<manage-assessments-component></manage-assessments-component>`,
      controller: function () {},
    })
    .state('assessment.assessment_draft', {
      url: '/assessment/draft',
      template: `<draft-assessments-component></draft-assessments-component>`,
      controller: function () {},
    })
    .state('assessment.assessment_score_key', {
      url: '/assessments/:assessmentTemplateId/score-key',
      template: `<assessment-score-key-component></assessment-score-key-component>`,
      controller: function () {},
    })
    .state('external_assessment', {
      controller: function () {},
      template: '<external-assessment-component />',
      url: '/external_assessment/:client_object_key/?draft',
      params: {
        client_object_key: {
          value: '',
          dynamic: true,
        },
        draft: {
          value: '',
          dynamic: true,
        },
      },
    })
    .state('assessment.assessment_completed', {
      url: '/assessments',
      template: `<completed-assessments-component></completed-assessments-component>`,
      controller: function () {},
    })
    .state('assessment_final_view', {
      controller: function () {},
      template: '<final-assessment-component />',
      url: '/share/assessment/:client_object_key',
      params: {
        client_object_key: {
          value: '',
          dynamic: true,
        },
      },
    })
}
