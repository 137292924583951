import { template } from 'lodash'
import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function adminCompanyConfig($stateProvider) {
  $stateProvider
    //Angular routes
    .state('admin.company', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
      data: {
        pageTitle: 'Company Administration',
      },
    })
    // .state('admin.company.logo', {
    //   url: '/company/logo',
    //   templateUrl: 'app/views/logo_form.html',
    //   controller: 'CompanyLogoCtrl',
    //   resolve: resolvers.adminOnlyViewResolver,
    // })
    // .state('admin.company.billing', {
    //   url: '/company/billing',
    //   templateUrl: 'app/views/billing_form.html',
    //   controller: 'CompanyBillingCtrl',
    //   resolve: resolvers.adminOnlyViewResolver,
    // })
    // React routes
    // .state('admin.company', {
    //   url: '/company',
    //   template: '<admin-company-info-component></admin-company-info-component>',
    //   controller: () => {},
    //   resolve: resolvers.adminOnlyViewResolver,
    // })
    .state('admin.company.logo', {
      url: '/company/logo',
      template: '<admin-company-logo-component></admin-company-logo-component>',
      controller: () => {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.company.billing', {
      url: '/company/billing',
      template:
        '<admin-company-billing-component></admin-company-billing-component>',
      controller: () => {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.company.disclaimer', {
      url: '/company/disclaimer',
      template:
        '<admin-company-disclaimer-component></admin-company-disclaimer-component>',
      controller: () => {},
      resolve: resolvers.adminOnlyViewResolver,
    })
}
