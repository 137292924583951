import { BinDeleteIcon } from '@/react/componentAssets'
import { AddNoteIcon } from '@/react/componentAssets/AddNoteIcon'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { Field, FieldArray, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import { SingleOption } from './SingleOption'
import { NestedOption } from './NestedOption'
import { useEffect, useState } from 'react'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { AddNoteModal } from './AddNoteModal'
import { EditNoteIcon } from '@/react/componentAssets/EditNoteIcon'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import manageIcon from '@/assets/icons/manage-icon.svg'
import { generateUUID, percentageShadeBlendColor } from '@/utils'
import { byOrder } from '@/utils/sortsAndFilter'
import { ColoredArrowUp } from '@/react/componentAssets/ColoredArrowUp'
import { ColoredArrowDown } from '@/react/componentAssets/ColoredArrowDown'
import React from 'react'
import { hexToRGBA } from '@/utils/generalUtilities.js'

type Question = {
  order: number
  required: boolean
  question_text: string
  field_type: { value: string; label: string }
  note: string
  id: string
  isSubHeaderCollapsed?: boolean
  isHeaderCollapsed?: boolean
}

type SetFieldType = (
  field: string,
  value: any,
  shouldValidate?: boolean,
) => Promise<void | FormikErrors<Values>>

export const TemplateQuestion = ({
  question,
  index,
  handleDelete,
  setButtonDisabled,
}) => {
  const { CurrentUser } = useAngularServices()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isNoteModalOpen, setNoteModalOpen] = useState(false)

  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { order, required, question_text, field_type } = question

  const selectOptions = [
    { value: 'header', label: 'Header' },
    { value: 'nested_option', label: 'Nested Option' },
    { value: 'options', label: 'Options' },
    { value: 'subheader', label: 'Subheader' },
    { value: 'text', label: 'Text' },
  ]

  const customSelectStyles = {
    control: (provided, state) => ({
      display: 'flex',
      width: '210px',
      minHeight: '32px',
      height: '32px',
      border: field_type ? '1px solid #e5e6e7' : '1px solid #C80404',
      background: '#FAFAFA',
      borderRadius: '0',
      outline: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
        border: '1px solid #e5e6e7',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      color: '#B1B1B1',
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '12px',
      borderBottom: '1px solid #E5E6E7',
      background: state.isSelected
        ? variableColor
        : state.options.indexOf(state.data) % 2 === 1
        ? '#FAFAFA'
        : 'none',
      '&:hover': {
        background: percentageShadeBlendColor(0.1, variableColor),
        color: 'white',
      },
    }),
  }

  const baseStyle = getBaseStyle(variableColor)

  const addOption = () => {
    const options = question.options ? [...question.options] : []
    setFieldValue(`questionsList[${index}].options`, [
      ...options,
      { name: '', weight: '', id: generateUUID() },
    ])
  }

  const addNestedOption = () => {
    const nestedOptions = question.nested_options
      ? [...question.nested_options]
      : []
    setFieldValue(`questionsList[${index}].nested_options`, [
      ...nestedOptions,
      {
        name: '',
        weight: '',
        nested_fields: [],
        id: generateUUID(),
      },
    ])
  }

  const handleHeaderBehavior = (state: boolean = false) => {
    if (['header', 'subheader'].includes(field_type?.value)) {
      setFieldValue(`questionsList[${index}]`, {
        ...question,
        [field_type?.value === 'header'
          ? 'isHeaderCollapsed'
          : 'isSubHeaderCollapsed']: state,
      })
    }
  }

  const updateOrder = async (indexGoTo: number) => {
    const items = Array.from(values.questionsList)
    const [reorderedItem] = items.splice(index, 1)
    items.splice(indexGoTo - 1, 0, reorderedItem)
    items.map((item, idx) => {
      item.order = idx + 1
      return item
    })

    await setFieldValue('questionsList', items)
  }

  useEffect(() => {
    setButtonDisabled(false)
  }, [values])

  const checkBoxDisabled =
    checkIfHeaderSibling(
      values.questionsList,
      index,
      false,
      field_type,
      true,
    ) ||
    checkIfHeaderSibling(values.questionsList, index, true, field_type, true)

  useEffect(() => {
    if (checkBoxDisabled && !question.required) {
      const changeStateByHeader = async () => {
        await setFieldValue(`questionsList[${index}].required`, true)
      }
      changeStateByHeader()
    }
  }, [values, question])

  if (
    checkIfHeaderSibling(values.questionsList, index, false, field_type) ||
    checkIfHeaderSibling(values.questionsList, index, true, field_type)
  )
    return null

  return (
    <Draggable key={question.id} draggableId={'' + question.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classNames('question__block', {
            header__collapsed:
              question?.isHeaderCollapsed || question?.isSubHeaderCollapsed,
          })}
          css={baseStyle}
        >
          <div
            className={classNames('question', {
              header:
                field_type?.value === 'header' ||
                field_type?.value === 'subheader',
            })}
          >
            <div
              className="label__field__manage"
              {...provided.dragHandleProps}
              style={{
                marginTop:
                  field_type?.value === 'header' ||
                  field_type?.value === 'subheader'
                    ? '0px'
                    : '7px',
              }}
            >
              <img src={manageIcon} alt="manage-icon" />
            </div>
            <div className="label__field order">
              <Field
                name={`questionsList[${index}].order`}
                className="order__input"
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.value = index + 1
                    setFieldValue(`questionsList[${index}].order`, index + 1)
                  }
                }}
                onChange={(e) => {
                  if (isNaN(e.target.value)) {
                    return
                  }

                  if (e.target.value > values.questionsList.length) {
                    setFieldValue(
                      `questionsList[${index}].order`,
                      values.questionsList.length,
                    )
                  } else {
                    setFieldValue(
                      `questionsList[${index}].order`,
                      e.target.value,
                    )
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    updateOrder(e.target.value)
                  }
                }}
                value={order}
              />
            </div>
            <div className="label__field required">
              <label className="checkbox-container">
                <Field
                  type="checkbox"
                  checked={required}
                  disabled={checkBoxDisabled}
                  name={`questionsList[${index}].required`}
                />
                <span className="checkmark">
                  <ThinCheckmark
                    color={checkBoxDisabled ? 'gray' : variableColor}
                  />
                </span>
              </label>
            </div>
            <div className="label__field assessment">
              <Field
                type="text"
                as={FormikAutoExpandedTextArea}
                name={`questionsList[${index}].question_text`}
                className={classNames('question__input', {
                  error: !question_text,
                })}
                placeholder="Enter Question"
                value={question_text}
              />
            </div>
            <div className="label__field select">
              <ReactSelect
                options={selectOptions}
                value={field_type}
                name={`questionsList[${index}].field_type`}
                onChange={(selectOption) => {
                  setFieldValue(`questionsList[${index}]`, {
                    ...question,
                    field_type: selectOption,
                    required: false,
                    options: [],
                    nested_options: [],
                    note: '',
                  })
                }}
                styles={customSelectStyles}
                isSearchable={false}
              />
            </div>
            {field_type?.value !== 'header' &&
            field_type?.value !== 'subheader' ? (
              <div className="label__field notes">
                {isNoteModalOpen ? (
                  <AddNoteModal
                    noteValue={question?.note}
                    name={`questionsList[${index}].note`}
                    handleClose={() => {
                      setNoteModalOpen(false)
                    }}
                  />
                ) : null}
                {!question.note ? (
                  <AddNoteIcon
                    color={variableColor}
                    onClick={() => {
                      setNoteModalOpen(true)
                    }}
                  />
                ) : (
                  <EditNoteIcon
                    color={variableColor}
                    onClick={() => {
                      setNoteModalOpen(true)
                    }}
                  />
                )}
              </div>
            ) : null}
            <div
              className={classNames('label__field delete', {
                header__delete:
                  field_type?.value === 'header' ||
                  field_type?.value === 'subheader',
              })}
            >
              {isDeleteModalOpen ? (
                <ConfirmationModal
                  action={'Delete'}
                  handleClick={() => {
                    handleDelete(index)
                    setDeleteModalOpen(false)
                    setFieldTouched('any', true)
                  }}
                  handleClose={() => {
                    setDeleteModalOpen(false)
                  }}
                />
              ) : null}
              <BinDeleteIcon
                color={variableColor}
                height="22px"
                width="17px"
                onClick={() => {
                  setDeleteModalOpen(true)
                }}
              />
            </div>
            {field_type?.value !== 'header' &&
            field_type?.value !== 'subheader' ? null : (
              <div className="header__arrow__wrapper">
                {question?.isHeaderCollapsed ||
                question?.isSubHeaderCollapsed ? (
                  <ColoredArrowDown
                    height="18"
                    width="18"
                    variableColor={variableColor}
                    onClick={() => {
                      handleHeaderBehavior(false)
                    }}
                  />
                ) : (
                  <ColoredArrowUp
                    height="18"
                    width="18"
                    variableColor={variableColor}
                    onClick={() => {
                      handleHeaderBehavior(true)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {['header', 'subheader'].includes(field_type?.value) &&
          (question.isHeaderCollapsed || question.isSubHeaderCollapsed) ? (
            <div className={classNames('question', {})}>
              <div className="fake__field label__field">
                <div className="order__input">...</div>
                <div className="line"></div>
              </div>
            </div>
          ) : null}
          {question?.options?.length ? (
            <FieldArray
              name={`questionsList[${index}].options`}
              render={(arrayHelpers) => {
                async function handleOnDragEnd(result) {
                  if (!result.destination) return

                  const items = Array.from(question.options)
                  const [reorderedItem] = items.splice(result.source.index, 1)
                  items.splice(result.destination.index, 0, reorderedItem)
                  items.forEach((item, idx) => {
                    item.order = idx + 1
                  })

                  await setFieldValue(`questionsList[${index}].options`, items)
                }

                return (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="options">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {question.options
                            .sort(byOrder)
                            .map((option, optionIndex) => (
                              <SingleOption
                                key={optionIndex}
                                oIndex={optionIndex}
                                qIndex={index}
                                option={option}
                                handleDelete={arrayHelpers.remove}
                              />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              }}
            />
          ) : null}
          {field_type?.value === 'options' ? (
            <p className="button" onClick={addOption}>
              + Option
            </p>
          ) : null}
          {field_type?.value === 'nested_option' ? (
            <p className="button" onClick={addNestedOption}>
              + Option
            </p>
          ) : null}
          {question?.nested_options?.length ? (
            <FieldArray
              name={`questionsList[${index}].nested_options`}
              render={(arrayHelpers) => {
                async function handleOnDragEnd(result) {
                  if (!result.destination) return

                  const items = Array.from(question.nested_options)
                  const [reorderedItem] = items.splice(result.source.index, 1)
                  items.splice(result.destination.index, 0, reorderedItem)
                  items.forEach((item, idx) => {
                    item.order = idx + 1
                  })

                  await setFieldValue(
                    `questionsList[${index}].nested_options`,
                    items,
                  )
                }

                return (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="nestedOptions">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {question.nested_options
                            .sort(byOrder)
                            .map((option, optionIndex) => (
                              <NestedOption
                                key={optionIndex}
                                oIndex={optionIndex}
                                qIndex={index}
                                option={option}
                                setFieldValue={setFieldValue}
                                handleDelete={arrayHelpers.remove}
                              />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              }}
            />
          ) : null}
        </div>
      )}
    </Draggable>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    '.question': {
      display: 'flex',
      paddingTop: '7px',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
      width: 'fit-content',
      alignItems: 'center',
    },
    '.header': {
      background: hexToRGBA(variableColor),
      alignItems: 'center',
      marginTop: '10px',
    },
    '.header__label': {
      fontSize: '12px',
      color: '#676A6C',
      margin: 0,
    },
    '.label__field': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.fake__field': {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      '.order__input': {
        marginLeft: 35,
      },
      '.line': {
        borderTop: '1px solid #D3D3D3',
        borderBottom: '1px solid #D3D3D3',
        height: 2,
        width: '85%',
        marginLeft: 80,
      },
    },
    '.label__field__manage': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      paddingRight: 5,
      marginRight: 10,
      img: {
        width: 20,
      },
    },
    '.order': {
      gap: '7px',
      marginRight: '25px',
      width: '35px',
    },
    '.order__input': {
      ...TextInputStyle,
      textAlign: 'center',
      width: '30px',
      background: '#FAFAFA',
      fontWeight: '600',
      fontSize: '12px',
      color: '#676A6C',
      border: '1px solid #B1B1B1',
    },
    '.required': {
      gap: '12px',
      marginRight: '25px',
      width: '26px',
      marginTop: '4px',
    },
    '.assessment': {
      gap: '7px',
      marginRight: '20px',
    },
    '.question__input': {
      ...TextInputStyle,
      overflow: 'hidden',
      background: '#FAFAFA',
      height: '32px',
      width: '641px',
      fontSize: '12px',

      '::placeholder': {
        color: '#B1B1B1',
      },
    },
    '.select': {
      gap: '7px',
      marginRight: '20px',
    },
    '.notes': {
      position: 'relative',
      gap: '10px',
      alignItems: 'center',
      marginRight: '20px',
      width: '35px',
    },
    '.delete': {
      position: 'relative',
      gap: '13px',
      alignItems: 'center',
      width: '40px',
    },
    '.header__arrow__wrapper': {
      display: 'flex',
      alignItems: 'flex-end',
    },
    '.error': {
      borderColor: '#C80404 !important',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FAFAFA',
      border: '1px solid #B1B1B1',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      border: 'none',
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    svg: {
      cursor: 'pointer',
    },
    '.button': {
      cursor: 'pointer',
      marginLeft: '192px !important',
      color: variableColor,
      fontSize: '12px',
      margin: 0,
      width: 'fit-content',
    },
    '.css-1xc3v61-indicatorContainer': {
      padding: '0px',
      paddingRight: '5px',
    },
    '.header__delete': {
      marginLeft: '55px',
    },
  })

const checkIfHeaderSibling = (
  questions: Question[],
  index: number,
  isSubheader: boolean,
  field_type: { value: string; label: string },
  requiredCheck: boolean = false,
): boolean => {
  if (index === 0) return false

  const headers = isSubheader ? ['header', 'subheader'] : ['header']
  if (headers.includes(field_type?.value)) return false

  let prevIndex = index - 1

  while (prevIndex >= 0) {
    const prevQuestion = questions[prevIndex]
    const isCollapsed = isSubheader
      ? prevQuestion.isSubHeaderCollapsed
      : prevQuestion.isHeaderCollapsed

    if (
      requiredCheck
        ? prevQuestion.required &&
          headers.includes(prevQuestion?.field_type?.value)
        : isCollapsed
    ) {
      return true
    } else if (headers.includes(prevQuestion?.field_type?.value)) {
      return false
    }

    prevIndex--
  }

  return false
}
