import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function appsQuestionsConfig($stateProvider) {
  $stateProvider
    .state('app.questions', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
    })
    .state('app.questions.list', {
      controller: 'QuestionsCtrl',
      templateUrl: 'app/views/question_list.html',
      url: '/questions?categoryId&pageNumber&search',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewQuestions'),
      },
    })
    .state('app.questions.my', {
      controller: 'QuestionsMyCtrl',
      templateUrl: 'app/views/question_list_my.html',
      url: '/questions/my?&pageNumber&search',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewMyQuestions'),
      },
    })
    .state('app.questions.projects', {
      controller: 'QuestionProjectsCtrl',
      templateUrl: 'app/views/question_projects.html',
      url: '/questions/:question/projects/:category/?previousPageNumber&pageNumber&order&reverse&search',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewQuestions'),
      },
    })
}
