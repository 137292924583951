import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  className?: string
  value: string
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  name: string
  placeholder?: string
  errorCondition?: boolean
  handleKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  minHeight?: number
  maxHeight?: number
  paddingTop?: number
}

export const AutoExpandTextArea = ({
  className = '',
  value,
  name,
  handleChange,
  placeholder = '',
  errorCondition = !value,
  handleKeyDown,
  minHeight = 23,
  maxHeight = 200,
  paddingTop = 1,
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const [textareaHeight, setTextareaHeight] = useState(`${minHeight}px`)

  useEffect(() => {
    if (textAreaRef.current) {
      const newHeight = Math.min(
        maxHeight,
        Math.max(minHeight, textAreaRef.current.scrollHeight),
      )
      setTextareaHeight(`${newHeight}px`)
    }
  }, [value])

  return (
    <textarea
      className={classNames(className, { error: errorCondition })}
      name={name}
      placeholder={placeholder}
      value={value}
      ref={textAreaRef}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      style={{
        height: `calc(${textareaHeight} - 2px)`,
        minHeight: `${minHeight}px`,
        maxHeight: `${maxHeight}px`,
        paddingTop: `${paddingTop}px`,
        overflowY: 'hidden',
        resize: 'none',
      }}
    />
  )
}
