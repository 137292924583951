import { flatMap } from 'lodash'
import { sortByAnswer } from './SortByAnswer'

/**
 * Interface for processed observation data
 */
interface ProcessedObservationData {
  no: Record<string, any>
  pr: Record<string, any>
  pa: Record<string, any>
  cls: Record<string, any>
  yes: Record<string, any>
  'n/a': Record<string, any>
  [key: string]: Record<string, any>
}

/**
 * Filters a list of questions based on the provided settings for NA questions and categories
 * and then sorts the filtered list using the sortByAnswer utility.
 *
 * @param questionsData - Object containing questions grouped by answer type
 * @param viewNaQuestions - Whether to show NA questions
 * @param viewNaCategories - Whether to show categories that only have NA questions
 * @returns A sorted and filtered list of questions
 */
export function filterAndSortQuestions(
  questionsData: ProcessedObservationData,
  viewNaQuestions: boolean,
  viewNaCategories: boolean,
): any[] {
  // Combine all questions into a single list
  const list = flatMap({
    ...questionsData.no,
    ...questionsData.pr,
    ...questionsData.pa,
    ...questionsData.cls,
    ...questionsData.yes,
    ...questionsData['n/a'],
  })

  // Get all category names that have non-N/A answers
  const answeredCategoryNames = list
    .filter((item: any) => item.answer.answer !== 'n/a')
    .map((item: any) => item.question.category.name)

  // Filter the list based on viewNaQuestions and viewNaCategories settings
  const filteredList = viewNaQuestions
    ? list
        .map((item: any) => {
          if (item.answer.answer === 'n/a') {
            return viewNaCategories
              ? answeredCategoryNames.length > 0
                ? item
                : null
              : answeredCategoryNames.includes(item.question.category.name)
              ? item
              : null
          }
          return item
        })
        .filter(Boolean)
    : list.filter((item: any) => item.answer.answer !== 'n/a')

  // Apply the same sorting as in QuestionsList
  return sortByAnswer(filteredList as any)
}
