import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { Department } from '../AddProject'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

type Props = {
  options: Department[]
  value: Department | undefined
  setValue: React.Dispatch<React.SetStateAction<Department | undefined>>
  disabled?: boolean
  resetFunction?: () => void
  width: string
  onEdit?: (departmentName: string) => void
  setOptions?: React.Dispatch<React.SetStateAction<Department[]>>
}

export const DepartmentsSelect = ({
  options,
  value,
  setValue,
  disabled = false,
  width,
  onEdit,
  setOptions,
  resetFunction = () => { },
}: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [departmentToDelete, setDepartmentToDelete] = useState<string | null>(
    null,
  )
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { CurrentUser, Api, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [popperWidth, setPopperWidth] = useState<number | null>(null)
  const [modalPosition, setModalPosition] = useState({
    top: '45rem',
    right: '35vw',
  })

  const selectOptions = useMemo(() => {
    return options.map((option) => option)
  }, [options])

  const confirmDeleteDepartment = (departmentId: string) => {
    setDepartmentToDelete(departmentId)
    setIsDeleteModalOpen(true)
  }

  const handleDeleteConfirmed = async () => {
    if (!departmentToDelete) return

    try {
      await Api.patchV2(`client_departments/${departmentToDelete}`, {
        deleted: true,
      })

      setOptions?.((prev) =>
        prev.filter((dep) => dep.id !== departmentToDelete),
      ) // оновлюємо список
      setValue((prevValue) =>
        prevValue?.id === departmentToDelete ? undefined : prevValue,
      )

      Notification.success('Department successfully deleted!')
    } catch (error) {
      Notification.error('Failed to delete department')
    } finally {
      setIsDeleteModalOpen(false)
      setDepartmentToDelete(null)
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      const inputElement = document.querySelector(
        '.MuiAutocomplete-root .MuiOutlinedInput-root',
      )
      if (inputElement) {
        setPopperWidth(inputElement.offsetWidth + 2)
      }
    }
    const updateModalPosition = () => {
      if (window.innerWidth < 1100) {
        setModalPosition({ top: '66rem', right: '17vw' })
      } else {
        setModalPosition({ top: '45rem', right: '41.6vw' })
      }
    }

    updateModalPosition()
    window.addEventListener('resize', updateModalPosition)
    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateModalPosition)
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        document.querySelectorAll('.MuiAutocomplete-option').forEach((el) => {
          ; (el as HTMLElement).style.minHeight = 'auto'
        })
      })
    }
  }, [open])

  const handleChange = (event: any, newValue: Department) => {
    resetFunction()
    setValue(newValue)
    setOpen(false)
  }

  const renderOption = (props, option, { selected, index }) => {
    const selectedStyles = getSelectedStyles(variableColor, selected, index)
    return (
      <li
        {...props}
        css={selectedStyles}
        key={`${option?.name}-${index}`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor:
            index % 2 === 0
              ? selected
                ? variableColor
                : '#F8F8F8'
              : selected
                ? variableColor
                : '#fff',
          padding: '5px',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '1',
            display: '-webkit-box',
            WebkitLineClamp: 10,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          <Highlighter
            highlightClassName={
              selected ? 'selected__item' : 'highlighted__item'
            }
            searchWords={[inputValue]}
            autoEscape={true}
            textToHighlight={option.name}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            flexShrink: 0,
          }}
        >
          <EditIcon
            color={selected ? '#fff' : variableColor}
            height="12px"
            onClick={(e: any) => {
              e.stopPropagation()
              onEdit && onEdit(option)
            }}
          />
          <BinDeleteIcon
            color={selected ? '#fff' : variableColor}
            height="12px"
            onClick={(e: any) => {
              e.stopPropagation()
              confirmDeleteDepartment(option.id)
            }}
          />
        </div>
      </li>
    )
  }

  return (
    <div className="single__select" css={baseStyle}>
      <Autocomplete
        open={open}
        onOpen={() => {
          if (options.length > 0) {
            setOpen(true)
          }
        }}
        onClose={() => setOpen(false)}
        readOnly={disabled}
        value={value ?? null}
        isOptionEqualToValue={(option, val) => option?.id === val?.id}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        sx={{
          display: options.length === 0 ? 'none' : 'block',
          width: `${width} !important`,
          border: '1px solid #ccc',
          background: disabled ? '#E6E6E6 !important' : 'rgb(250, 250, 250)',
          '@media (max-width: 900px)': {
            width: '317px',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: '15px',
            right: '9px',
            transform: open ? 'rotate(180deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s ease',
          },
          '& .MuiOutlinedInput-root': {
            paddingBottom: '0px !important',
            marginTop: '-10px !important',
            borderBottom: 'none !important',
          },
          '& .MuiAutocomplete-popper': {
            width: '476px !important',
            marginTop: '-1px !important',
            backgroundColor: '#fff',
            border: '1px solid #ccc !important',
            boxShadow: 'none !important',
          },
          '& .MuiAutocomplete-option': {
            padding: '5px 10px !important',
            borderBottom: '1px solid #ccc !important',
          },
          '& .MuiAutocomplete-option:last-child': {
            borderBottom: 'none !important',
          },
        }}
        style={{
          height: 34,
          border: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          background: 'rgb(255 255 255)',
        }}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
          setOpen(false)
        }}
        componentsProps={{
          popper: {
            sx: {
              width: popperWidth ? `${popperWidth}px !important` : 'auto',
              '& .MuiAutocomplete-listbox': {
                padding: '0px',
                maxHeight: '600px',
                overflowY: 'auto',
                borderRadius: '0px !important',
              },
              '& .MuiPaper-root': {
                boxShadow: 'none !important',
                borderRadius: '0px !important',
              },
              '& .MuiAutocomplete-option': {
                minHeight: '25px !important',
                maxHeight: '525px !important',
                lineHeight: '25px !important',
                padding: '0 10px !important',
              },
            },
          },
        }}
        disableClearable={false}
        id="controllable-states-demo"
        options={selectOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            sx={{
              '& .MuiAutocomplete-clearIndicator': {
                visibility: 'visible !important',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '13px',
                color: '#000',
                fontFamily: 'Open Sans',
                fontWeight: '400',
                lineHeight: 'normal',
                padding: '7.5px 4px 4.5px 3px !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            InputProps={{
              ...params.InputProps,
              placeholder: '',
            }}
          />
        )}
        renderOption={renderOption}
      />
      {isDeleteModalOpen && (
        <div className='deleteModalDepartment'>
          <ConfirmationModal
            title="Delete Department?"
            handleClick={handleDeleteConfirmed}
            handleClose={() => setIsDeleteModalOpen(false)}
          />
        </div>
      )}
    </div>
  )
}

function getSelectedStyles(
  variableColor: string,
  selected: boolean,
  index: number,
) {
  return css({
    border: '1px solid #ccc',
    borderTop: index !== 0 ? 'none' : '1px solid #ccc',
    maxHeight: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 300,
    fontSize: '12px',
    color: selected ? '#fff' : '#676A6C',
    backgroundColor: selected ? variableColor : '#fff',
    borderLeft: selected ? 'none' : '1px solid #ccc',
    borderRight: selected ? 'none' : '1px solid #ccc',

    svg: {
      marginRight: selected ? '1px !important' : '',
    },

    ':hover': {
      backgroundColor: `${variableColor} !important`,
      color: '#fff !important',
      marginLeft: '1px important',
      borderLeft: 'none',
      borderRight: 'none',
      svg: {
        marginRight: '1px !important',
        color: '#fff',
      },
    },

    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },

    '.selected__item': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
  })
}

const baseStyle = css({
  height: '34px',
  '@media(max-width: 900px)': {
    '.deleteModalDepartment': {
      left: '28rem !important'
    },
  },

  '.deleteModalDepartment': {
    position: 'absolute',
    top: '3%',
    left: '109%',
    zIndex: 9999,
  },


  '.select__title': {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '4px',
  },
})
