import { css } from '@emotion/react'
import * as React from 'react'
import { ButtonElement } from '@/react/components'

type Props = {
  onClose: () => void
  mobileForm: string
  project: string
  alertType: string
}

export const InfoPopUp = ({
  onClose,
  mobileForm,
  project,
  alertType = 'privacy',
}: Props) => {
  const baseStyle = getBaseStyle(alertType)

  return (
    <div css={baseStyle}>
      <div className="background-holder">
        <div className="background" onClick={onClose} />
      </div>
      <div className="alert-holder">
        <div className="alert-base">
          {alertType === 'privacy' ? (
            <div className="text-base">
              <label className="text-uppercase text-center">
                Privacy Setting
              </label>
              <ul className="info-list">
                <li className="hyphen-marker">
                  Inactive allows the author, supervisors, and administrators to
                  view the report. Public link sharing is available.
                </li>
                <li className="hyphen-marker">
                  Active only allows the author and administrators to view the
                  report. Public link sharing is disabled.
                </li>
                <li className="hyphen-marker">
                  If your {mobileForm} is not listed, please be sure it is
                  assigned to at least one {project}.
                </li>
              </ul>
            </div>
          ) : null}
          {alertType === 'alert' ? (
            <div className="text-base">
              <div className="info-block">
                Users who are selected will receive an alert via email when the
                form is completed or edited. The user must be assigned to that
                project
              </div>
            </div>
          ) : null}
          {alertType === 'email' ? (
            <div className="text-base">
              <div className="info-block">
                Customize the email external parties receive after submitting
                this report.
              </div>
            </div>
          ) : null}
          <div className="button-holder">
            <ButtonElement
              buttonType={'submit'}
              text={'OK'}
              functionToTrigger={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function getBaseStyle(alertType) {
  return css({
    '.background-holder': {
      height: 0,
      width: 0,
      '.background': {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        right: 0,
        top: 0,
        zIndex: 100,
      },
    },
    '.alert-holder': {
      height: 0,
      width: 0,
      position: 'relative',
      '.alert-base': {
        paddingBottom: 25,
        position: 'absolute',
        width: alertType === 'privacy' ? 446 : 252,
        right: 5,
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        zIndex: 200,
        '.text-base': {
          paddingTop: 25,
          paddingBottom: 10,
          ul: {
            textAlign: 'left',
            paddingRight: 28,
            li: {
              fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
              fontStyle: 'normal',
              fontWeight: 'normal',
              letterSpacing: 'normal',
              marginBottom: 15,
            },
          },
          '.info-block': {
            textAlign: 'center',
            fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            fontSize: '14px',
            marginBottom: 15,
            paddingLeft: 30,
            paddingRight: 30,
          },
        },
        '.button-holder': {
          fontWeight: 400,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  })
}
