import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { Field, FieldArray, useFormikContext } from 'formik'
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  NodeSelection,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'
import classNames from 'classnames'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { useAngularServices } from '@/react/components'
import { ScoreRange } from './ScoreRange'
import { useEffect, useState } from 'react'

import { ColoredArrowUp } from '@/react/componentAssets/ColoredArrowUp'
import { ColoredArrowDown } from '@/react/componentAssets/ColoredArrowDown'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { ExternalLinkModal } from './ExternalLinkModal'
import { InfoModal } from '@/react/componentAssets/InfoModal'
import { InfoIcon } from '@/react/componentAssets/InfoIcon'

export const SingleScoring = ({
  score,
  index,
  serverResultTypes,
  setResultTypesRefresh,
}) => {
  let range
  let selection = new NodeSelection()

  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const { Api, CurrentUser } = useAngularServices()
  const [isPopUpVisible, setPopUpVisible] = useState(false)
  const [isSectionOpen, setSectionOpen] = useState(false)
  const [isLinkModalOpen, setLinkModalOpen] = useState(false)
  const [saveSelection, setSaveSelection] = useState()
  const [rteObj, setRteObj] = useState()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'FullScreen',
      '|',
      'Undo',
      'Redo',
    ],
  }

  const quickToolbarSettings = {
    image: [
      'Replace',
      'Align',
      'Caption',
      'Remove',
      'InsertLink',
      'OpenImageLink',
      '-',
      'EditImageLink',
      'RemoveImageLink',
      'Display',
      'AltText',
      'Dimension',
    ],
    link: ['Open', 'Edit', 'UnLink'],
  }

  const baseStyle = getBaseStyle(variableColor)

  const handleExternalIconClick = () => {
    setLinkModalOpen(true)
  }

  const selectionSave = () => {
    range = selection.getRange(document) // get the range
    setSaveSelection(selection.save(range, document))
    if (rteObj.formatter.getUndoRedoStack().length === 0) {
      rteObj.formatter.saveData()
    }
  }

  const OnCreated = () => {
    document
      .getElementById('custom_tbarbtn_3')
      .addEventListener('mousedown', selectionSave.bind(this))
  }

  const handleExternalLink = (displayText: string) => {
    setLinkModalOpen(false)
    saveSelection.restore()

    if (rteObj.formatter.getUndoRedoStack().length === 0) {
      rteObj.formatter.saveData()
    }
    saveSelection.restore()
    rteObj.executeCommand(
      'insertHTML',
      `<a href="{{ share_link }}">${displayText}</a>`,
    )
    rteObj.formatter.saveData()
    rteObj.formatter.enableUndo(rteObj)
  }

  document.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
    })
  })

  const emailToolBarSettings = {
    items: [
      ...toolbarSettings.items,
      {
        template:
          '<svg xmlns="http://www.w3.org/2000/svg" style="height:17px; width: 17px" id="custom_tbarbtn_3" viewBox="0 0 10 12" fill="none"><path d="M7.8845 9.62398H7.01462C6.95313 9.62398 6.89415 9.64841 6.85066 9.6919C6.80718 9.73538 6.78275 9.79436 6.78275 9.85586H6.78181V10.2593H1.33443V3.94191H3.7099C3.7714 3.94191 3.83038 3.91748 3.87386 3.874C3.91735 3.83051 3.94178 3.77153 3.94178 3.71004V1.33457H6.78167V6.20748H6.78315C6.78444 6.26799 6.80938 6.3256 6.85262 6.36796C6.89586 6.41032 6.95396 6.43407 7.01449 6.43413H7.88436C8.01076 6.43413 8.11289 6.33307 8.11571 6.20748H8.11624V0.770825H8.11557V0.231877C8.11557 0.17038 8.09114 0.111401 8.04766 0.0679153C8.00417 0.0244299 7.94519 0 7.88369 0H3.47803L0 3.47816V11.3619C0 11.49 0.103742 11.5937 0.231877 11.5937H7.88369C7.94519 11.5937 8.00417 11.5693 8.04766 11.5258C8.09114 11.4823 8.11557 11.4234 8.11557 11.3619V11.0337H8.11624V9.85559C8.11621 9.79415 8.09177 9.73524 8.04832 9.69181C8.00486 9.64838 7.94594 9.62398 7.8845 9.62398Z" fill="#737373"/><path d="M7.25 9.25H6.25C5.90417 9.25 5.60942 9.12808 5.36575 8.88425C5.12208 8.64042 5.00017 8.34567 5 8C4.99983 7.65433 5.12175 7.35958 5.36575 7.11575C5.60975 6.87192 5.9045 6.75 6.25 6.75H7.25V7.25H6.25C6.04167 7.25 5.86458 7.32292 5.71875 7.46875C5.57292 7.61458 5.5 7.79167 5.5 8C5.5 8.20833 5.57292 8.38542 5.71875 8.53125C5.86458 8.67708 6.04167 8.75 6.25 8.75H7.25V9.25ZM6.5 8.25V7.75H8.5V8.25H6.5ZM7.75 9.25V8.75H8.75C8.95833 8.75 9.13542 8.67708 9.28125 8.53125C9.42708 8.38542 9.5 8.20833 9.5 8C9.5 7.79167 9.42708 7.61458 9.28125 7.46875C9.13542 7.32292 8.95833 7.25 8.75 7.25H7.75V6.75H8.75C9.09583 6.75 9.39067 6.87192 9.6345 7.11575C9.87833 7.35958 10.0002 7.65433 10 8C9.99983 8.34567 9.87792 8.6405 9.63425 8.8845C9.39058 9.1285 9.09583 9.25033 8.75 9.25H7.75Z" fill="#737373"/></svg>',
        undo: true,
        click: handleExternalIconClick.bind(this),
        tooltipText: 'Edit External Link',
      },
    ],
  }

  const handleSectionOpen = () => {
    setSectionOpen(!isSectionOpen)
  }

  const handleScoreRangeAdd = () => {
    if (score?.score_ranges?.length) {
      setFieldValue(`scoreList[${index}].score_ranges`, [
        ...score?.score_ranges,
        {
          low_score: '',
          high_score: '',
          result_type: null,
          equals: '',
          result_text: '',
          section: score?.id,
        },
      ])
    } else {
      setFieldValue(`scoreList[${index}].score_ranges`, [
        {
          low_score: '',
          high_score: '',
          result_type: null,
          equals: '',
          result_text: '',
          section: score?.id,
        },
      ])
    }
  }

  useEffect(() => {
    if (score?.score_ranges?.some((range) => range.range_error)) {
      setSectionOpen(true)
    }
  }, [score])

  const handleBeforeDialogOpen = (args) => {
    if (args.target === 'InsertLink') {
      setTimeout(() => {
        const openInNewTabCheckbox = document.querySelector(
          'input.e-rte-linkTarget',
        )
        if (openInNewTabCheckbox) {
          openInNewTabCheckbox.checked = true
        }
      }, 100)
    }
  }

  const beforeQuickToolbarOpen = (args) => {
    const selection = window.getSelection()
    if (!selection.rangeCount) {
      return
    }

    const range = selection.getRangeAt(0)
    const parentElement = range.commonAncestorContainer.parentElement

    if (parentElement && parentElement.tagName === 'A') {
      if (!parentElement.title) {
        args.cancel = true
      }
      if (parentElement.href === parentElement.title) {
        args.cancel = true
      }
    }
  }

  const handleTogglePopup = () => {
    setPopUpVisible((prev) => !prev)
  }

  return (
    <div className="scoring__wrapper" css={baseStyle}>
      <div
        className={classNames('score__header', {
          light: index % 2 === 0,
        })}
        onClick={handleSectionOpen}
      >
        <p className="score__name">{score?.name}</p>
        {isSectionOpen ? (
          <ColoredArrowUp
            variableColor={variableColor}
            onClick={handleSectionOpen}
          />
        ) : (
          <ColoredArrowDown
            variableColor={variableColor}
            onClick={handleSectionOpen}
          />
        )}
      </div>
      {isSectionOpen && (
        <div className="score__functionality">
          <div className="input__wrapper">
            <div className="input__info">
              <label className="input__label">
                Minimum (%) Questions Answered
              </label>
              <InfoIcon color={variableColor} handleClick={handleTogglePopup} />
              {isPopUpVisible ? (
                <InfoModal
                  message={
                    'Users will receive an inconclusive score if they answer below the minimum percentage of overall questions answered.'
                  }
                  setModalOpen={setPopUpVisible}
                />
              ) : null}
            </div>
            <Field
              name={`scoreList[${index}].minimum_question`}
              className="percentage__input"
              placeholder="%"
              value={score?.minimum_question}
              onChange={(e) => {
                setFieldTouched(`scoreList[${index}].minimum_question`, true)
                if (
                  Number(e.target.value) <= 100 &&
                  /^\d*$/.test(e.target.value)
                ) {
                  setFieldValue(
                    `scoreList[${index}].minimum_question`,
                    e.target.value,
                  )
                }
              }}
            />
          </div>
          <div className="description__wrapper">
            <label className="input__label">Inconclusive Description</label>
            <RichTextEditorComponent
              saveInterval={1}
              toolbarSettings={toolbarSettings}
              quickToolbarSettings={quickToolbarSettings}
              value={score.inconclusive_description}
              change={(e) => {
                const fieldName = `scoreList[${index}].inconclusive_description`
                setFieldValue(fieldName, e.value)
                setFieldTouched(fieldName, true)
              }}
            >
              <Inject
                services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
              />
            </RichTextEditorComponent>
          </div>
          {score?.name === 'Overall Scoring' ? (
            <div className="description__wrapper email__wrapper">
              <label className="input__label">Email Text</label>
              <RichTextEditorComponent
                id="defaultRTE"
                ref={(richtexteditor) => {
                  setRteObj(richtexteditor)
                }}
                saveInterval={1}
                toolbarSettings={emailToolBarSettings}
                beforeQuickToolbarOpen={beforeQuickToolbarOpen}
                beforeDialogOpen={handleBeforeDialogOpen}
                value={values?.email_text}
                change={(e) => {
                  const fieldName = 'email_text'
                  setFieldValue(fieldName, e.value)
                  setFieldTouched(fieldName, true)
                }}
                created={OnCreated.bind(this)}
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
              {isLinkModalOpen ? (
                <ExternalLinkModal
                  handleExternalLink={handleExternalLink}
                  handleClose={() => {
                    setLinkModalOpen(false)
                  }}
                />
              ) : null}
            </div>
          ) : null}
          {score?.score_ranges?.length ? (
            <FieldArray
              name={`scoreList[${index}].score_ranges`}
              render={(arrayHelpers) => (
                <div className="ranges__wrapper">
                  {score?.score_ranges?.map((range, rangeIndex) => (
                    <ScoreRange
                      key={rangeIndex}
                      scoreIndex={index}
                      range={range}
                      rangeIndex={rangeIndex}
                      handleDelete={async () => {
                        if (range?.id) {
                          await Api.delete(
                            `assessment/score_ranges/${range?.id}`,
                            {},
                          )
                        }
                        setFieldTouched('score_range', true)
                        arrayHelpers.remove(rangeIndex)
                      }}
                      resultTypes={serverResultTypes}
                      setResultTypesRefresh={setResultTypesRefresh}
                    />
                  ))}
                </div>
              )}
            />
          ) : null}
          <p className="button" onClick={handleScoreRangeAdd}>
            + Score Range
          </p>
        </div>
      )}
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    '.score__header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '10px',
      paddingRight: '15px',
      background: hexToRGBAWithOpacity(variableColor, 0.25),
      cursor: 'pointer',
    },
    '.score__name': {
      margin: 0,
      fontWeight: 600,
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.1),
    },
    '.score__functionality': {
      paddingLeft: '10px',
    },
    '.input__wrapper': {
      paddingTop: '10px',
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
    },
    '.input__label': {
      margin: 0,
      fontWeight: 600,
    },
    '.input__info': {
      display: 'flex',
      gap: '5px',
      position: 'relative',
    },
    '.info__icon': {
      display: 'flex',
      alignSelf: 'flex-start',
      cursor: 'pointer',
      height: '10px',
      width: '12px',
      position: 'relative',
    },
    '.percentage__input': {
      ...TextInputStyle,
      textAlign: 'center',
      width: '69px',
      height: '32px',
      background: '#FAFAFA',
      fontSize: '12px',
      color: '#676A6C',
      border: '1px solid #E6E6E6',
    },
    '.description__wrapper': {
      paddingTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      paddingBottom: '10px',
    },
    '.email__textarea': {
      ...TextInputStyle,
      height: '87px',
      fontSize: '12px',
      color: '#000',
      border: '1px solid #E6E6E6',
      fontWeight: 200,
    },
    '.e-keyboard': {
      fontWeight: 200,
    },
    '.button': {
      cursor: 'pointer',
      paddingTop: '10px',
      paddingBottom: '10px',
      color: variableColor,
      fontSize: '12px',
      margin: 0,
    },
    svg: {
      cursor: 'pointer',
    },
    '.email__wrapper': {
      paddingTop: 0,
      position: 'relative',
    },
    '.ranges__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  })
