import {
  AdvancedFilter,
  ButtonElement,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { singleHeaderTypes } from '../../components'
import { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { DeletedRow } from './components/DeletedRow'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { checkIsSafari } from '@/utils/checkIsSafari'
import useClickOutside from '@/react/hooks/useClickOutside'

export const DeletedTable = ({
  search,
  page,
  listCount,
  setListCount,
  setPage,
  isAllChecked,
  setAllChecked,
}) => {
  const [order, setOrder] = useState('-date_created')
  const { Api, CurrentUser, Notification } = useAngularServices()
  const [advancedFilters, setAdvancedFilters] = useState()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [deletedList, setDeletedList] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [isRestoreClicked, setRestoreClicked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const restoreRef = useRef(null)

  const restorePopUpRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(restorePopUpRef, setRestoreClicked)

  useEffect(() => {
    const getDeletedReports = async () => {
      const { app } = stateService.params
      const { data } = await Api.get('reports', {
        application: app,
        deleted: true,
        page,
        search: search,
        order,
        table: true,
        is_draft: false,
        non_empty: true,
      })
      setDeletedList(data.results)
      setListCount(data.count)
      setRefresh(false)
    }

    getDeletedReports()
  }, [page, search, order, refresh])

  const handleChecked = (item) => {
    const selectedIds = selectedItems.map((el) => el.id)
    if (selectedIds.includes(item.id)) {
      setAllChecked(false)
      setSelectedItems(selectedItems.filter((el) => el.id !== item.id))
    } else {
      setSelectedItems((prev) => [...prev, item])
    }
  }

  const handleCheckedAll = () => {
    setAllChecked(!isAllChecked)

    if (!isAllChecked === true) {
      setSelectedItems(deletedList)
    } else {
      setSelectedItems([])
    }
  }

  const handleRestoreAction = async () => {
    const idsList = selectedItems.map((item) => item?.id).join(',')
    try {
      await Api.postWithParams(
        'reports/bulk_restore',
        {
          deleted: 'false',
        },
        {
          ids: isAllChecked ? 'all' : idsList,
          search: search,
          is_draft: false,
          non_empty: true,
        },
      )
      setPage(1)
      setRefresh(true)
    } catch {
      Notification.danger('Failed to restore reports')
    }
    setRestoreClicked(false)
    setSelectedItems([])
    setAllChecked(false)
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '50px',
        textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '22px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              <div
                className="action__text"
                onClick={() => {
                  setRestoreClicked(true)
                  setActionsClicked(false)
                }}
              >
                Restore
              </div>
            </div>
          )}
          {isRestoreClicked && selectedItems.length ? (
            <div className="restore__popup" ref={restorePopUpRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setRestoreClicked(false)
                }}
              />
              <div className="popup__title">Restore?</div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  handleRestoreAction()
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      className: 'table__header title__column',
      name: 'Title',
      type: 'report__title',
      additionalStyles: {
        minWidth: '245px',
        width: '245px',
        maxWidth: '245px',
        textAlign: 'left',
        paddingLeft: '10px',
        p: {
          display: 'flex',
          width: 'fit-content',
          paddingLeft: '10px',
        },
        '.header-base': {
          width: 'fit-content',
          display: 'flex',
        },
        '.header-sort-arrow': {
          marginLeft: '3px',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'report__title'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Template',
      type: 'form__name',
      additionalStyles: {
        minWidth: '198px',
        width: '198px',
        maxWidth: '195px',
        padding: 0,
        textAlign: 'left',
        p: {
          display: 'flex',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '170px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'form__name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Name',
      type: 'user__first_name',
      additionalStyles: {
        minWidth: '136px',
        width: '136px',
        maxWidth: '136px',
        padding: 0,

        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      // additionalInnerElement: (
      //   <AdvancedFilter
      //     fieldName={'user__first_name'}
      //     advancedFilters={advancedFilters}
      //     setAdvancedFilters={setAdvancedFilters}
      //     updateUrl={false}
      //   />
      // ),
    },
    {
      className: 'table__header',
      name: 'Company',
      type: 'company_name',
      additionalStyles: {
        minWidth: '232px',
        maxWidth: '232px',
        width: '232px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '130px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'company_name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: 'Project',
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        minWidth: '106px',
        width: '106px',
        maxWidth: '106px',
        padding: 0,
        textAlign: 'left',
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '100px',
          },
        },
      },
      //   additionalInnerElement: (
      //     <AdvancedFilter
      //       fieldName={'project__name'}
      //       advancedFilters={advancedFilters}
      //       setAdvancedFilters={setAdvancedFilters}
      //       updateUrl={false}
      //     />
      //   ),
    },
    {
      className: 'table__header',
      name: 'Created',
      type: 'date_created',
      filterHeader: true,
      additionalStyles: {
        minWidth: '86px',
        width: '86px',
        maxWidth: '86px',
        padding: 0,
        textAlign: 'left',
        '.header-base': {
          '.header-sort-arrow': {
            marginLeft: '3px',
          },
          '.alert-button-opening': {
            marginLeft: '23px',
          },
        },
        '@media(max-width: 700px)': {
          '.header-base': {
            width: '70px',
          },
        },
      },
    },
  ]
  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(variableColor, isSafari)

  return (
    <div className="table__container" css={baseStyle}>
      <table>
        <TableHeaders
          headers={TABLE_HEADER_SETTINGS}
          callback={setOrder}
          popUpLocation={false}
          updateUrl={false}
          order={order}
          setOrder={setOrder}
        />
        <tbody>
          {deletedList.map((report, index) => (
            <DeletedRow
              key={report.id}
              report={report}
              index={index}
              handleChecked={handleChecked}
              selectedItems={selectedItems}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const getBaseStyle = (listLength, isSafari) =>
  css({
    '@media(max-width: 1370px)': {
      overflowX: 'scroll',
      overflowY: 'hidden',
      minHeight: '200px',
    },
    table: {
      marginBottom: listLength < 3 ? '50px' : '0',
    },
    tr: {
      position: 'relative',
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.checkmark__td': {
      paddingLeft: '15px',
    },
    '.action': {
      marginTop: '3px',
      paddingLeft: '5px',
      cursor: 'pointer',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      position: 'relative',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.title__td': {
      cursor: 'pointer',
      color: '#337ab7',
      paddingLeft: '15px',
    },
    '.documentation__row': {
      color: '#575757',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.view__td': {
      cursor: 'pointer',
      textAlign: 'center',
    },
    '.title__text': {
      margin: '0',
      maxHeight: '40px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.company__fullname': {
      left: '650px',
    },
    '.name__fullname': {
      left: '550px',
    },
    '.project__fullname': {
      left: '850px',
    },
    '.title': {
      maxWidth: '245px',
    },
    '.form': {
      maxWidth: '198px',
    },
    '.name': {
      maxWidth: '136px',
    },
    '.company': {
      maxWidth: '232px',
    },
    '.project': {
      maxWidth: '106px',
    },
    '.created': {
      maxWidth: '86px',
    },
    '.checkbox-container': {
      paddingLeft: '10px',
      marginBottom: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.restore__popup': {
      position: 'absolute',
      background: '#FFF',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
      zIndex: 1,
      width: '200px',
      left: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.popup__cross': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginTop: '20px',
      marginBottom: '11px',
      paddingInline: '20px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '@media(max-width: 1000px)': {
      '.full__name': {
        display: 'none',
      },
    },
  })
