import { css } from '@emotion/react'
import { Field, useFormikContext } from 'formik'
import { TextInputStyle } from '@screens/components'
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'
import { useAngularServices } from '@/react/components'
import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { CreateEditResultType } from './CreateEditResultType'
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import classNames from 'classnames'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { toolbarSettings, quickToolbarSettings } from './toolbarVariables'
import { useRouter } from '@/react/hooks'
import { ErrorModal } from './ErrorModal'

export const ScoreRange = ({
  scoreIndex,
  rangeIndex,
  range,
  handleDelete,
  resultTypes,
  setResultTypesRefresh,
}) => {
  const { Api, CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [selectInputValue, setSelectInputValue] = useState('')

  const [isModalOpen, setModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [resultTypeDeleteData, setResultTypeDeleteData] = useState(null)
  const [resultTypeEditData, setResultTypeEditData] = useState(null)
  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const { stateService } = useRouter()
  const templateId = stateService?.params?.assessmentTemplateId

  const handleResultTypeAdd = async (inputValue: string) => {
    await Api.post('assessment/result_types', {
      name: inputValue,
      template: templateId,
    })

    setModalOpen(false)
    setResultTypesRefresh(true)
  }

  const handleResultTypeEdit = async (newValue: string, id) => {
    setModalOpen(false)

    const { data: updatedResultType } = await Api.patch(
      `assessment/result_types/${id}`,
      {
        name: newValue,
        template: templateId,
      },
    )

    setFieldTouched(`result_type`, true)

    setFieldValue(
      `scoreList`,
      values.scoreList.map((scoreItem) => {
        const updatedRanges = scoreItem.score_ranges.map((range) => {
          if (range?.result_type?.name === resultTypeEditData?.name) {
            range.result_type = updatedResultType
          }
          return range
        })

        return {
          ...scoreItem,
          score_ranges: updatedRanges,
        }
      }),
    )

    setResultTypesRefresh(true)
    setResultTypeEditData(null)
  }

  const handleResultTypeDelete = async () => {
    await Api.delete(`assessment/result_types/${resultTypeDeleteData?.id}`, {})

    setFieldValue(
      `scoreList`,
      values.scoreList.map((scoreItem) => {
        const updatedRanges = scoreItem.score_ranges.map((range) => {
          if (range?.result_type?.name === resultTypeDeleteData?.name) {
            range.result_type = null
          }
          return range
        })

        return {
          ...scoreItem,
          score_ranges: updatedRanges,
        }
      }),
    )

    setResultTypesRefresh(true)
    setResultTypeDeleteData(null)
  }

  const handleRangeDelete = () => {
    handleDelete(rangeIndex)
    setDeleteModalOpen(false)
  }

  const renderOption = (props, option, { selected, index }) => {
    const selectedStyles = getSelectedStyles(variableColor, selected, index)
    return (
      <li
        {...props}
        css={selectedStyles}
        key={option?.name}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor:
            index % 2 === 0
              ? selected
                ? variableColor
                : '#F8F8F8'
              : selected
              ? variableColor
              : '#fff',
        }}
      >
        <p className="option__text">{option?.name}</p>
        <div className="icons__wrapped">
          <EditIcon
            color={variableColor}
            height="15px"
            onClick={() => {
              setResultTypeEditData(option)
              setModalOpen(true)
            }}
          />
          <BinDeleteIcon
            color={variableColor}
            height="15px"
            onClick={() => {
              setResultTypeDeleteData(option)
            }}
          />
        </div>
      </li>
    )
  }

  const baseStyle = getBaseStyle(variableColor)

  const availableResultTypeOptions = resultTypes?.filter(
    (option) =>
      !values.scoreList[scoreIndex].score_ranges.some(
        (range) => range?.result_type?.name === option?.name,
      ),
  )

  const handleScoreRangeErrorReset = () => {
    setFieldValue(`scoreList[${scoreIndex}].score_ranges[${rangeIndex}]`, {
      ...range,
      range_error: '',
    })
  }

  return (
    <div css={baseStyle}>
      <div className="score__row">
        {range?.range_error ? (
          <ErrorModal
            error_message={range?.range_error}
            handleErrorReset={handleScoreRangeErrorReset}
          />
        ) : null}
        <div className="score__wrapper">
          <label className="input__label">Score Range (%)</label>
          <div className="score__inputs__wrapper">
            <Field
              type="text"
              className={classNames('score__input', {
                error:
                  (range.low_score === '' && range.high_score) ||
                  +range.low_score > +range.high_score ||
                  range?.range_error ||
                  range?.low_score === '',
              })}
              placeholder="Score %"
              name={`scoreList[${scoreIndex}].score_ranges[${rangeIndex}].low_score`}
              value={range?.low_score}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= 100 &&
                  /^\d*$/.test(e.target.value)
                ) {
                  setFieldValue(
                    `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].low_score`,
                    e.target.value,
                  )
                  setFieldTouched(
                    `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].low_score`,
                    true,
                  )
                }
              }}
            />
            <p className="score__label">to</p>
            <Field
              type="text"
              className={classNames('score__input', {
                error:
                  (range.low_score && range.high_score === '') ||
                  +range.low_score > +range.high_score ||
                  range?.range_error ||
                  range?.low_score === '',
              })}
              placeholder="Score %"
              value={range?.high_score}
              name={`scoreList[${scoreIndex}].score_ranges[${rangeIndex}].high_score`}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= 100 &&
                  /^\d*$/.test(e.target.value)
                ) {
                  setFieldValue(
                    `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].high_score`,
                    e.target.value,
                  )
                  setFieldTouched(
                    `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].high_score`,
                    true,
                  )
                }
              }}
            />
            <p className="score__label">equals</p>
          </div>
        </div>
        <div className="dropdown__wrapper">
          <div className="dropdown__headers">
            <label className="input__label">Result Type</label>
            <p
              className="button"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              + Result Type
            </p>
            {isModalOpen && (
              <CreateEditResultType
                resultTypes={resultTypes}
                setIsModalOpen={setModalOpen}
                handleClick={
                  !resultTypeEditData
                    ? handleResultTypeAdd
                    : handleResultTypeEdit
                }
                editData={resultTypeEditData}
              />
            )}
          </div>
          <Autocomplete
            value={range?.result_type}
            sx={{
              width: '335px',
              height: '32px',
              '& .MuiAutocomplete-endAdornment': {
                top: '15px',
                right: '9px',
              },
              "& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: '#FF8787',
                },
              '& .MuiOutlinedInput-root': {
                padding: '9px',
                paddingBottom: 0,
                marginBottom: '9px',
              },
            }}
            style={{
              height: 32,
              border: '1px solid #E6E6E6',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              background: '#FAFAFA',
            }}
            onChange={(event: any, newValue: string | null) => {
              setFieldTouched(
                `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].result_type`,
                true,
              )
              setFieldValue(
                `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].result_type`,
                newValue,
              )
            }}
            inputValue={selectInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectInputValue(newInputValue)
            }}
            id="controllable-states-demo"
            options={availableResultTypeOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-input': {
                    fontSize: '12px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: 'Select...',
                }}
              />
            )}
            renderOption={renderOption}
          />
        </div>
        <div className="delete__wrapper">
          <label className="input__label">Delete</label>
          <BinDeleteIcon
            color={variableColor}
            onClick={() => setDeleteModalOpen(true)}
          />
          {isDeleteModalOpen && (
            <ConfirmationModal
              title={'Delete?'}
              handleClick={handleRangeDelete}
              handleClose={() => {
                setDeleteModalOpen(false)
              }}
            />
          )}
          {resultTypeDeleteData && (
            <ConfirmationModal
              title={'Delete Result Type?'}
              handleClick={handleResultTypeDelete}
              handleClose={() => setResultTypeDeleteData(null)}
            />
          )}
        </div>
      </div>
      <div className="description__wrapper">
        <label className="input__label">Result Description</label>
        <RichTextEditorComponent
          style={{ fontSize: '12px' }}
          saveInterval={1}
          toolbarSettings={toolbarSettings}
          quickToolbarSettings={quickToolbarSettings}
          value={range.result_text}
          change={(e) => {
            const fieldName = `scoreList[${scoreIndex}].score_ranges[${rangeIndex}].result_text`
            setFieldValue(fieldName, e.value)
            setFieldTouched(fieldName, true)
          }}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      </div>
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
    borderLeft: `2px solid ${variableColor}`,

    position: 'relative',
    ':before': {
      content: '""',
      display: 'block',
      top: 0,
      width: '15px',
      height: '2px',
      left: '-2px',
      background: `${variableColor}`,

      position: 'absolute',
    },
    ':after': {
      content: '""',
      display: 'block',
      width: '15px',
      height: '2px',
      bottom: '-2px',
      left: '-2px',
      background: `${variableColor}`,

      position: 'absolute',
    },
    '.score__inputs__wrapper': {
      display: 'flex',
      gap: '10px',
      paddingTop: '10px',
      alignItems: 'center',
    },
    '.score__row': {
      display: 'flex',
      gap: '20px',
    },
    '.score__input': {
      ...TextInputStyle,
      textAlign: 'center',
      width: '69px',
      height: '32px',
      background: '#FAFAFA',
      fontSize: '12px',
      color: '#676A6C',
      border: '1px solid #E6E6E6',

      '::placeholder': {
        color: '#B1B1B1',
      },
    },
    '.error': {
      borderColor: '#C80404',
    },
    '.score__label': {
      fontSize: '12px',
      color: '#676A6C',
      fontWeight: '600',
      margin: 0,
    },
    '.dropdown__headers': {
      display: 'flex',
      gap: '10px',
      paddingBottom: '10px',
    },
    '.button': {
      padding: 0,
      position: 'relative',
    },
    '.delete__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      position: 'relative',

      svg: {
        cursor: 'pointer',
      },
    },
  })

function getSelectedStyles(
  variableColor: string,
  selected: boolean,
  index: number,
) {
  return css({
    borderTop: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
    borderBottom: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
    ':hover': {
      backgroundColor: `${variableColor} !important`,
      color: '#fff',
      '.option__text': {
        color: '#fff',
      },
      svg: {
        color: '#fff',
      },
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.selected__item': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
    '.option__text': {
      margin: 0,
      maxWidth: '260px',
      overflow: 'hidden',
      maxHeight: '35px',
      fontSize: '12px',
      fontWeight: 500,
      color: selected ? '#fff' : '#676A6C',
    },
    svg: {
      color: selected ? '#fff' : variableColor,
    },
    '.icons__wrapped': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      position: 'relative',
    },
  })
}
