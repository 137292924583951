import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { ConfigNavBar } from '../components/ConfigNavBar'
import { LogoDropzone } from './components/LogoDropzone'
import { useState } from 'react'
import { css } from '@emotion/react'

export const CompanyLogo = () => {
  const [file, setFile] = useState(null)
  const { Api, CurrentUser, Notification } = useAngularServices()
  const [loading, setLoading] = useState(false)

  const handleAddFile = async (file) => {
    setLoading(true)
    const dataUrlReader = new FileReader()

    dataUrlReader.onloadend = () => {
      const fileAsDataURL = dataUrlReader.result as string

      setFile({ ...file, image: fileAsDataURL })
    }
    dataUrlReader.readAsDataURL(file)
    try {
      const { data: uploadResponse } = await Api.uploadImage(file)
      setFile(uploadResponse)
    } catch {
      console.log('error')
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      const { id } = CurrentUser.getClientCompany()
      await Api.patch(`companies/${id}`, {
        id: id,
        logo: file?.id,
      })
      setFile(null)
      Notification.success('Logo successfully updated.')
      window.location.reload()
    } catch {
      console.log('error')
      Notification.success('Logo uploading was failed.')
    }
  }

  return (
    <section css={baseStyle} className="logo__wrapper">
      <CommonPageHeader headerText="Settings" />
      <ConfigNavBar />
      <div className="dropzone__wrapper">
        <LogoDropzone
          fileStorage={file}
          fileSetStorage={setFile}
          isLoading={loading}
          addCallback={handleAddFile}
        />
        <div className="button__wrapper">
          <ButtonElement
            text="Save"
            disabled={!file}
            buttonType="submit"
            functionToTrigger={handleSubmit}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  '.dropzone__wrapper': {
    background: '#fff',
    paddingTop: '30px',
    paddingInline: '30px',
    marginLeft: '5px',
    width: '624px',
  },
  '.button__wrapper': {
    paddingTop: '13px',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '@media(max-width: 900px)': {
    '.dropzone__wrapper': {
      width: '365px',
    },
  },
})
