import { ImageWithPreview, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Alert } from '@screens/ToolboxTalks/components/Alert'
import StringUtil from '@/utils/autolinks'
import { checkIsSafari } from '@/utils/checkIsSafari'
import pluralize from 'pluralize'

export const ViewExternalPage = () => {
  const { $rootScope, Api, Notification } = useAngularServices()
  const clientObjectKey = $rootScope.$stateParams.key
  const [secondaryColor, setSecondaryColor] = useState('')

  const [toolboxData, setToolboxData] = useState(null)
  const [isSynchronized, setIsSynchronized] = useState(null)
  const [error, setError] = useState(null)
  const [notFoundError, setNotFoundError] = useState(false)
  const [formattedProjectName, setFormattedProjectName] = useState('')

  const isToolboxSynchronised = () => {
    Api.get(`shares/toolbox/${clientObjectKey}`, {}, (res) => {
      setIsSynchronized(res.synchronised)
      if (res.synchronised) {
        setError(null)
      }
    })
  }

  const isSafari = checkIsSafari()

  const intervalRef = useRef(null)

  const waitForToolboxSync = () => {
    intervalRef.current = setInterval(() => {
      if (isSynchronized) {
        getToolboxData()
        clearInterval(intervalRef.current)
      } else {
        isToolboxSynchronised()
      }
    }, 1000)
  }

  useEffect(() => {
    if (!isSynchronized && notFoundError) {
      waitForToolboxSync()
    } else {
      clearInterval(intervalRef.current)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isSynchronized])

  const getToolboxData = async () => {
    const { data: res } = await Api.get(`shares/toolbox/${clientObjectKey}`, {})
    if (res.client?.general_settings?.colors.secondary_color) {
      document.body.style = `background: ${res.client.general_settings.colors.secondary_color};`
      setSecondaryColor(res.client.general_settings.colors.secondary_color)
    } else {
      document.body.style = `background: rgb(57, 129, 205)`
    }
    if (res.message) {
      setError(res.message)
      Notification.error(res.message)
    } else {
      setToolboxData(res)
      setFormattedProjectName(
        pluralize.singular(res.application.projects_display),
      )
      setIsSynchronized(res.synchronised)
      if (!res.synchronised) {
        setError(
          'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.',
        )
      }
    }
  }

  useEffect(() => {
    getToolboxData()
  }, [])

  if (!toolboxData || error) return null

  return (
    <section css={wrapperStyle}>
      {error && <Alert text={error} type="danger" />}
      <main css={baseStyle(secondaryColor, isSafari)}>
        <div className="view-internal-base-page">
          <div className="header-base">
            <div className="logo-holder">
              <img
                className="logo"
                src={toolboxData.company?.logo_url}
                alt="logo"
              />
            </div>
            <div className="info-text-block-wrapper">
              <div className="sub-header">
                <div className="page-stat-title">ORIGINATOR:</div>
                <div className="page-stat-desc">
                  {toolboxData.user.first_name} {toolboxData.user.last_name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">
                  {formattedProjectName.toUpperCase()}:
                </div>
                <div className="page-stat-desc page-stat-border-bottom">
                  {toolboxData.project.name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">CREATED:</div>
                <div className="page-stat-desc">
                  {moment(toolboxData.date_created).format('MM/DD/YY - hh:mmA')}
                </div>
              </div>
            </div>
          </div>
          <div className="content-title">
            <span>{toolboxData.topic.name}</span>
          </div>
          {toolboxData.topic?.summary?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">SUMMARY</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.summary,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.importance?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">WHY IS IT IMPORTANT?</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.importance,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.consider?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">THINGS TO CONSIDER</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData?.topic?.consider,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.discussion?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">DISCUSSION ITEMS</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.discussion,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.notes?.length > 0 &&
            toolboxData.notes.some((note) => !note.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">NOTES</h3>
                <ul>
                  {toolboxData.notes
                    .filter((note) => !note.deleted)
                    .map((note) => {
                      return (
                        <li key={note.id} className="note-item">
                          <div
                            style={{ whiteSpace: 'pre-wrap', lineHeight: 1 }}
                          >
                            <span className="gray">
                              {moment(toolboxData.date_created).format(
                                'MM/DD/YY - hh:mmA',
                              )}{' '}
                              {note.user.first_name} {note.user.last_name}{' '}
                              -&nbsp;
                            </span>
                            <span> {note.text}</span>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.photos?.length > 0 &&
            toolboxData.photos.some((photo) => !photo.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">PHOTOS</h3>
                <ul className="photo-holder">
                  {toolboxData.photos
                    .filter((photo) => !photo.deleted)
                    .map((photo) => {
                      return (
                        <li key={photo.id} className="photo-item">
                          <ImageWithPreview
                            photo={photo}
                            photoIdx={photo.id}
                            onlyImage={true}
                          />
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.signatures?.length > 0 &&
            toolboxData.signatures.some((signature) => !signature.deleted) && (
              <div className="content-block last">
                <h3 className="content-block-title">SIGNATURES</h3>
                <ul className="signature-holder">
                  {toolboxData.signatures
                    .filter((signature) => !signature.deleted)
                    .map((signature) => {
                      return (
                        <li
                          key={signature.image.id}
                          className="signature-item-holder"
                        >
                          <div className="signature-item">
                            <img
                              className="s-img"
                              src={signature.image.image}
                              alt="signature"
                            />
                            <div className="signature-info">
                              <div className="signature-name">
                                {signature.printed_name &&
                                  signature.printed_name}
                              </div>
                              <div className="signature-company">
                                {signature.company_name}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.disclaimer?.length > 0 && (
            <div
              className="content-block-text"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: StringUtil.parseHyperlinks(toolboxData.disclaimer),
              }}
            ></div>
          )}
        </div>
      </main>
    </section>
  )
}

const wrapperStyle = css({
  paddingBottom: '25px',
  marginTop: '-4rem',
  '@media (max-width: 799px)': {
    paddingLeft: '15px',
  },
})

const baseStyle = (secondaryColor, isSafari: boolean) =>
  css({
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    marginTop: '2vw',
    borderRadius: '20px',

    '@media (max-width: 553px)': {
      '.view-internal-base-page': {
        padding: '10px !important',
      },

      '.header-base': {
        flexDirection: 'column !important',
        height: '100%',
        gap: '15px',
        paddingBottom: '5px !important',
        marginTop: '10px !important',

        '.logo-holder': {
          maxWidth: '265px !important',
          maxHeight: '100px !important',

          '.logo': {
            maxWidth: '265px !important',
            maxHeight: '100px !important',
          },
        },

        '.info-text-block-wrapper': {
          marginLeft: '10px !important',
          height: '100% !important',
          flexDirection: 'column !important',
          alignItems: 'flex-start !important',
          border: '0 !important',
          paddingLeft: '0 !important',
        },
      },

      '.content-block-title': {
        marginBottom: '5px !important',
      },

      '.content-block ': {
        paddingBottom: '12px !important',
        paddingTop: '10px !important',
      },

      '.photo-holder': {
        display: 'flex !important',
        flexDirection: 'flex-start !important',
        alignItems: 'center !important',

        '.photo-item': {
          paddingBottom: '0 !important',
          width: '100% !important',
          display: 'flex !important',
          justifyContent: 'flex-start !important',
        },
      },

      '.signature-holder': {
        marginBottom: '0 !important',
        width: '100%  !important',
        display: 'flex  !important',
        flexDirection: 'column  !important',
        alignItems: 'flex-start  !important',

        '.signature-item-holder': {
          width: '100%  !important',
          paddingBottom: '5px !important',

          '.signature-item': {
            width: '100%  !important',
          },
        },

        '.signature-info': {
          paddingTop: '3px !important',
        },
      },
    },

    '.last': {
      border: '0 !important',
      paddingBottom: '0 !important',
    },

    '.header-base': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #eeeeee',

      '.logo-holder': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '120px',
        maxHeight: '125px',
        height: 'auto',
        width: 'auto',
        marginRight: 5,
        verticalAlign: 'top',
        '.logo': {
          width: 'auto',
          maxWidth: '120px',
          maxHeight: '125px',
          height: 'auto',
        },
      },
      '.info-text-block-wrapper': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '124px',
        paddingLeft: '105px',
        borderLeft: '1px solid #eeeeee',

        '.sub-header': {
          display: 'inline-block',
          width: '50%',
          paddingBottom: 15,
          '@media (max-width: 553px)': {
            display: 'block',
          },
        },
      },
    },

    '.content-title': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px 0 2px 0 ',

      span: {
        color: '#7F8183',
        textAlign: 'center',
        fontSize: '24px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        letterSpacing: '-0.24px',
      },
    },

    '.photo-holder': {
      display: isSafari ? 'block' : 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      marginBottom: '0 !important',
      '.photo-item': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 8,
        paddingRight: 15,
        width: 'calc(33.3333% - 30px)',
      },
    },
    '.signature-holder': {
      display: 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      '.signature-item-holder': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 10,
        paddingRight: 10,
        '.signature-item': {
          display: 'inline-block',
          width: 135,
          '.s-img': {
            width: '100%',
            border: '1.5px solid lightgray',
          },
        },
      },
    },
    '.view-internal-base-page': {
      padding: '25px',
    },
    '.info-text-block-wrapper': {
      alignItems: 'flex-start',
    },
    '.stat-block': {
      width: 'calc(50% - 24px)',
    },
    '.content-block': {
      paddingTop: '12px',
      paddingBottom: '12px',
      borderBottom: '1px solid #f2f2f2',
    },
    '.content-block-title': {
      marginTop: '2px',
      fontSize: '14px',
      fontWeight: 700,
      color: secondaryColor,
      lineHeight: '19px',
    },
    '.content-block-text': {
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontWeight: '600',
      color: '#7F8183',
      lineHeight: '17.7px',
    },
    '.page-stat-title': {
      fontSize: '14px',
      fontWeight: 700,
      color: secondaryColor,
      lineHeight: '19px',
      paddingBottom: '5px',
    },
    '.page-stat-desc': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#a9a9a9',
      lineHeight: '19px',
    },
    '.gray': {
      fontFamily: 'Open Sans',
      fontSize: '13px',
      fontWeight: '600',
      color: '#7F8183',
    },
    '.page-title': {
      fontSize: '24px',
      color: '#686A6C',
      textAlign: 'center',
      margin: '10px 0 0',
      lineHeight: '33px',
    },
    '.page-title-project': {
      fontSize: '12px',
      color: '#676A6C',
      lineHeight: '15px',
      textAlign: 'center',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    li: {
      padding: '0',
    },
    '.note-item': {
      padding: '3px 0',
    },
    '.photo-item': {
      width: 'calc(33.3333% - 15px)',
    },
    '.singleImage': {
      marginTop: '0',
      marginRight: '0',
      width: 'auto',
    },
    '.signature-item-block': {
      maxWidth: '135px',
    },
    '.signature-img-w': {
      position: 'relative',
    },
    '.signature-info': {
      gap: '5px',
      maxWidth: '135px',
      paddingTop: '10px',
      fontSize: '9px',
      display: 'flex',
      flexDirection: 'column',
    },
    '.signature-name': {
      color: '#000',
    },
    '.signature-company': {
      color: '#808080',
    },
    '.img-w': {
      height: '69px',
      overflow: 'hidden',
    },
    '.img': {
      maxWidth: '135px',
      height: '100%',
      border: '1.5px solid lightgray',
    },
    '.button-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '25px 0 0',
    },
    '@media print': {
      '.button-print': {
        display: 'none',
      },
    },
  })
