import * as React from 'react'
import { css } from '@emotion/react'

import {
  useAngularServices,
  CommonPageHeader,
  ButtonElement,
} from '@/react/components'
import { TextInputStyle } from '@screens/components'
import { useRouter } from '@/react/hooks'
import ReactSelect from 'react-select'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'
import { CostCodesNavBar } from '../components/CostCodesNavBar'
import { CurrentUserType } from '@/react/types/documentation'

export const CostCodesCreate = () => {
  const { Api, Notification, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [projectData, setProjectData] = React.useState<object[]>([])
  const [costCodeName, setCostCodeName] = React.useState<string>('')
  const [costCodeNum, setCostCodeNum] = React.useState<string>('')
  const [selectedProject, setSelectedProject] = React.useState<object>()
  const [disableButton, setDisableButton] = React.useState(false)
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const isEditMode = stateService.params.id
  React.useEffect(() => {
    fetchProjects()

    if (isEditMode) {
      fetchCostCodeById()
    }
  }, [])

  async function fetchCostCodeById() {
    await Api.CostCodes.byID(stateService.params.id, (resp) => {
      if (resp) {
        setSelectedProject({
          value: resp.project.id,
          label: `${resp.project.number ? resp.project.number : ''} ${
            resp.project.name
          }`,
        })
        setCostCodeName(resp.name)
        setCostCodeNum(resp.costcode_code)
      }
    })
  }

  async function fetchProjects() {
    await Api.get(
      'projects',
      {
        is_active: true,
        client: CurrentUser.getClientId(),
        page_size: 1000,
        activity_dropdown: true,
        app: stateService.params.app,
      },
      (resp) => {
        const projectOptions = []
        resp.results.forEach((project) => {
          const labelContent = project.number ? (
            <>
              <span className="project-number">{project.number}</span>{' '}
              {project.name}
            </>
          ) : (
            project.name
          )
          projectOptions.push({
            value: project.id,
            label: labelContent,
          })
        })

        setProjectData(projectOptions)
      },
    )
  }

  async function onSubmit() {
    setDisableButton(true)
    try {
      if (isEditMode) {
        await Api.patch(`cost_codes/${stateService.params.id}`, {
          name: costCodeName,
          costcode_code: costCodeNum,
          project: selectedProject.value,
        })
      } else {
        await Api.post('cost_codes', {
          name: costCodeName,
          costcode_code: costCodeNum,
          project: selectedProject.value,
        })
      }

      stateService.go('admin.cost_codes_list', {
        pageNumber: stateService.params.previousPageNumber,
        search: stateService.params.previousSearch,
      })
    } catch (error) {
      Notification.clearNotification()
      Notification.danger(error.data.non_field_errors[0])
      setDisableButton(false)
    }
  }

  const costCodeNumHandler = (e) => {
    setCostCodeNum(e.target.value)
  }

  const costCodeNameHandler = (e) => {
    setCostCodeName(e.target.value)
  }

  return (
    <section>
      <CommonPageHeader headerText="Cost Code" />
      <CostCodesNavBar />
      <section css={baseStyle}>
        <div className="field-row">
          <div className="field">
            <label>Cost Code Number</label>
            <input
              placeholder={''}
              type="text"
              value={costCodeNum}
              onChange={costCodeNumHandler}
            />
          </div>
          <div className="field">
            <label>Cost Code Name</label>
            <input
              placeholder={''}
              type="text"
              value={costCodeName}
              onChange={costCodeNameHandler}
            />
          </div>
        </div>
        <div className="field-row mt-4">
          <div className="field">
            <label>Project</label>
            <ReactSelect
              placeholder="Select project"
              options={projectData}
              value={selectedProject}
              onChange={(value) => {
                setSelectedProject(value)
              }}
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isSelected
                    ? percentageShadeBlendColor(0.1, variableColor) +
                      ' !important'
                    : state.isFocused
                    ? percentageShadeBlendColor(0.4, variableColor) +
                      ' !important'
                    : 'white !important',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                backgroundColor: '#FFFFFF',
                backgroundImage: 'none',
                borderColor: '#e5e6e7',
                borderStyle: 'solid',
                borderWidth: '1px',
                maxHeight: '64px',
                borderRadius: '0',
                fontSize: '14px',
                marginBottom: '10px',
                colors: {
                  ...theme.colors,
                  primary: percentageShadeBlendColor(0.2, variableColor),
                },
              })}
            />
          </div>
        </div>
        <div className="button-row d-flex justify-content-end">
          <ButtonElement
            text={'Cancel'}
            functionToTrigger={() => {
              stateService.go('admin.cost_codes_list', {
                pageNumber: stateService.params.previousPageNumber,
                search: stateService.params.previousSearch,
              })
            }}
            width={'auto'}
          />
          <ButtonElement
            text={'Save'}
            functionToTrigger={onSubmit}
            buttonType={'submit'}
            width={'auto'}
            additionalStyles={{
              marginLeft: '15px',
            }}
            debounceTimeout={1000}
            disabled={
              !selectedProject || !costCodeNum || !costCodeName || disableButton
            }
          />
        </div>
      </section>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  padding: 40,
  marginLeft: 1,
  input: {
    ...TextInputStyle,
  },
  '.length-message': {
    width: '100%',
    textAlign: 'right',
  },
  '.button-row': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  '.project-number': {
    fontWeight: 'bold',
  },
  '.field-row': {
    display: 'flex',
    justifyContent: 'space-between',
    '.field': {
      display: 'flex',
      flexDirection: 'column',
      width: '47%',
    },
  },
})
