import { adminUser } from '@screens/Admin/utils/commomTypes'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import {
  CustomCheckbox,
  TableHighlight,
  useAngularServices,
} from '@/react/components'
import { BinDeleteIcon } from '@/react/componentAssets'
import { useState } from 'react'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

type Props = {
  user: adminUser
  variableColor: string
  idx: number
  search: string
  onDelete: () => void
}

export const AdminTableRow = ({
  user,
  variableColor,
  idx,
  search,
  onDelete,
}: Props) => {
  const { Api } = useAngularServices()

  const [isDeleteClicked, setDeleteClicked] = useState(false)

  const styles = getBaseStyle(idx)

  const handleClick = () => {
    setDeleteClicked(true)
  }

  const handleDelete = async () => {
    try {
      await Api.delete(`users/${user.id}/soft_delete`)
      onDelete()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckbox = async () => {
    try {
      await Api.post(`users/${user.id}/toggle_active`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <tr css={styles}>
      <td className="username__td">
        <UISref to={'true_admin.admin_edit'} params={{ id: user.id }}>
          <a>
            <TableHighlight text={user.username} search={search} />
          </a>
        </UISref>
      </td>
      <td className="fullname__td">
        <TableHighlight
          text={`${user.first_name} ${user.last_name}`}
          search={search}
          width={300}
        />
      </td>
      <td className="email__td">
        <TableHighlight text={user.email} width={250} search={search} />
      </td>
      <td className="active__td">
        <CustomCheckbox
          onChange={handleCheckbox}
          isDefaultChecked={user.is_active}
          reversedColor={true}
        />
      </td>
      <td className="icon__td">
        <BinDeleteIcon color={variableColor} onClick={handleClick} />
      </td>
      {isDeleteClicked && (
        <ConfirmationModal
          action="Delete"
          title={user.username}
          handleClick={handleDelete}
          handleClose={() => {
            setDeleteClicked(false)
          }}
        />
      )}
    </tr>
  )
}

function getBaseStyle(idx: number) {
  return css({
    position: 'relative',
    backgroundColor: Math.abs(idx % 2) == 1 ? 'none' : 'rgb(242, 243, 245)',
    '.username__td': {
      width: '30%',
    },
    '.fullname__td': {
      width: '30%',
    },
    '.email__td': {
      width: '30%',
    },
    '.modal__wrapper': {
      right: '10px !important',
    },
  })
}
