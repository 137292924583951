import * as React from 'react'
import { css } from '@emotion/react'
import { updateQueryParams } from '@/utils/updateQueryParams'

import { useRouter } from '@/react/hooks'
import { useAngularServices } from '@components/ReactAngularContext'
import { CurrentUserType } from '../types/documentation'

type Props = {
  filterHeader: boolean
  className: string
  name: string
  type?: string
  callback: any
  additionalStyles?: object
  additionalInnerElement?: object
  alternativePosition?: boolean
  updateUrl?: boolean
  orderFromState?: string
  setOrder?: any
  multiType?: string[]
}

export const SingleTableHeader: React.FC<Props> = ({
  filterHeader = true,
  className,
  name,
  type,
  callback,
  additionalStyles,
  additionalInnerElement = null,
  alternativePosition = false,
  updateUrl = true,
  orderFromState,
  setOrder,
  multiType,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const headerStyle = getHeaderStyle(variableColor)
  const arrowStyle = css({
    color: variableColor,
  })

  if (!filterHeader)
    return (
      <th css={[headerStyle, css(additionalStyles)]} className={className}>
        <div className="header-base non-filtered">
          <p className="non-filtered-p">{name}</p>
          {additionalInnerElement}
        </div>
      </th>
    )

  const { stateService } = useRouter()

  const order =
    (updateUrl ? stateService.params.order : orderFromState) || 'name'

  let arrow = <span style={{ height: 20, width: 10 }} />
  let orderRedirect = ''

  if (order === type) {
    arrow = (
      <span css={arrowStyle} className={'header-sort-arrow fa fa-angle-down'} />
    )
    orderRedirect = '-' + type
  } else if (order === '-' + type) {
    arrow = (
      <span css={arrowStyle} className={'header-sort-arrow fa fa-angle-up'} />
    )
    orderRedirect = type
  } else if (type) {
    orderRedirect = type
  }

  const reversedMultiType = multiType?.map((order) => '-' + order).join(',')

  if (order === multiType?.join(',')) {
    arrow = (
      <span css={arrowStyle} className={'header-sort-arrow fa fa-angle-down'} />
    )

    orderRedirect = reversedMultiType
  } else if (order === reversedMultiType) {
    arrow = (
      <span css={arrowStyle} className={'header-sort-arrow fa fa-angle-up'} />
    )
    orderRedirect = multiType?.join(',')
  } else if (multiType) {
    orderRedirect = multiType?.join(',')
  }

  function changeOrderRedirect() {
    if (updateUrl) {
      updateQueryParams({ order: orderRedirect }, true)
    } else {
      setOrder(orderRedirect)
    }
    callback(orderRedirect)
  }

  return (
    <th css={[headerStyle, css(additionalStyles)]} className={className}>
      <div className="header-base">
        {alternativePosition && additionalInnerElement}
        <p
          onClick={() => {
            changeOrderRedirect()
          }}
        >
          {name}
          {/* {arrow} */}
        </p>
        {!alternativePosition && additionalInnerElement}
      </div>
    </th>
  )
}

function getHeaderStyle(variableColor: string) {
  return css({
    height: '40px',
    paddingLeft: '5px',
    paddingRight: '7px',
    whiteSpace: 'normal',
    width: '20px',
    minWidth: '60px',
    textAlign: 'center',
    '.header-base': {
      width: '100%',
      display: 'flex',
      ':hover': {
        '.alert-button-opening': {
          '.line': {
            borderBottomStyle: 'solid',
            borderColor: variableColor,
            borderWidth: 2,
            marginTop: 3,
            marginBottom: 3,
          },
        },
      },
    },
    p: {
      cursor: 'pointer',
      marginBottom: '0px',
      display: 'flex',
      alignItems: 'center',
    },
    '.non-filtered': {
      display: 'flex',
      justifyContent: 'center',
      '.non-filtered-p': {
        width: 'auto',
        cursor: 'default',
      },
    },
    '.header-sort-arrow': {
      marginLeft: 10,
    },
  })
}
