import React, { useEffect, useRef, useState } from 'react'

export const FormikAutoExpandedTextArea = (formikProps) => {
  const [textareaHeight, setTextareaHeight] = useState('')
  const textAreaRef = useRef()

  const adjustTextareaHeight = () => {
    if (textAreaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textAreaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(`${rows * lineHeight}px`)
    }
  }

  useEffect(() => {
    adjustTextareaHeight()
  })
  return (
    <textarea
      {...formikProps}
      onInput={adjustTextareaHeight}
      ref={textAreaRef}
      style={{
        height: `${textareaHeight}`,
        resize: 'vertical',
        overflow: 'hidden',
      }}
      onFocus={adjustTextareaHeight}
      onScroll={adjustTextareaHeight}
      onMouseEnter={adjustTextareaHeight}
    />
  )
}
