import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { useState } from 'react'
import { DeleteElement } from './DeleteElement'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import classNames from 'classnames'
import { CurrentUserType } from '@/react/types/documentation'

interface Project {
  name: string
}
interface CostCode {
  is_active: boolean
  id: number
  name: string
  costcode_code: string
  project: Project
}
type Props = {
  rowData: CostCode
  fetchData: void
  index: number
  search: string
  page: number
}

export const TableRow: React.FC<Props> = (props) => {
  const { rowData, fetchData, index, search, page } = props
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [costCodeSwitch, setCostCodeSwitch] = useState(
    rowData.is_active || false,
  )
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const toggleCostCode = async (costCode: CostCode) => {
    setCostCodeSwitch(!costCode.is_active)

    await Api.patch(
      `cost_codes/${costCode.id}`,
      {
        is_active: !costCode.is_active,
      },
      (resp) => {
        costCode.is_active = resp.is_active
      },
    )
  }

  const deleteCostCode = async (costCode: CostCode) => {
    await Api.patch(
      `cost_codes/${costCode.id}`,
      {
        deleted: true,
      },
      (resp) => {
        if (resp) {
          fetchData()
        }
      },
    )
  }

  return (
    <tr
      className={classNames('table__row', {
        dark: index % 2 === 0,
      })}
    >
      <td className="textAlignLeft tablePadding">
        <div
          onClick={() =>
            stateService.go('admin.cost_codes_update', {
              id: rowData.id,
              previousSearch: search,
              previousPageNumber: page,
            })
          }
          className="link__td"
        >
          {rowData.name}
        </div>
      </td>
      <td>{rowData.costcode_code}</td>
      <td className="textAlignLeft">{rowData?.project?.name}</td>
      <td>
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={costCodeSwitch}
            onChange={() => {
              toggleCostCode(rowData)
            }}
          />
          <span className="checkmark">
            <TrainingsCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      <td>
        <DeleteElement
          id={rowData.id}
          deleteRequest={deleteCostCode.bind(null, rowData)}
          color={variableColor}
        />
      </td>
    </tr>
  )
}
