export const redirectLogic = async (
  CurrentUser,
  stateService,
  Api,
  setCanWeRender,
) => {
  const userData = CurrentUser.getInstance()

  if (userData.token) {
    setCanWeRender(false)
    const { data: user } = await Api.get('users/me', {})
    if (
      user.role === 'trainee_manager' ||
      user.role === 'trainee' ||
      user.role === 'read_only' ||
      user.role === 'client_user'
    ) {
      stateService.go('trainings.documentation', {})
    } else {
      if (user.client.risk_dashboard_is_active) {
        stateService.go('index.dashboard', {})
      } else if (user.role === 'supervisor') {
        const { data: apps } = await Api.Apps.get({ myapps: true })
        stateService.go('app.company_news.list', { app: apps.results[0].id })
      } else {
        stateService.go('admin.projects.list', {})
      }
    }
  } else {
    setCanWeRender(true)
  }
}

export const handleSubmit = async (
  stateService,
  Notification,
  Api,
  password,
  confirmPassword,
  setPasswordsEqual,
) => {
  const token = stateService.params.token
  Notification.clearNotification()
  try {
    if (stateService.current.name === 'invite') {
      await Api.put(`invites/${token}/`, {
        password,
        password_confirm: confirmPassword,
      })
    } else {
      await Api.post('users/reset_confirm', {
        password,
        confirm_password: confirmPassword,
        uid: token,
      })
    }
    delete stateService.params.token

    window.location.href = '/login'


  } catch {
    if (password !== confirmPassword) {
      setPasswordsEqual(false)
    }
    Notification.clearNotification()
  }
}

export const handleValidation = (inputValue: string) => {
  const lengthTest = inputValue.length >= 8
  const upperCaseTest = /[A-Z]/.test(inputValue)
  const lowerCaseTest = /[a-z]/.test(inputValue)
  const numericCharacterTest = /\d/.test(inputValue)
  const symbolsRegex = /[\/`|"!@#$%^&?*()~{}”';>.<,}\[:.+_=\\\-\\\]]/
  const specialCharacterTest = symbolsRegex.test(inputValue)

  return {
    lengthTest,
    upperCaseTest,
    lowerCaseTest,
    numericCharacterTest,
    specialCharacterTest,
  }
}
