import React, { useEffect } from 'react'
import { getTimeCardNavigationData } from '../components/commonVariables'
import { css } from '@emotion/react'
import {
  useAngularServices,
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
  TableHeaders,
  Search,
  PageSwitcher,
  AdvancedFilter,
  getFilterParam,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import pluralize from 'pluralize'
import { singleHeaderTypes } from '../../components'
import DailyReportTableRow from './components/DailyReportTableRow'
import { encodeString } from '@/utils/additionalEncodeLogic'
import { AdvancedDateFilter } from '@/react/components/AdvancedDateFilter'
import { useDateFilters } from '@/react/hooks/useDateFilters'

const PAGE_LENGTH = 20

export const DailyReports = () => {
  const { $rootScope, Api } = useAngularServices()
  const { stateService } = useRouter()
  const [dailyReportsData, setDailyReportsData] = React.useState([])
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const [count, setCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(stateService.params.order || '-date')
  const [advancedFilters, setAdvancedFilters] = React.useState(
    getFilterStates(stateService),
  )
  const [filteredDailyReportsData, setFilteredDailyReportsData] =
    React.useState([])

  const { dateFilters, handleStartDate, handleEndDate, filterByDates } =
    useDateFilters(['date'])

  // Add filtering effect
  useEffect(() => {
    const filtered = filterByDates(dailyReportsData)
    setFilteredDailyReportsData(filtered)
  }, [dailyReportsData, dateFilters])

  const app = $rootScope.mobile_apps.find((obj) => {
    return obj.id === Number(stateService.params.app)
  })
  const appProject: string = pluralize.singular(app.projects_display)
  const fitContent = {
    p: {
      width: 'fit-content',
    },
    '.header-base': {
      width: 'fit-content',
    },
  }
  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'date',
      name: 'Date',
      type: 'date',
      filterHeader: true,
      additionalStyles: {
        width: '10%',
        textAlign: 'left',
        p: {
          width: 'auto',
        },
      },
      additionalInnerElement: (
        <AdvancedDateFilter
          setStartDate={handleStartDate}
          setEndDate={handleEndDate}
          fieldName="date"
        />
      ),
    },
    {
      className: 'job',
      name: appProject || 'Job',
      type: 'job',
      filterHeader: true,
      additionalStyles: {
        width: '30%',
        textAlign: 'left',
        ...fitContent,
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'job'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'supervisor',
      name: 'Supervisor',
      type: 'user_full_name',
      filterHeader: true,
      additionalStyles: {
        width: '15%',
        textAlign: 'center',
        ...fitContent,
        '.header-base': {
          ...fitContent['header-base'],
          display: 'flex',
          justifyContent: 'center',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'total_workers',
      name: 'Workers',
      type: 'total_workers',
      filterHeader: true,
      additionalStyles: {
        width: '12.5%',
        textAlign: 'center',
      },
    },
    {
      className: 'total_hours',
      name: 'Total Hours',
      type: 'total_hours',
      filterHeader: true,
      additionalStyles: {
        width: '12.5%',
        textAlign: 'center',
      },
    },
    {
      className: 'view',
      name: 'View',
      filterHeader: false,
      additionalStyles: {
        width: '10%',
        textAlign: 'center',
      },
    },
    {
      className: 'pdf',
      name: 'PDF',
      filterHeader: false,
      additionalStyles: {
        width: '10%',
        textAlign: 'center',
      },
    },
  ]

  const fetchData = () => {
    const params = {
      page: page,
      application: stateService.params.app,
    }

    if (search) params.search = search
    if (order) params.order = order

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    Api.TimecardDailyReports.get(stringParams.slice(0, -1), function (resp) {
      if (resp.results) {
        setDailyReportsData(resp.results)
        setCount(resp.count)
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [page, search, order, advancedFilters])

  return (
    <section>
      <CommonPageHeader
        headerText={
          $rootScope.mobile_apps.filter((obj) => {
            return obj.id === Number(stateService.params.app)
          })[0].main_menu_timecard
        }
      />
      <FloatingWindowHorizontalNavigation
        navSettings={getTimeCardNavigationData('daily_reports')}
      />
      <main css={baseStyle}>
        <div className="search-base">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
          />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            popUpLocation={false}
            callback={setOrder}
          />
          <tbody>
            {filteredDailyReportsData.length
              ? filteredDailyReportsData.map((report, idx) => (
                  <DailyReportTableRow row={report} key={report.id} idx={idx} />
                ))
              : null}
          </tbody>
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pages={PAGE_LENGTH}
            listCount={
              filteredDailyReportsData.length < 20
                ? filteredDailyReportsData.length
                : count
            }
            currentPage={page}
            callback={setPage}
          />
        </div>
      </main>
    </section>
  )
}

function getFilterStates(stateService) {
  const filtersToReturn = {
    user_full_name: {},
    job: {},
  }
  if (stateService.params.user_full_name) {
    filtersToReturn.user_full_name = JSON.parse(
      stateService.params.user_full_name,
    )
  }
  if (stateService.params.job) {
    filtersToReturn.job = JSON.parse(stateService.params.job)
  }

  return filtersToReturn
}

const baseStyle = css({
  padding: '19px 23px',
  background: '#fff',
  '.search-base': {
    marginBottom: '19px',
  },
  table: {
    width: '100%',
    'thead:first-child': {
      borderBottom: '2px solid #E5E5E5',
      p: {
        margin: 0,
      },
    },
    tbody: {
      position: 'relative',
      tr: {
        height: '45px',
        td: {
          textAlign: 'center',
          padding: '0 5px',
          img: {
            height: '18px',
            cursor: 'pointer',
          },
        },
        '.textAlignLeft': {
          textAlign: 'left',
        },
      },
    },
  },
  '.page-switcher-base': {
    paddingBottom: '20px',
  },
})
