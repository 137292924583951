import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const DeletedObservationRow = ({
  observation,
  index,
  handleChecked,
  selectedIds,
  isAllChecked,
  search,
  page,
}) => {
  const [hoveredField, setHoveredField] = useState(null)
  const { CurrentUser } = useAngularServices()

  const { title, date_performed, user, project, date_created } = observation
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  return (
    <>
      <tr
        className={classNames('', {
          dark: index % 2 == 0,
        })}
      >
        <td className="checkmark__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(observation.id) || isAllChecked}
              onChange={() => {
                handleChecked(observation?.id)
              }}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'title__fullname',
              text: title,
              max__length: 43,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to={'app.observations.view'}
            params={{
              observation: observation.id,
              previousPageNumber: page,
              previousSearch: search,
            }}
          >
            <p className="title__text category">
              <Highlighter
                highlightClassName="highlighted__text"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={title}
              />
            </p>
          </UISref>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name__fullname',
              text: `${user.first_name} ${user.last_name}`,
              max__length: 21,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text name">
            <Highlighter
              highlightClassName="highlighted__text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={`${user.first_name} ${user.last_name}`}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: user?.company_name,
              max__length: 25,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text company">
            <Highlighter
              highlightClassName="highlighted__text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={user?.company_name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project?.name,
              max__length: 27,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text project">
            <Highlighter
              highlightClassName="highlighted__text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={project?.name}
            />
          </p>
        </td>
        <td>
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={
              date_performed
                ? moment(date_performed).format('MM/DD/YYYY')
                : moment(date_created).format('MM/DD/YYYY')
            }
          />
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
