import {
  CommonPageHeader,
  FloatingWindowHorizontalNavigation,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { getTimeCardNavigationData } from '@screens/Timecard/components/commonVariables'
import { css } from '@emotion/react'
import { ReloadableElements } from './components'

export const TimeAndMaterialsList = () => {
  const { $rootScope, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const role = CurrentUser.getRole()

  if (role !== 'client_admin' && role !== 'supervisor') {
    stateService.go('index.dashboard', {})
    return
  }

  const timeCardCheck = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].has_timecard

  if (!timeCardCheck) {
    stateService.go('index.dashboard', {})
    return null
  }

  return (
    <section>
      <CommonPageHeader headerText={'T&M'} />
      <FloatingWindowHorizontalNavigation
        navSettings={getTimeCardNavigationData('tam')}
      />
      <ReloadableElements />
      <div css={css({ height: 100 })} />
    </section>
  )
}
