import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { AssignSwitch } from '../components/AssignSwitch'
import { TemplateRow } from './components/TemplateRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { ToolboxTemplatesNavBar } from '../components/ToolboxTemplatesNavBar'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { CurrentUserType } from '@/react/types/documentation'

export const AssignProjectList = () => {
  const [search, setSearch] = useState('')
  const { stateService } = useRouter()
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [order, setOrder] = useState('name')
  const { Api, $rootScope, CurrentUser } = useAngularServices()
  const [templates, setTemplates] = useState([])
  const [count, setCount] = useState(20)

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'Templates',
      name: 'Templates',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '16px',
        width: '1200px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'Projects',
      name: configurableNamesList.projects_display,
      type: 'projects',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        width: '100px',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
  ]

  useEffect(() => {
    if (stateService.params.pageNumber) {
      setPage(+stateService.params.pageNumber)
    }
    if (stateService.params.search) {
      setSearch(stateService.params.search)
    }
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [stateService.params])

  useEffect(() => {
    const getTemplatesList = async () => {
      const { data } = await Api.get(
        'toolbox_topics/projects',
        {
          application: stateService.params.app,
          order,
          page,
          search,
          is_active: true,
        },
        () => {},
        () => {},
        true,
      )
      setTemplates(data.results)
      setCount(data.count)
      setRefresh(false)
    }

    getTemplatesList()
  }, [page, order, search, refresh])

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)

  return (
    <div css={baseStyle}>
      <CommonPageHeader
        headerText={`${configurableNamesList.toolbox}  Templates`}
      />
      <ToolboxTemplatesNavBar />
      <section className="templates__wrapper">
        <div className="top__wrapper">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
          />
          <AssignSwitch />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {templates.map((template, index) => (
            <TemplateRow
              key={template?.id}
              search={search}
              template={template}
              index={index}
              page={page}
            />
          ))}
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getBaseStyle(variableColor: string, isSafari: boolean) {
  return css({
    paddingBottom: '300px',
    '.templates__wrapper': {
      background: '#fff',
      paddingLeft: '23px',
      width: '834px',
      marginLeft: '5px',
    },
    '#search': {
      width: '264px',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    table: {
      width: '783px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.template__row': {
      height: '40px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.name__cell': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.projects__count': {
      margin: 0,
      width: '15px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
      whiteSpace: 'pre-wrap',
    },
    '@media(max-width: 1050px)': {
      '.top__wrapper': {
        flexDirection: 'column-reverse',
        gap: '17px',
      },
      '#search': {
        width: '264px',
      },
      '.templates__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__cell': {
        maxWidth: '204px',
      },
      '.full__name': {
        display: 'none',
      },
    },
  })
}
