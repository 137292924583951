import {
  CommonPageHeader,
  PageSwitcher,
  useAngularServices,
} from '@/react/components'
import { NavBar } from '../LMSCommonComponents/NavBar'
import { css } from '@emotion/react'
import { SingeTopic } from './components/SingleTopic'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { AddEditTopicModal } from './components/AddEditTopicModal'

export const TrainingTopics = () => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [training, setTraining] = useState({})
  const [topicsList, setTopicsList] = useState([])
  const [count, setCount] = useState(0)
  const [isAddModalVisible, setAddModalVisible] = useState(false)

  const [refresh, setRefresh] = useState(false)

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const baseStyle = getBaseStyle(variableColor)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const getTopicData = async () => {
      const { data: trainingResponse } = await Api.get(
        `trainings/${stateService.params.trainingID}`,
        {},
      )

      const { data: topicsResponse } = await Api.get('training_topics', {
        page: page,
        page_size: 20,
        training: stateService.params.trainingID,
        order: 'order',
      })

      setTraining(trainingResponse)
      setTopicsList(topicsResponse.results)
      setCount(topicsResponse.count)
      setRefresh(false)
    }

    getTopicData()
  }, [refresh])

  const maxTopicOrder = useMemo(() => {
    if (topicsList.length) {
      return topicsList[topicsList.length - 1]?.order + 1
    } else {
      return 1
    }
  }, [topicsList])

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="topics__wrapper">
        <div className="top__container">
          <h4 className="topic__title">{training?.name}</h4>
          <p
            className="add__button"
            onClick={() => {
              setAddModalVisible(true)
            }}
          >
            + Topic
          </p>
          {isAddModalVisible && (
            <AddEditTopicModal
              handleClose={() => {
                setAddModalVisible(false)
                setRefresh(true)
              }}
              maxTopicOrder={maxTopicOrder}
            />
          )}
        </div>
        <div className="topics__list">
          {topicsList.map((topic, index) => (
            <SingeTopic
              topic={topic}
              key={index}
              trainingId={training?.id}
              setRefresh={setRefresh}
              maxTopicOrder={maxTopicOrder}
            />
          ))}
        </div>
        <PageSwitcher
          pageLength={20}
          listCount={count}
          currentPage={page}
          callback={setPage}
          updateUrl={false}
        />
      </div>
    </section>
  )
}

const getBaseStyle = (variableColor) => {
  return css({
    paddingBottom: '300px',
    '.topics__wrapper': {
      background: '#fff',
      alignItems: 'center',
      width: '773px',
    },
    '.top__container': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      paddingTop: '15px',
      paddingLeft: '15px',
      paddingBottom: '15px',
    },
    '.topic__title': {
      fontSize: '12px',
      margin: 0,
      textOverflow: 'ellipsis',
      maxWidth: '690px',
    },
    '.add__button': {
      fontSize: '12px',
      color: '#3980CE',
      margin: 0,
      cursor: 'pointer',
      position: 'relative',
      width: 'fit-content',
    },
    '.topic': {
      position: 'relative',
      borderTop: '1px solid #EEEFF3',
      borderBottom: '1px solid #EEEFF3',
    },

    '.topic__info': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingInline: '15px',
      height: '45px',
    },
    '.training__title': {
      margin: 0,
      color: '#575757',
      maxWidth: '600px',
      paddingRight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.training__buttons': {
      display: 'flex',
      gap: '25px',
      alignItems: 'center',
    },
    '.dark': {
      background: '#FAFAFA',
      border: 'none',
    },
    '.hovered': {
      backgroundColor: variableColor,
      cursor: 'pointer',
      '.training__title': {
        color: 'white',
      },
      svg: {
        color: 'white',
      },
      img: {
        color: 'white',
      },
    },
    '.topic__content': {
      paddingLeft: '40px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      paddingBottom: '20px',
    },
    '.single__item': {
      display: 'flex',
      alignItems: 'center',
      width: '460px',
      justifyContent: 'space-between',
      cursor: 'pointer',
      position: 'relative',
    },
    '.item__info': {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
    },
    '.item__title': {
      color: '#3980CE',
      margin: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '400px',
    },
    svg: {
      cursor: 'pointer',
    },
    '@media(max-width: 1024px)': {
      '.topics__wrapper': {
        width: '550px',
      },
      '.topic__title': {
        maxWidth: '460px',
        maxHeight: '27px',
      },
    },
  })
}
