import * as React from 'react'
import { SingleVariableAmountField } from './SingleVariableAmountField'
import { css } from '@emotion/react'
import moment from 'moment'
import { useAngularServices } from '@/react/components'

type Props = {
  nameOfField: string
  typeOfField: string
  answersLink: object[]
  startWithEmptyField?: boolean
  wholeAnswerLink?: any
  setWholeAnswerLink?: () => void
  maxLength?: number
  uniqModalId?: string
  width?: string
}

export function FieldsWithVariableAmount({
  nameOfField,
  typeOfField,
  answersLink,
  startWithEmptyField,
  wholeAnswerLink,
  setWholeAnswerLink,
  maxLength,
  uniqModalId,
  width = '30%',
}: Props) {
  const [inputList, setInputList] = React.useState([])

  const { CurrentUser } = useAngularServices()
  const user = CurrentUser.getInstance()

  if (wholeAnswerLink)
    wholeAnswerLink.corrective_actions_error_ref = React.useRef()

  function addField(typeOfField, link) {
    setInputList([
      ...inputList,
      <SingleVariableAmountField
        key={
          wholeAnswerLink.question +
          '-' +
          wholeAnswerLink.category +
          '-' +
          inputList.length
        }
        wholeAnswerLink={wholeAnswerLink}
        setWholeAnswerLink={setWholeAnswerLink}
        uniqModalId={uniqModalId}
        typeOfField={typeOfField}
        fieldId={inputList.length}
        maxLength={maxLength}
        answersLink={link}
        errorRef={
          wholeAnswerLink ? wholeAnswerLink.corrective_actions_error_ref : null
        }
        isIssueResolved={startWithEmptyField}
      />,
    ])
    link.push({
      text: '',
      fieldmeta: {
        text: {
          last_updated: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          last_updated_by_user_id: user.id,
        },
      },
      deleted: false,
    })
  }

  React.useEffect(() => {
    const initialFields = []
    answersLink?.forEach((initial, idx) => {
      if (initial.deteled) return
      answersLink[idx] = {
        id: initial.id,
        text: initial.text,
        deleted: false,
      }
      initialFields.push(
        <SingleVariableAmountField
          key={
            wholeAnswerLink.question +
            '-' +
            wholeAnswerLink.category +
            '-' +
            idx
          }
          wholeAnswerLink={wholeAnswerLink}
          setWholeAnswerLink={setWholeAnswerLink}
          typeOfField={typeOfField}
          fieldId={idx}
          uniqModalId={uniqModalId}
          answersLink={answersLink}
          maxLength={maxLength}
          errorRef={
            wholeAnswerLink
              ? wholeAnswerLink.corrective_actions_error_ref
              : null
          }
          isIssueResolved={startWithEmptyField}
        />,
      )
    })
    if (!initialFields.length) {
      answersLink[0] = {
        text: '',
        deleted: false,
      }
      setInputList([
        <SingleVariableAmountField
          key={
            wholeAnswerLink.question + '-' + wholeAnswerLink.category + '-' + 0
          }
          wholeAnswerLink={wholeAnswerLink}
          setWholeAnswerLink={setWholeAnswerLink}
          uniqModalId={uniqModalId}
          typeOfField={typeOfField}
          fieldId={0}
          maxLength={maxLength}
          answersLink={answersLink}
          errorRef={
            wholeAnswerLink
              ? wholeAnswerLink.corrective_actions_error_ref
              : null
          }
          isIssueResolved={startWithEmptyField}
        />,
      ])
    } else {
      setInputList(initialFields)
    }
  }, [answersLink])

  const cssParams = startWithEmptyField
    ? css({
        '.corrective_action:first-child:last-child .beside-input-button': {
          display: 'none',
        },
      })
    : css({
        marginTop: 6,
      })

  return (
    <div css={baseStyle(width)}>
      <b>{nameOfField}:</b>
      <a
        className={'ccs-link'}
        onClick={() => {
          addField(typeOfField, answersLink)
        }}
      >
        {' + '}
        {nameOfField}
      </a>
      <div css={cssParams}>{inputList}</div>
      {startWithEmptyField ? (
        <div
          className="attention-text"
          ref={wholeAnswerLink.corrective_actions_error_ref}
        >
          Please add a {nameOfField.toLowerCase()} to resolved issues
        </div>
      ) : null}
    </div>
  )
}

const baseStyle = (width: string) =>
  css({
    width,
    marginRight: '2%',
    b: {
      marginRight: '15px',
    },
    '.attention-text': {
      color: 'red',
      display: 'none',
      fontStyle: 'italic',
    },
  })
