import arrowUp from '@/assets/icons/arrow-up.svg'
import arrowDown from '@/assets/icons/arrow-down.svg'
import { VideoIcon } from '@/react/componentAssets/VideoIcon'
import { PdfTrainingsIcon } from '@/react/componentAssets/PdfTrainingsIcon'
import { PptIcon } from '@/react/componentAssets/PptIcon'
import checkMark from '@/assets/icons/checkmark-icon.svg'
import { QuizIcon } from '@/react/componentAssets/QuizIcon'
import { css } from '@emotion/react'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import { ButtonElement, useAngularServices } from '@/react/components'
import { useEffect, useRef, useState } from 'react'
import { DefaultTrainingIcon } from '@/react/componentAssets/DefaultTrainingIcon'
import closeIcon from '@/assets/icons/close-icon.svg'
import React from 'react'
import { HandsOnModalIcon } from '@/react/componentAssets/HandsOnModalIcon'
import { useRouter } from '@/react/hooks'

export const TopicElement = ({ topic, jobId, setRefresh }) => {
  const [isSectionOpen, setSectionOpen] = useState(false)
  const [completeCode, setCompleteCode] = useState('')
  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  const { content_items } = topic
  const [completeCodeError, setCompleteCodeError] = useState(false)
  const { stateService } = useRouter()
  const [quizVisible, setQuizVisible] = useState(false)
  const { CurrentUser, Api, Notification } = useAngularServices()

  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(completeCode)

  const modalRef = useRef()

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setCompleteModalOpen(false)
    }
  }

  useEffect(() => {
    if (completeModalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [completeModalOpen])

  const handleCompleteCode = async (id) => {
    try {
      const { data: codeResponse } = await Api.get(`code/${id}`, {
        code: completeCode,
      })
      await Api.patch(`jobs/${jobId}`, {
        content_completed: codeResponse?.id,
        user_filter: true,
      })
      setCompleteModalOpen(false)
    } catch {
      Notification.clearNotification()
      setCompleteCodeError(true)
    }
    setRefresh(true)
    handleQuizVisible()
  }

  const handleQuizVisible = () => {
    const topicItems = topic.content_items
      .slice(0, -1)
      .filter((item) => item.quiz_completed === true)

    if (topic.content_items.length - 1 === topicItems.length) {
      return setQuizVisible(true)
    }

    return setQuizVisible(false)
  }

  useEffect(() => {
    const { content_items } = topic
    const attachmentId = +stateService.params.search

    if (content_items.some((item) => item.id === attachmentId)) {
      setSectionOpen(true)
    }

    handleQuizVisible()
  }, [topic])

  return (
    <div css={baseStyle}>
      <div className="whole__wrapper">
        <div
          className="table__element"
          onClick={() => {
            setSectionOpen(!isSectionOpen)
          }}
        >
          <p className="element__title">{topic.name}</p>
          <div className="left__block">
            <div className="percentage">{`${topic.status}%`}</div>
            <img
              className="icon"
              src={isSectionOpen ? arrowUp : arrowDown}
              alt="view icon"
            />
          </div>
        </div>
        {isSectionOpen &&
          content_items.map((item: any, index: number) => {
            if (item.content_type === 'ho') {
              return (
                <>
                  <div
                    className="extra__info"
                    onClick={() => {
                      setCompleteModalOpen(true)
                      setCompleteCode('')
                    }}
                  >
                    <div className="info__element">
                      {item.quiz_completed && (
                        <img src={checkMark} alt="checkmark" />
                      )}
                      <DefaultTrainingIcon color={variableColor} />
                      <p className="element__text">{item.name}</p>
                    </div>
                  </div>
                  {completeModalOpen && (
                    <div className="complete__overlay">
                      <div className="complete__modal" ref={modalRef}>
                        <img
                          className="close__icon"
                          src={closeIcon}
                          alt=""
                          onClick={() => {
                            setCompleteModalOpen(false)
                          }}
                        />
                        <div className="modal__title">
                          <HandsOnModalIcon color={variableColor} />
                          <p className="modal__text">{item.name}</p>
                        </div>
                        <div className="modal__body">
                          <p className="modal__subtitle">{item.description}</p>
                          {item.quiz_completed ? (
                            <></>
                          ) : (
                            <>
                              <div className="input__block">
                                <p className="input__title">Completion Code*</p>
                                <input
                                  type="text"
                                  className={classNames('input', {
                                    error: completeCodeError,
                                  })}
                                  value={completeCode}
                                  onChange={(e) => {
                                    setCompleteCode(e.target.value)
                                    setCompleteCodeError(false)
                                  }}
                                />
                                {completeCodeError && (
                                  <p className="modal_error">
                                    Incorrect Code. Try again.
                                  </p>
                                )}
                              </div>
                              <ButtonElement
                                text="Submit"
                                buttonType="submit"
                                additionalStyles={css({
                                  alignSelf: 'center',
                                })}
                                disabled={!completeCode || item.quiz_completed}
                                functionToTrigger={() => {
                                  handleCompleteCode(item.id)
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            }

            return (
              <>
                {item.content_type === 'quiz' ? (
                  <>
                    {!quizVisible ? (
                      <div className="extra__info">
                        <div className="info__element">
                          {item.quiz_completed && (
                            <img src={checkMark} alt="checkmark" />
                          )}
                          <QuizIcon color={'#575757'} />
                          <p className="element__text">{item.name}</p>
                          <div className="hover__message">
                            <p className="hover__text">
                              All Topic Contents must be completed before taking
                              quiz.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <UISref
                        key={item.id}
                        to={'trainings.trainee_quiz'}
                        params={{
                          jobId: jobId,
                          programId: item.id,
                        }}
                      >
                        <div className="extra__info">
                          <div className="info__element">
                            {item.quiz_completed && (
                              <img src={checkMark} alt="checkmark" />
                            )}
                            <QuizIcon color={variableColor} />
                            <p className="element__text">{item.name}</p>
                          </div>
                          {item.attempts > 0 ? (
                            <div className="quiz__info">
                              <div className="quiz__results">
                                Quiz result:{' '}
                                <p
                                  className={classNames('quiz__results', {
                                    red:
                                      item.quiz_status <
                                      item.quiz_passing_score,
                                    green:
                                      item.quiz_status >=
                                      item.quiz_passing_score,
                                  })}
                                >
                                  {` ${item.quiz_status}%`}
                                </p>
                              </div>
                              <div className="quiz__attempts">
                                Attempts: {item.attempts}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </UISref>
                    )}
                  </>
                ) : (
                  <UISref
                    key={item.id}
                    to={'trainings.preview_content'}
                    params={{
                      jobId: jobId,
                      programId: item.id,
                    }}
                  >
                    <div className="extra__info">
                      <div className="info__element">
                        {item.quiz_completed && (
                          <img src={checkMark} alt="checkmark" />
                        )}
                        {(() => {
                          switch (item.content_type) {
                            case 'video':
                              return <VideoIcon color={variableColor} />
                            case 'pdf':
                              return <PdfTrainingsIcon color={variableColor} />
                            case 'ppt':
                              return <PptIcon color={variableColor} />
                            default:
                              return (
                                <DefaultTrainingIcon color={variableColor} />
                              )
                          }
                        })()}
                        <p className="element__text">{item.name}</p>
                      </div>
                      {item.content_type === 'quiz' && item.quiz_status > 0 && (
                        <div className="quiz__info">
                          <div className="quiz__results">
                            Quiz result:{' '}
                            <p
                              className={classNames('quiz__results', {
                                red: item.quiz_status < 80,
                                green: item.quiz_status > 80,
                              })}
                            >
                              {` ${item.quiz_status}%`}
                            </p>
                          </div>
                          <div className="quiz__attempts">
                            Attempts: {item.attempts}
                          </div>
                        </div>
                      )}
                    </div>
                  </UISref>
                )}
              </>
            )
          })}
      </div>
    </div>
  )
}

function getBaseStyle(completeCode) {
  return css({
    '@media(max-width: 800px)': {
      '.table__element': {
        gap: '0px !important',
      },
      '.left__block': {
        gap: '15px !important',
      },
    },
    '.whole__wrapper': {
      borderStyle: 'solid none',
      borderColor: '#EEEFF3',
      borderWidth: '1px',
    },
    '.table__element': {
      display: 'flex',
      gap: '100px',
      height: '60px',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      border: 'none',
    },
    '.left__block': {
      display: 'flex',
      gap: '60px',
      marginRight: '25px',
    },
    '.element__title': {
      color: '#68696D',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      letterSspacing: '0.12px',
      marginLeft: '12px',
      marginBottom: 0,
    },
    '.percentage': {
      color: '#000',
      textAlign: 'center',
      fontSize: '12px',
      marginLeft: '55px',
    },
    '.icon': {
      cursor: 'pointer',
    },
    '.extra__info': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0px',
      marginLeft: '28px',
      cursor: 'pointer',
      marginBottom: '15px',
    },
    '.info__element': {
      display: 'flex',
      gap: '12px',
      margin: 0,
      svg: {
        marginTop: '2px',
      },
    },
    '.quiz__info': {
      marginLeft: '30px',
    },
    '.quiz__results': {
      display: 'flex',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    '.red': {
      color: '#C80404',
      whiteSpace: 'pre',
      border: 'none',
    },
    '.green': {
      color: '#19A503',
      whiteSpace: 'pre',
      border: 'none',
    },
    '.quiz__attempts': {
      color: '#888',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    '.element__text': {
      color: '#575757',
      fontSize: '12px',
      margin: 0,
    },
    '.complete__overlay': {
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      left: '0px',
      top: '0px',
      background: 'rgba(87, 87, 87, 0.25)',
      zIndex: 100,
    },
    '.complete__modal': {
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      width: '354px',
      position: 'absolute',
      boxShadow: ' 0px 2px 7px 0px rgba(0, 0, 0, 0.25)',
      alignItems: 'center',
      zIndex: 100000,
      left: '50%',
      top: '42%',
      transform: 'translate(-50%,-50%)',
    },
    '.modal__title': {
      display: 'flex',
      alignContent: 'center',
      gap: '7px',
      padding: '9px 35px 0px 35px',
    },
    '.modal_error': {
      margin: 0,
      color: ' #C80404',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.08px',
    },
    '.modal__image': {
      height: '16px',
      width: '16px',
    },
    '.modal__text': {
      color: '#68696D',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    '.close__icon': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginTop: '10px',
      marginRight: '12px',
      cursor: 'pointer',
    },
    '.modal__subtitle': {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      display: 'flex',
      alignSelf: 'flex-start',
      marginTop: '7px',
    },
    '.modal__body': {
      display: 'flex',
      gap: '17px',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      padding: '0px 35px 0px 35px',

      button: {
        marginBottom: '17px',
      },

      'button[disabled]': {
        background: '#888 !important',
        color: '#fff !important',
      },
    },
    '.input__block': {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    '.input__title': {
      color: '#68696D',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
      margin: 0,
    },
    '.input': {
      width: '284px',
      height: '36px',
      border: !completeCode ? '0.5px solid #C80404' : '1px solid #D3D3D3',
      paddingLeft: '13px',
      background: '#fff',
      outline: 'none',
    },
    '.error': {
      border: '0.5px solid #C80404',
    },
    '.info__element:last-child': {
      position: 'relative',
      '&:hover': {
        '.hover__message': {
          display: 'block',
        },
      },
    },
    '.hover__message': {
      height: '24px',
      width: '329px',
      background: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      marginLeft: '5px',
      display: 'none',
      position: 'absolute',
      left: 0,
      top: '20px',
    },
    '.hover__text': {
      color: '#68696D',
      margin: 0,
      padding: '4px',

      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
  })
}
