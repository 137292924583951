import { ViewIcon } from '@/react/componentAssets'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import { CurrentUserType } from '@/react/types/documentation'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo, useState } from 'react'

export const DeletedRow = ({ report, index, handleChecked, selectedItems }) => {
  const { form, date_created, fields, project, user, external_contacts } =
    report
  const { CurrentUser } = useAngularServices()
  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const { first_name, last_name, company_name } = user
  const [hoveredField, setHoveredField] = useState(null)

  const selectedIds = useMemo(() => {
    return selectedItems.map((el) => el.id)
  }, [selectedItems])

  return (
    <>
      <tr
        className={classNames('', {
          dark: index % 2 == 0,
        })}
      >
        <td className="checkmark__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(report.id)}
              onChange={() => {
                handleChecked(report)
              }}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'title__fullname',
              text: fields[0].text || form.name,
              max__length: 38,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to={'app.forms.reports_view'}
            params={{
              report: report.id,
            }}
          >
            <p className="title__text title">{fields[0].text || form.name}</p>
          </UISref>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'template__fullname',
              text: form.name,
              max__length: 31,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text form">{form.name}</p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name__fullname',
              text: external_contacts[0]?.external_contact
                ? `${external_contacts[0].external_contact.first_name} ${external_contacts[0].external_contact.last_name}`
                : `${first_name} ${last_name}`,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text name">
            {external_contacts[0]?.external_contact
              ? `${external_contacts[0]?.external_contact.first_name} ${external_contacts[0]?.external_contact.last_name}`
              : `${first_name} ${last_name}`}
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: external_contacts[0]?.external_contact
                ? external_contacts[0].external_contact.company_name
                : company_name,
              max__length: 35,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text company">
            {external_contacts[0]?.external_contact
              ? external_contacts[0]?.external_contact.company_name
              : company_name}
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project.name,
              max__length: 14,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="title__text project">{project.name}</p>
        </td>
        <td>{moment(date_created).format('MM/DD/YYYY')}</td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
