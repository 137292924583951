import { css } from '@emotion/react'
import moment from 'moment'
import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { useAngularServices } from './ReactAngularContext'
import { FilterIcon } from '../componentAssets'
import { ButtonElement } from './ButtonElement'
import { AdvancedDateFilterProps } from '@/react/types/AdvancedDateFilter/filter'
import { CurrentUserType } from '../types/documentation'

export const AdvancedDateFilter = ({
  setStartDate,
  setEndDate,
  isTrainedFilterApplied,
  isExpiringFilterApplied,
  setIsFilterApplied,
  setAllChecked,
  setPage,
  dateField,
  setDateField,
  dateFieldName,
}: AdvancedDateFilterProps) => {
  const { CurrentUser } = useAngularServices()
  const [isCalendarVisible, setCalendarVisible] = useState(false)
  const [isDateExplicitlyApplied, setIsDateExplicitlyApplied] = useState(false)

  const getTodayMidnight = () => moment().startOf('day').toDate()

  const [tempRange, setTempRange] = useState([
    {
      startDate: getTodayMidnight(),
      endDate: getTodayMidnight(),
      key: 'selection',
    },
  ])
  const [appliedRange, setAppliedRange] = useState([
    {
      startDate: getTodayMidnight(),
      endDate: getTodayMidnight(),
      key: 'selection',
    },
  ])

  const handleDateSelection = (date) => {
    const { selection } = date
    setTempRange([selection])
    setIsDateExplicitlyApplied(false)
    setIsFilterApplied(false)
    setAllChecked(false)
  }

  const handleApply = () => {
    const newStartDate = moment(tempRange[0].startDate).format('YYYY-MM-DD')
    const newEndDate = moment(tempRange[0].endDate).format('YYYY-MM-DD')

    setStartDate(newStartDate)
    setEndDate(newEndDate)

    if (setDateField) setDateField(dateFieldName)
    setAppliedRange(tempRange)
    setIsDateExplicitlyApplied(true)
    setCalendarVisible(false)
    setIsFilterApplied(true)
    setAllChecked(false)
    setPage(1)
  }

  const handleClear = () => {
    const initialRange = [
      {
        startDate: getTodayMidnight(),
        endDate: getTodayMidnight(),
        key: 'selection',
      },
    ]
    setTempRange(initialRange)
    setAppliedRange(initialRange)
    setStartDate('')
    setEndDate('')
    setCalendarVisible(false)
    setIsDateExplicitlyApplied(false)
    setIsFilterApplied(false)

    if (dateFieldName === 'date_trained') {
      setIsFilterApplied(false)
      if (!isExpiringFilterApplied) {
        setDateField('')
      } else {
        setDateField('date_training_expires')
      }
    } else if (dateFieldName === 'date_training_expires') {
      setIsFilterApplied(false)
      if (!isTrainedFilterApplied) {
        setDateField('')
      } else {
        setDateField('date_trained')
      }
    }

    setAllChecked(false)
  }

  const variableColor = (CurrentUser as CurrentUserType).getClientSettings()
    .web_primary_color
  const baseStyle = getBaseStyle(variableColor)

  const isDateFiltered = () => {
    return (
      (isDateExplicitlyApplied && dateField === dateFieldName) ||
      !moment(appliedRange[0]?.startDate).isSame(getTodayMidnight(), 'day') ||
      !moment(appliedRange[0]?.endDate).isSame(getTodayMidnight(), 'day') ||
      !moment(appliedRange[0]?.startDate).isSame(
        appliedRange[0]?.endDate,
        'day',
      )
    )
  }

  return (
    <div css={baseStyle}>
      <div
        className="alert-button-opening"
        onClick={() => {
          setCalendarVisible(!isCalendarVisible)
        }}
      >
        {!isDateFiltered() ? (
          [
            <div key="1-line" className="line" />,
            <div key="2-line" className="line" />,
            <div key="3-line" className="line" />,
          ]
        ) : (
          <div className="filtered-icon">
            <FilterIcon height="16px" width="16px" color={variableColor} />
          </div>
        )}
      </div>
      {isCalendarVisible && (
        <div className="date__wrapper">
          <DateRangePicker
            onChange={(item) => {
              handleDateSelection(item)
            }}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={tempRange}
            direction="horizontal"
            color={variableColor}
            rangeColors={[variableColor]}
          />
          <div className="buttons__wrapper">
            <ButtonElement text="Clear" functionToTrigger={handleClear} />
            <ButtonElement
              text="Apply"
              buttonType="submit"
              functionToTrigger={handleApply}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    position: 'relative',
    '.alert-button-opening': {
      paddingTop: 1,
      marginLeft: '5px !important',
      cursor: 'pointer',
      '.line': {
        width: 16,
        transition: '500ms',
      },
      '.filtered-icon': {
        width: 16,
        img: {
          width: 16,
        },
      },
    },
    '.date__wrapper': {
      position: 'absolute',
      right: '-105px',
      top: '20px',
      zIndex: 1000,
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
    },
    '.rdrDefinedRangesWrapper': {
      display: 'none',
    },
    '.rdrStaticRangeSelected': {
      color: `${variableColor} !important`,
    },
    '.rdrDateDisplay': {
      color: `${variableColor} !important`,
    },
    '.rdrDateDisplayWrapper': {
      background: '#fff',
    },
    '.buttons__wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#fff',
      padding: '10px',
    },
  })
